<template>
    <div style="padding: 20px;">
        <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -15px;">
            <tr>
                <td class="col1">监考开始时间</td>
                <td class="col2">2022-04-17 18:00:00</td>
            </tr>
            <tr>
                <td class="col1">监考结束时间</td>
                <td class="col2">2022-04-17 20:00:00</td>
            </tr>
            <tr>
                <td class="col1">考场</td>
                <td class="col2">1412考场</td>
            </tr>
            <tr>
                <td class="col1">考生</td>
                <td class="col2">12人</td>
            </tr>
            <tr>
                <td class="col1">考生登录</td>
                <td class="col2"></td>
            </tr>
        </table>

        <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -25px;">
            <tr>
                <td class="col1" style="font-weight: normal; font-size: 14px;">小程序双机位监考</td>
                <td class="col2" style="font-weight: normal; color: #BABABA; font-size: 14px;">
                    考生通过考生入口中的考试码使用微信扫码登录，将微信小程序作为第二机位，全程摄像监考
                </td>
            </tr>
        </table>

        <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -15px;">
            <tr>
                <td class="col1">监考手段</td>
                <td class="col2"></td>
            </tr>
        </table>

        <table style="width: 100%; -webkit-border-vertical-spacing: 5px; margin-top: -15px;">
            <tr>
                <td class="col1" style="font-weight: normal; font-size: 14px;">独占模式</td>
                <td class="col2" style="font-weight: normal; color: #BABABA; font-size: 14px;">
                    独占模式下客户端程序将会占据整个屏幕，防止考生使用其他程序，一旦不在最前会报警
                </td>
            </tr>
            <tr>
                <td class="col1" style="font-weight: normal; font-size: 14px;">弹窗通知</td>
                <td class="col2" style="font-weight: normal; color: #BABABA; font-size: 14px;">
                    监考员可向单个考生/整个考场/整个考试发送客户端弹窗通知消息
                </td>
            </tr>
        </table>

        <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -5px;">
            <tr>
                <td class="col1">浏览器防作弊</td>
                <td class="col2"></td>
            </tr>
        </table>

        <table style="width: 100%; -webkit-border-vertical-spacing: 5px; margin-top: -15px;">
            <tr>
                <td class="col1" style="font-weight: normal; font-size: 14px;">自动收卷</td>
                <td class="col2" style="font-weight: normal; color: #BABABA; font-size: 14px;">
                    考生离开当前页面3次， 自动收卷
                </td>
            </tr>
        </table>

        <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -10px;">
            <tr>
                <td class="col1">考试事项</td>
                <td class="col2"></td>
            </tr>
        </table>

        <div class="warning">
            <div style="font-weight: bold;">1. 本场考试为开卷考试，考生可自行携带需要的纸质参考资料与教材。</div>
        </div>

        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 15px;">
            <div><input type="checkbox"></div>
            <div>作为监考老师，我已明确本场考试的注意事项</div>
        </div>

        <div style="width: 100%; margin-top: 20px;" @click="$router.push('/ois/dashboard?eId=8dc499e7-7a3c-43a5-a6be-b210c734720f&eTitle=医学统计学期末考试');">
            <buttons style="margin-left: auto;" content="开始监考" btn-color="#054FBF" txt-color="white"
                     :w="150"
                     :h="50"></buttons>
        </div>
    </div>
</template>

<script>
import Buttons from "../../../../components/Buttons";

export default {
    name: "Abstract",
    components: {Buttons},
}
</script>

<style scoped>
.col1 {
    width: 15%;
    font-weight: bold;
    vertical-align: top;
    text-align: left;
}

.col2 {
    width: 85%;
    font-weight: bold;
    vertical-align: top;
    text-align: left;
}

.warning {
    border-radius: 10px;
    width: 100%;
    min-height: 45px;
    background-color: #EFEFEF;
    margin-top: -10px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}
</style>
