<template>
    <div id="task">
        <div id="header">
            <div class="card-title">课程任务</div>
            <div id="sc">
                <Search :name="'搜索章节'"></Search>
            </div>
        </div>
        <div id="body">
            <img style="margin-left:calc(50% - 300px);top: calc(50% - 300px)" src="../../assets/common/empty.svg">
        </div>
    </div>
</template>

<script>
import Search from "../../components/common/Search";
export default {
    name: "Task",
    components: {Search}
}
</script>

<style scoped>
#task{
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
#sc{
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}
#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}
</style>
