<template>
  <div id="myQuestionBank">
    <div><!--navbar in card-->
      <div style="display: flex; flex-direction: row; align-items: center;">
        <div class="card-title">{{ $route.query.libTitle }}</div><!--title-->
        <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
          <!--new exam button-->
<!--          <div @click="back()">-->
<!--            <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"-->
<!--                     btn-color="#054FBF"-->
<!--                     txt-color="white"-->
<!--                     content="返回"-->
<!--                     :h="30"-->
<!--                     :w="80"-->
<!--                     :f-size="5"></buttons>-->
<!--          </div>-->
          <div @click="clickToPushArg('/funcCenter/problemSet/questions/newQuestion', $route.query.libId,
                    $route.query.libTitle);">
            <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"
                     btn-color="#054FBF"
                     txt-color="white"
                     content="+ 添加题目"
                     :h="30"
                     :w="80"
                     :f-size="5"></buttons>
          </div>

          <div style="display: flex; flex-direction: row; align-items: center;">
            <div style="position:relative;">
              <Search></Search>
              <!--                            <input v-model="kw" type="text" placeholder="搜索题目..."-->
              <!--                                   style="height: 30px; border-radius: 50px; border: none; background-color: #EFEFEF; text-indent: 10px;">-->
            </div>

            <!--                        <div class="search-qslib" style="position: absolute; z-index: 20; right: 0; margin-right: 65px; margin-top: 5px;">-->
            <!--                            &lt;!&ndash;search icon&ndash;&gt;-->
            <!--                            <svg xmlns="http://www.w3.org/2000/svg" width="15.043" height="15.267"-->
            <!--                                 viewBox="0 0 15.043 15.267">-->
            <!--                                <g id="组_44" data-name="组 44" transform="translate(-1621 -119)">-->
            <!--                                    <g id="椭圆_8" data-name="椭圆 8" transform="translate(1621 119)" fill="none"-->
            <!--                                       stroke="#606060" stroke-width="1.5">-->
            <!--                                        <circle cx="5.75" cy="5.75" r="5.75" stroke="none"/>-->
            <!--                                        <circle cx="5.75" cy="5.75" r="5" fill="none"/>-->
            <!--                                    </g>-->
            <!--                                    <line id="直线_8" data-name="直线 8" x2="5" y2="5.25"-->
            <!--                                          transform="translate(1630.5 128.5)" fill="none" stroke="#606060"-->
            <!--                                          stroke-width="1.5"/>-->
            <!--                                </g>-->
            <!--                            </svg>-->
            <!--                        </div>-->
          </div>

        </div>
      </div>
      <div class="line"></div><!--line-->
    </div>

    <transition name="slide-fade">
      <div v-show="true" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
        <!--content of "题目"-->
        <lite-list :data-object="dataObject" :key-word="kw" :num-per-page="20"></lite-list>
      </div>
    </transition>
  </div>
</template>

<script>
import LiteList from "../components/LiteList";
import MainSideBar from "../components/SideBar/MainSideBar";
import Buttons from "../components/Buttons";
import Search from "../components/common/Search";
export default {
  name: "MyQuestionBank",
  components: {
    Search,
    LiteList,
    Buttons,
    MainSideBar
  }, data() {
    return {
      kw: '',

      dataObject: [
        // {
        //     "qId": "f6bfeb6d-bf38-4d0e-bf30-e88223535fe4",
        //     "title": "痊愈、显效、好转、无效属于",
        //     "tp": "选择题",
        //     "creator": "李兰辰",
        //     "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
        //     "createTime": "2022年1月2日 10:00",
        // },
        {
          "qId": "526fafcd-c620-4781-91ca-92804cd8d1f0",
          "title": "某市250名8岁男孩体重有95%的人在18-30kg范围内...",
          "creator": "李兰辰",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "76321b3d-6749-4b97-9a2e-9f7b90826481",
          "title": "单因素方差分析中，造成各组均数不等的原因有",
          "creator": "李兰辰",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        // {
        //     "qId": "0374a321-eebe-4682-aa4d-d6d88da6ca12",
        //     "title": "已知一组数字，它的平均值是3.14，而它的中位数也是3.14，那么…",
        //     "creator": "检验讲师SS",
        //     "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
        //     "createTime": "2022年1月2日 10:00",
        //     "tp": "选择题",
        // },
        {
          "qId": "b1890d8e-44a6-4265-b934-11dea5bc973e",
          "title": "吸烟的人中，有68%的几率会得肺癌，而有10%的几率会得胃癌…",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "3d6f8a36-9ab2-49c6-9bdd-dfbb607fe096",
          "title": "已知，抛一枚硬币，正面向上的概率是1/2，请问同时抛掷4枚硬…",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "dfde6a70-13c4-4cc4-8933-a19a8bf34c51",
          "title": "正态分布的特征是？",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "dc552f79-ee48-4da7-aab0-7e564511bfaf",
          "title": "下列情况中，哪个是偏态分布？",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "fab2e739-0e9b-478e-a8ed-296b9639ebcd",
          "title": "T检验的要求是？",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "f15ef97f-3bf4-40aa-9e70-f2ebc3a16de2",
          "title": "卡方检验的步骤是？",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "78ab5a71-1f6c-4b5b-8257-19a2e635b8cb",
          "title": "活着飞回美军航母上的战斗机，其机翼、护甲部分弹孔分布密集…",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        {
          "qId": "5db9acc3-5e20-4572-bbf1-130f6214e6c7",
          "title": "第二次世界大战中，情报战作为破译敌军机密信息，掌握战场主…",
          "creator": "检验讲师SS",
          "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
          "createTime": "2022年1月2日 10:00",
          "tp": "选择题",
        },
        // {
        //     "qId": "f798db39-7a77-4eec-8ce9-4be1fd06c13c",
        //     "title": "关于医学统计学，下列说法正确的是？",
        //     "creator": "检验讲师SS",
        //     "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
        //     "createTime": "2022年1月2日 10:00",
        //     "tp": "选择题",
        // },
      ],
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    switchTabs(position) {
      /**
       * switch tabs
       */
      this.$data.isShow.forEach((item, index) => {
        if (this.$data.isShow[index]) this.$set(this.$data.isShow, index, false);
      });
      this.$set(this.$data.isShow, position, true);
    },
    clickToPush(url) {
      this.$router.push(url);
    },
    clickToPushArg(url, id, title) {
      this.$router.push({
        path: url,
        query: {
          libId: id,
          libTitle: title,
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.$data.dataObject.push({
        "qId": "f6bfeb6d-bf38-4d0e-bf30-e88223535fe4",
        "title": "痊愈、显效、好转、无效属于",
        "tp": "选择题",
        "creator": "李兰辰",
        "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
        "createTime": "2022年1月2日 10:00",
      });
    }, 0);
  },
  created() {
  },
}
</script>
<style scoped>
#myQuestionBank {
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  margin: 18px;
  background: white;
  border-radius: 15px;
}
.card-title {
  margin-top: 14px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 13px;
}

.card-options {
  margin-left: 50px;
  margin-top: 24px;
  color: #666666;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-options:hover {
  cursor: pointer;
}

.line {
  width: 100%;
  border-bottom: 1px solid #E9E9E9;
}

.option-underline {
  height: 4px;
  width: 110%;
  background-color: #054FBF;
  margin-top: 10px;
}

.search-qslib {
  position: relative;
}

.search-qslib::after {
  cursor: pointer;
  position: absolute;
  content: "";
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
}

/*animation*/
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>

