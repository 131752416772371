import { render, staticRenderFns } from "./Problems.vue?vue&type=template&id=d68f14c6&scoped=true&"
import script from "./Problems.vue?vue&type=script&lang=js&"
export * from "./Problems.vue?vue&type=script&lang=js&"
import style0 from "./Problems.vue?vue&type=style&index=0&id=d68f14c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d68f14c6",
  null
  
)

export default component.exports