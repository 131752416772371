<template>
    <div id="questionBank">
        <div id="header">
            <div class="card-title">关联题库</div>
            <div class="card-options" @click="switchTabs(0);">
                <div :style="myData.idx === 0? 'color: #054FBF;' : ''">所有题库</div>
                <div :style="myData.idx === 0? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(1);">
                <div :style="myData.idx === 1? 'color: #054FBF;' : ''">私有题库</div>
                <div :style="myData.idx === 1? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(2);">
                <div :style="myData.idx === 2? 'color: #054FBF;' : ''">公共题库</div>
                <div :style="myData.idx === 2? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>

            <div id="sc">
                <Search :name="'搜索考试...'"></Search>
            </div>
            <button>+关联公有题库</button>
            <button>+关联私有题库</button>
        </div>
        <div id="body">
            <transition name="slide-fade1">
                <div v-show="myData.idx === 0" class="tab">
                    <QuestionBankCard :data-question-bank-card="dataQuestionBankCard[0]"></QuestionBankCard>
                    <QuestionBankCard :data-question-bank-card="dataQuestionBankCard[1]"></QuestionBankCard>
                    <QuestionBankCard :data-question-bank-card="dataQuestionBankCard[2]"></QuestionBankCard>
                </div>
            </transition>
            <transition :name="myData.fa === 0 ? 'slide-fade2':'slide-fade1'">
                <div v-show="myData.idx === 1" class="tab">
                    <QuestionBankCard :data-question-bank-card="dataQuestionBankCard[0]"></QuestionBankCard>
                    <QuestionBankCard :data-question-bank-card="dataQuestionBankCard[1]"></QuestionBankCard>
                </div>
            </transition>
            <transition name="slide-fade2">
                <div v-show="myData.idx === 2" class="tab">
                    <QuestionBankCard :data-question-bank-card="dataQuestionBankCard[2]"></QuestionBankCard>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Search from "../../components/common/Search";
import QuestionBankCard from "../../components/Card/QuestionBankCard";
export default {
    name: "QuestionBank",
    components: {QuestionBankCard, Search},
    data:function () {
        return {
            myData:{
                fa: 0,
                idx: 0
            },
            dataQuestionBankCard: [
                {
                    title: '选择题',
                    startTime: '2022-02-01 11:10:20',
                    founder:'李兰辰',
                    origin:'私有题库'
                },
                {
                    title: '填空题',
                    startTime: '2022-02-01 15:10:20',
                    founder:'李兰辰',
                    origin:'私有题库'
                },
                {
                    title: '主观题',
                    startTime: '2022-02-01 11:10:20',
                    founder:'李兰辰',
                    origin:'公共题库'
                }
            ]
        }
    },
    methods:{
        switchTabs(index) {
            this.$set(this.myData,'fa',this.myData.idx)
            this.$set(this.myData,"idx",index);
        }
    }
}
</script>

<style scoped>
#questionBank {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
#sc{
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.card-options {
    user-select: none;
    float: left;
    margin-left: 50px;
    margin-top: 20px;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*font-weight: 500;*/
    /*font-size: 16px;*/
}
.card-options:hover {
    cursor: pointer;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}

button {
    float: right;
    width: 108px;
    height: 31px;
    margin-top: 12px;
    margin-right: 25px;
    border: none;
    border-radius: 30px;
    background: #054FBF;
    color: white;
}

button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}

.tab {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.slide-fade1-enter-active {
    transition: all 0.3s ease;
}

.slide-fade1-enter,.slide-fade1-leave-to{
    transform: translateX(-300px);
    opacity: 0;
}

.slide-fade2-enter-active{
    transition: all 0.3s ease;
}
.slide-fade2-enter,.slide-fade2-leave-to{
    transform: translateX(300px);
    opacity: 0;
}


/*滚动条样式*/
::-webkit-scrollbar {
    width:3px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background-color:#dddddd;
    background-clip:padding-box;
    min-height:28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
}



</style>
