<template>
    <div class="choose">
        <div class="title">{{prob.qType === 's_choose' ? '单项' : '多项'}}选择（{{prob.data.qPrice}}分）</div>
        <div class="problem1">{{prob.data.qTitle}}</div>
        <div class="st" v-for="(val,idx) in prob.data.qOptions">
            <div class="select">{{String.fromCharCode(idx + 65)}}</div>
            <div class="select_s">{{val.oTitle}}</div>
        </div>
        <div class="body">
            <div style="overflow: auto">
                <div class="number">总人数：{{ans.length}}人</div>
                <div class="right">正确率：{{Math.round(statistic.crt / ans.length * 100)}}%</div>
            </div>
            <div class="st1" v-for="(val,idx) in statistic.opt">
                <div :class="val.correct ? 'select selected' : 'select'" >{{String.fromCharCode(65+idx)}}</div>
                <div class="a">
                    <div class="b" :style="{
                      width: val.cnt / ans.length * 100 + '%'
                    }"></div>
                </div>
                <div class="c">{{val.cnt}}人</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Choose",
  data() {
    return {

    }
  },
  methods: {

  },
  props: {
    prob: {
      type: Object,
      default: ()=> {}
    },
    ans: {
      type: Array,
      default: ()=> []
    }
  },
  computed: {
    statistic() {
      let data = {
        opt: []
      }
      let opts = this.prob.data.qOptions
      let cnt = {}
      if (this.prob.qType === 's_choose') {
        for (let i in this.ans) {
          if (!(this.ans[i] in cnt)) cnt[this.ans[i]] = 0;
          cnt[this.ans[i]] += 1;
        }
      } else if (this.prob.qType === 'm_choose') {
        for (let i in this.ans) {
          for (let j in this.ans[i]) {
            if (!(this.ans[i][j] in cnt)) cnt[this.ans[i][j]] = 0;
            cnt[this.ans[i][j]] += 1;
          }

        }
      }

      for (let i in opts) {
        data.opt.push({
          title: opts[i].oTitle,
          correct: opts[i].correct,
          cnt: cnt[opts[i].oID] ? cnt[opts[i].oID] : 0
        })
        if (opts[i].correct) data.crt = cnt[opts[i].oID] ? cnt[opts[i].oID] : 0;
      }

      console.log(data)
      return data
    }
  }

}
</script>

<style scoped>
    .choose{
        width: 100%;
        height: 100%;
    }
    .title {
        width: 100%;
        text-align: center;
        height: 80px;
        line-height: 80px;
        font-weight: 500;
        font-size: 24px;
    }
    .problem1{
        font-weight: 300;
        font-size: 17px;
        margin: 13px 0 0 57px;
    }
    .st {
        overflow: auto;
        margin-top: 16px;
        margin-left: 57px;
    }
    .st1 {
        overflow: auto;
        margin-top: 16px;
    }
    .select{
        font-weight: 400;
        float: left;
        border: 1px solid #999999;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 7px;
    }
    .selected {
      background: #4FDE4F
    }
    .select_s{
        float: left;
        height: 28px;
        line-height: 28px;
        margin-left: 18px;
    }
    .body {
        margin-top: 40px;
        width: 92%;
        margin-left: 4%;
        overflow: auto;
    }
    .number {
        float: left;
        font-weight: 300;
        font-size: 18px;
    }
    .right {
        float: left;
        font-weight: 300;
        font-size: 18px;
        margin-left: 60px;
    }
    .a {
        float: left;
        width: 80%;
        margin-left: 26px;
        height: 28px;
        border: 1px solid #999999;
        border-radius: 20px;
    }
    .b{
        height: 28px;
        background: rgb(76,143,243);
        /*background: #0547AC;*/
        border-radius: 20px;
    }
    .c {
        float: left;
        height: 28px;
        line-height: 28px;
        margin-left: 20px;
        font-weight: 300;
        font-size: 18px;
    }
</style>
