<template xmlns="http://www.w3.org/1999/html">
    <div id="addRemark">
        <transition name="slide-fade">
            <div id="screen" v-if="ok"></div>
        </transition>
        <transition name="bounce">
            <div id="Pop-ups" v-if="ok">
                <div id="pTitle">
                    <div id="pTitleFont">修改备注</div>
                    <img @click="sendOk" id="close" src="../../assets/SideBar/close.svg">
                </div>
                <textarea id="bz" placeholder="在此输入备注..."></textarea>
                <button @click="sendInfo">保 存</button>
                <button class="notOk" @click="sendOk">取 消</button>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "AddRemark",
    props:{
        ok:Boolean
    },
    methods:{
        sendOk() {
            let okk = !this.ok;
            this.$emit('sendOk',okk)
        },
        sendInfo(){
            this.$emit('sendInfo',document.getElementById("bz").value);
        }
    }
}
</script>

<style scoped>
#addRemark{}

textarea {
    color: #333333;
    font-size: 16px;
    font-weight: 100;
    outline: none;
    resize: none;
    height: 135px;
    width: 82%;
    margin-top: 20px;
    margin-left: 9%;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
}
button {
    float: left;
    width: 80px;
    height: 36px;
    margin-top: 25px;
    margin-left: 143px;
    color: white;
    border-radius: 6px;
    background: #054FBF;
    border: none;
    font-size: 16px;
    font-weight: 100;
}
button:hover{
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}
.notOk {
    background: #bbbbbb;
    color: black;
}
#Pop-ups {
    z-index:251;
    overflow:auto;
    width: 590px;
    height: 310px;
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

#screen {
    z-index:250;
    display: block;
    overflow:auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
#pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}
#pTitleFont {
    font-weight: 100;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}
#close{
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}
#close:hover {
    cursor: pointer;
}

.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.bounce-enter-active {
    animation: bounce-in .5s;
}
.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.tooltip .tooltiptext {

    visibility: hidden;
    width: 250px;
    background-color: #efefef;
    color: #666666;
    /*text-align: center;*/
    padding: 10px 3px 10px 11px;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -120px;
    /* position */
    font-size: 16px;
    font-weight: 100;
    position: absolute;
    text-align: left;
    text-indent: 33px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 253;
}
</style>
