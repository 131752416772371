<template>
    <div class="blank-question">

        <!--modal-->
        <transition name="slide-fade">
            <div class="screen" v-show="showEdit"></div>
        </transition>
        <transition name="bounce">
            <div class="Pop-ups" v-show="showEdit">
                <div class="pTitle">
                    <div class="pTitleFont">编辑</div>
                    <img @click="showEdit = !showEdit;" class="close" src="../../../assets/SideBar/close.svg" alt="">
                </div>
                <div class="p-body">
                    <div class="p-body-content2" style="padding: 10px;">
                        <div class="blank-question-edit">
                            <div style="display: flex; flex-direction: row; width: 100%; margin-bottom: 10px;">
                                <input style="width: 100%;" v-model="showTitle" class="set-input"
                                       placeholder="输入本题的显示标题">
                            </div>

                            <div style="display: flex; flex-direction: row; width: 100%;">
                                <!--question title and score-->
                                <div style="width: 70%; padding-right: 20px;" class="txt-area-wrap">
                    <textarea :style="{'height': txtAreaHeight}"
                              ref="txtArea" v-model="qTitle"
                              class="set-txt-area" style="display: block; width: 100%;"
                              placeholder="请输入题干内容"></textarea>
                                </div>

                                <div style="flex: 1;">
                                    <input v-model="questionObject.qPrice" class="set-input"
                                           style="display: block; width: 100%;"
                                           type="number"
                                           placeholder="请输入该题分值">
                                </div>
                            </div>
                            <div
                                style="display: flex; flex-direction: row; width: 100%; margin-top: 10px; padding-bottom: 10px; border-bottom: #707070 1px solid;">
                                <!--insert pic and formula-->
                                <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片"
                                         txt-color="#555555"
                                         btn-color="white" :h="30" :w="90"></buttons>
                            </div>

                            <div class="txt-area-wrap"
                                 style="display: flex; flex-direction: row; width: 100%; margin-bottom: 10px; margin-top: 10px;">
                <textarea :style="{'height': txtAreaHeightAnswer}"
                          ref="txtAreaAnswer" v-model="qAnswer"
                          class="set-txt-area" style="display: block; width: 100%;"
                          placeholder="输入答案与解析"></textarea>
                            </div>
                            <div
                                style="display: flex; flex-direction: row; width: 100%;">
                                <!--insert pic and formula-->
                                <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片"
                                         txt-color="#555555"
                                         btn-color="white" :h="30" :w="90"></buttons>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="showEdit = !showEdit;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="saveBtn">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="保存"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <div class="blank-question-preview"><!--question preview-->
            <div style="font-weight: bold;">{{ qTitle }}&nbsp;&nbsp;({{questionObject.qPrice !== 0 ? questionObject.qPrice+'分':"未赋分"}})</div>

            <div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
                <div style="margin: 0;" @click="showEdit = !showEdit;">
                    <buttons :content="showEdit ? '收起' : '编辑'" btn-color="#D94E4E" txt-color="white" :h="30" :w="60"
                             :f-size="5"></buttons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Buttons from "../../Buttons";
import 'font-awesome/css/font-awesome.min.css'
import calcTextareaHeight from "../../../utils/calcTextareaHeight";

export default {
    name: "Discussion",
    components: {
        Buttons
    },
    props: {
        qId: {
            type: String,
            require: true,
        }
    },
    data() {
        return {
            txtAreaHeight: '35px',
            txtAreaHeightAnswer: '35px',

            showTitle: '',
            qTitle: '题干',
            qAnswer: '',

            showEdit: false,
            questionObject: {
                "qType": 'blank',
                "qPicPath": '', // if question title wants to use picture to explain the meaning
                "qMathLatex": '', // if question title wants to use math formula to explain the meaning
            },
        };
    },
    methods: {
        save() {
            /**
             * save the question object to localStorage
             */

            // add question title
            this.$set(this.$data.questionObject, 'qTitle', this.$data.qTitle);

            // add show title
            this.$set(this.$data.questionObject, 'showTitle', this.$data.showTitle);

            // add correct answer
            this.$set(this.$data.questionObject, 'correct', this.$data.qAnswer);

            this.$emit("addquestion", this.$data.questionObject, this.$props.qId);

            localStorage.setItem("myPaper-" + this.$props.qId.split('-').join('')
                , JSON.stringify(this.$data.questionObject));

        },
        saveBtn() {
            this.save();
            this.$data.showEdit = false;
        },

        getHeight() {
            this.txtAreaHeight = calcTextareaHeight(this.$refs.txtArea, 1, null).height;
        },
        getHeightAnswer() {
            this.txtAreaHeightAnswer = calcTextareaHeight(this.$refs.txtAreaAnswer, 1, null).height;
        },
        insertBlankSign() {
            let txtAreaElement = this.$refs.txtArea;
            let cursor = txtAreaElement.selectionEnd;
            txtAreaElement.value = txtAreaElement.value.slice(0, cursor) + '______' + txtAreaElement.value.slice(cursor, txtAreaElement.value.length);
            this.$data.qTitle = txtAreaElement.value;
        },
        howManyBlanks() {
            return this.$data.qTitle.match(/______/g) !== null ? this.$data.qTitle.match(/______/g).length : 0;
        }
    },
    watch: {
        qTitle() {
            this.getHeight();
        },
        qAnswer() {
            this.getHeightAnswer();
        }
    },
    mounted() {
        /**
         * save the question object to localStorage every minute
         * @type {number}
         */
        this.timer = setInterval(this.save, 1000 * 30);
    },
    created() {
        let qs = localStorage.getItem("myPaper-" + this.$props.qId.split('-').join(''));
        if (qs && JSON.parse(qs)['qTitle']) { // if you choose a question type or save the question
            this.$data.questionObject = JSON.parse(qs);
            this.$set(this.$data.questionObject, 'action', '');

            this.$data.showTitle = JSON.parse(qs)['showTitle'];
            this.$data.qTitle = JSON.parse(qs)['qTitle'];
            this.$data.qAnswer = JSON.parse(qs)['correct'];
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.blank-question {
    display: flex;
    flex-direction: column;
}

.blank-question-preview {
    display: flex;
    flex-direction: column;
    background-color: rgb(248,248,248);
    border:1px solid rgb(231,234,237);
    border-radius: 5px;
    padding: 20px;
}

.blank-question-edit {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.set-input {
    height: 35px;
    border-radius: 10px;
    text-indent: 10px;
    font-size: 18px;
    border: #707070 1px solid;
}

.txt-area-wrap .set-txt-area {
    border-radius: 10px;
    resize: none;
    display: inline-block;
    font-size: 18px;
    border: #707070 1px solid;
    padding: 5px;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

input {
    box-sizing: border-box;
}

textarea {
    box-sizing: border-box;
}


/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}


/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 800px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none;
}

/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.p-body-content2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
}
</style>
