<template>
    <div id="notice">
        <div id="header">
            <div class="card-title">通知</div>
            <div id="sc">
                <Search :name="'搜索通知...'"></Search>
            </div>
        </div>
        <div id="body" style="overflow: auto">
            <div class="infromation" style="margin-top: 22px;">
                <div class="first">
                    <img src="../../assets/Class/info.svg">
                    <div class="title">期末考试通知</div>
                    <div class="title_information">考试通知</div>
                    <div class="delete">
                        <img src="../../assets/Class/delete.svg">
                    </div>
                    <div v-show="ok[0]" @click="change(0)" class="button">
                        <img src="../../assets/Class/right.svg">
                        确认信息
                    </div>
                    <div v-show="!ok[0]" class="isOk">
                        已确认
                    </div>

                </div>
                <div class="by">
                    李兰辰·2022年2月5日 13:10
                </div>
                <div class="content">
                    期末考试将于明日开始，请大家带好涂卡笔、学生证、身份证！诚信考试，从大家做起！
                </div>
            </div>

            <div class="infromation" style="border-top: none;">
                <div class="first">
                    <img src="../../assets/Class/info.svg">
                    <div class="title">期中考试通知</div>
                    <div class="title_information">考试通知</div>
                    <div class="delete">
                        <img src="../../assets/Class/delete.svg">
                    </div>
                    <div v-show="ok[1]" @click="change(1)" class="button">
                        <img src="../../assets/Class/right.svg">
                        确认信息
                    </div>
                    <div v-show="!ok[1]" class="isOk">
                        已确认
                    </div>
                </div>
                <div class="by">
                    VOID_JACK·2022年2月5日 13:10
                </div>
                <div class="content">
                    这次的期中考试较为特殊，由于疫情的关系，不组织线下考试，代之以线上考试。不过这毕竟是一场考试，相关的纪律应牢记心中！不要违反考场规则，尤其不要作弊！另外，还请注意使用考试系统的步骤，不要到时候忙中出错！
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Search from "../../components/common/Search";
export default {
    name: "Notice",
    components: {Search},
    data:function () {
        let ok = [true,true]
        return {
            ok
        }
    },
    methods:{
        change(idx) {
            this.$set(this.ok,idx,false)
        }
    }
}
</script>

<style scoped>
    #notice {
        width: 100%;
        height: 100%;
    }
    #header {
        top: 0;
        width: 100%;
        height: 55px;
        border-bottom: 1px solid #e9e9e9;
    }
    #sc{
        float: right;
        width: 157px;
        margin-top: 13.5px;
        margin-right: 25px;
    }
    .card-title {
        user-select: none;
        float: left;
        margin-top: 12px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }
    #body {
        width: 100%;
        height: calc(100% - 55px);
        overflow: auto;
    }
    .infromation {
        overflow: auto;
        width: 92%;
        margin-left: 4%;
        border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
    }
    .first{
        width: 100%;
        overflow: auto;
        margin-top: 8px;
    }

    .first > img {
        margin-top: 5px;
        margin-left: 13px;
        float: left;
    }
    .title_information {
        margin-top: 7px;
        margin-left: 13px;
        height: 23px;
        float: left;
        font-size: 13px;
        font-weight: 100;
        width: 66px;
        text-align: center;
        line-height: 23px;
        border-radius: 20px;
        background: #C01616;
        color: white;
    }
    .title {
        /*margin-top: 7px;*/
        float: left;
        margin-left: 13px;
        font-size: 25px;
        font-weight: 600;
    }
    .button {
        font-weight: 100;
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        background: #054FBF;
        /*margin-top: 10px;*/
        float: right;
        height: 30px;
        width: 98px;
        border-radius: 20px;
        margin-right: 20px;
    }
    .button:hover{
        cursor: pointer;
    }
    .delete {
        margin-top: 4px;
        float: right;
        margin-right: 15px;
    }
    .delete:hover{
        cursor: pointer;
    }
    .by {
        margin-top: 8px;
        font-size: 13px;
        font-weight: 100;
        color: #666666;
        margin-left: 46px;
    }
    .content {
        text-indent: 32px;
        width: 80%;
        margin-top: 13px;
        font-weight: 100;
        margin-left: 46px;
        margin-bottom: 8px;
    }
    .isOk {
        font-weight: 800;
        font-size: 14px;
        color: #000000;
        line-height: 30px;
        text-align: center;
        background: #efefef;
        /*margin-top: 10px;*/
        float: right;
        height: 30px;
        width: 98px;
        border-radius: 20px;
        margin-right: 20px;
    }
    /*滚动条样式*/
    ::-webkit-scrollbar {
        width:5px;
    }

    ::-webkit-scrollbar-track-piece {
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:#dddddd;
        background-clip:padding-box;
        min-height:28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#bbb;
    }
</style>
