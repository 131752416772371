<template>
<div class="manager">
  <ExamSideBar></ExamSideBar>
  <div class="content">
<!--    <div>123</div>-->
    <div id="body">
      <router-view></router-view>
    </div>
  </div>

</div>
</template>

<script>
import ExamSideBar from "../../components/SideBar/ExamSideBar";
export default {
  name: "ExamManager",
  components: {ExamSideBar}
}
</script>

<style scoped lang="less">
.manager {
  height: calc(100% - 32px);
  width: 100%;
  min-width: 1050px;
}

.content {
  display: flex;
  width: calc(100% - 213px);
  height: 100%;
  flex-direction: column;
  float: left;
  margin-top: 16px;
  margin-left: 16px;

  #body {
    flex: 1;


    width: 100%;
    /*min-width: 754px;*/
    height: 100%;
    background: white;
    border-radius: 15px;
  }
}


</style>
