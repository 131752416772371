<template>
  <div class="enter">
    <EnterExamHeader :flip="this.$route.name === 'Problem'"></EnterExamHeader>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ExamHeader from "../../components/Header/ExamHeader";
import ExamSideBar from "../../components/SideBar/ExamSideBar";
import EnterExamHeader from "../../components/Header/EnterExamHeader";
export default {
  name: "ExamIndex",
  components: {EnterExamHeader, ExamSideBar, ExamHeader},
  mounted() {
  }
}
</script>

<style scoped lang="less">
.enter {
  height: 100%;
  width: 100%;
  min-width: 1050px;
}

.content {
  height: calc(100% - 55px);
}
</style>
