<template>
    <div class="blank">
        <div class="title">
            <span>{{index}}、</span><span>{{questionObject.title}}</span>
        </div>
        <div class="content">
            <el-input v-model="ans" placehoder="请输入答案" @change="$emit('answer',ans)"></el-input>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Blank",
        data() {
            return {
                ans: ''
            }
        },
        props: {
            index: {
                type: String,
                default: () => '1'
            },

            questionObject: {
                type: Object,
                default: function () {
                    return {};
                }
            },
        },
        model: {
            prop: 'answer',
            event: 'answer'
        },
        created() {
            this.ans = this.questionObject.answer;
        }
    }
</script>

<style scoped lang="less">
    .blank {
        font-size: 18px;

        .title {
            margin: 16px 0;
        }

        .content {
            margin: 0 20px;
        }
    }

    .option-content {
        font-size: 16px;
        margin-left: 10px;
        font-weight: 400;
    }
</style>
