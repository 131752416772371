<template>
    <div id="app">
        <router-view v-if="isRouterAlive"/>
    </div>
</template>
<style>
</style>
<style lang="less">
#app {
    height: 100%;
    width: 100%;
    min-width: 740px;
}
</style>

<script>
import MainHeader from './components/Header/MainHeader'

export default {
    name: "App",
    provide() {
        return {
            reload: this.reload,
        }
    },
    components: {
        MainHeader
    },
    data() {
        return {
            isRouterAlive: true,
        }
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true
            });
            console.log('已刷新');
        }
    }
}
</script>
