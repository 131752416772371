<template>
    <div v-show="showThis" id="teacherExamCard">
        <div id="header">
            <b>{{dataTeacherExamCard['title']}}</b>
            <img @mouseenter="openHint()" @mouseleave="closeHint()" id="more" src="../../assets/Card/more.svg">
            <transition name="sub-comments">
                <div v-show="showGrade" class="hint" @mouseenter="openHint()" @mouseleave="closeHint()">
                    <div @click="deleteExam()" class="set" style="border-radius: 5px 5px 0 0;">删 除</div>
                    <div @click="trueShow()" class="set">发 布</div>
                    <div class="set" style="border-radius: 0px 0px 5px 5px;border-bottom: none">导 出</div>
                </div>
            </transition>
        </div>
        <div id="center">
            <div class="information" style="margin-top: 14px">考试开始时间：{{dataTeacherExamCard['startTime']}}</div>
            <div class="information">考试结束时间：{{dataTeacherExamCard['endTime']}}</div>
            <div class="examStatus">考试状态：<b v-bind:class="{start:dataTeacherExamCard['status'] === '开放登录'||dataTeacherExamCard['status'] === '开放中',end:dataTeacherExamCard['status'] === '已结束'}">{{dataTeacherExamCard['status']}}<b style="color: #DD1E1E">{{msg}}</b></b></div>
            <div class="examStatus">
                考试类型：<b v-bind:class="{start:dataTeacherExamCard['status'] === '开放登录'||dataTeacherExamCard['status']=== '开放中'}">{{dataTeacherExamCard['type']}}</b>
            </div>
            <button @click="enterExam()">{{dataTeacherExamCard['enter']}}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeacherExamCard',
    props:{
        dataTeacherExamCard: {
            type: Object,
            default: function () {
                return {
                    isShow:0,
                    id: 1,
                    title: "计算机网络",
                    startTime: "2022-02-03 09:45:00",
                    endTime: "2022-02-03 11:45:00",
                    status: "开放登录",
                    type: "双机位考试",
                    enter: "进入"
                }
            }
        }
    },
    data:function () {
        return {
            ok:false,
            msg:this.dataTeacherExamCard['isShow'] === 0 ? " (未发布)":"",
            showThis:true,
            showGrade: false
        }
    },
    methods: {
        openHint() {
            this.ok = true;
            this.showGrade = true;
        },
        closeHint() {
            this.ok = false;
            let that = this;
            setTimeout(function (){
                console.log(this.ok);
                if(that.ok === false) that.$set(that.$data,'showGrade',false);
            },300);
        },
         enterExam() {
            let routeUrl = this.$router.resolve({
                path: "/exam/manager/config",
                query:{examId:this.dataTeacherExamCard['id']}
            });
            window.open(routeUrl.href, '_blank');
        },
        deleteExam() {
            let ok = confirm("确定删除 "+name+" 吗?");
            if(ok === true) {
                this.$axios.get(
                    '/apiExam/deleteExam', {
                        params: {
                            examId: this.dataTeacherExamCard['id']
                        }
                    }).then((resp) => {
                    this.$toasted.success('删除成功！', {
                        iconPack: 'custom-class',
                        icon: 'fa fa-check-circle-o',
                        theme: 'bubble',
                        position:'top-center'
                    }).goAway(2500);
                    this.$set(this.$data,'showThis',false);
                }).catch((err) => {
                    console.log("网络连接错误");
                })
            }
        },
        trueShow() {
            this.$axios.get(
                '/apiExam/changeShow', {
                    params: {
                        examId: this.dataTeacherExamCard['id'],
                        isShow: 1
                    }
                }).then((resp) => {
                    this.$toasted.success('发布成功！', {
                        iconPack: 'custom-class',
                        icon: 'fa fa-check-circle-o',
                        theme: 'bubble',
                        position:'top-center'
                    }).goAway(2500);
                    this.$set(this.$data,'msg',"");
            }).catch((err) => {
                console.log(err);
            })
        }
    }
}
</script>

<style scoped>
#teacherExamCard {
    transition: 0.5s all;
    position: relative;
    height: 176px;
    width: 290px;
    float: left;
    margin-top: 45px;
    margin-left: max(40px,calc(25% - 310px));
    border-radius: 15px;
    border: 0px;
    outline:none;
    box-shadow: 0px 0px 15px #DDDDDD;
}
@media screen and (max-width: 1636px) {
    #teacherExamCard {
        margin-left: max(40px,calc(33% - 330px));
    }
}
@media screen and (max-width: 1313px) {
    #teacherExamCard {
        margin-left: max(53px,calc(50% - 340px));
    }
}

@media screen and (max-width: 920px) {
    #teacherExamCard {
        margin-left: max(53px,calc(50% - 145px));
    }
}

#teacherExamCard:hover{
    transform: translateY(-3px);
    box-shadow: 0px 0px 35px #cccccc;
}

#header {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 47px;
    height: 47px;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    background: #054FBF;
    border: 0px;
    outline:none;
}
#more {
    /*background: black;*/
    width: 20px;
    position: absolute;
    left: 255px;
    top: 15px;
}
img {
    width: 100%;
}
#center {
    height: 129px;
    width: 100%;
}
#more:hover {
    cursor: pointer;
}
.information {
    margin-top: 8px;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 100;
}
.examStatus {
    margin-top: 5px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 100;
}
.hint {
    z-index: 999;
    height: 82px;
    overflow: hidden;
    position: absolute;
    width: 50px;
    background: white;
    margin-top: -15px;
    margin-left: 240px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #888888;
}
.set{
    color: #3a3a3a;
    width: 100%;
    height: 26px;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 26px;
    border-bottom: 2px solid #EFEFEF;
}
.set:hover {
    background: rgba(5,71,172,0.85);
    color: white;
    cursor: pointer;
}

.start{
    color: #00A03E;
}
.end {
    color:#DD1E1E;
}


button {
    width: 57px;
    height: 29px;
    position: absolute;
    margin-top: -20px;
    margin-left: 219px;
    border-radius: 5px;
    background: #054FBF;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 100;
}
button:hover {
    cursor: pointer;
    background: #0547AC;
}
a {
    color: white;
    text-decoration:none;
}

.sub-comments-leave-active,.sub-comments-enter-active {
    transition: max-height 0.08s;
}
.sub-comments-enter,.sub-comments-leave-to {
    max-height:0 ;
}
.sub-comments-enter-to,.sub-comments-leave {
    max-height: 4rem ;
}

</style>

