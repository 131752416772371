<template>
    <div id="questionBankCard">
        <div id="header">
            <b>{{dataQuestionBankCard['title']}}</b>
            <img id="more" src="../../assets/Card/more.svg">
        </div>
        <div id="center">
            <div class="information" style="margin-top: 25px">题库创建时间：{{dataQuestionBankCard['startTime']}}</div>
            <div class="examStatus" style="margin-top: 15px">创建人：{{dataQuestionBankCard['founder']}}</div>
            <p class="examStatus">题库来源：<b class="start">{{dataQuestionBankCard['origin']}}</b></p>
            <router-link target="_blank" :to="{path:'/u/funcCenter/problemSet/questions',query:{libId: dataQuestionBankCard['libId'], libTitle: dataQuestionBankCard['title']}}"><button>管理</button></router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuestionBankCard',
    props:{
        dataQuestionBankCard: {
            type: Object,
            default: function () {
                return {
                    title: "计算机网络",
                    startTime: "2022-02-03 09:45:00",
                    founder: "李兰辰",
                    origin: '公共题库'
                }
            }
        }
    }
}
</script>

<style scoped>
#questionBankCard {
    transition: 0.5s all;
    position: relative;
    height: 176px;
    width: 290px;
    float: left;
    margin-top: 45px;
    margin-left: max(40px,calc(25% - 310px));
    border-radius: 15px;
    border: 0px;
    outline:none;
    box-shadow: 0px 0px 15px #DDDDDD;
}

@media screen and (max-width: 1636px) {
    #questionBankCard {
        margin-left: max(40px,calc(33% - 330px));
    }
}
@media screen and (max-width: 1313px) {
    #questionBankCard {
        margin-left: max(53px,calc(50% - 340px));
    }
}
@media screen and (max-width: 920px) {
    #questionBankCard {
        margin-left: max(53px,calc(50% - 145px));
    }
}

#questionBankCard:hover{
    transform: translateY(-3px);
    box-shadow: 0px 0px 35px #cccccc;
}

#header {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 47px;
    height: 47px;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    background: #054FBF;
    border: 0px;
    outline:none;
}
#more {
    /*background: black;*/
    width: 20px;
    position: absolute;
    left: 255px;
    top: 15px;
}
img {
    width: 100%;
}
#center {
    height: 129px;
    width: 100%;
}
#more:hover {
    cursor: pointer;
}
.information {
    margin-top: 4px;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 100;
}
.examStatus {
    margin-top: 9px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 100;
}
.start{
    color: #00A03E;
}
button {
    width: 57px;
    height: 29px;
    position: absolute;
    margin-top: -35px;
    margin-left: 219px;
    border-radius: 5px;
    background: #054FBF;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 100;
}
button:hover {
    cursor: pointer;
    background: #0547AC;
}
a {
    color: white;
    text-decoration:none;
}
</style>


