<!--problemset list-->
<!--
list element height: 55px
element margin: 10px
border radius: 14px
font size: 1.3em
-->
<template>
    <div>
        <transition name="slide-fade">
            <div class="screen" v-if="delOk || attrSettings"></div>
        </transition>

        <!--属性设置-->
        <transition name="bounce">
            <div class="Pop-ups" v-show="attrSettings">
                <div class="pTitle">
                    <div class="pTitleFont">修改题库“{{ willModifyTitle }}”的属性</div>
                    <img @click="toggleModal()" class="close" src="../assets/SideBar/close.svg">
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>题库的名称:&nbsp;</div>
                        <div><input type="text" class="p-body-input" v-model="willModifyTitle"></div>
                    </div>
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>题库知识点:&nbsp;</div>
                        <div><input type="text" class="p-body-input" v-model="willModifyKoPoints"></div>
                    </div>
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>学科的门类:&nbsp;</div>
                        <div>
                            <!--                            <input type="text" class="p-body-input">-->
                            <select id="classOptions" ref="classOptions" :value="willModifyKind"
                                    class="p-body-input">
                                <option value="sub:01:__philosophy__">哲学</option>
                                <option value="sub:02:__economics__">经济学</option>
                                <option value="sub:03:__law__">法学</option>
                                <option value="sub:04:__pedagogy__">教育学</option>
                                <option value="sub:05:__literature__">文学</option>
                                <option value="sub:06:__history__">历史学</option>
                                <option value="sub:07:__science__">理学</option>
                                <option value="sub:08:__engineering__">工学</option>
                                <option value="sub:09:__agronomy__">农学</option>
                                <option value="sub:10:__medicine__">医学</option>
                                <option value="sub:12:__management__">管理学</option>
                                <option value="sub:13:__art__">艺术学</option>
                            </select>
                        </div>
                    </div>
                    <div class="p-body-content">
                        <!--checkbox of allow assigning permissions-->
                        <div><input type="checkbox"></div>
                        <div>允许分配本题库的编辑权限给他人</div>
                    </div>
                    <div class="p-body-content">
                        <!--checkbox of allow collection-->
                        <div><input type="checkbox"></div>
                        <div>上传至公共题库后允许他人收藏你的题库</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="toggleModal();">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="modifyMyQuestionBank">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="修改"></buttons>
                    </div>
                </div>
            </div>
        </transition>

        <!--delete modal-->
        <transition name="bounce">
            <div class="Pop-ups" v-if="delOk">
                <div class="pTitle">
                    <div class="pTitleFont">确认删除</div>
                    <img @click="toggleDelModal();" class="close" src="../assets/SideBar/close.svg">
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>确定删除题库"{{ willDelTitle }}"？删除该题库后会连同内部的题目一起删除，且不可撤销，要继续吗？</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="toggleDelModal();">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="否"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="delLib">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="是"></buttons>
                    </div>
                </div>
            </div>
        </transition>

        <!--list-->
        <div v-for="(item, index) in cutPageList(defaultPageNum)">

            <!--list element-->
            <div class="hv-list-frame">

                <div style="display: flex; width: 100%; min-height: 55px; align-items: center;"><!--info summary-->
                    <div class="hv-list-frame-summary">
                        <div class="hv-list-frame-title-container"><!--title&icon div-->

                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <div class="hv-list-frame-title-icon"><!--icon-->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                                        <path id="路径_63" data-name="路径 63"
                                              d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm2,16H8V16h8Zm0-4H8V12h8ZM13,9V3.5L18.5,9Z"
                                              transform="translate(-4 -2)" fill="#054fbf"/>
                                    </svg>
                                </div>

                                <div class="hv-list-frame-title-title" @click="expandDetails(item['libid'])">
                                    <!--title-->
                                    {{ item['title'] }}
                                </div>
                                <buttons :content="item['subjectclass']" :f-size="8" style="margin-left: 4px;"
                                         btn-color="#054FBF" :h="15" :w="56" tp="super_rounded_rectangle"
                                         txt-color="white"></buttons>

                                <div class="operate-button">
                                    <div class="hv-list-frame-expand-icon" @click="expandDetails(item['libid'])"
                                         v-show="details !== item['libid']"><!--expand icon-->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.591" height="12.715"
                                             viewBox="0 0 20.591 12.715">
                                            <path id="路径_65" data-name="路径 65"
                                                  d="M24.171,8.59,16.3,16.449,8.419,8.59,6,11.009,16.3,21.3l10.3-10.3Z"
                                                  transform="translate(-6 -8.59)" fill="#8e8e8e"/>
                                        </svg>
                                    </div>

                                    <div class="hv-list-frame-expand-icon"
                                         @click="willDelId = item['libid']; willDelTitle = item['title']; delOk = true;"
                                         v-show="details === item['libid']">
                                        <!--delete icon-->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24.429"
                                             viewBox="0 0 19 24.429">
                                            <path id="路径_67" data-name="路径 67"
                                                  d="M22.643,24.714a2.722,2.722,0,0,1-2.714,2.714H9.071a2.722,2.722,0,0,1-2.714-2.714V8.429H22.643ZM5,4.357H9.75L11.107,3h6.786L19.25,4.357H24V7.071H5Z"
                                                  transform="translate(-5 -3)" fill="#d94e4e"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div class="hv-list-frame-details"><!--list element details-->

                                <div class="creator">{{ item['creator'] }} · {{ item['createtime'] }} · {{ item['creatorins'] }}</div>
                                <!--creator + time-->

                                <div class="ko-points"><span style="font-weight: bold">本题库知识点：</span>{{
                                        item['kopoints']
                                    }}
                                </div><!--knowledge points-->

                            </div>

                        </div>
                    </div>

                </div>

                <transition name="slide-fade">
                    <!--                    <div class="hv-list-frame-details" v-show="details === item['libId']">&lt;!&ndash;list element details&ndash;&gt;-->

                    <!--                        <div class="creator">{{ item['creator'] }} · {{ item['createTime'] }}</div>&lt;!&ndash;creator + time&ndash;&gt;-->

                    <!--                        <div class="ko-points"><span style="font-weight: bold">本题库知识点：</span>{{ item['koPoints'] }}-->
                    <!--                        </div>&lt;!&ndash;knowledge points&ndash;&gt;-->

                    <!--                    </div>-->
                </transition>
                <transition name="slide-fade">
                    <div class="hv-list-frame-buttons" v-show="details === item['libid']">
                        <!--list element buttons-->
                        <div
                            @click="clickToPush('/u/funcCenter/problemSet/questions', item['libid'], item['title']);">
                            <buttons style="margin: 0; margin-right: 25px;" tp="default" btn-color="#274DE2"
                                     txt-color="#FFFFFF"
                                     :h="32" :w="80" :f-size="14"
                                     content="进入题库"></buttons>
                        </div>
                        <div @click="willModifyId = item['libid']; willModifyTitle = item['title'];
                        willModifyKind = item['subjectclassid']; willModifyKoPoints = item['kopoints']; attrSettings = true;">
                            <buttons style="margin: 0; margin-right: 25px;" tp="default" btn-color="#ffffff"
                                     txt-color="#525353"
                                     :h="32" :w="80" :f-size="14"
                                     content="属性设置"></buttons>
                        </div>


                        <div class="share-dropdown-button" @mouseover="shareDropdownMenu = true;"
                             @mouseleave="shareDropdownMenu = false;">
                            <div class="share" style="margin: 0;"><!--share icon-->
                                <svg id="share_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24">
                                    <path id="路径_68" data-name="路径 68" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="路径_69" data-name="路径 69"
                                          d="M18,16.08a2.912,2.912,0,0,0-1.96.77L8.91,12.7A3.274,3.274,0,0,0,9,12a3.274,3.274,0,0,0-.09-.7l7.05-4.11A2.993,2.993,0,1,0,15,5a3.274,3.274,0,0,0,.09.7L8.04,9.81a3,3,0,1,0,0,4.38l7.12,4.16a2.821,2.821,0,0,0-.08.65A2.92,2.92,0,1,0,18,16.08Z"/>
                                </svg>
                            </div>
                            <transition name="slide-fade">
                                <div v-show="shareDropdownMenu" class="dropdown-menu">
                                    <div v-if="item['isstar'] === 0" class="dropdown-option" @click="upload(item['libid'])"
                                         style="width: 100%; height: 50%; display: flex; justify-content: center; align-items: center;">
                                        发布至公共题库
                                    </div>

                                    <div v-else class="dropdown-option" @click="cancelUpload(item['libid'])"
                                         style="width: 100%; height: 50%; display: flex; justify-content: center; align-items: center;">
                                        取消发布
                                    </div>

                                    <div style="border: #e8e8e8 1px solid; width: 99%;"></div>
                                    <div class="dropdown-option" @click="star(item['libid'])"
                                         style="width: 100%; height: 50%; display: flex; justify-content: center; align-items: center;">
                                        添加至我的收藏
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <div class="hv-list-frame-not-expand-icon" style="transform: rotate(180deg)"
                             v-show="details === item['libid']"
                             @click="notExpandDetails">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.591" height="12.715"
                                 viewBox="0 0 20.591 12.715">
                                <path id="路径_65" data-name="路径 65"
                                      d="M24.171,8.59,16.3,16.449,8.419,8.59,6,11.009,16.3,21.3l10.3-10.3Z"
                                      transform="translate(-6 -8.59)" fill="#8e8e8e"/>
                            </svg>
                        </div>
                    </div>
                </transition>

            </div>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center;align-items: center;"
             v-show="cutPageList(defaultPageNum).length === 0">
            <div style="margin: 5px;">
                暂无符合条件的结果
            </div>
        </div>

        <!--page indicator-->
        <div style="display: flex; justify-content: center;">
            <div class="page-indicator-surface">
                <div class="page-num" @click="prePage"><!--previous page-->
                    <div style="font-weight: bold;"><</div>
                </div>

                <div class="page-num"
                     v-for="count in (showPageElementNumProcessor() > 3 ? 3 : showPageElementNumProcessor())"
                     style="cursor: pointer;" @click="switchPageNum(count);"
                     :style="defaultPageNum === count ? 'background-color: #274DE2; color: white;' : ''">
                    <!--total num of page and position-->
                    <h3 style="cursor: pointer;">{{ count }}</h3>
                </div>

                <div class="page-num" v-show="showPageElementNumProcessor() > 3" style="text-align: center;"
                     @click="showJumpPageInput = !showJumpPageInput;">
                    <!--if total num of page greater than 3 then show this div-->
                    <h3 style="cursor: pointer;">{{ '...' }}</h3>
                </div>
                <transition name="slide-fade">
                    <div class="page-num" v-show="showJumpPageInput"><!--input num to jump the specific page-->
                        <input ref="p" @keyup.enter="customizePage" type="number" placeholder="输入页码然后回车"
                               style="height: 100%; border: 1px solid; border-color: #e3e3e3; border-radius: 8px; padding: 2px;">
                    </div>
                </transition>

                <div class="page-num" @click="nextPage"><!--next page-->
                    <div style="font-weight: bold;">></div>
                </div>
                <div v-show="defaultPageNum > 0 && defaultPageNum <= showPageElementNumProcessor()"
                     style="font-weight: bold; color: #959595;">
                    第{{ defaultPageNum }}页，共{{
                        showPageElementNumProcessor()
                    }}页，共{{ keyWord === '' ? dataObject.length : searchLength }}条记录
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Buttons from "./Buttons";
import 'x0popup/dist/x0popup.min.css';
import x0p from 'x0popup/dist/x0popup.min'
import axios from "axios";

export default {
    inject: ['reload'],
    name: "HeavyList",
    components: {Buttons},
    data() {
        return {
            details: '',
            defaultPageNum: 1,
            showJumpPageInput: false,

            searchLength: 0,

            delOk: false, // control the delete modal
            willDelId: '', // if check this qsLib to delete and its libId will assign to this variable
            willDelTitle: '',

            shareDropdownMenu: false,

            attrSettings: false,
            willModifyId: '',
            willModifyTitle: '',
            willModifyKoPoints: '',
            willModifyKind: '',
        };
    },
    props: {
        dataObject: Array,
        numPerPage: { // customize number of elements per page, default 10
            type: Number,
            default: 10
        },
        keyWord: {
            type: String,
        },
    },
    methods: {
        expandDetails(id) {
            /**
             * expand specific details
             */
            this.$data.details = id;
        },
        notExpandDetails() {
            /**
             * close all details
             */
            this.$data.details = "";
        },
        showPageElementNumProcessor() {
            /**
             * return value of total page num.
             */
            let total = this.$props.dataObject.length; // total data num
            if (this.$props.keyWord === '') return Math.ceil(total / this.$props.numPerPage);
            else return Math.ceil(this.$data.searchLength / this.$props.numPerPage);
        },
        searchEveryThing(item, index, ar) {
            /**
             * search every criterion value
             */
            let reg = RegExp(this.$props.keyWord);
            return reg.test(item['title']) || reg.test(item['creator'])
                || reg.test(item['createtime']) || reg.test(item['subjectclass'])
                || reg.test(item['subjectclassid']);
        },
        cutPageList(pageRank) {
            /**
             * return customize page data list.
             * @param pageRank
             */

            let rawData = this.$props.dataObject;
            if (this.$props.keyWord !== '') rawData = this.$props.dataObject.filter(this.searchEveryThing);

            let customizeNumPerPage = this.$props.numPerPage;

            // re calculate the num of page
            this.$data.searchLength = rawData.length;

            return rawData.slice((pageRank - 1) * customizeNumPerPage, pageRank * customizeNumPerPage);
        },
        switchPageNum(page) {
            /**
             * process the click page number
             */
            this.$data.defaultPageNum = page;
        },
        customizePage() {
            /**
             * process the input page number
             */
            if (this.$refs.p.value <= 0) {
                x0p('错误', '请输入正整数！', 'error', false);
            } else this.$data.defaultPageNum = Math.floor(this.$refs.p.value); // if user input float then remove the decimal part
        },
        prePage() {
            /**
             * switch to the previous page
             */
            this.$data.defaultPageNum = this.$data.defaultPageNum > 1 ? this.$data.defaultPageNum - 1 : 1;
        },
        nextPage() {
            /**
             * switch to the next page
             */
            this.$data.defaultPageNum = this.$data.defaultPageNum + 1 <= Math.ceil(this.$props.dataObject.length / this.$props.numPerPage) ? this.$data.defaultPageNum + 1 : this.$data.defaultPageNum;
        },

        clickToPush(url, id, title) {
            this.$router.push({
                path: url,
                query: {
                    libId: id,
                    libTitle: title,
                }
            });
        },
        PopUps(puMsg) {
            this.$toasted.success(puMsg, {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position:'top-right'
            }).goAway(2500);
        },
        delLib() {
            console.log(this.$data.willDelId);
            axios.get('apiQuestion/u/delQb?libId=' + this.$data.willDelId).then(res => {
                this.PopUps('删除题库 \''+this.$data.willDelTitle+'\' 成功');
                this.reload();
            }).catch(e => {
                console.log(e);
                this.$toasted.error(JSON.parse(e['request']['responseText'])['msg'], {
                    iconPack: 'custom-class',
                    icon: 'fa fa-times-circle',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
            });
        },
        toggleDelModal() {
            this.$data.delOk = !this.$data.delOk;
        },

        toggleModal() {
            this.$data.attrSettings = !this.$data.attrSettings;
        },

        upload(qbId) {
            axios.get('apiQuestion/u/upload?uniId=' + this.$cookies.get('uniCode') + '&qbId=' + qbId).then(res => {
                this.$toasted.success('发布成功！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
                this.reload();
            }).catch(e => {
                this.$toasted.error(JSON.parse(e['request']['responseText'])['msg'], {
                    iconPack: 'custom-class',
                    icon: 'fa fa-times-circle',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
            });
        },

        cancelUpload(qbId) {
            // 取消发布题库
            axios.get('apiQuestion/u/cancelUpload?uniId=' + this.$cookies.get('uniCode') + '&qbId=' + qbId).then(res => {
                this.$toasted.success('取消发布成功！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
                this.reload();
            }).catch(e => {
                this.$toasted.error(JSON.parse(e['request']['responseText'])['msg'], {
                    iconPack: 'custom-class',
                    icon: 'fa fa-times-circle',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
            });
        },

        star(qbId) {
            axios.get('apiQuestion/u/star?qbId=' + qbId).then(res => {
                this.$toasted.success('收藏成功！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
                this.reload();
            }).catch(e => {
                this.$toasted.error(JSON.parse(e['request']['responseText'])['msg'], {
                    iconPack: 'custom-class',
                    icon: 'fa fa-times-circle',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
            });
        },

        modifyMyQuestionBank() {
            // 修改题库属性
            let libId = this.$data.willModifyId;
            let libName = this.$data.willModifyTitle;
            let libKoPoints = this.$data.willModifyKoPoints;
            let libKindId = this.$data.willModifyKind;

            let dateObj = new Date();
            let subjectHash = {
                "sub:01:__philosophy__": "哲学",
                "sub:02:__economics__": "经济学",
                "sub:03:__law__": "法学",
                "sub:04:__pedagogy__": "教育学",
                "sub:05:__literature__": "文学",
                "sub:06:__history__": "历史学",
                "sub:07:__science__": "理学",
                "sub:08:__engineering__": "工学",
                "sub:09:__agronomy__": "农学",
                "sub:10:__medicine__": "医学",
                "sub:12:__management__": "管理学",
                "sub:13:__art__": "艺术学",
            };

            let myModifyQb = {
                "title": libName,
                "createtime": dateObj.getFullYear() + "年" + (dateObj.getMonth() + 1) + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                "kopoints": libKoPoints,
                "subjectclass": subjectHash[this.$refs.classOptions.value],
                "subjectclassid": this.$refs.classOptions.value,
            };

            this.$axios.post('/apiQuestion/u/modify_qb?libId=' + libId, myModifyQb).then(res => {
                this.reload();
            }).catch(e => {
                console.log(e);
            });
        }
    },
    mounted() {
    },

    created() {
    }
}
</script>

<style scoped>
.hv-list-frame {
    border: 1px solid;
    border-color: #e3e3e3;
    border-radius: 14px;
    min-height: 55px;
    margin-top: 10px;
    margin-bottom: 10px;

    /* Center vertically */
    display: flex;
    flex-direction: column;
}

.hv-list-frame:hover {
    border: none;
    box-shadow: 0 0 10px #cbcbcb;
}

.hv-list-frame-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.hv-list-frame-title-container {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    margin-left: 20px;
    margin-top: 10px;
    width: 100%;
}

.hv-list-frame-title-icon {
    display: flex;
    align-items: center;
}

.hv-list-frame-title-title {
    font-size: 1.3em;
    font-weight: bold;
    margin-left: 10px;
    position: relative;
}

.hv-list-frame-title-title::after {
    position: absolute;
    cursor: pointer;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.hv-list-frame-expand-icon {
    margin-left: auto;
    margin-right: 20px;
    position: relative;
}

.hv-list-frame-expand-icon::after {
    position: absolute;
    cursor: pointer;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.hv-list-frame-details {
    /*margin-left: 20px;*/
    margin-right: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.creator {
    font-size: 0.5em;
    color: #6f6f6f;
}

.ko-points {
    font-size: 0.9em;
}

.operate-button {
    margin-left: auto;
}

.hv-list-frame-buttons {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.hv-list-frame-not-expand-icon {
    margin-left: auto;
    margin-right: 0;
    position: relative;
}

.hv-list-frame-not-expand-icon::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.page-indicator-surface {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.page-num {
    border: 1px solid;
    border-color: #e3e3e3;
    height: 38px;
    min-width: 42px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.page-num:hover {
    background-color: #274DE2;
    color: white;
}

.share-dropdown-button {
    position: relative;
    display: flex;
    flex-direction: row;
}

.dropdown-menu {
    position: absolute;
    width: 150px;
    height: 80px;
    border-radius: 8px;
    background-color: white;
    border: #707070 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.dropdown-option {
    transition: background-color 0.3s;
}

.dropdown-option:hover {
    cursor: pointer;
    background-color: #e8e8e8;
}

.share {
    position: relative;
}

.share::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none;
}


/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>
