<template>
  <div id="screen-bar">
    <div id="screen-bar-left">
      <span @click="click('sort','time')" :class="'screen-item ' + (filter.sort == 'time' ? 'active' : '')">按时间排序</span>
      <span @click="click('sort','group')" :class="'screen-item ' + (filter.sort == 'group' ? 'active' : '')">按课程整理</span>
    </div>

    <div id="screen-bar-right">
      <span @click="click('select','finish')" :class="'screen-item ' + (filter.select == 'finish' ? 'active' : '')">已结束</span>
      <span @click="click('select','close')" :class="'screen-item ' + (filter.select == 'close' ? 'active' : '')">未开放</span>
      <span @click="click('select','open')" :class="'screen-item ' + (filter.select == 'open' ? 'active' : '')">已开放</span>
      <span @click="click('select','all')" :class="'screen-item ' + (filter.select == 'all' ? 'active' : '')">全部</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenBar",
  props: {
    filter: {
      sort: 'group', // group | time
      select: 'all', // all | open | close | finish
    }
  },
  model: {
    prop: 'filter',
    event: 'changeStatus'
  },
  watch: {
    filter(newV) {
      this.$emit('changeStatus',newV)
    }
  },
  methods: {
    click(type,val) {
      this.filter[type] = val;
      this.$emit('click');
    }
  }
}
</script>

<style scoped lang="less">
#screen-bar {
  display: flex;
  //width: 100%;
  margin: 0 15px 0 30px;

  #screen-bar-left {
    flex: 1;
    display: flex;
  }

  #screen-bar-right {
    display: flex;
  }

  .screen-item {
    display: block;
    padding: 20px 10px 0 10px;
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
  }

  .active {
    color: #008AFF;
  }
}
</style>
