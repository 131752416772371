import { render, staticRenderFns } from "./Invigilate.vue?vue&type=template&id=516f3c1c&scoped=true&"
import script from "./Invigilate.vue?vue&type=script&lang=js&"
export * from "./Invigilate.vue?vue&type=script&lang=js&"
import style0 from "./Invigilate.vue?vue&type=style&index=0&id=516f3c1c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516f3c1c",
  null
  
)

export default component.exports