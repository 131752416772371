<template>
    <div id="peopleGrade">
        <div class="main" ref="main"></div>
    </div>
</template>

<script>
const echarts = require('./echarts.min');
export default {
    name: "PeopleGrade",
    data() {
        return {
            myChart:null
        }
    },
    methods:{

        initChart(){
            let chartDom = this.$refs.main;
            this.myChart = echarts.init(chartDom);
            let option;
            option = {
                title: {
                    text: '分数'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['平均分', '普奇文','满分']
                },
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    // type: 'category',
                    // boundaryGap: false,
                    data: ['单项选择题', '填空题', '总分'],
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '平均分',
                        type: 'line',
                        // stack: 'Total',
                        data: [8, 2.5, 10.5]
                    },
                    {
                        name: '普奇文',
                        type: 'line',
                        // stack: 'Total',
                        data: [9, 3, 12]
                    },
                    {
                        name: '满分',
                        type: 'bar',
                        barWidth : 75,
                        itemStyle:{
                            normal:{
                                color:'rgba(116,142,222,0.3)'
                            }
                        },
                        data: [12, 4, 16]
                    }
                ]
            };

            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
    mounted() {
        this.initChart();
    }
}
</script>

<style scoped>
    #peopleGrade {
        width: 100%;
        height: 70%;
    }
    .main{
        padding-top: 4%;
        width: 70%;
        height: 100%;
        margin-left: 10%;
    }
</style>
