<template>
    <div id="group">
        <div id="header">
            <div class="card-title">群组</div>
            <div class="card-options" @click="switchTabs(false);">
                <div :style="myData.ok ? 'color: #054FBF;' : ''">班级管理</div>
                <div :style="myData.ok ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(true);">
                <div :style="!myData.ok ? 'color: #054FBF;' : ''">教务组管理</div>
                <div :style="!myData.ok ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
        </div>
        <div id="body">

            <transition name="slide-fade1">
                <div class="manager" v-show="myData.ok">
                    <div class="bar">
                        <div class="bar_title">班级
                            <button>+ 新建</button>
                        </div>
                        <div class="scroll">
                            <ul>
                                <li v-for="(item,index) of classMessage" :class="classSelected === index ? 'selected':''">
                                    <div @click="selected('classSelected',index)"  class="fa">
                                        <div class="title">
                                            {{item.Name}}
                                        </div>
                                        <div class="number">
                                            {{item.Number}}
                                        </div>
                                    </div>
                                    <img src="../../assets/common/click.svg">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="bar">
                        <div class="bar_title">学生名单
                            <button>+ 添加</button>
                        </div>
                        <div class="scroll">
                            <ul>
                                <li v-for="(item,index) of studentMessage" :class="studentSelected === index ? 'selected':''">
                                    <div @click="selected('studentSelected',index)"  class="fa">
                                        <div class="st_title">
                                            {{item.Name}}
                                        </div>
                                    </div>
                                    <img src="../../assets/common/click.svg">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-for="(item,index) in studentMessage" v-show="index === studentSelected" class="content">
                        <ViewAndSet :name="item.Name"></ViewAndSet>
                    </div>
                </div>
            </transition>

            <transition name="slide-fade2">
                <div class="manager" v-show="!myData.ok">
                    <div class="bar">
                        <div class="bar_title">分类</div>
                        <div class="scroll">
                            <ul>
                                <li v-for="(item,index) of sort" :class="sortSelected === index ? 'selected':''">
                                    <div @click="selected('sortSelected',index)"  class="fa">
                                        <div class="title">
                                            {{item.Name}}
                                        </div>
                                        <div class="number">
                                            {{item.Number}}
                                        </div>
                                    </div>
                                    <img src="../../assets/common/click.svg">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="bar">
                        <div class="bar_title">教师名单
                            <button>+ 添加</button>
                        </div>
                        <div class="scroll" v-show="sortSelected === 0">
                            <ul>
                                <li v-for="(item,index) of teacherMessage" :class="teacherSelected === index ? 'selected':''">
                                    <div @click="selected('teacherSelected',index)"  class="fa">
                                        <div class="st_title">
                                            {{item.Name}}
                                        </div>
                                        <div v-show="item.admin === true" class="admin">
                                            创建者
                                        </div>
                                    </div>
                                    <img src="../../assets/common/click.svg">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-for="(item,index) in teacherMessage" v-show="index === teacherSelected" class="content">
                        <TeacherView :name="item.Name" :idx="index"></TeacherView>
                        <TeacherSet></TeacherSet>
                    </div>

                </div>
            </transition>
        </div>

    </div>
</template>

<script>
import Search from "../../components/common/Search";
import Buttons from "../../components/Buttons";
import ViewAndSet from "../../components/Class/ViewAndSet";
import TeacherView from "../../components/Class/TeacherView";
import TeacherSet from "../../components/Class/TeacherSet";
export default {
    name: "Group",
    components: {TeacherSet, TeacherView, ViewAndSet, Buttons, Search},
    data:function () {
        return {
            myData:{
                ok: true
            },
            classMessage:[
                {
                    Name: '19级1班',
                    Number:'12人'
                },
                {
                    Name: '19级2班',
                    Number:'15人'
                }
            ],
            studentMessage:[
                {
                    Name:'学生1'
                },
                {
                    Name:'学生2'
                },
                {
                    Name:'学生3'
                },
                {
                    Name:'学生4'
                },
                {
                    Name:'学生5'
                },
                {
                    Name:'学生6'
                },
                {
                    Name:'学生7'
                },
                {
                    Name:'学生8'
                },
                {
                    Name:'学生9'
                },
                {
                    Name:'学生10'
                },
                {
                    Name:'学生11'
                },
                {
                    Name:'学生12'
                }
            ],
            sort:[
                {
                    Name:'教师',
                    Number:'2人'
                },
                {
                    Name:'助教',
                    Number:'0人'
                }
            ],
            teacherMessage:[
                {
                    Name:'毛东东',
                    admin:false
                },
                {
                    Name:'李兰辰',
                    admin: true
                }
            ],
            classSelected: 0,
            studentSelected:0,
            sortSelected:0,
            teacherSelected:0,
        }
    },
    methods:{
        switchTabs(isOk) {
            if(isOk === this.myData.ok) this.$set(this.myData,"ok",!isOk);
        },
        selected(name,index) {
            this.$set(this.$data,name,index);
        }
    }
}
</script>

<style scoped>
    #group{
        width: 100%;
        height: 100%;
    }
    #header {
        top: 0;
        width: 100%;
        height: 55px;
        border-bottom: 1px solid #e9e9e9;
    }

    .card-title {
        user-select: none;
        float: left;
        margin-top: 12px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }
    .card-options {
        user-select: none;
        float: left;
        margin-left: 50px;
        margin-top: 20px;
        color: #999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*font-weight: 500;*/
        /*font-size: 16px;*/
    }
    .card-options:hover {
        cursor: pointer;
    }

    .option-underline {
        height: 4px;
        width: 110%;
        background-color: #054FBF;
        margin-top: 10px;
    }
    a{
        color: black;
    }
    #body {
        width: 100%;
        height: calc(100% - 55px);
        overflow: auto;
    }
    .admin {
        line-height: 18px;
        height: 18px;
        width: 45px;
        text-align: center;
        background: #DD1E1E;
        color: white;
        border-radius: 25px;
        margin-top: 10px;
        float: left;
        font-size: 10px;
        font-weight: 100;
    }


    .manager {
        border-radius: 15px;
        width: 94%;
        margin-left: 3%;
        height: calc(100% - 70px);
        margin-top: 35px;
        box-shadow: 0px 0px 6px #dbdbdb;
    }
    .bar {
        float: left;
        min-width: 140px;
        width: 15%;
        height: 100%;
        border-right: 1px solid #D9D9D9;
    }
    .bar_title {
        width: 100%;
        line-height: 45px;
        text-indent: 16px;
        font-weight: 100;
        font-size: 19px;
        height: 45px;
        border-bottom: 1px solid #D9D9D9;
    }
    @media screen and (max-width: 1300px){
        .bar_title {
            font-size: 17px;
            text-indent: 10px;
        }
    }
    .fa {
        width: 125px;
        height: 76px;
        float: left;
    }
    @media screen and (max-width: 1368px){
        .admin {
            height: 55px;
            width: 17px;
            text-align: center;
            background: #DD1E1E;
            color: white;
            border-radius: 25px;
            margin-top: 10px;
            float: left;
            font-size: 10px;
            font-weight: 100;
        }
        .fa {
            width: 100px;
            height: 76px;
            float: left;
        }
    }


    .bar_title > button {
        float: right;
        margin-top: 10px;
        margin-right: 6px;
        line-height: 24px;
        border-radius: 55px;
        border: none;
        background: #054FBF;
        color: white;
        height: 26px;
        width: 52px;
        font-weight: 100;
        font-size: 13px;
    }
    button:hover {
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
        cursor: pointer;
    }
    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
    }
    li {

        /*无法复制*/
        -moz-user-select:none; /* Firefox私有属性 */
        -webkit-user-select:none; /* WebKit内核私有属性 */
        -ms-user-select:none; /* IE私有属性(IE10及以后) */
        -khtml-user-select:none; /* KHTML内核私有属性 */
        -o-user-select:none; /* Opera私有属性 */
        user-select:none; /* CSS3属性 */


        /*display: flow;*/
        /*text-align: center;*/
        height: 76px;
        width: 100%;
        color: black;
        font-size: 14px;
        border-bottom: 1px solid #D9D9D9;
    }

    .fa:hover {
        cursor: pointer;
    }
    .title {
        text-indent: 16px;
        margin-top: 18px;
        font-size: 19px;
    }
    .st_title {
        float: left;
        text-indent: 16px;
        line-height: 76px;
        font-size: 19px;
    }
    .number {
        font-size: 13px;
        font-weight: 100;
        color: #999999;
        text-indent: 19px;
        margin-top: 4px;
        /*margin-top: 45px;*/
        /*margin-left: -50px;*/
    }
    .scroll {
        overflow: auto;
        width: 100%;
        height: calc(100% - 45px);
    }
    li > img {
        margin-top: 24px;
        float: right;
        width: 30px;
        margin-right: 5px;
    }
    li > img:hover{
        cursor: pointer;
    }
    .content {
        height: 100%;
        float: left;
        width: calc(100% - max(30% ,280px) - 2px);
    }

    .selected {
        background: #F0F6FF;
    }


    .slide-fade1-enter-active {
        transition: all 0.3s ease;
    }

    .slide-fade1-enter,.slide-fade1-leave-to{
        transform: translateX(-300px);
        opacity: 0;
    }

    .slide-fade2-enter-active{
        transition: all 0.3s ease;
    }
    .slide-fade2-enter,.slide-fade2-leave-to{
        transform: translateX(300px);
        opacity: 0;
    }


    /*滚动条样式*/
     .scroll::-webkit-scrollbar {
        width:3px;
     }

    ::-webkit-scrollbar-track-piece {
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:#dddddd;
        background-clip:padding-box;
        min-height:28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#bbb;
    }
</style>
