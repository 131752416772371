<template>
    <div id="courseAndExam">
        <transition name="slide-fade">
            <div id="screen" v-if="ok"></div>
        </transition>
        <transition name="bounce">
            <div id="Pop-ups" v-if="ok">
                <div id="pTitle">
                    <div id="pTitleFont">加入考试 / 课程</div>
                    <img @click="sendOk" id="close" src="../../assets/SideBar/close.svg">
                </div>
                <router-link :to = "isLogin ? './exam/create' : '../login'">
                    <div class="pBody">
                        <div class="tooltip">
                            <span class="tooltiptext">建立单场考试，适合单场次的竞赛、选拔等场景</span>
                            <img style="margin-top: 26%" src="../../assets/SideBar/exam.svg"><br>
                            <div>考试</div>
                        </div>
                    </div>
                </router-link>
                <router-link to="#">
                    <div class="pBody">
                        <div class="tooltip">
                            <span class="tooltiptext">建立课程管理多场考试，拥有对多场考试按照比例计算总分等功能，适合对单门课程进行考核等场景</span>
                            <img style="margin-top: 26%" src="../../assets/SideBar/class.svg"><br>
                            <div>课程</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "CourseAndExam",
    props:{
        isLogin:Boolean,
        ok:Boolean
    },
    methods:{
        sendOk() {
            let okk = !this.ok;
            this.$emit('sendOk',okk)
        }
    }

}
</script>

<style scoped>
    #courseAndExam{}

    .tooltip {
        margin-top: 36px;
        position: relative;
        display: inline-block;
    }

    a {
        color: #000000;
        text-decoration:none;
    }

    #Pop-ups {
        z-index:251;
        overflow:auto;
        width: 590px;
        height: 310px;
        border-radius: 10px;
        background-color: #ffffff;
        position: fixed;
        top: 25%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    #screen {
        z-index:250;
        display: block;
        overflow:auto;
        width: 100%;
        height: calc(100% + 55px);
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: -55px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    #pTitle {
        width: 100%;
        height: 55px;
        border-bottom: #E8EAEC 1px solid;
    }
    #pTitleFont {
        font-weight: 100;
        height: 100%;
        line-height: 55px;
        float: left;
        margin-left: 23px;
        font-size: 19px;
        font-family: "Microsoft Yahei";
    }
    #close{
        float: right;
        width: 15px;
        margin-right: 23px;
        margin-top: 20px;
    }
    #close:hover {
        cursor: pointer;
    }
    .pBody {
        text-align: center;
        font-weight: 100;
        border-radius: 5px;
        font-size: 21px;
        margin-left: 70px;
        margin-top: 40px;
        float: left;
        width: 190px;
        height: 175px;
        box-shadow: 0px 0px 9px #cccccc;
    }

    .slide-fade-enter-active {
        transition: all .5s ease;
    }
    .slide-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }

    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

    .tooltip .tooltiptext {

        visibility: hidden;
        width: 250px;
        background-color: #efefef;
        color: #666666;
        /*text-align: center;*/
        padding: 10px 3px 10px 11px;
        border-radius: 6px;
        bottom: 100%;
        left: 50%;
        margin-left: -120px;
        /* position */
        font-size: 16px;
        font-weight: 100;
        position: absolute;
        text-align: left;
        text-indent: 33px;
    }

    /* hover and show tooltip */
    .tooltip:hover .tooltiptext {
        visibility: visible;
        z-index: 253;
    }
</style>
