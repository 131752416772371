<template>
    <div class="m_choose">
        <div class="title">
            <span>{{index}}、</span><span>{{questionObject.title}}</span>
        </div>
        <div class="content">
            <el-checkbox-group v-model="ans">
                <div class="option" v-for="(val,idx) in questionObject.options">
                    <el-checkbox @change="$emit('answer', ans)" :label="val.id">
                        <span class="option-content">{{String.fromCharCode('A'.charCodeAt(0) + idx)}}. {{val.title}}</span>
                    </el-checkbox>
                </div>
            </el-checkbox-group>
        </div>
    </div>
</template>

<script>

    export default {
        name: "MultiChoose",
        data() {
            return {
                ans: []
            }
        },
        props: {
            index: {
                type: String,
                default: () => '1'
            },
            questionObject: {
                type: Object,
                default: function () {
                    return {};
                },
            },
        },
        model: {
            prop: 'answer',
            event: 'answer'
        },
        created() {
            this.ans = this.questionObject.answer;
        },
    }
</script>

<style scoped lang="less">
    .m_choose {
        font-size: 18px;

        .title {
            margin: 16px 0 0 0;
        }

        .content {
            margin-left: 40px;
        }
    }

    .option-content {
        font-size: 16px;
        margin-left: 10px;
        font-weight: 400;
    }

    .option {
        margin: 7px 0;
    }


</style>
