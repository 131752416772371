<template>
    <div id="backstageSideBar">
        <div class="choose">
            <ul>
                <li v-for="(item,i) in list" :class="{selected: isActive[i],notSelected:!isActive[i],first:(i===0),end:(i===6)}" @click="change(i)">
                    <router-link :to="Link[i]">
                        <img class="a" :src="url[i]">
                        <div class="cf" :class="{selected: isActive[i]}">{{item}}</div>
                    </router-link>
                </li>
            </ul>
        </div>
        <div style="height: 108px;">
        <router-link to="#">
            <img class="help" src="../../assets/SideBar/welcome use aojie.svg">
        </router-link>
        </div>
        <div id="footer">
            <p>傲杰考试系统 V1.0傲杰之星</p>
            <p>©兴风科技2022</p>
            <p>浙ICP备20011690号-3</p>
        </div>
    </div>
</template>

<script>
import set from '../../assets/SideBar/set.svg'
import notice from '../../assets/SideBar/notice.svg'
import mans from '../../assets/SideBar/mans.svg'
import admin from '../../assets/SideBar/admin.svg'
import invigilation from '../../assets/SideBar/invigilation.svg'
import questionbank from '../../assets/SideBar/questionbank.svg'
import testpaper from '../../assets/SideBar/testpaper.svg'
import knowledge from '../../assets/SideBar/knowledge.svg'
import exam from '../../assets/SideBar/exam.svg'
import lookllook from '../../assets/SideBar/mans.svg'

export default {
    name: "BackstageSideBar",
    data: function () {
        //这里依次输入侧边栏网站名字
        let Link = ['analyze','account','permission','examManage','examination','score','face'];

        let isActive = []
        for(let i = 0;i < 7; i++) {
            if(this.$route.name === Link[i]) isActive.push(true)
            else isActive.push(false)
        }

        let list = [
            '统计分析',
            '账号管理',
            '权限管理',
            '考试管理',
            '考务管理',
            '成绩管理',
            '人脸认证',
        ]
        let url = [
            set,
            mans,
            notice,
            admin,
            invigilation,
            questionbank,
            testpaper,
            knowledge,
            exam,
            lookllook
        ]
        return {
            isActive,
            Link,
            list,
            url
        }
    },
    methods:{
        change(idx) {
            for(let i = 0;i < 7; i++) {
                if(i === idx) this.$set(this.isActive,i,true);
                else this.$set(this.isActive,i,false);
            }
        }
    }
}
</script>

<style scoped>
    #backstageSideBar{
        margin-left: 16px;
        float: left;
        width: 165px;
        height: calc(100% - 55px);
    }
    .choose {
        width: 100%;
        margin-top: 16px;
        color: white;
        border-radius: 15px;
        background: white;
    }

    ul {
        list-style: none;
        display: block;
        margin: 0px;
        padding: 0px;
    }
    li {
        line-height: 45px;
        text-align: center;
        height: 45px;
        width: 100%;
        color: black;
        font-size: 14px;
        font-weight: 100;
    }
    .notSelected:hover{
        background: rgba(5,79,191,0.1);
    }
    .help {
        width: 100%;
        margin-top: 16px;
        border-radius: 15px;
    }

    .a {
        margin-left: 35px;
        float: left;
        margin-top: 13px;
        width: 18px;
    }

    .cf{

        float: left;
        margin-left: 18px;
    }
    a {
        height: 100%;
        width: 100%;
        color: black;
        display: block;
        text-decoration:none;
    }
    .selected {
        color: white;
        background: #054FBF;
    }
    li:hover {
        cursor: pointer;
    }
    .first {
        border-radius: 15px 15px 0 0;
    }
    .end {
        border-radius: 0 0 15px 15px;
    }



    .help:hover {
        cursor: pointer;
    }

    #footer {
        margin-top: 11px;
    }

    p {
        text-align: center;
        color: #999999;
        font-size: 12.5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>
