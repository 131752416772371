import ClassArea from "../views/class/ClassArea";
import Notice from "../views/class/Notice";
import CExam from "../views/class/Exam";
import CGrade from "../views/class/Grade";
import CTask from "../views/class/Task";

export default [
    {
        path: '/class/classArea',
        name: 'classArea',
        component: ClassArea,
        children: [
            {
                path: '/',
                redirect: 'notice'
            },
            {
                path:'notice',
                name:'cNotice',
                component: Notice
            },
            {
                path:'exam',
                name:'cExam',
                component: CExam
            },
            {
                path:'grade',
                name:'cGrade',
                component: CGrade
            },
            {
                path:'task',
                name:'cTask',
                component: CTask
            }
        ]
    }
]
