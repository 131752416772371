<template>
    <div>
        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center;">
                <div class="card-title">医学统计学计算题大题</div><!--title-->
                <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
                    <!--new exam button-->
                    <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"
                             btn-color="#054FBF"
                             txt-color="white"
                             content="+ 添加题目"
                             :h="30"
                             :w="80"
                             :f-size="5"></buttons>
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div style="position:relative;">
                            <input type="text" placeholder="搜索题目..."
                                   style="height: 30px; border-radius: 50px; border: none; background-color: #EFEFEF; text-indent: 10px;">
                        </div>

                        <div class="search-qslib"
                             style="position: absolute; z-index: 20; right: 0; margin-right: 65px; margin-top: 5px;">
                            <!--search icon-->
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.043" height="15.267"
                                 viewBox="0 0 15.043 15.267">
                                <g id="组_44" data-name="组 44" transform="translate(-1621 -119)">
                                    <g id="椭圆_8" data-name="椭圆 8" transform="translate(1621 119)" fill="none"
                                       stroke="#606060" stroke-width="1.5">
                                        <circle cx="5.75" cy="5.75" r="5.75" stroke="none"/>
                                        <circle cx="5.75" cy="5.75" r="5" fill="none"/>
                                    </g>
                                    <line id="直线_8" data-name="直线 8" x2="5" y2="5.25"
                                          transform="translate(1630.5 128.5)" fill="none" stroke="#606060"
                                          stroke-width="1.5"/>
                                </g>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
            <div class="line"></div><!--line-->
        </div>

        <transition name="slide-fade">
            <div v-show="true" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;"><!--content of "题目"-->
                <lite-list :data-object="dataObject"></lite-list>
            </div>
        </transition>
    </div>
</template>

<script>
import LiteList from "../../../components/LiteList";
import MainSideBar from "../../../components/SideBar/MainSideBar";
import BasalCard from "../../../components/Card/BasalCard";
import CourseJoinCard from "../../../components/Card/CourseJoinCard";
import CourseManageCard from "../../../components/Card/CourseManageCard";
import QuestionBankCard from "../../../components/Card/QuestionBankCard";
import ScoreCard from "../../../components/Card/ScoreCard";
import GroupCard from "../../../components/Card/GroupCard";
import Buttons from "../../../components/Buttons";

export default {
    name: "Problems",
    components: {
        LiteList,
        Buttons,
        GroupCard,
        ScoreCard,
        QuestionBankCard,
        CourseManageCard,
        CourseJoinCard,
        BasalCard,
        MainSideBar
    },
    data() {
        return {
            dataObject: [
                {
                    "qId": "71a422c8-6976-415a-9d4a-5902f14da152",
                    "title": "请问吃下含有囊尾蚴的猪肉会…",
                    "tp": "选择题",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                },
                {
                    "qId": "2d8f164a-66b7-4981-b049-8132fcc63400",
                    "title": "LOAEL的含义是",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "3f2e2a2e-cb37-45fa-b5d0-65d85def7bfa",
                    "title": "ATP的组成是",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "bd12999c-74a1-4a57-8b48-f3b61debc366",
                    "title": "烯烃一般会发生什么反应？",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "57cd7bcb-29c0-4666-89d5-2e2ea7c51795",
                    "title": "已知一组数字，它的平均值是3.14，而它的中位数也是3.14，那么…",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "0e0d48ad-f305-46d0-bf64-9374e87ef2d6",
                    "title": "吸烟的人中，有68%的几率会得肺癌，而有10%的几率会得胃癌…",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "112f7e88-29a3-4be2-97ce-76392eed12d8",
                    "title": "已知，抛一枚硬币，正面向上的概率是1/2，请问同时抛掷4枚硬…",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "a10a4ddc-988b-4fcb-b7a7-f3a7b9829eab",
                    "title": "正态分布的特征是？",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "71dcf559-5b85-48bb-8c20-63ced052accf",
                    "title": "下列情况中，哪个是偏态分布？",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "48314529-e393-4016-a006-029a348c6c37",
                    "title": "T检验的要求是？",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "d0592170-c764-4af8-bb7d-8ecfab70eb95",
                    "title": "卡方检验的步骤是？",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "a287c0b8-3ae3-4b5e-a7b9-c5f868ecb462",
                    "title": "活着飞回美军航母上的战斗机，其机翼、护甲部分弹孔分布密集…",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "121019ff-9643-4893-a8a9-ace5dc35fb1d",
                    "title": "第二次世界大战中，情报战作为破译敌军机密信息，掌握战场主…",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
                {
                    "qId": "2f30a7cd-377f-4ce5-9f05-0f5cc41dd5d7",
                    "title": "关于医学统计学，下列说法正确的是？",
                    "creator": "检验讲师SS",
                    "createTime": "2022年1月2日 10:00",
                    "tp": "选择题",
                },
            ],
        }
    },
    methods: {
        switchTabs(position) {
            /**
             * switch tabs
             */
            this.$data.isShow.forEach((item, index) => {
                if (this.$data.isShow[index]) this.$set(this.$data.isShow, index, false);
            });
            this.$set(this.$data.isShow, position, true);
        }
    },
    mounted() {
    },
    created() {
    },
}
</script>
<style scoped>
.card-title {
    margin-top: 14px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 13px;
}

.card-options {
    margin-left: 50px;
    margin-top: 24px;
    color: #666666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-options:hover {
    cursor: pointer;
}

.line {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}

.search-qslib {
    position: relative;
}

.search-qslib::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
