<template>
    <div id="grade">
        <div id="header">
            <div class="card-title">成绩</div>
            <div id="sc">
                <Search :name="'搜索考试...'"></Search>
            </div>
        </div>
        <div id="body">
            <ScoreCard :data-score-card="dataScoreCard[0]"></ScoreCard>
            <ScoreCard :data-score-card="dataScoreCard[1]"></ScoreCard>
        </div>
    </div>
</template>

<script>
import Search from "../../components/common/Search";
import ScoreCard from "../../components/Card/ScoreCard";
export default {
    name: "Grade",
    components: {ScoreCard, Search},
    data:function () {
        return {
            dataScoreCard: [
                {
                    title: "期中考试",
                    startTime: "2022-01-03 09:45:00",
                    endTime: "2022-01-03 11:45:00",
                    status:"已完成",
                    grade:"批改中",
                    rank:"/",
                },
                {
                    title: "平时测验",
                    startTime: "2022-01-03 09:45:00",
                    endTime: "2022-02-15 11:45:00",
                    status:"已完成",
                    grade:"100",
                    rank:"1",
                }
            ]
        }
    }
}
</script>

<style scoped>
#grade {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
#sc{
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}
#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}

::-webkit-scrollbar {
    width:5px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color:#dddddd;
    background-clip:padding-box;
    min-height:28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
}
</style>
