<template>
    <div>
        <!--        {{ $route.query.libId }}-->
        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center;">
                <div class="card-title">{{ $route.query.libTitle }}</div><!--title-->
                <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
                    <!--new exam button-->
                    <div @click="clickToPushArg('/u/funcCenter/problemSet/questions/newQuestion', $route.query.libId,
                    $route.query.libTitle);">
                        <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"
                                 btn-color="#054FBF"
                                 txt-color="white"
                                 content="+ 添加题目"
                                 :h="30"
                                 :w="80"
                                 :f-size="12"></buttons>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div style="position:relative;">
<!--                          <Search></Search>-->
                            <input v-model="kw" type="text" placeholder="搜索题目..."
                                   style="height: 30px; border-radius: 50px; border: none; background-color: #EFEFEF; text-indent: 10px;">
                        </div>

                        <div class="search-qslib" style="position: absolute; z-index: 20; right: 0; margin-right: 65px; margin-top: 5px;">
                            <!--search icon-->
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.043" height="15.267"
                                 viewBox="0 0 15.043 15.267">
                                <g id="组_44" data-name="组 44" transform="translate(-1621 -119)">
                                    <g id="椭圆_8" data-name="椭圆 8" transform="translate(1621 119)" fill="none"
                                       stroke="#606060" stroke-width="1.5">
                                        <circle cx="5.75" cy="5.75" r="5.75" stroke="none"/>
                                        <circle cx="5.75" cy="5.75" r="5" fill="none"/>
                                    </g>
                                    <line id="直线_8" data-name="直线 8" x2="5" y2="5.25"
                                          transform="translate(1630.5 128.5)" fill="none" stroke="#606060"
                                          stroke-width="1.5"/>
                                </g>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
            <div class="line"></div><!--line-->
        </div>

        <transition name="slide-fade">
            <div v-show="true" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
                <!--content of "题目"-->
                <lite-list :lib-title="$route.query.libTitle" :lib-id="$route.query.libId" :data-object="dataObject" :key-word="kw" :num-per-page="20"></lite-list>
            </div>
        </transition>
    </div>
</template>

<script>
import LiteList from "../../../components/LiteList";
import MainSideBar from "../../../components/SideBar/MainSideBar";
import Buttons from "../../../components/Buttons";
import Search from "../../../components/common/Search";

export default {
    name: "Questions",
    components: {
      Search,
        LiteList,
        Buttons,
        MainSideBar
    },
    data() {
        return {
            kw: '',

            dataObject: [
            ],
        }
    },
    methods: {
        switchTabs(position) {
            /**
             * switch tabs
             */
            this.$data.isShow.forEach((item, index) => {
                if (this.$data.isShow[index]) this.$set(this.$data.isShow, index, false);
            });
            this.$set(this.$data.isShow, position, true);
        },
        clickToPush(url) {
            this.$router.push(url);
        },
        clickToPushArg(url, id, title) {
            this.$router.push({
                path: url,
                query: {
                    libId: id,
                    libTitle: title,
                }
            });
        },
    },
    mounted() {
    },
    created() {
        this.$axios.get('/apiQuestion/u/get_questions?libId=' + this.$route.query.libId).then(res => {
            this.$data.dataObject = JSON.parse(res['data']['msg']);
        }).catch(e => {
            console.log(e);
        });
    },
}
</script>
<style scoped>
.card-title {
    margin-top: 14px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 13px;
}

.card-options {
    margin-left: 50px;
    margin-top: 24px;
    color: #666666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-options:hover {
    cursor: pointer;
}

.line {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}

.search-qslib {
    position: relative;
}

.search-qslib::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
