<template>
  <div id="invigilate">
    <div id="header">
      <div class="card-title">我的监考</div>
      <div id="sc">
        <Search :name="'搜索课程...'"></Search>
      </div>
    </div>
    <ScreenBar v-model="filter"></ScreenBar>
    <div id="body"  style="overflow: auto;">

      <BasalCard :data-basal-card="{
        title: '大学生创业基础期末考试',
        startTime:'2022-02-03 00:00:00',
        endTime:'2022-02-12 23:59:59',
        status:'已开放',
        path:'/ois'
      }" btnText='进入监考' path='/'></BasalCard>

      <BasalCard :data-basal-card="{
        title: '国学智慧期末考试',
        startTime:'2022-02-10 00:00:00',
        endTime:'2022-02-12 23:59:59',
        status:'未开放'
      }" btnText='进入监考' path='/'></BasalCard>

      <BasalCard :data-basal-card="{
        title: 'Java Web开发期末考试',
        startTime:'2022-02-12 13:45:00',
        endTime:'2022-02-12 15:45:00',
        status:'未开放'
      }" btnText='进入监考' path='/'></BasalCard>

      <BasalCard :data-basal-card="{
        title: '《形势与政策（2021秋）...',
        startTime:'2022-01-01 00:00:00',
        endTime:'2022-01-01 23:59:59',
        status:'已结束'
      }" btnText='进入监考' path='/'></BasalCard>

      <div id="footer"></div>
    </div>
  </div>
</template>

<script>
import Search from "../../../components/common/Search";
import GroupCard from "../../../components/Card/GroupCard";
import BasalCard from "../../../components/Card/BasalCard";
import ScreenBar from "../../../components/common/ScreenBar";

const data = {
  filter: {
    sort: 'group', // group | time
    select: 'all', // all | open | close | finish
  }
}

export default {
  name: "Invigilate",
  components:{ScreenBar, BasalCard, GroupCard, Search},
  data() {
    return data;
  },

}
</script>

<style scoped lang="less">

#invigilate {
  width: 100%;
  height: 100%;
}

#header {
  top: 0;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #e9e9e9;
  /*border-radius: 15px 15px 0 0;*/
  .card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
  }
  #sc {
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
  }
}

#body {
  /*position: fixed;*/
  width: 100%;
  height: calc(100% - 56px - 37px);
}

</style>
