<template>
    <div>
        <div class="innerHeader">


            <div id="logo">
                <div id="name">{{ eName }}</div>
            </div>
            <router-link to=""><img id="user" src="../../assets/Header/user.png" alt=""></router-link>
            <router-link to=""><img id="mail" src="../../assets/Header/mail.svg" alt=""></router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InnerHeader',
    props: {
        eName: String,
        eId: String,
    },
    methods: {
        clickToPushArg(url, id, title) {
            this.$router.push({
                path: url,
                query: {
                    eId: id,
                    eTitle: title,
                }
            });
        },
    }
}
</script>

<style scoped lang="less">

//整个头
.innerHeader {
    width: 100%;
    height: 55px;
    background: white;
    border: none;
    text-align: center;
    line-height: 55px;
    border: 0px;
    outline: none;
    box-shadow: 0px 3px 15px #dbdbdb;
    min-width: 740px;
}

//logo和图片
#logo {
    margin-left: 30px;
    margin-right: 13px;
    float: left;
}

//logo-名字
#name {
    font-size: 18px;
    font-weight: 800;
}

ul {
    float: left;
    list-style: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

li {
    text-align: -webkit-match-parent;
    //width: 60px;
    float: left;
    margin-left: 25px;
}


//选中
.selected {
    color: #054FBF;
}

.selected::before, li > div:hover::before {
    content: "";
    width: 100%;
    height: 4px;
    background: #054FBF;
    position: absolute;
    bottom: 0;
    left: 0;
}

li > div {
    font-weight: 400;
    font-size: 16px;
    font-family: PingFang SC;
    color: #666666;
    display: block;
    position: relative;
    padding: 0 5px;
    text-decoration: none;
}

//邮箱
#mail {
    height: 18px;
    float: right;
    margin-top: 20px;
    margin-right: 33px;
}

#mail:hover {
    cursor: pointer;
}

//用户
#user {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    font-size: 5px;
    margin-right: 38px;
    float: right;
}

#user:hover {
    cursor: pointer;
}

a:hover {
    color: #054FBF;
    cursor: pointer;
}
</style>
