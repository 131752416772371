<template>
    <div id="myClassArea">
        <MainHeader></MainHeader>
        <TeacherSideBar></TeacherSideBar>
        <div id="body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import MainHeader from "../../components/Header/MainHeader";
import TeacherSideBar from "../../components/SideBar/TeacherSideBar";
export default {
    name: "MyClassArea",
    components: {TeacherSideBar, MainHeader}
}
</script>

<style scoped>
#myClassArea {
    width: 100%;
    height: 100%;
    min-width: 840px;
}
#body {
    width: calc(100% - 233px);
    height: calc(100% - 87px);
    min-height: 500px;
    margin-left: 16px;
    margin-top: 16px;
    float: left;
    background: white;
    border-radius: 15px;
}
</style>

