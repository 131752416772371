<template>
    <div>
        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center;">
                <div class="card-title">考生列表</div><!--title-->
                <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
                    <!--new exam button-->
                    <div>
                        <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"
                                 btn-color="#054FBF"
                                 txt-color="white"
                                 content="导出考生信息"
                                 :h="30"
                                 :w="100"
                                 :f-size="13"></buttons>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div style="position:relative;">
                            <input v-model="kw" type="text" placeholder="搜索考生..."
                                   style="height: 30px; border-radius: 50px; border: none; background-color: #EFEFEF; text-indent: 10px;">
                        </div>

                        <div class="search-exe"
                             style="position: absolute; z-index: 20; right: 0; margin-right: 65px; margin-top: 5px;">
                            <!--search icon-->
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.043" height="15.267"
                                 viewBox="0 0 15.043 15.267">
                                <g id="组_44" data-name="组 44" transform="translate(-1621 -119)">
                                    <g id="椭圆_8" data-name="椭圆 8" transform="translate(1621 119)" fill="none"
                                       stroke="#606060" stroke-width="1.5">
                                        <circle cx="5.75" cy="5.75" r="5.75" stroke="none"/>
                                        <circle cx="5.75" cy="5.75" r="5" fill="none"/>
                                    </g>
                                    <line id="直线_8" data-name="直线 8" x2="5" y2="5.25"
                                          transform="translate(1630.5 128.5)" fill="none" stroke="#606060"
                                          stroke-width="1.5"/>
                                </g>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
            <div class="line"></div><!--line-->
        </div>

        <examinee-table :data-object="examineeList" :num-per-page="15" :key-word="kw"></examinee-table>
    </div>
</template>

<script>
import Buttons from "../../../../components/Buttons";
import ExamineeTable from "../../../../components/ExamineeTable";

export default {
    name: "ExamineeList",
    components: {
        ExamineeTable,
        Buttons,
    },
    data() {
        return {
            kw: '',
            examineeList: [
                {
                    'exId': '001',
                    'stuId': '201912213701023',
                    'stuName': '普奇文',
                    'exStatus': 'normal_terminate',
                },
                {
                    'exId': '002',
                    'stuId': '201912213701005',
                    'stuName': '万冷雪',
                    'exStatus': 'normal_processing',
                },
                {
                    'exId': '003',
                    'stuId': '201912213701012',
                    'stuName': '胥永元',
                    'exStatus': 'slight_warning',
                },
                {
                    'exId': '004',
                    'stuId': '201912213701008',
                    'stuName': '左端',
                    'exStatus': 'normal_processing',
                },
                {
                    'exId': '005',
                    'stuId': '201912213701051',
                    'stuName': '江湛恩',
                    'exStatus': 'middle_warning',
                },
                {
                    'exId': '006',
                    'stuId': '201912213701001',
                    'stuName': '赏巧夏',
                    'exStatus': 'fatal_warning',
                },
                {
                    'exId': '007',
                    'stuId': '201912213701016',
                    'stuName': '阚尔丝',
                    'exStatus': 'sometimes_auto_sign_out',
                },
                {
                    'exId': '008',
                    'stuId': '201912213701039',
                    'stuName': '冀学',
                    'exStatus': 'normal_processing',
                },
                {
                    'exId': '009',
                    'stuId': '201912213701021',
                    'stuName': '竹骊洁',
                    'exStatus': 'force_terminate',
                },
                {
                    'exId': '010',
                    'stuId': '201912213701034',
                    'stuName': '许藏',
                    'exStatus': 'login_error',
                },
                {
                    'exId': '011',
                    'stuId': '201912213701027',
                    'stuName': '功思禹',
                    'exStatus': 'normal_processing',
                },
                {
                    'exId': '012',
                    'stuId': '201912213701046',
                    'stuName': '刘倩倩',
                    'exStatus': 'normal_processing',
                },
            ],
        };
    }
}
</script>

<style scoped>
.card-title {
    margin-top: 14px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 13px;
}

.search-exe {
    position: relative;
}

.search-exe::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.line {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
}
</style>
