import Main from "../views/function_center/ois/Main";
import MonitorQuestions from "../views/function_center/ois/MonitorQuestions";
import Abstract from "../views/function_center/ois/man/Abstract";
import Dashboard from "../views/function_center/ois/man/Dashboard";
import Chat from "../views/function_center/ois/man/Chat";
import ExamineeList from "../views/function_center/ois/man/ExamineeList";
import ExamineeDetails from "../views/function_center/ois/man/ExamineeDetails";

export default [
    {
        name: 'OIS',
        path: '/ois',
        component: Main,
        children: [
            {
                path: 'questions',
                name: 'questions',
                component: MonitorQuestions,
            },
            {
                path: 'manage',
                redirect: 'abstract',
            },
            {
                path: '',
                redirect: 'questions',
            },
            {
                path: 'abstract',
                name: 'abstract',
                component: Abstract,
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard,
            },
            {
                path: 'chat',
                name: 'chat',
                component: Chat,
            },
            {
                path: 'examinee_list',
                name: 'eList',
                component: ExamineeList,
            },
            {
                path: 'e_details',
                name: 'eDetails',
                component: ExamineeDetails,
            }
        ]
    }
]
