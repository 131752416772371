<template>
    <div class="number">
        <div class="left">
            <div class="title">人数统计</div>
            <div class="main" ref="main"></div>
        </div>
        <div class="right">
            <div class="top">
                <div class="content">
                    <div class="content_title">
                        总人数
                    </div>
                    <div class="content_body">
                        21124人
                    </div>
                </div>
                <div class="content">
                    <div class="content_title" style="margin-left: 8px">
                        学生
                    </div>
                    <div class="content_body" style="margin-left: 8px">
                        18180人
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="content">
                    <div class="content_title">
                        教师
                    </div>
                    <div class="content_body">
                        2016人
                    </div>
                </div>
                <div class="content">
                    <div class="content_title" style="margin-left: 8px">
                        教务人员
                    </div>
                    <div class="content_body" style="margin-left: 8px">
                        508人
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require('./echarts.min');
export default {
    name: "Number",
    methods:{
        initChart() {
            let chartDom = this.$refs.main;
            let myChart = echarts.init(chartDom);
            let option;
            option = {
                tooltip: {
                    trigger: 'item'
                },

                graphic:[
                    {
                        type:"text",
                        left:"center",
                        top:"36%",
                        style:{
                            text:"学生占比",
                            textAlign:"center",
                            fill: "#666666",
                            fontSize:19,
                            fontWeight:700
                        }
                    },
                    {
                        type:"text",
                        left:"center",
                        top:"56%",
                        style:{
                            text:"88%",
                            textAlign:"center",
                            fill:"#000000",
                            fontSize:28,
                            fontWeight:700
                        }
                    }
                ],
                series: [
                    {
                        name: '人数',
                        type: 'pie',
                        radius: ['78%', '90%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },

                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 18600, name: '学生' },
                            { value: 2016, name: '教师' },
                            { value: 508, name: '教务人员' },
                        ]
                    }
                ]
            };
            option && myChart.setOption(option);
        }
    },
    mounted() {
        this.initChart();
    }
}
</script>

<style scoped>
    .number {
        width: 100%;
        height: 234px;
        background: white;
        border: none;
        border-radius: 15px;
    }
    .left{
        width: 245px;
        height: 100%;
        float: left;
    }
    .right{
        width: calc(100% - 245px);
        height: 100%;
        float: left;
    }
    .title {
        margin: 12px 0 0 18px;
        font-size: 22px;
        font-weight: 600;
    }
    .main{
        margin-left: 58px;
        width: 200px;
        height: 180px;
    }
    .top{
        margin-left: 7%;
        margin-top: 58px;
        width: 84%;
        height: 72px;
        border-bottom: 1px solid #707070;
    }
    .bottom{
        margin-top: 9px;
        margin-left: 7%;
        width: 84%;
        height: 72px;
    }
    .content {
        width: 50%;
        height: 100%;
        float: left;
    }
    .content_title {
        font-size: 18px;
        font-weight: 600;
        color: #666666;
    }
    .content_body {
        margin-top: 9px;
        font-size: 22px;
        font-weight: 600;
        color: #000000;
    }
</style>
