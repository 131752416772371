<template>
    <div id="exam">
        <div id="header">
            <div class="card-title">考试</div>
            <div id="sc">
                <Search :name="'搜索考试...'"></Search>
            </div>
        </div>
        <div id="body">
            <div id="footer">
                <div :style="Check ? 'color: #000000':'color: #999999' "  style="margin-left: 110px;font-weight: 100;font-size: 15px">
                    <input @click="Check = !Check" style="float: left;margin-right: 8px" type="checkbox">
                    只显示未结束的考试
                </div>
            </div>
            <StudentExamCard :data-student-exam-card="dataStudentExamCard[0]"></StudentExamCard>
            <StudentExamCard v-show="!Check" :data-student-exam-card="dataStudentExamCard[1]"></StudentExamCard>
            <StudentExamCard :data-student-exam-card="dataStudentExamCard[2]"></StudentExamCard>
        </div>
    </div>
</template>

<script>
import Search from "../../components/common/Search";
import StudentExamCard from "../../components/Card/StudentExamCard";
export default {
    name: "Exam",
    components: {StudentExamCard, Search},
    data:function () {
        return {
            dataStudentExamCard: [
                {
                    title: "期末考试",
                    startTime: "2022-02-03 09:45:00",
                    endTime: "2022-02-03 11:45:00",
                    status:"开放登录",
                    Type:"双机位考试",
                    info:"双机位考试请提前登录",
                    enter:"进入"
                },
                {
                    title: "期中考试",
                    startTime: "2022-01-03 09:45:00",
                    endTime: "2022-01-03 11:45:00",
                    status:"已结束",
                    Type:"单机位考试",
                    info:"",
                    enter:"查看"
                },
                {
                    title: "平时测验",
                    startTime: "2022-01-03 09:45:00",
                    endTime: "2022-02-15 11:45:00",
                    status:"开放中(已完成)",
                    Type:"开卷",
                    info:"共三次考试机会，剩余2次",
                    enter:"查看"
                }
            ],
            Check:false
        }
    }
}
</script>

<style scoped>
    #exam {
        width: 100%;
        height: 100%;
    }
    #header {
        top: 0;
        width: 100%;
        height: 55px;
        border-bottom: 1px solid #e9e9e9;
    }
    #sc{
        float: right;
        width: 157px;
        margin-top: 13.5px;
        margin-right: 25px;
    }
    .card-title {
        user-select: none;
        float: left;
        margin-top: 12px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }
    input:hover {
        cursor: pointer;
    }
    #body {
        width: 100%;
        height: calc(100% - 55px);
        overflow: auto;
    }
    #footer {
        float: left;
        margin-top: 20px;
        margin-bottom: -20px;
        width: 100%;
    }
    ::-webkit-scrollbar {
        width:5px;
    }

    ::-webkit-scrollbar-track-piece {
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:#dddddd;
        background-clip:padding-box;
        min-height:28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#bbb;
    }
</style>
