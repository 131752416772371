import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueCookies from 'vue-cookies';


Object.assign(axios.defaults,{
  baseURL: '/'
});
// axios.defaults.withCredentials=true;
Vue.prototype.$axios = axios;

export default axios

Vue.use(ElementUI);
Vue.use(Toasted);
Vue.use(VueCookies);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
