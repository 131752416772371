<template>
  <div id="search">
    <input id="sc2539" :placeholder="name" type="text" />
    <img src="../../assets/common/search.svg"/>
  </div>
</template>

<script>
export default {
  name: "Search",
  props:{
    name:{
      type:String,
      default:'搜索'
    }
  }
}



</script>

<style scoped>
#search {
  position: relative;
  height: 28px;
  width: 156px;
}
input {
  height: 100%;
  width: 100%;
  border-radius: 50px;
  border: none;
  background-color: #efefef;
  color: #333333;
  font-size: 13px;
  font-weight: 100;
  outline: none;
  text-indent: 13px;
}
img {
  width: 15px;
  position: absolute;
  top: 8px;
  left: 133px;
}
img:hover{
  cursor: pointer;
}
</style>
