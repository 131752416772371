<template>
    <div id="functionCenter">
        <MainHeader></MainHeader><!--navbar-->
        <div id="body" style="overflow: auto;">
            <div class="banner">
                <h1>傲杰考试系统</h1>
                <p>本系统采用现代主流先进信息技术，结合后疫情时代高等教育的发展现状，基于教考分离模式与各高等院校常用考试流程进行深入设计，通过健全的题库建设、智能组卷与手动组卷相配合的组卷方式，实现教学与出卷相分离;通过双机位监考、多种智能监考手段和防作弊措施共同协作，完成教学与监考相独立;通过机器阅卷等辅助手段提升阅卷效率，达到教学与阅卷相分离的目的;同时采用可视化技术多维度展现学生的考试结果，方便任课老师优化教学内容，为全面评价教学效果和人才培养质量提供更为完善的方案，促进教考分离理念的落实，推动高等教育信息化改革。</p>
            </div>
            <div class="function">
                <video controls autoplay>
                    <source src="https://void-lee.cn/2102064.mp4" type="video/mp4">
                </video>
                <div class="select">
                    <ul>
                        <li :class="selectId === 0 ? 'selected' : ''"><a @click="change(0)">双机位监考</a></li>
                        <li :class="selectId === 1 ? 'selected' : ''"><a @click="change(1)">公共题库</a></li>
                        <li :class="selectId === 2 ? 'selected' : ''"><a @click="change(2)">题干可标注</a></li>
                        <li :class="selectId === 3 ? 'selected' : ''"><a @click="change(3)">考试结果多维度可视化</a></li>
                        <li :class="selectId === 4 ? 'selected' : ''"><a @click="change(4)">智能组卷</a></li>
                        <li :class="selectId === 5 ? 'selected' : ''"><a @click="change(5)">题型多样化</a></li>
                    </ul>
                </div>
                <div class="des">{{des[selectId]}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import MainHeader from "../components/Header/MainHeader";
import IndexApploudCard from "../components/Card/IndexApploudCard";

export default {
    name: "Index",
    components: {
        MainHeader,
        IndexApploudCard,
    },
    data() {
        return {
            selectId: 0,
            des: ['多角度实时视频监控考生，电脑摄像头作为第一机位，拍摄考生正面，手机辅助，作为第二机位摄像头；客户端自动检测考生行为是否违纪，发出报警信息；实时随机抓拍，采用人脸识别防替考。','提供各类具有专业特色、代表性强、数量充足的在线试题。推动高等教育资源整合，打破地域壁垒，实现资源共享。','实时保存做题进度与痕迹，提高在线答题效率和提升考试体验。','帮助教师进行宏观和微观的，成绩分析，提供精准的评价结果；归纳考生对考察，知识点的掌握情况；直观展现考生，总体的成绩分布；有效反映试卷的难易程度。','出卷老师：指定题目类型、考查知识点、难度等诸多条件。系统：自动从指定题库中随机抽取题目。=>学生：获得随机题目。','支持单选、多选、填空、判断、名次解释、论述、程序设计题等多类题型，满足不同专业对于在线考试的不同需求。']
        };
    },
    methods: {
        change(id) {
            this.selectId = id
        }
    }
}
</script>

<style scoped lang="less">
#functionCenter {
    height: 100%;
}

#body {
    width: 100%;
    min-height: 100%;
}

.banner {
    background: url("../assets/index/banner.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 40vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin: 0 0 30px 0;
        color: white;
        font-size: 5vw;
    }

    p {
        color: white;
        text-indent: 32px;
        font-weight: 300;
        font-size: 1.3vw;
        line-height: 2.2vw;
        width: 70%;
    }
}

.function {
    padding: 20px 0;
    video {
        border-radius: 20px;
        display: block;
        width: 90%;
        margin: 0 auto;
    }

    .select {
        margin: 30px;
        display: flex;
        justify-content: center;

        ul {
            float: left;
            list-style: none;
            display: block;
            margin: 0px;
            padding: 0px;

            li {
                text-align: -webkit-match-parent;
                //width: 60px;
                float: left;
                margin-left: 25px;
                font-size: 20px;

                a {
                    cursor: pointer;
                }
            }

            .selected > a {
                border-bottom: 4px #054FBF solid;
            }

        }

    }

    .des {
        text-indent: 32px;
        font-weight: 300;
        width: 80%;
        margin: 0 auto;
        height: 100px;
    }
}

.people {
    background: #eaeef1;
    .item {
        display: flex;
        padding: 50px;
        img {
            width: 145px;
            height: 200px;
            border-radius: 10px;
        }

        div {
            flex: 1;
            margin: 0 30px;

            h1 {
                font-weight: 400;
                color: black;
                margin: 0;
                font-size: 18px;
            }

            h2 {
                color: #555;
                font-weight: 400;
                margin: 0;
                font-size: 18px;
            }

            p {
                font-size: 17px;
                font-weight: 300;
            }
        }




    }
}

</style>
