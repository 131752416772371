<template>
    <div id="teacherSet" style="overflow: auto">
        <ClassAssignment :ok="ok" @sendOk="getOk"></ClassAssignment>
        <div class="body">
            <div class="title">考试权限</div>
            <div class="a">
                <div class="a_a">
                    <input type="checkbox" checked="checked">
                    <div class="a_a_a">允许创建试卷</div>
                    <div class="a_a_b">(默认允许)</div>
                </div>
                <div class="a_b">
                    <input type="checkbox" checked="checked">
                    <div class="a_a_a">允许创建考试</div>
                    <div class="a_a_b">(默认允许)</div>
                </div>
                <div class="a_b">
                    <input type="checkbox" checked="checked">
                    <div class="a_a_a">允许发布考试</div>
                    <div class="a_a_b">(默认允许)</div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="title">题库权限</div>
            <div class="a">
                <div class="a_a">
                    <input type="checkbox" checked="checked">
                    <div class="a_a_a">允许新建题库课程</div>
                    <div class="a_a_b">(默认允许)</div>
                </div>
                <div class="a_b">
                    <input type="checkbox">
                    <div class="a_a_a">允许查看所有题库课程</div>
                </div>
                <div class="a_b">
                    <input type="checkbox">
                    <div class="a_a_a">允许修改他人创建的课程题库</div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="title">班级管理</div>
            <div class="a">
                <div class="a_a">
                    <input type="checkbox" checked="checked">
                    <div class="a_a_a">允许邀请学生加入自己管理的班级</div>
                    <div class="a_a_b">(默认允许)</div>
                </div>
                <div class="a_b">
                    <button @click="ok=!ok">班级分配</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ClassAssignment from "../PopUps/ClassAssignment";
export default {
    name: "TeacherSet",
    components: {ClassAssignment},
    data:function () {
        let ok = false;
        return {
            ok
        }
    },
    methods:{
        getOk(okk) {
            this.ok = okk
        }
    }

}
</script>

<style scoped>
     #teacherSet {
         /*无法复制*/
         -moz-user-select:none; /* Firefox私有属性 */
         -webkit-user-select:none; /* WebKit内核私有属性 */
         -ms-user-select:none; /* IE私有属性(IE10及以后) */
         -khtml-user-select:none; /* KHTML内核私有属性 */
         -o-user-select:none; /* Opera私有属性 */
         user-select:none; /* CSS3属性 */

         width: 100%;
         height: calc(100% - 353px);
     }
     .body {
         margin-top: 30px;
         overflow: auto;
         width: 100%;
     }
     .title {
         float: left;

         font-size: 18px;
         font-weight: 600;
         margin-left: 45px;
     }
     .a {
         overflow: auto;
         float: left;
         margin-left: 180px;
     }
     .a_a {
         overflow: auto;
         /*margin-top: 10px;*/
     }
     .a_b {
         overflow: auto;
         margin-top: 10px;
     }
     .a_a > input {
         float: left;
         margin-top: 5px;
     }
     .a_b > input {
         float: left;
         margin-top: 5px;
     }

     .a_b > button {
         border-radius: 80px;
         border: none;
         background: #054FBF;
         color: white;
         height: 30px;
         width: 78px;
         font-weight: 100;
         margin-left: 6px;
     }
     .a_b > button:hover {
         -webkit-filter: brightness(90%);
         filter: brightness(90%);
         cursor: pointer;
     }

     .a_a_a{
         /*width: 200px;*/
         float: left;
         margin-left: 8px;
     }
     .a_a_b {
         float: left;
         font-weight: 100;
         font-size: 14px;
         margin-top: 3px;
         margin-left: 10px;
         color: #666666;
     }

     /*滚动条样式*/
     ::-webkit-scrollbar {
         width:5px;
     }

     ::-webkit-scrollbar-track-piece {
         border-radius: 3px;
     }

     ::-webkit-scrollbar-thumb {
         background-color:#dddddd;
         background-clip:padding-box;
         min-height:28px;
         border-radius: 10px;
     }

     ::-webkit-scrollbar-thumb:hover {
         background-color:#bbb;
     }


</style>
