<template>
  <div class="blank">
    <div class="title">填空（{{prob.data.qPrice}}分）</div>
    <div class="problem1">{{prob.data.qTitle}}</div>
    <div class="st" v-if="this.prob.data.correct">
      正确答案：{{prob.data.correct}}
    </div>
    <div class="body">
      <div style="overflow: auto">
        <div class="number">总人数：{{ans.length}}人</div>
        <div v-if="this.prob.data.correct" class="right">正确率：{{Math.round(statistic.crt / ans.length * 100)}}%</div>
      </div>
      <div class="st1">
        <div id="cloud">

        </div>
        <div id="circle">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'echarts-wordcloud'
import * as echarts from 'echarts'

export default {
  name: "Blank",
  data() {
    return {
      cloud: null,
      cloudOption: {
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        tooltip:{},
        series: [{
          type: 'wordCloud',
          //maskImage: maskImage,
          sizeRange: [15, 80],
          rotationRange: [0, 0],
          rotationStep: 45,
          gridSize: 20,
          // shape: 'pentagon',
          width: '100%',
          height: '100%',
          textStyle: {
            color: function () {
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')';
            }
          },
          emphasis: {
            textStyle: {
              color: '#f00'
            }
          },
          data: []
        }]
      },
      circle: null,
      circleOption: {
        tooltip: {},
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            width: '100%',
            height: '100%',
            type: 'pie',
            radius: [10, 150],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {

  },
  mounted() {
    this.cloud = echarts.init(document.getElementById('cloud'))
    this.cloudOption.series[0].data = this.statistic.keywords
    this.cloud.setOption(this.cloudOption)

    this.circle = echarts.init(document.getElementById('circle'))
    this.circleOption.series[0].data = this.statistic.keywords
    this.circle.setOption(this.circleOption)



    let that = this;
    window.addEventListener("resize", function() {
      that.cloud.resize();
      that.circle.resize();
    });

  },
  props: {
    prob: {
      type: Object,
      default: ()=> {}
    },
    ans: {
      type: Array,
      default: ()=> []
    }
  },
  computed: {
    statistic() {
      let data = {
        crt: 0,
        keywords: []
      }
      let type = {}
      for (let i in this.ans) {
        if (this.ans[i] === this.prob.data.correct) data.crt ++;
        if (!(this.ans[i] in type)) type[this.ans[i]] = 0;
        type[this.ans[i]] ++;
      }

      for (let i in type) {
        data.keywords.push({
          name: i,
          value: type[i]
        })
      }
      return data
    }
  }

}
</script>

<style scoped lang="less">
.blank{
  width: 100%;
  height: 100%;
}
.title {
  width: 100%;
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-weight: 500;
  font-size: 24px;
}
.problem1{
  font-weight: 300;
  font-size: 17px;
  margin: 13px 0 0 57px;
}
.st {
  overflow: auto;
  margin-top: 16px;
  margin-left: 57px;
}
.st1 {
  margin-top: 16px;
  display: flex;
  height: 400px;

  #cloud {
    flex: 1;
  }

  #circle {
    flex: 1;
  }

}

.body {
  margin-top: 40px;
  width: 92%;
  margin-left: 4%;
  overflow: auto;
}
.number {
  float: left;
  font-weight: 300;
  font-size: 18px;
}
.right {
  float: left;
  font-weight: 300;
  font-size: 18px;
  margin-left: 60px;
}
</style>
