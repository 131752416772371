<template>
<div id="create-exam-sidebar">
  <div id="SideOptions">
    <h2>创建考试</h2>
    <div class="gray-box">
      设置考试名称、考试开始结束时间以及一些<br>
      其他限制，即可创建一场独立的考试，并安<br>
      排群组对其管理，分配或邀请对象进行参加
    </div>
    <div class="button" @click="back"><img src="../../assets/SideBar/back.svg"><span>返回</span></div>
  </div>
</div>
</template>

<script>
export default {
  name: "CreateExamSideBar",
  methods: {
    back() {
      this.$router.push("../")
    }
  }
}
</script>

<style scoped lang="less">
#create-exam-sidebar {
  float: left;
  text-align: center;
  margin-top: 16px;
  margin-left: 16px;
  width: 260px;
}

#SideOptions {
  border-radius: 15px;
  height: 183px;
  width: 100%;
  background: white;
  border: 0;
  outline: none;
  padding: 12px 16px;
  box-sizing: border-box;

  h2 {
    text-align: left;
    font-size: 24px;
    margin: 0 0 12px;
  }

  .gray-box {
    font-size: 12px;
    text-align: justify-all;
    font-weight: 100;
    padding: 7px 5px;
    line-height: 20px;
    background: #EFEFEF;
    border-radius: 10px;
    margin: 0 -7px;
  }

  .button {
    border: 1px solid #054FBF;
    background: white;
    color: #054FBF;
    width: 100%;
    padding: 5px;
    margin: 10px -7px;
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 5px;
      display: block;
      //border: none;
    }

    span {
      display: block;
    }
  }

  .button:hover {
    border: 1px solid #054FBF;
    background: #054FBF;
    color: white;
    img {
      //fill: white;
      filter: brightness(100);
    }
  }
}


</style>
