<template>
    <div class="multi-choose-question">


        <!--modal-->
        <transition name="slide-fade">
            <div class="screen" v-show="showEdit"></div>
        </transition>
        <transition name="bounce">
            <div class="Pop-ups" v-show="showEdit">
                <div class="pTitle">
                    <div class="pTitleFont">编辑</div>
                    <img @click="showEdit = !showEdit;" class="close" src="../../../assets/SideBar/close.svg" alt="">
                </div>
                <div class="p-body">
                    <div class="p-body-content2" style="padding: 10px;">
                        <div class="multi-choose-question-edit">
                            <div style="display: flex; flex-direction: row; width: 100%;">
                                <!--question title and score-->
                                <div style="flex-grow: 8;" class="txt-area-wrap"><textarea
                                        :style="{'height': txtAreaHeight}"
                                        ref="txtArea" v-model="qTitle"
                                        class="set-txt-area" style="width: 94%;"
                                        placeholder="请输入题干内容"></textarea>
                                </div>
                                <div style="flex-grow: 1;"><input v-model="questionObject.qPrice" class="set-input"
                                                                  style="width: 94%;"
                                                                  type="number"
                                                                  placeholder="请输入该题分值"></div>
                            </div>
                            <div
                                    style="display: flex; flex-direction: row; width: 100%; margin-top: 10px; padding-bottom: 10px; border-bottom: #707070 1px solid;">
                                <!--insert pic and formula-->
                                <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片"
                                         txt-color="#555555"
                                         btn-color="white" :h="30" :w="90"></buttons>
                                <buttons style="border: #707070 1px solid;" content="插入公式" txt-color="#555555"
                                         btn-color="white"
                                         :h="30"
                                         :w="90"></buttons>
                            </div>

                            <div style="border-bottom: #707070 1px solid;"><!--options-->

                                <!--options-->
                                <div v-for="(item, index) in questionObject.qOptions"
                                     style="display: flex; flex-direction: row; align-items: center; padding-top: 10px; justify-content: flex-end;">
                                    <div style="flex-grow: 4;"><!--option txt content-->
                                        <input v-model="questionObject.qOptions[index].oTitle" style="width: 100%;"
                                               type="text"
                                               class="set-input" placeholder="请输入选项内容">
                                    </div>

                                    <div
                                            style="display: flex; flex-direction: row; margin-left: auto; align-items: center; flex-grow: 1;">
                                        <div style="margin-left: auto;"><!--option insert pic-->
                                            <buttons style="border: #707070 1px solid;" content="插入图片"
                                                     txt-color="#555555"
                                                     btn-color="white"
                                                     :h="30" :w="90"></buttons>
                                        </div>
                                        <div style="margin-left: auto;"><!--option insert formula pic-->
                                            <buttons style="border: #707070 1px solid; margin-left: 5px;" content="插入公式"
                                                     txt-color="#555555"
                                                     btn-color="white"
                                                     :h="30" :w="90"></buttons>
                                        </div>
                                        <div style="display: flex; flex-direction: row; margin-left: auto;">
                                            <!--set this to correct answer-->
                                            <div><input v-model="questionObject.qOptions[index].correct"
                                                        type="checkbox"></div>
                                            <div>设置为正确答案</div>
                                        </div>
                                    </div>
                                </div>

                                <div style="display: flex; flex-direction: row; margin-top: 10px; margin-bottom: 10px;">
                                    <!--plus and minus options-->
                                    <div @click="addOption">
                                        <buttons style="border: #707070 1px solid; margin-right: 10px;" content="+ 增加选项"
                                                 txt-color="#555555"
                                                 btn-color="white" :h="30" :w="95"></buttons>
                                    </div>
                                    <div @click="delOption">
                                        <buttons style="border: #707070 1px solid;" content="- 减少选项" txt-color="#555555"
                                                 btn-color="white"
                                                 :h="30"
                                                 :w="95"></buttons>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: row; align-items: center; padding-top: 10px;">
                                <!--random options-->
                                <div><input v-model="questionObject.isRandom" type="checkbox"></div>
                                <div>选项顺序随机变化</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="showEdit = !showEdit;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="saveBtn">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="保存"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <div class="multi-choose-question-preview"><!--question preview-->
            <div style="font-weight: bold;margin-bottom: 10px">{{ qTitle }}&nbsp;&nbsp;({{questionObject.qPrice !== 0 ?
                questionObject.qPrice+'分':"未赋分"}})
            </div>

            <div v-for="(item, index) in questionObject.qOptions"
                 style="display: flex; flex-direction: row; align-items: center;margin-top: 15px">
                <div><input :checked="item.correct === true" :disabled="item.correct === false" name="s_choose"
                            type="checkbox"></div>
                <div>{{ questionObject.qOptions[index].oTitle }}</div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
                <div style="margin: 0;" @click="showEdit = !showEdit;">
                    <buttons :content="showEdit ? '收起' : '编辑'" btn-color="#D94E4E" txt-color="white" :h="30" :w="60"
                             :f-size="5"></buttons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Buttons from "../../Buttons";
    import 'font-awesome/css/font-awesome.min.css'
    import calcTextareaHeight from "../../../utils/calcTextareaHeight";
    import {v4 as uuidV4} from 'uuid';

    export default {
        name: "MultiChoose",
        components: {
            Buttons
        },
        props: {
            qId: {
                type: String,
                require: true,
            }
        },
        data() {
            return {
                txtAreaHeight: '25px',

                qTitle: '题干',

                showEdit: false,
                questionObject: {
                    "qType": 'm_choose',
                    "qPicPath": '', // if question title wants to use picture to explain the meaning
                    "qMathLatex": '', // if question title wants to use math formula to explain the meaning
                    "qOptions": [ // options list
                        {
                            "oID": uuidV4(),
                            "oTitle": '选项1',
                            "oPicPath": '', // if option wants to use picture to explain the meaning
                            "oMathLatex": '', // if option wants to use math formula to explain the meaning
                            "correct": false,
                        },
                        {
                            "oID": uuidV4(),
                            "oTitle": '选项2',
                            "oPicPath": '',
                            "oMathLatex": '',
                            "correct": false,
                        }
                    ],
                    "isRandom": null, // sort options randomly
                },
            };
        },
        methods: {
            addOption() {
                this.$data.questionObject.qOptions.push({
                    "oID": uuidV4(),
                    "oTitle": '',
                    "oPicPath": '',
                    "oMathLatex": '',
                    "correct": false
                });
            },

            delOption() {
                this.$data.questionObject.qOptions.pop();
            },

            save() {
                /**
                 * save the question object to localStorage
                 */

                // add question title
                this.$set(this.$data.questionObject, 'qTitle', this.$data.qTitle);

                // add show title
                this.$set(this.$data.questionObject, 'showTitle', this.$data.qTitle.slice(0, 20));
                this.$emit("addquestion", this.$data.questionObject, this.$props.qId);

                localStorage.setItem("myPaper-" + this.$props.qId.split('-').join('')
                    , JSON.stringify(this.$data.questionObject));

                // this.$data.showEdit = false;
            },
            saveBtn() {
                this.save();
                this.$data.showEdit = false;
            },

            getHeight() {
                this.txtAreaHeight = calcTextareaHeight(this.$refs.txtArea, 1, null).height;
            }
        },
        watch: {
            qTitle() {
                this.getHeight();
            }
        },
        mounted() {
            /**
             * save the question object to localStorage every minute
             * @type {number}
             */
            this.timer = setInterval(this.save, 1000 * 60);
        },
        created() {
            let qs = localStorage.getItem("myPaper-" + this.$props.qId.split('-').join(''));
            if (qs && JSON.parse(qs)['qTitle']) { // if you choose a question type or save the question
                this.$data.questionObject = JSON.parse(qs);
                this.$data.qTitle = JSON.parse(qs)['qTitle'];
                this.$data.qAnswer = JSON.parse(qs)['correct'];
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    }
</script>

<style scoped>
    .multi-choose-question {
        display: flex;
        flex-direction: column;
    }

    .multi-choose-question-preview {
        display: flex;
        flex-direction: column;
        background-color: rgb(248, 248, 248);
        border: 1px solid rgb(231, 234, 237);
        border-radius: 5px;
        padding: 20px;
    }

    .multi-choose-question-edit {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .set-input {
        height: 35px;
        border-radius: 10px;
        text-indent: 10px;
        font-size: 18px;
        border: #707070 1px solid;
    }

    .txt-area-wrap .set-txt-area {
        border-radius: 10px;
        resize: none;
        display: inline-block;
        font-size: 18px;
        border: #707070 1px solid;
        padding: 6px;
    }

    input:focus {
        outline: none;
    }

    textarea:focus {
        outline: none;
    }


    /*animation*/
    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }


    /*modal*/
    .Pop-ups {
        z-index: 251;
        overflow: auto;
        width: 800px;
        /*min-height: 310px;*/
        border-radius: 10px;
        background-color: #ffffff;
        position: fixed;
        top: 25%;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .screen {
        z-index: 250;
        display: block;
        overflow: auto;
        width: 100%;
        height: calc(100% + 55px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: -55px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .pTitle {
        width: 100%;
        height: 55px;
        border-bottom: #E8EAEC 1px solid;
    }

    .pTitleFont {
        font-weight: bold;
        height: 100%;
        line-height: 55px;
        float: left;
        margin-left: 23px;
        font-size: 19px;
        font-family: "Microsoft Yahei";
    }

    .close {
        float: right;
        width: 15px;
        margin-right: 23px;
        margin-top: 20px;
    }

    .close:hover {
        cursor: pointer;
    }

    .p-body {
        height: calc(100% - 87px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .p-foot {
        width: 100%;
        height: 55px;
        border-top: #E8EAEC 1px solid;
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
    }

    .p-body-content {
        margin-top: 6px;
        margin-bottom: 6px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .p-body-input {
        height: 30px;
        width: 350px;
        border-radius: 5px;
        border: #999999 1px solid;
        display: flex;
        justify-content: center;
    }

    input:focus {
        outline: none;
    }

    /*modal animation*/
    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

    .p-body-content2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
</style>
