<template>
    <div>
        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center; height: 55px !important;">
                <div class="card-title">账号设置</div><!--title-->
            </div>
            <div class="line"></div><!--line-->
        </div>

        <div style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
            <div class="p-body-content">
                <div>切换所在机构:&nbsp;</div>
                <div>
                    <select id="classOptions" ref="classOptions" value="sub:01:__philosophy__"
                            class="p-body-input">
                        <option v-for="(item, index) in institutionList" :value="item['unitId']">{{ item['unitName']
                            }}
                        </option>
                    </select>
                </div>
            </div>

            <div @click="switchInstitution">
                <buttons style="margin-top: 10px;" content="切换" btn-color="#054FBF" txt-color="white"
                         :w="100"
                         :h="40"></buttons>
            </div>

            <div style="width: 300px !important; background-size: contain; margin-top: 10px;">
                <img v-if="myImg === null || myImg === ''" style="width:auto; height:auto; max-width:100%; max-height:100%;" :src="defaultImg" alt="">
                <img v-else style="width:auto; height:auto; max-width:100%; max-height:100%;" :src="myImg" alt="">
            </div>

            <input type="file" id="uploadImg" style="display: none;" accept="image/*">

            <div @click="uploadImg">
                <buttons style="margin-top: 10px;" content="上传照片" btn-color="#054FBF" txt-color="white"
                         :w="100"
                         :h="40"></buttons>
            </div>
        </div>
    </div>
</template>

<script>
    import Buttons from "../../components/Buttons";

    import DefaultImg from "../../assets/user.png";

    export default {
        inject: ['reload'],
        name: "AccountSettings",
        components: {
            Buttons
        },
        data() {
            return {
                institutionList: [],
                myImg: null,
                defaultImg: DefaultImg,
            };
        },
        methods: {
            switchInstitution() {
                let uni = this.$refs.classOptions.value;
                this.$cookies.set('uniCode', uni, 60 * 60 * 24, '/');

                this.$toasted.success('切换机构成功！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                    position: 'top-center'
                }).goAway(2500);
            },
            uploadImg() {
                document.getElementById("uploadImg").click();
            }
        },
        mounted() {
            document.getElementById("uploadImg").onchange = () => {
                // input file上传文件时发生变化，将上传的文件传到后端
                let img = document.getElementById("uploadImg").files[0];

                if (img.size / 1000000 > 2) this.$toasted.error('图片大小不得超过2MB！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-times-circle',
                    theme: 'bubble',
                    position: 'top-right',
                }).goAway(2500);
                else {
                    let reader = new FileReader();  // 新建一个FileReader
                    reader.readAsDataURL(img);  // 读取文件
                    reader.onload = evt => { //读取完文件之后会回来这里
                        var imgUrl = evt.target.result; // 读取文件内容

                        this.$axios.post('/apiLogin/upload_my_img?token=' + this.$cookies.get('TOKEN'), {
                            'img': imgUrl,
                        }).then(res => {
                            this.$toasted.success('图片上传成功！', {
                                iconPack: 'custom-class',
                                icon: 'fa fa-check-circle-o',
                                theme: 'bubble',
                                position: 'top-center'
                            }).goAway(2500);
                            this.reload();
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                }
            }
        },
        created() {
            this.$axios.get('/apiLogin/getAllIns?token=' + this.$cookies.get('TOKEN')).then(res => {
                this.$data.institutionList = JSON.parse(res['data']['msg']);
            }).catch(err => {
                console.log(err);
            });

            // 获取我的照片
            this.$axios.get('/apiLogin/get_my_img?token=' + this.$cookies.get('TOKEN')).then(res => {
                if (res['data']['msg'] !== null) this.$data.myImg = res['data']['msg'];
            }).catch(err => {
                console.log(err);
            });
        }
    }
</script>

<style scoped>
    .card-title {
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }

    .line {
        width: 100%;
        border-bottom: 1px solid #E9E9E9;
    }

    .p-body-input {
        height: 30px;
        width: 350px;
        border-radius: 5px;
        border: #999999 1px solid;
        display: flex;
        justify-content: center;
    }
</style>
