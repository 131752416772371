<template>
  <div id="classMan">
    <CourseAndExam :ok="ok" @sendOk="getOk"></CourseAndExam>
    <div id="header">
      <div class="card-title">课程</div>
      <div class="card-options" @click="switchTabs(false);">
        <div :style="myData.ok ? 'color: #054FBF;' : ''">我参加的课程</div>
        <div :style="myData.ok ? '' : 'opacity: 0;'" class="option-underline"></div>
      </div>
      <div class="card-options" @click="switchTabs(true);">
        <div :style="!myData.ok ? 'color: #054FBF;' : ''">我管理的课程</div>
        <div :style="!myData.ok ? '' : 'opacity: 0;'" class="option-underline"></div>
      </div>
      <div id="sc">
        <Search :name="'搜索课程...'"></Search>
      </div>
      <div class="button" @click="ok=true">创建考试/课程</div>
    </div>
    <div id="body"  style="overflow: auto;">
      <transition name="slide-fade1">
        <div v-show="myData.ok" >
          <div class="footer">
            <div :style="Check ? 'color: #000000':'color: #999999' "  style="margin-left: 90px;font-weight: 100;font-size: 15px">
              <input @click="Check = !Check" style="float: left;margin-right: 8px" type="checkbox" :checked="Check">
              只显示未结束的课程
            </div>
          </div>
          <CourseJoinCard :data-course-join-card="dataCourseJoinCard[0]"></CourseJoinCard>
          <CourseJoinCard :data-course-join-card="dataCourseJoinCard[1]"></CourseJoinCard>
          <CourseJoinCard v-show="newClass" :data-course-join-card="dataCourseJoinCard[2]"></CourseJoinCard>
          <CourseJoinCard v-show="!Check" :data-course-join-card="dataCourseJoinCard[3]"></CourseJoinCard>
          <Footer></Footer>
        </div>
      </transition>
      <div class="transition-box">
        <transition name="slide-fade2">
          <div v-show="!myData.ok">
            <div class="footer">
              <div :style="Check ? 'color: #000000':'color: #999999' "  style="margin-left: 90px;font-weight: 100;font-size: 15px">
                <input @click="Check = !Check" style="float: left;margin-right: 8px" type="checkbox" :checked="Check">
                只显示未结束的课程
              </div>
            </div>
            <CourseManageCard :data-course-mange-card="dataCourseManageCard[0]"></CourseManageCard>
            <CourseManageCard v-show="!Check" :data-course-mange-card="dataCourseManageCard[1]"></CourseManageCard>
            <Footer></Footer>
          </div>
        </transition>
      </div>


    </div>
  </div>
</template>

<script>
import Search from "../../../components/common/Search";
import BasalCard from "../../../components/Card/BasalCard";
import CourseJoinCard from "../../../components/Card/CourseJoinCard";
import CourseManageCard from "../../../components/Card/CourseManageCard";
import GroupCard from "../../../components/Card/GroupCard";
import QuestionBankCard from "../../../components/Card/QuestionBankCard";
import ScoreCard from "../../../components/Card/ScoreCard";
import Footer from "../../../components/common/Footer";
import CourseAndExam from "../../../components/PopUps/CourseAndExam";
import StudentExamCard from "../../../components/Card/StudentExamCard";
import TeacherExamCard from "../../../components/Card/TeacherExamCard";
export default {
  name: "ClassMan",
  components: {
    TeacherExamCard,
    StudentExamCard,
    CourseAndExam,
    Footer, ScoreCard, QuestionBankCard, GroupCard, CourseManageCard, CourseJoinCard, BasalCard, Search},
  data:function () {

    return {
      newClass:false,
      ok:false,
      myData:{
        ok:true
      },
      dataCourseJoinCard: [
        {
          title: "计算机网络",
          startTime: "2022-02-03 00:00:00",
          endTime: "2022-02-12 23:59:59",
          status: "进行中",
          teacher: "李兰辰",
          class: "默认班级"
        },
        {
          title: "数据结构与算法",
          startTime: "2022-02-03 00:00:00",
          endTime: "2022-02-12 23:59:59",
          status: "进行中",
          teacher: "李兰辰",
          class: "默认班级"
        },
        {
          title: "Java Web 应用开发",
          startTime: "2022-02-03 00:00:00",
          endTime: "2022-02-12 23:59:59",
          status: "进行中",
          teacher: "李兰辰",
          class: "默认班级"
        }
        ,
        {
          title: "形势与政策2021（冬）",
          startTime: "2021-02-03 00:00:00",
          endTime: "2021-02-12 23:59:59",
          status: "已结束",
          teacher: "李兰辰",
          class: "默认班级"
        }
      ],
      dataCourseManageCard:[
        {
          title:"医学信息学",
          status:"进行中",
          teacher:"李兰辰"
        },
        {
          title:"ACM暑假训练",
          status:"已结束",
          teacher:"李兰辰"
        }
      ],
      Check:false
    }
  },
  methods:{
    switchTabs(f){
      if(f === this.myData.ok) this.$set(this.myData,"ok",!this.myData.ok);
    },
    getOk(ok) {
      this.ok = ok;
    },
    addClass() {
      this.newClass = true;
    }
  }
}
</script>

<style scoped>
#classMan{
  width: 100%;
  height: 100%;
}
#header {
  top: 0;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #e9e9e9;
  /*border-radius: 15px 15px 0 0;*/
}
#sc{
  float: right;
  width: 157px;
  margin-top: 13.5px;
  margin-right: 25px;
}
#body {
  position: relative;
  /*position: fixed;*/
  width: 100%;
  height: calc(100% - 56px);
}
.footer {

  float: left;
  margin-top: 20px;
  margin-bottom: -20px;
  width: 100%;

}
.card-title {
  user-select: none;
  float: left;
  margin-top: 12px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 1.5em;
}
.card-options {
  user-select: none;
  float: left;
  margin-left: 50px;
  margin-top: 20px;
  color: #999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*font-weight: 500;*/
  /*font-size: 16px;*/
}

.card-options:hover {
  cursor: pointer;
}

.option-underline {
  height: 4px;
  width: 110%;
  background-color: #054FBF;
  margin-top: 10px;
}

a{
  color: black;
}

.transition-box {
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.button {
  font-size: 14px;
  font-weight: 100;
  line-height: 28px;
  text-align: center;
  color: white;
  border-radius: 15px;
  margin-top: 14px;
  margin-right: 18px;
  float: right;
  height: 28px;
  width: 112px;
  background: #054FBF;
}
.button:hover {
  cursor: pointer;
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}


.slide-fade1-enter-active {
  transition: all 0.3s ease;
}

.slide-fade1-enter,.slide-fade1-leave-to{
  transform: translateX(-300px);
  opacity: 0;
}

.slide-fade2-enter-active{
  transition: all 0.3s ease;
}
.slide-fade2-enter,.slide-fade2-leave-to{
  transform: translateX(300px);
  opacity: 0;
}



/*滚动条样式*/
::-webkit-scrollbar {
  width:5px;
}

::-webkit-scrollbar-track-piece {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color:#dddddd;
  background-clip:padding-box;
  min-height:28px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color:#bbb;
}

</style>
