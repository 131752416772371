<template xmlns="http://www.w3.org/1999/html">
    <div id="classAssignment">
        <transition name="slide-fade">
            <div id="screen" v-if="ok"></div>
        </transition>
        <transition name="bounce">
            <div id="Pop-ups" v-if="ok">
                <div id="pTitle">
                    <div id="pTitleFont">班级分配</div>
                    <img @click="sendOk" id="close" src="../../assets/common/close2.svg">
                </div>
                <div id="body">
                    <div id="all">
                        <input type="checkbox" @click="checkAll()" :checked="allCheck">
                        <div class="a" @click="checkAll()">全部班级(共2个)</div>
                        <div class="b">当前分配人</div>
                    </div>
                    <ul>
                        <li v-for="(item,index) in this.class" :class="item.check ? 'selected':'notSelected'">
                            <input type="checkbox" :checked="item.check" @click="isCheck(index)">
                            <div class="a" @click="isCheck(index)">{{ item.Name }}</div>
                            <div class="b">{{ item.teacher }}</div>
                        </li>
                    </ul>
                </div>
                <div id="footer">
                    <div class="notice">分配成员后，该班级仅被分配成员和课程创建者可见</div>
                    <button class="Cancel" @click="sendOk">取消</button>
                    <button class="Save" @click="sendOk">保存</button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "ClassAssignment",
    props:{
        ok:Boolean
    },
    data:function () {
        return {
            allCheck:false,
            class:[
                {
                    Name:'19级1班',
                    teacher:'李兰辰，毛东东',
                    check:true
                },
                {
                    Name:'19级2班',
                    teacher:'李兰辰',
                    check:false
                }
            ]
        }
    },

    methods:{
        sendOk() {
            let okk = !this.ok;
            this.$emit('sendOk',okk)
        },
        isCheck(index) {
            this.$set(this.$data.class[index],"check",!this.class[index].check);
            if(this.class[index].check === false) this.allCheck = false;
            console.log(this.$data.class[index].check)
        },
        checkAll() {
            this.allCheck = !this.allCheck;
            for(let i = 0;i < this.class.length; i++) {
                this.$set(this.$data.class[i],"check",this.allCheck);
            }
        }
    }
}
</script>

<style scoped>
#classAssignment{}

textarea {
    color: #999999;
    font-size: 15px;
    font-weight: 100;
    outline: none;
    resize: none;
    height: 135px;
    width: 82%;
    margin-top: 20px;
    margin-left: 9%;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
}

button:hover{
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

.selected {
    /*background: #054FBF;*/
    background: #F0F6FF;
}
#body {
    width: 100%;
    overflow: auto;
    border-bottom: 1px solid #efefef;
    height: 280px;
}

#Pop-ups {
    z-index:251;
    overflow:auto;
    width: 590px;
    height: 380px;
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0 0 6px #666666;
}
#all {
    height: 40px;
    width: 100%;
    background: #efefef;
    line-height: 40px;
}
#all > input {
    margin-top: 15px;
    margin-left: 15px;
    float: left;

}

li > input {
    margin-top: 18px;
    margin-left: 15px;
    float: left;
}

.a {
    float: left;
    width: 260px;
    margin-left: 5px;
    font-weight: 100;
    font-size: 14px;
}

.a:hover{
    cursor: pointer;
}

.b {
    float: left;
    font-weight: 100;
    font-size: 14px;
}

input:hover {
    cursor: pointer;
}

ul {
    list-style: none;
    display: block;
    margin: 0px;
    padding: 0px;
}
li {
    line-height: 50px;
    height: 50px;
    width: 100%;
    color: black;
    font-size: 14px;
    font-weight: 100;
    border-bottom: 1px solid #efefef;
}
#footer {
    width: 100%;

}
.notice {
    float: left;
    font-size: 13px;
    font-weight: 100;
    margin-top: 15px;
    margin-left: 20px;
}
.Cancel {
    float: left;
    height: 25px;
    width: 60px;
    border-radius: 10px;
    border: none;
    margin-top: 11px;
    margin-left: 80px;
    background: #efefef;
}
.Save {
    float: left;
    height: 25px;
    width: 60px;
    border-radius: 10px;
    border: none;
    background: #054FBF;
    color: white;
    margin-top: 11px;
    margin-left: 30px;
}

#screen {
    z-index:250;
    display: block;
    overflow:auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
#pTitle {
    background: #054FBF;
    width: 100%;
    height: 55px;
    /*border-bottom: #E8EAEC 1px solid;*/
}
#pTitleFont {
    color: white;
    font-weight: 100;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 257px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}
#close{
    float: right;
    width: 23px;
    margin-right: 16px;
    margin-top: 16px;
}
#close:hover {
    cursor: pointer;
}

.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.bounce-enter-active {
    animation: bounce-in .5s;
}
.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.tooltip .tooltiptext {

    visibility: hidden;
    width: 250px;
    background-color: #efefef;
    color: #666666;
    /*text-align: center;*/
    padding: 10px 3px 10px 11px;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -120px;
    /* position */
    font-size: 16px;
    font-weight: 100;
    position: absolute;
    text-align: left;
    text-indent: 33px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 253;
}
</style>
