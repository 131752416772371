<template>
    <div id="notice">
        <div id="header">
            <div class="card-title">通知</div>
            <div class="card-options" @click="switchTabs(false);">
                <div :style="myData.ok ? 'color: #054FBF;' : ''">发件箱</div>
                <div :style="myData.ok ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(true);">
                <div :style="!myData.ok ? 'color: #054FBF;' : ''">收件箱</div>
                <div :style="!myData.ok ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div id="sc">
                <Search :name="'搜索通知...'"></Search>
            </div>
            <transition name="slide-fade">
                <div v-show="myData.ok" class="button1">+新建消息</div>
            </transition>
        </div>
        <div id="body" style="overflow: auto">
            <transition name="slide-fade1">
                <div v-show="myData.ok">
                    <div class="infromationhead"></div>
                    <div v-for="(item,idx) in infromationDataPost" class="infromation">
                        <div class="first">
                            <img src="../../assets/Class/info.svg">
                            <div class="title">{{item.title}}</div>
                            <div class="title_information">{{item.typee}}</div>
                            <div class="delete">
                                <img src="../../assets/Class/delete.svg">
                            </div>
                            <div class="edit">
                                <img src="../../assets/Class/edit.svg">
                            </div>
                            <div :class="item.content === '草稿'?'notSend':'send'">{{item.content}}</div>
                        </div>
                        <div class="by">
                            {{item.from}}
                        </div>
                    </div>
                </div>
            </transition>

            <div class="transition-box">
                <transition name="slide-fade2">
                    <div v-show="!myData.ok">
                        <div class="infromationhead"></div>
                        <div v-for="(item,idx) in infromationDataGet" class="infromation">
                            <div class="first">
                                <img src="../../assets/Class/info.svg">
                                <div class="title">{{ item.title }}</div>
                                <div class="title_information">{{ item.typee }}</div>
                                <div class="delete">
                                    <img src="../../assets/Class/delete.svg">
                                </div>
                                <div @click="item.check=!item.check" v-show="item.check" class="button">
                                    <img src="../../assets/Class/right.svg">
                                    确认信息
                                </div>
                                <div v-show="!item.check" class="isOk">
                                    已确认
                                </div>
                            </div>
                            <div class="by">
                                {{ item.from }}
                            </div>
                            <div class="content">
                                {{item.content}}
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Search from "../../components/common/Search";
import Buttons from "../../components/Buttons";
export default {
    name: "Notice",
    components: {Buttons, Search},
    data:function () {
        return {
            myData:{
                ok: true
            },
            infromationDataGet:[
                {
                    title:'期末考试通知',
                    from:'教务处·2022年1月15日 13:10',
                    typee:'考试通知',
                    content:'考试周即将开始，请各位老师通知本班学生考试时间',
                    check:true
                },
                {
                    title:'期中考试通知',
                    from:'教务处·2021年12月15日 13:10',
                    typee:'考试通知',
                    content:'这次的期中考试较为特殊，由于疫情的关系，不组织线下考试，代之以线上考试。请各位老师通知本班学生。',
                    check:false
                }
            ],
            infromationDataPost:[
                {
                    title:'期末考试通知',
                    from:'教务处·2022年1月15日 13:10',
                    typee:'考试通知',
                    content:'发送成功'
                },
                {
                    title:'期中考试通知',
                    from:'教务处·2021年12月15日 13:10',
                    typee:'考试通知',
                    content:'草稿',
                }
            ]
        }
    },
    methods:{
        switchTabs(isOk) {
            if(isOk === this.myData.ok) this.$set(this.myData,"ok",!isOk);
        }
    }
}
</script>

<style scoped>
#notice {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
#sc{
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.card-options {
    user-select: none;
    float: left;
    margin-left: 50px;
    margin-top: 20px;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*font-weight: 500;*/
    /*font-size: 16px;*/
}
.card-options:hover {
    cursor: pointer;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}




a{
    color: black;
}

#body {
    position: relative;
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}
.infromation {
    overflow: auto;
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #E3E3E3;
}

.infromationhead {
    height: 22px;
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #E3E3E3;
}

.first{
    width: 100%;
    overflow: auto;
    margin-top: 8px;
}

.first > img {
    margin-top: 5px;
    margin-left: 13px;
    float: left;
}
.title_information {
    margin-top: 7px;
    margin-left: 13px;
    height: 23px;
    float: left;
    font-size: 13px;
    font-weight: 100;
    width: 66px;
    text-align: center;
    line-height: 23px;
    border-radius: 20px;
    background: #C01616;
    color: white;
}
.title {
    /*margin-top: 7px;*/
    float: left;
    margin-left: 13px;
    font-size: 25px;
    font-weight: 600;
}
.edit {
    margin-top: 4px;
    float: right;
    height: 22px;
    border-radius: 20px;
    margin-right: 66px;
}
.edit > img {
    height: 100%;
}
.edit:hover{
    cursor: pointer;
}
.delete {
    margin-top: 4px;
    float: right;
    margin-right: 15px;
}
.delete:hover{
    cursor: pointer;
}

.send{
    float: right;
    color: #00A03E;
    margin-top: 3px;
    margin-right: 66px;
    font-size: 16px;
    font-weight: 100;
}
.notSend{
    float: right;
    color: #999999;
    margin-top: 3px;
    margin-right: 66px;
    font-size: 16px;
    font-weight: 100;
}

.button {
    font-weight: 100;
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    background: #054FBF;
    /*margin-top: 10px;*/
    float: right;
    height: 30px;
    width: 98px;
    border-radius: 20px;
    margin-right: 20px;
}
.button:hover{
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.button1 {
    font-size: 14px;
    font-weight: 100;
    line-height: 28px;
    text-align: center;
    color: white;
    border-radius: 15px;
    margin-top: 14px;
    margin-right: 18px;
    float: right;
    height: 28px;
    width: 92px;
    background: #054FBF;
}
.button1:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.by {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 100;
    color: #666666;
    margin-left: 46px;
    margin-bottom: 8px;
}
.content {
    text-indent: 32px;
    width: 80%;
    margin-top: 13px;
    font-weight: 100;
    margin-left: 46px;
    margin-bottom: 8px;
}

.isOk {
    font-weight: 800;
    font-size: 14px;
    color: #000000;
    line-height: 30px;
    text-align: center;
    background: #efefef;
    /*margin-top: 10px;*/
    float: right;
    height: 30px;
    width: 98px;
    border-radius: 20px;
    margin-right: 20px;
}
.transition-box {
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.slide-fade1-enter-active {
    transition: all 0.3s ease;
}

.slide-fade1-enter,.slide-fade1-leave-to{
    transform: translateX(-300px);
    opacity: 0;
}


.slide-fade2-enter-active{
    transition: all 0.3s ease;
}
.slide-fade2-enter,.slide-fade2-leave-to{
    transform: translateX(300px);
    opacity: 0;
}
.slide-fade-enter-active {
    transition: opacity 0.3s;
}
.slide-fade-leave-active {
    transition: opacity 0.2s;
}

.slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
}


/*滚动条样式*/
::-webkit-scrollbar {
    width:5px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color:#dddddd;
    background-clip:padding-box;
    min-height:28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
}
</style>
