<template>
    <div id="studentExamCard" style="margin-right: 150px;">
        <div id="header">
            <b>{{dataStudentExamCard['title']}}</b>
        </div>
        <div id="center">
            <div class="information" style="margin-top: 10px">{{dataStudentExamCard['startTime']}}</div>
            <button>{{dataStudentExamCard['enter']}}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexApploudCard',
    props:{
        dataStudentExamCard: {
            type: Object,
            default: function () {
                return {
                    title: "计算机网络",
                    startTime: "2022-02-03 09:45:00",
                    endTime: "2022-02-03 11:45:00",
                    status: "开放登录",
                    Type: "双机位考试",
                    info: "诚信考试，请勿作弊",
                    enter: "进入"
                }
            }
        }
    }
}
</script>

<style scoped>
#studentExamCard {
    transition: 0.5s all;
    position: relative;
    height: 176px;
    width: 290px;
    float: left;
    margin-bottom: 45px;
    margin-right: max(40px,calc(25% - 310px));
    border-radius: 15px;
    border: 0px;
    outline:none;
    box-shadow: 0px 0px 15px #DDDDDD;
}
@media screen and (max-width: 1636px) {
    #studentExamCard {
        margin-left: max(40px,calc(33% - 330px));
    }
}
@media screen and (max-width: 1313px) {
    #studentExamCard {
        margin-left: max(53px,calc(50% - 340px));
    }
}

@media screen and (max-width: 920px) {
    #studentExamCard {
        margin-left: max(53px,calc(50% - 145px));
    }
}

#studentExamCard:hover{
    transform: translateY(-3px);
    box-shadow: 0px 0px 35px #cccccc;
}

#header {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 47px;
    height: 47px;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    background: #054FBF;
    border: 0px;
    outline:none;
}
#more {
    /*background: black;*/
    width: 20px;
    position: absolute;
    left: 255px;
    top: 15px;
}
img {
    width: 100%;
}
#center {
    height: 129px;
    width: 100%;
}
#more:hover {
    cursor: pointer;
}
.information {
    margin-left: 25px;
    font-size: 14px;
    font-weight: 100;
}
.examStatus {
    margin-top: 5px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 100;
}

.info {
    position: absolute;
    margin-top: 3px;
    font-size: 13px;
    font-weight: 100;
    color: #DD1E1E;
    margin-left: 16px;
}

.start{
    color: #00A03E;
}
.end {
    color:#DD1E1E;
}


button {
    width: 80px;
    height: 29px;
    position: absolute;
    bottom: 10px;
    margin-left: 190px;
    border-radius: 5px;
    background: #054FBF;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 100;
}
button:hover {
    cursor: pointer;
    background: #0547AC;
}
a {
    color: white;
    text-decoration:none;
}
</style>
