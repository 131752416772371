<template>
    <div class="single-choose-question">

        <!--modal-->
        <transition name="slide-fade">
            <div class="screen" v-show="ok"></div>
        </transition>

        <transition name="bounce">
            <div class="Pop-ups" v-show="ok">
                <div class="pTitle">
                    <div class="pTitleFont">插入图片/公式</div>
                    <img @click="ok = !ok;" class="close" src="../../assets/SideBar/close.svg">
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>选择图片:&nbsp;</div>
                        <div><input type="file" class="p-body-input"></div>
                    </div>
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>插入公式:&nbsp;</div>
                        <div class="txt-area-wrap"><textarea :style="{'height': txtAreaHeightMath}"
                                       ref="mathLatex" v-model="qMathLatex"
                                       class="set-txt-area" style="width: 350px;"
                                       placeholder="输入Latex代码"></textarea></div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="ok = !ok;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="插入"></buttons>
                    </div>
                </div>
            </div>
        </transition>

        <div class="single-choose-question-preview"><!--question preview-->
            <div style="font-weight: bold;">{{ qTitle }}</div>

            <div>
                <math-jax :latex="qMathLatex"></math-jax>
            </div>

            <div v-for="(item, index) in questionObject.qOptions"
                 style="display: flex; flex-direction: row; align-items: center;">
                <div><input name="s_choose" type="radio"></div>
                <div>{{ questionObject.qOptions[index].oTitle }}</div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end;">
                <div style="margin: 0;" @click="showEdit = !showEdit;">
                    <buttons :content="showEdit ? '收起' : '编辑'" btn-color="#D94E4E" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>

                <div v-if="!isExistsQid['exists']" style="margin: 0; margin-left: 5px;" @click="submitQuestion">
                    <buttons content="提交" btn-color="#054FBF" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>
                <div v-else style="margin: 0; margin-left: 5px;" @click="modifyQuestion">
                    <buttons content="修改" btn-color="#054FBF" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="single-choose-question-edit" v-show="showEdit"><!--question edit-->
                <div style="display: flex; flex-direction: row; width: 100%;"><!--question title and score-->
                    <div style="flex-grow: 8;" class="txt-area-wrap"><textarea :style="{'height': txtAreaHeight}"
                                                                               ref="txtArea" v-model="qTitle"
                                                                               class="set-txt-area" style="width: 94%;"
                                                                               placeholder="输入题干"></textarea>
                    </div>
                    <div style="flex-grow: 1;"><input v-model="questionObject.qPrice" class="set-input"
                                                      style="width: 94%;"
                                                      type="number"
                                                      placeholder="请输入该题分值"></div>
                </div>
                <div @click="ok = !ok;"
                    style="display: flex; flex-direction: row; width: 100%; margin-top: 10px; padding-bottom: 10px; border-bottom: #707070 1px solid;">
                    <!--insert pic and formula-->
                    <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片/公式" txt-color="#555555"
                             btn-color="white" :h="30" :w="120"></buttons>
                </div>

                <div style="border-bottom: #707070 1px solid;"><!--options-->

                    <!--options-->
                    <div v-for="(item, index) in questionObject.qOptions"
                         style="display: flex; flex-direction: row; align-items: center; padding-top: 10px; justify-content: flex-end;">
                        <div style="flex-grow: 4;"><!--option txt content-->
                            <input v-model="questionObject.qOptions[index].oTitle" style="width: 100%;" type="text"
                                   class="set-input" placeholder="请输入选项内容">
                        </div>

                        <div
                            style="display: flex; flex-direction: row; margin-left: auto; align-items: center; flex-grow: 1;">
                            <div style="margin-left: auto;"><!--option insert pic-->
                                <buttons style="border: #707070 1px solid;" content="插入图片" txt-color="#555555"
                                         btn-color="white"
                                         :h="30" :w="90"></buttons>
                            </div>
                            <div style="margin-left: auto;"><!--option insert formula pic-->
                                <buttons style="border: #707070 1px solid; margin-left: 5px;" content="插入公式"
                                         txt-color="#555555"
                                         btn-color="white"
                                         :h="30" :w="90"></buttons>
                            </div>
                            <div style="display: flex; flex-direction: row; margin-left: auto;">
                                <!--set this to correct answer-->
                                <div><input v-model="questionObject.qOptions[index].correct" type="checkbox"></div>
                                <div>设置为正确答案</div>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: row; margin-top: 10px; margin-bottom: 10px;">
                        <!--plus and minus options-->
                        <div @click="addOption">
                            <buttons style="border: #707070 1px solid; margin-right: 10px;" content="+ 增加选项"
                                     txt-color="#555555"
                                     btn-color="white" :h="30" :w="95"></buttons>
                        </div>
                        <div @click="delOption">
                            <buttons style="border: #707070 1px solid;" content="- 减少选项" txt-color="#555555"
                                     btn-color="white"
                                     :h="30"
                                     :w="95"></buttons>
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-direction: row; align-items: center; padding-top: 10px;">
                    <!--random options-->
                    <div><input v-model="questionObject.isRandom" value="true" type="checkbox"></div>
                    <div>选项顺序随机变化</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Buttons from "../Buttons";
import 'font-awesome/css/font-awesome.min.css'
import calcTextareaHeight from '../../utils/calcTextareaHeight';
import {v4 as uuidv4} from 'uuid';

export default {
    name: "SingleChoose",
    components: {
        Buttons,
    },
    props: {
        libId: {
            type: String,
            require: true,
        },
        isExistsQid: {
            type: Object,
            require: true,
            default: {
                'exists': false,
                'content': null,
            }
        }
    },
    data() {
        return {
            ok: false, // control the modal

            txtAreaHeight: '25px',
            txtAreaHeightMath: '25px',

            qTitle: '题干',

            qMathLatex: '', // if question title wants to use math formula to explain the meaning
            creator: '',
            showEdit: false,
            questionObject: {
                "qType": 's_choose',
                "qPicPath": '', // if question title wants to use picture to explain the meaning
                "qOptions": [ // options list
                    {
                        "oTitle": '选项1',
                        "oPicPath": '', // if option wants to use picture to explain the meaning
                        "oMathLatex": '', // if option wants to use math formula to explain the meaning
                        "correct": false, // is correct answer?
                    },
                    {
                        "oTitle": '选项2',
                        "oPicPath": '',
                        "oMathLatex": '',
                        "correct": false,
                    }
                ],
                // "correct": '',
                "isRandom": '', // sort options randomly
            },
        };
    },
    methods: {
        addOption() {
            this.$data.questionObject.qOptions.push({
                "oTitle": '新增的选项',
                "oPicPath": '',
                "oMathLatex": '',
                "correct": false,
            });
        },

        delOption() {
            this.$data.questionObject.qOptions.pop();
        },

        autoSave() {
            /**
             * save the question object to localStorage
             */

            // add question title
            this.$set(this.$data.questionObject, 'qTitle', this.$data.qTitle);

            // add question math latex
            this.$set(this.$data.questionObject, 'qMathLatex', this.$data.qMathLatex);

            // add show title
            this.$set(this.$data.questionObject, 'showTitle', this.$data.qTitle.slice(0, 20));

            // localStorage key must be "myQuestion"
            localStorage.setItem("myQuestion-" + this.$props.libId.split('-').join(''), JSON.stringify(this.$data.questionObject));

            // vue-toasted
            this.$toasted.success('已保存！', {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
            }).goAway(3000);
        },

        getHeight() {
            this.txtAreaHeight = calcTextareaHeight(this.$refs.txtArea, 1, null).height;
        },

        getHeightMath() {
            this.txtAreaHeightMath = calcTextareaHeight(this.$refs.mathLatex, 1, null).height;
        },

        submitQuestion() {
            let dateObj = new Date();
            this.autoSave();
            console.log(JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))));
            //提交题目到数据库
            this.$axios.post('apiQuestion/u/create_question', {
                'qid': uuidv4(),
                'title': this.$data.qTitle.slice(0, 20),
                'tp': '单选题',
                'createtime': dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                'creator': this.$data.creator,
                'creatorid': this.$cookies.get('TOKEN'),
                'belongtoid': this.$props.libId,
                'content': JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))),
            }).then(res => {
                this.$router.go(-1); // back to the previous page
            }).catch(e => {
            });
        },

        modifyQuestion() {
            let dateObj = new Date();
            this.autoSave();

            // 提交修改的题目到数据库
            this.$axios.post('apiQuestion/u/modify_question?qId=' + this.$props.isExistsQid['content'], {
                'qid': uuidv4(),
                'title': this.$data.showTitle,
                'createtime': dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                'creator': this.$data.creator,
                'creatorid': this.$cookies.get('TOKEN'),
                'belongtoid': this.$props.libId,
                'tp': '填空题',
                'content': JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))),
            }).then(res => {
                this.$toasted.success('已提交修改！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                }).goAway(3000);

                localStorage.removeItem("myQuestion-" + this.$props.libId.split('-').join('')); // rm the localstorage

                this.$router.go(-1); // back to the previous page
            }).catch(e => {

            });
        },
    },
    watch: {
        qTitle() {
            this.getHeight();
        },

        qMathLatex() {
            this.getHeightMath();
        }
    },
    mounted() {
        /**
         * save the question object to localStorage every minute
         * @type {number}
         */
        // this.timer = setInterval(this.autoSave, 1000 * 60);
    },
    created() {
        this.$axios.get('/apiQuestion/u/fetch_info').then(res => {
            this.$data.creator = res['data']['msg'];
        }).catch(e => {
            console.log(e);
        });

        let qs = localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''));
        if (JSON.parse(qs) && JSON.parse(qs)['qTitle']) { // if you choose a question type or save the question
            this.$data.questionObject = JSON.parse(qs);
            this.$set(this.$data.questionObject, 'action', ''); // force update the view
            this.$data.qTitle = JSON.parse(qs)['qTitle'];
            this.$data.qAnswer = JSON.parse(qs)['correct'];
            this.$data.qMathLatex = JSON.parse(qs)['qMathLatex'];
            this.$data.showEdit = true;
            console.log(this.$data.questionObject);
        } else {

        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.single-choose-question {
    display: flex;
    flex-direction: column;
}

.single-choose-question-preview {
    display: flex;
    flex-direction: column;
    /*background-color: rgb(248,248,248);*/
    border: 1px solid rgb(231,234,237);
    border-radius: 14px;
    padding: 20px;
}

.single-choose-question-edit {
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 14px;
    margin-top: 20px;
    padding: 20px;
}

.set-input {
    height: 35px;
    border-radius: 10px;
    text-indent: 10px;
    font-size: 18px;
    border: #707070 1px solid;
}

.txt-area-wrap .set-txt-area {
    border-radius: 10px;
    resize: none;
    display: inline-block;
    font-size: 18px;
    border: #707070 1px solid;
    padding: 6px;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}


/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    /*top: 25%;*/
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    padding: 10px;
}

input:focus {
    outline: none;
}

/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>
