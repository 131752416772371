<template>
  <div>
    <MainHeader></MainHeader><!--navbar-->
  </div>
</template>

<script>
import MainHeader from "../components/Header/MainHeader";
export default {
    name: "Competition",
    components: {MainHeader}
}
</script>

<style scoped>

</style>
