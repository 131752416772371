<template>
  <div class="problem">
    <div class="sideBar">
      <div class="bar">
        <div class="type" v-for="(val,idx) in paper.type">
          <h3>{{typeMapper[val]}}</h3>
          <div class="content">
            <a v-for="(val,idx) in paper.content[val]" :class="'box ' + (isBlank(result[val.id]) ? 'ok' : '')" :href="'#' + val.id">{{idx + 1}}</a>
          </div>
          <div style="clear: both"></div>
        </div>
        <div class="button-zone"  v-if="!ok && mode !== 'view'">
          <button @click="submit">提交</button>
          <button @click="save">保存</button>
        </div>
      </div>


    </div>
    <div class="main">
      <div class="exam-select">
        <span>预览的试卷：</span>
        <el-select v-if="mode === 'view'" v-model="pid" @change="initPaper">
          <el-option v-for="(it,key) in examList" :key="key" :label="it.title" :value="it.id"></el-option>
        </el-select>
      </div>
      <div class="page">
        <h1>{{paper.title}}</h1>
        <div v-for="(tp,idx) in paper.type">
          <h2>{{chineseIndex[idx]}}、{{typeMapper[tp]}}</h2>
          <div v-if="tp === 's_choose'">
            <SingleChoose v-for="(val,idx) in paper.content[tp]" v-model="result[val.id]" :key="val.id"
                          :id="val.id" :index="String(idx + 1)" :q-title="val.title"
                          :question-object="val"></SingleChoose>
          </div>
          <div v-if="tp === 'm_choose'">
            <MultiChoose v-for="(val,idx) in paper.content[tp]" v-model="result[val.id]" :key="val.id"
                         :id="val.id" :index="String(idx + 1)" :q-title="val.title"
                         :question-object="val"></MultiChoose>
          </div>
          <div v-if="tp === 'blank'">
            <Blank v-for="(val,idx) in paper.content[tp]" v-model="result[val.id]" :key="val.id"  :id="val.id" :index="String(idx + 1)" :q-title="val.title" :question-object="val"></Blank>
          </div>
        </div>

        <button v-if="!ok && mode !== 'view'" @click="submit">提交</button>
      </div>
    </div>

  </div>
</template>

<script>

import SingleChoose from "../../components/problem/type/SingleChoose";
import MultiChoose from "../../components/problem/type/MultiChoose";
import Blank from "../../components/problem/type/Blank";

const func = {
  initExamList() {
    let that = this;
    this.$axios.get(
        '/apiInvigilate/getPaperTitle?examId=' + that.$route.query.examId).then((resp) => {
      console.log(resp)
      that.examList = resp.data.data;
      console.log(that.examList)
      // 第一次打开页面时自动加载第一张试卷
      that.$data.pid = that.examList[0].id;
      that.initPaper();

    }).catch((err) => {
      console.log("网络连接错误");
    })
  },
  initPaper() {
    let that = this;
    this.result = {}
    this.$axios.get(
        '/apiInvigilate/getMyPaper?paperId=' + that.pid).then((resp) => {
      that.paper = resp.data;
    }).catch((err) => {
      console.log("网络连接错误");
    })
  },
  isBlank(val) {
    if (val == [] || val == '' || val === undefined || val === null) return false;
    return val;
  },
  getAns() {
    const result = this.result;
    // alert(JSON.stringify(result))
    return result
  },
  save() {
    let that = this;
    let ans = JSON.stringify(this.getAns());
    this.$axios.post(
        '/apiExam/postUserPaper',{
          examId: that.$route.query.examId,
          paperId: that.pid,
          content: ans
        }).then((resp) => {

    }).catch((err) => {
      console.log("网络连接错误");
    })
  },
  submit() {
    let that = this;
    this.$confirm('提交答案并结束考试, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      that.confirmSubmit();
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消提交'
      });
    });

  },
  confirmSubmit() {
    let that = this;
    let ans = JSON.stringify(this.getAns());
    this.$axios.post(
        '/apiExam/postUserPaper',{
          examId: that.$route.query.examId,
          paperId: that.pid,
          content: ans
        }).then((resp) => {
      this.$message({
        type: 'success',
        message: '提交成功!'
      });
      that.paper = {title: '考试结束，请关闭本页面！'}
      that.ok = true;

    }).catch((err) => {
      console.log("网络连接错误");
    })

  }
}

export default {
  name: "Problem",
  components: {Blank, MultiChoose, SingleChoose},
  mounted() {
    if (this.$route.query.paperId) {
      this.pid = this.$route.query.paperId
    }
    else this.initExamList();
    this.initPaper();
  },
  data() {
    return {
      ok: false,
      pid: '',
      examList: [],
      result: {},
      chineseIndex: ['一','二','三','四','五','六','七','八','九','十','十一','十二','十三','十四','十五','十六'],
      typeMapper: {
        "s_choose": '选择题',
        "m_choose": '多选题',
        "blank": '填空题'
      },
      paper: {}

    };
  },
  methods: func,
  computed: {
    mode() { // view | web | aois
      if (this.$route.query.paperId) return 'web';
      else return 'view';
    }
  }
}
</script>

<style scoped lang="less">
.problem {

  display: flex;
  .sideBar {
    width: 265px;
    margin: 20px;
    position: fixed;

    .bar {
      background: white;
      border-radius: 16px;
      padding: 16px 0 0 0;

      .button-zone {
        display: flex;
        button {
          flex: 1;
          height: 35px;
          border: none;
          cursor: pointer;
          color: white;
        }

        button:nth-child(1) {
          background: #054FBF;
          border-bottom-left-radius: 16px;
        }

        button:nth-child(2) {
          background: #28a745;
          border-bottom-right-radius: 16px;
        }
      }

      .type {
        padding: 0 16px;
        h3 {
          margin: 0;
        }
        .content {
          .box {
            text-decoration: none;
            cursor: pointer;
            float: left;
            border: 1.5px solid #999;
            color: #999;
            width: 30px;
            height: 30px;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            transition: 0.2s all;
          }
          .box:hover {
            border: 1.5px solid #008AFF;
            color: #008AFF;
            background: #008AFF22;
          }
          .ok {
            border: 1.5px solid #2baf48;
            color: #2baf48;
            background: #2baf4822;
          }
        }
      }
    }


  }
  .main {
    margin-left: 305px;
    flex: 1;

    .exam-select {
      display: flex;
      margin: 20px 0 0 0;
      width: calc(100% - 20px);
      span {
        width: 100px;
        display: block;
        text-align: center;
        line-height: 40px;
      }
      .el-select {
        flex: 1;
      }
    }

    .page {
      background: white;
      padding: 20px 40px;
      border-radius: 16px;
      margin: 20px 20px 20px 0;

      h1 {
        text-align: center;
      }

      button {
        margin: 20px auto 0 auto;
        display: block;
        border: 2px solid #054FBF;
        background: white;
        color: #054FBF;
        border-radius: 10px;
        width: 300px;
        height: 50px;
        font-size: 20px;
        transition: 0.2s all;
        cursor: pointer;
      }

      button:hover {
        background: #054FBF;
        color: white;
      }
    }
  }
}
</style>
