<template>
    <div id="basalCard">
        <div id="header">
            <b>{{dataBasalCard['title']}}</b>
            <img id="more" src="../../assets/Card/more.svg">
        </div>
        <div id="center">
            <p class="information">考试开始时间：{{dataBasalCard['startTime']}}</p>
            <p class="information">考试结束时间：{{dataBasalCard['endTime']}}</p>
            <p id="examStatus">考试状态：<b v-bind:class="{start:dataBasalCard['status'] === '进行中' || dataBasalCard['status'] === '已开放',end:dataBasalCard['status'] === '已结束',mid:dataBasalCard['status']==='未公布'}">{{dataBasalCard['status']}}</b></p>
            <router-link :to="{path:dataBasalCard['path']}"><button>查看</button></router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "BasalCard",
    props:{
        dataBasalCard: {
            type: Object,
            default: function () {
                return {
                    title: "计算机网络",
                    startTime: "2022-02-03 09:45:00",
                    endTime: "2022-02-03 11:45:00",
                    status:"进行中",
                    path:"#"
                }
            }
        }
    }
}
</script>

<style scoped>
#basalCard {
    transition: 0.5s all;
    position: relative;
    height: 176px;
    width: 290px;
    float: left;
    margin-top: 45px;
    margin-left: max(40px,calc(25% - 310px));
    border-radius: 15px;
    border: 0px;
    outline:none;
    box-shadow: 0px 0px 15px #DDDDDD;
}
@media screen and (max-width: 1636px) {
    #basalCard {
        margin-left: max(40px,calc(33% - 330px));
    }
}
@media screen and (max-width: 1313px) {
    #basalCard {
        margin-left: max(53px,calc(50% - 340px));
    }
}
@media screen and (max-width: 920px) {
    #basalCard {
        margin-left: max(53px,calc(50% - 145px));
    }
}
#basalCard:hover{
    transform: translateY(-3px);
    box-shadow: 0px 0px 35px #cccccc;
}

#header {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 47px;
    height: 47px;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    background: #054FBF;
    border: 0px;
    outline:none;
}
#more {
    /*background: black;*/
    width: 20px;
    position: absolute;
    left: 255px;
    top: 15px;
}
img {
    width: 100%;
}
#center {
    height: 129px;
    width: 100%;
}
#more:hover {
    cursor: pointer;
}
.information {

    margin-left: 25px;
    font-size: 14px;
    font-weight: 100;
}
#examStatus {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 100;
}
.start{
    color: #00A03E;
}
.end {
    color:#DD1E1E;
}
.mid{
    color: #008AFF;
}

button {
    width: 57px;
    height: 29px;
    position: absolute;
    margin-top: -28px;
    margin-left: 219px;
    border-radius: 5px;
    background: #054FBF;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 100;
}
button:hover {
    cursor: pointer;
    background: #0547AC;
}
a {
    color: white;
    text-decoration:none;
}
</style>
