var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"invigilate"}},[_c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"card-title"},[_vm._v("我的监考")]),_c('div',{attrs:{"id":"sc"}},[_c('Search',{attrs:{"name":'搜索课程...'}})],1)]),_c('ScreenBar',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('div',{staticStyle:{"overflow":"auto"},attrs:{"id":"body"}},[_c('BasalCard',{attrs:{"data-basal-card":{
      title: '大学生创业基础期末考试',
      startTime:'2022-02-03 00:00:00',
      endTime:'2022-02-12 23:59:59',
      status:'已开放',
      path:'/ois'
    },"btnText":"进入监考","path":"/"}}),_c('BasalCard',{attrs:{"data-basal-card":{
      title: '国学智慧期末考试',
      startTime:'2022-02-10 00:00:00',
      endTime:'2022-02-12 23:59:59',
      status:'未开放'
    },"btnText":"进入监考","path":"/"}}),_c('BasalCard',{attrs:{"data-basal-card":{
      title: 'Java Web开发期末考试',
      startTime:'2022-02-12 13:45:00',
      endTime:'2022-02-12 15:45:00',
      status:'未开放'
    },"btnText":"进入监考","path":"/"}}),_c('BasalCard',{attrs:{"data-basal-card":{
      title: '《形势与政策（2021秋）...',
      startTime:'2022-01-01 00:00:00',
      endTime:'2022-01-01 23:59:59',
      status:'已结束'
    },"btnText":"进入监考","path":"/"}}),_c('div',{attrs:{"id":"footer"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }