<template>
    <div id="backstageHeader">
        <div class="logo">
            <img class="Avatar" src="../../assets/logo.svg">
            <div class="name">傲杰</div>
        </div>
        <router-link to="#"><img class="mail" src="../../assets/Header/mail.svg"></router-link>
        <router-link to="#"><img class="user" src="../../assets/Header/user.png"></router-link>

    </div>
</template>

<script>
export default {
    name: "BackstageHeader"
}
</script>

<style scoped>
    #backstageHeader{
        min-width: 820px;
        width: 100%;
        height: 55px;
        background: white;
        border: none;
        text-align: center;
        line-height: 55px;
        border: 0px;
        outline: none;
        box-shadow: 0px 3px 15px #dbdbdb;
    }
    .logo {
        width: 130px;
        float: left;
    }

    .Avatar {
        border-radius: 0px;
        width: 33px;
        margin-top: 10px;
        margin-left: 25px;
        font-size: 5px;
        float: left;
    }

    .name {
        font-size: 18px;
        font-weight: 800;
    }
    .mail {
        height: 18px;
        float: left;
        margin-top: 20px;
        margin-left: calc(100% - 265px);
    }

    .mail:hover {
        cursor: pointer;
    }

    .user {
        width: 35px;
        height: 35px;
        margin-top: 10px;
        margin-left: 33px;
        font-size: 5px;
        float: left;
    }

    .user:hover {
        cursor: pointer;
    }

    a:hover {
        color: #054FBF;
        cursor: pointer;
    }
</style>
