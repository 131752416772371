<template>
    <div id="userLogin">
        <div class="header">
            <div class="card-options" @click="switchTabs(false);">
                <div :style="ok ? 'color: #054FBF;' : ''">用户登录</div>
                <div :style="ok ? '':'margin-left:200%'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(true);">
                <div :style="!ok ? 'color: #054FBF;' : ''">机构号登录</div>
            </div>
        </div>
        <transition name="slide-fade1">
            <div v-show="ok">
                <transition name="slide-fade3">
                    <div v-show="!usePhone">
                        <div>
                            <input ref="name" placeholder="手机号/用户名" type="text">
                            <img src="../../assets/Login/account.svg">
                        </div>
                        <div>
                            <input ref="pwd" placeholder="傲杰系统密码" type="password">
                            <img src="../../assets/Login/password.svg">
                        </div>
                    </div>
                </transition>
                <transition name="slide-fade4">
                    <div v-show="usePhone">
                        <div>
                            <input placeholder="手机号" type="text">
                            <img src="../../assets/Login/account.svg">
                        </div>
                        <div>
                            <input class="input1" placeholder="验证码" type="text">
                            <img src="../../assets/Login/verify.svg">
                            <div class="button" @click="send($event)">发送</div>
                        </div>
                    </div>
                </transition>
                <button id="usrLogin">登录</button>

                <div class="select">
                    <router-link to="/login/register">
                        <div class="register">新用户注册</div>
                    </router-link>
                    <router-link to="#">
                        <div class="phone" v-show="!usePhone" @click="usePhone=!usePhone">手机验证码登录</div>
                        <div class="phone" v-show="usePhone" @click="usePhone=!usePhone">账号密码登录</div>
                    </router-link>
                </div>
                <div class="line"></div>
                <router-link to="#">
                    <div class="find">忘记密码？点我重置密码！</div>
                </router-link>
            </div>
        </transition>
        <div class="anmimate-box" v-show="!ok">
            <transition name="slide-fade2">
                <div v-show="!ok">
                    <div>
                        <input placeholder="机构名称" type="text">
                        <img src="../../assets/Login/school.svg">
                    </div>
                    <div>
                        <input placeholder="学号/工号" type="text">
                        <img src="../../assets/Login/id.svg">
                    </div>
                    <div>
                        <input placeholder="傲杰系统密码" type="password">
                        <img src="../../assets/Login/password.svg">
                    </div>
                    <button>登录</button>
                    <router-link to="#">
                        <div class="find">忘记密码？点我重置密码！</div>
                    </router-link>
                </div>
            </transition>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import axios from 'axios'
    import Footer from "../../components/Login/Footer";
    import {vaptcha} from '../../utils/vaptcha';
    import id from "../../utils/vaptchaId";

    export default {
        name: "UserLogin",
        components: {Footer},
        data: function () {
            return {
                ok: true,
                usePhone: false,
                isSend: false
            }
        },
        methods: {
            switchTabs(ok) {
                this.$set(this.$data, 'ok', !ok);
            },
            send(event) {
                if (this.isSend) return;
                this.$set(this.$data, "isSend", true);
                let tp = event.target;
                tp.style.background = "#efefef";
                tp.style.border = "1px solid #efefef";
                tp.style.color = "#000000";
                let time = 60;
                let that = this;
                tp.innerHTML = time;
                time--;
                let timing = setInterval(function () {
                    tp.innerHTML = time;
                    time--;
                    if (time === -1) {
                        tp.style.background = "#054FBF";
                        tp.style.border = "1px solid #054FBF";
                        tp.style.color = "#ffffff";
                        tp.innerHTML = '发送';
                        that.$set(that.$data, "isSend", false);
                        clearInterval(timing);
                    }
                }, 1000);
            },
            login(VAPTCHAObj, verifyObj) {
                this.$axios.get(
                    '/apiLogin/loginTel', {
                        params: {
                            name: this.$refs.name.value,
                            password: this.$refs.pwd.value,
                            verifyUrl: verifyObj['server'],
                            verifyToken: verifyObj['token'],
                        }
                    }).then((resp) => {
                    if (resp.data.msg === 'error') {
                        this.popUps("账号或密码错误")
                    } else {
                        this.popUps("登陆成功");
                        this.$router.push("/funcCenter");
                    }
                }).catch((err) => {
                    console.log("网络连接错误");
                    VAPTCHAObj.reset();
                })
            },
            popUps(puMsg) {
                this.$toasted.success(puMsg, {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                    position: 'top-center'
                }).goAway(2500);
            },
        },
        mounted() {
            // let self = this;
            // document.onkeydown = function (e) {
            //     let ev = document.all ? window.event : e;
            //     if (ev.keyCode === 13) {
            //         self.login();
            //     }
            // };

            vaptcha().then(vaptcha => {
                vaptcha({
                    vid: id, // 验证单元id
                    type: 'invisible', // 显示类型 点击式
                    scene: 1, // 场景值 默认0
                }).then(VAPTCHAObj => {
                    // 将VAPTCHA验证实例保存到局部变量中

                    // 验证成功进行后续操作
                    VAPTCHAObj.listen('pass', () => {
                        let serverToken = VAPTCHAObj.getServerToken();
                        let data = {
                            server: serverToken.server,
                            token: serverToken.token,
                        };

                        this.login(VAPTCHAObj, data);
                    });

                    document.getElementById('usrLogin').onclick = () => {
                        VAPTCHAObj.validate();
                    };
                });
            });
        }
    }
</script>

<style scoped>
    #userLogin {
        overflow: auto;
        width: 580px;
        height: 500px;
        border: none;
        border-radius: 15px;
        background-color: #ffffff;
        position: fixed;
        top: calc(50% - 250px);
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .header {
        margin-left: 20%;
        top: 0;
        width: 60%;
        height: 70px;
        border-bottom: 2px solid #e9e9e9;
    }

    .card-options {
        width: 50%;
        user-select: none;
        float: left;
        margin-top: 30px;
        color: #999999;
        display: flex;
        font-size: 22px;
        font-weight: 500;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-options:hover {
        cursor: pointer;
    }

    .option-underline {
        height: 3px;
        width: 100%;
        background-color: #054FBF;
        margin-top: 9px;
        transition: 0.3s;
    }

    input {
        color: black;
    }

    .input1 {
        float: left;
        outline: none;
        border-radius: 5px 0 0 5px;
        border: 1px solid #a3a3a3;
        border-right: none;
        width: 290px;
        height: 41px;
        margin-left: 98px;
        margin-top: 36px;
        font-weight: 100;
        font-size: 17px;
        text-indent: 48px;
    }

    input {
        outline: none;
        border-radius: 5px;
        border: 1px solid #a3a3a3;
        width: 380px;
        height: 41px;
        margin-left: 98px;
        margin-top: 36px;
        font-weight: 100;
        font-size: 17px;
        text-indent: 48px;
    }

    img {
        position: absolute;
        height: 23px;
        margin-top: 47px;
        left: 112px;
    }

    button {
        width: 382px;
        height: 41px;
        margin-left: 99px;
        margin-top: 35px;
        outline: none;
        border-radius: 13px;
        border: none;
        background: #054FBF;
        color: white;
        font-size: 20px;
        font-weight: 100;
    }

    button:hover {
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
        cursor: pointer;
    }

    .select {
        margin-top: 22px;
    }

    .register {
        float: left;
        margin-left: 130px;
    }

    .phone {
        float: left;
        margin-left: 126px;
    }

    .select > a {
        font-size: 15px;
        font-weight: 100;
        color: #054FBF;
        text-decoration: none;
    }

    .line {
        margin-top: 58px;
        margin-left: 98px;
        width: 385px;
        border: 1px dashed #dddddd;
    }

    .find {
        color: black;
        font-weight: 100;
        margin-top: 30px;
        text-align: center;
    }

    .find:hover {
        color: #054FBF;
    }

    .anmimate-box {
        position: absolute;
        top: 70px;
        bottom: 0px;
        width: 100%;
        overflow: hidden;
    }

    .button {
        text-align: center;
        line-height: 43px;
        margin-top: 36px;
        float: left;
        width: 90px;
        border: 1px solid #054FBF;
        height: 43px;
        background: #054FBF;
        color: white;
        border-radius: 0 5px 5px 0;
        font-weight: 100;
        font-size: 17px;
    }

    .button:hover {
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
        cursor: pointer;
    }

    .anmimate-box1 {
        position: absolute;
        top: 70px;
        bottom: 260px;
        width: 100%;
        overflow: hidden;
    }


    .slide-fade1-enter-active {
        transition: all 0.3s ease;
    }

    .slide-fade1-enter, .slide-fade1-leave-to {
        transform: translateX(-300px);
        opacity: 0;
    }


    .slide-fade2-enter-active {
        transition: all 0.3s ease;
    }

    .slide-fade2-enter, .slide-fade2-leave-to {
        transform: translateX(300px);
        opacity: 0;
    }


    .slide-fade3-enter-active {
        transition: all 0.3s ease;
        /*position: absolute;*/
        transition-delay: 0.3s;
        z-index: 99;
    }

    .slide-fade3-leave-active {
        transition: all 0.3s ease;
        position: absolute;
    }

    .slide-fade3-enter {
        transform: rotateY(90deg);
    }

    .slide-fade3-leave-to {
        transform: rotateY(90deg);
    }

    .slide-fade4-enter-active {
        transition: all 0.3s ease;
        transition-delay: 0.3s;
    }

    .slide-fade4-leave-active {
        transition: all 0.3s ease;
        position: absolute;
        top: 72px;
        z-index: 100;
    }

    .slide-fade4-enter {
        transform: rotateY(90deg);
    }

    .slide-fade4-leave-to {
        transform: rotateY(90deg);
    }
</style>
