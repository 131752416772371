import Login from "../views/login/Login";
import UserLogin from "../views/login/UserLogin";
import Register from "../views/login/Register";


export default [
    {
        path: '/Login',
        name: 'login',
        component: Login,
        children: [
            {
                path: '/',
                redirect: 'userLogin'
            },
            {
                path: 'userLogin',
                name: 'userLogin',
                component: UserLogin
            },

            {
                path: 'register',
                name: 'register',
                component: Register
            }
        ]
    }
];
