<template>
    <div class="blank-question">
        <div class="blank-question-preview"><!--question preview-->
            <div style="font-weight: bold;">{{ qTitle }}</div>

            <div style="display: flex; flex-direction: row; justify-content: flex-end;">
                <div style="margin: 0;" @click="showEdit = !showEdit;">
                    <buttons :content="showEdit ? '收起' : '编辑'" btn-color="#D94E4E" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>

                <div v-if="!isExistsQid['exists']" style="margin: 0; margin-left: 5px;" @click="submitQuestion">
                    <buttons content="提交" btn-color="#054FBF" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>
                <div v-else style="margin: 0; margin-left: 5px;" @click="modifyQuestion">
                    <buttons content="修改" btn-color="#054FBF" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="blank-question-edit" v-show="showEdit"><!--question edit-->

                <div style="display: flex; flex-direction: row; width: 100%; margin-bottom: 10px;">
                    <input style="width: 100%;" v-model="showTitle" class="set-input" placeholder="输入本题的显示标题...">
                </div>

                <div style="display: flex; flex-direction: row; width: 100%;">
                    <!--question title and score-->
                    <div style="width: 70%; padding-right: 20px;" class="txt-area-wrap">
                    <textarea :style="{'height': txtAreaHeight}"
                              ref="txtArea" v-model="qTitle"
                              class="set-txt-area" style="display: block; width: 100%;"
                              placeholder="请输入题干内容..."></textarea>
                    </div>

                    <div style="flex: 1;">
                        <input v-model="questionObject.qPrice" class="set-input" style="display: block; width: 100%;"
                               type="number"
                               placeholder="请输入该题分值...">
                    </div>
                </div>
                <div
                    style="display: flex; flex-direction: row; width: 100%; margin-top: 10px; padding-bottom: 10px; border-bottom: #707070 1px solid;">
                    <!--insert pic and formula-->
                    <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片" txt-color="#555555"
                             btn-color="white" :h="30" :w="90"></buttons>
                    <buttons style="border: #707070 1px solid;" content="插入公式" txt-color="#555555" btn-color="white"
                             :h="30"
                             :w="90"></buttons>
                </div>

                <div class="txt-area-wrap"
                     style="display: flex; flex-direction: row; width: 100%; margin-bottom: 10px; margin-top: 10px;">
                <textarea :style="{'height': txtAreaHeightAnswer}"
                          ref="txtAreaAnswer" v-model="qAnswer"
                          class="set-txt-area" style="display: block; width: 100%;"
                          placeholder="输入答案与解析..."></textarea>
                </div>
                <div
                    style="display: flex; flex-direction: row; width: 100%;">
                    <!--insert pic and formula-->
                    <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片" txt-color="#555555"
                             btn-color="white" :h="30" :w="90"></buttons>
                    <buttons style="border: #707070 1px solid;" content="插入公式" txt-color="#555555" btn-color="white"
                             :h="30"
                             :w="90"></buttons>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Buttons from "../Buttons";
import 'font-awesome/css/font-awesome.min.css'
import calcTextareaHeight from "../../utils/calcTextareaHeight";
import {v4 as uuidv4} from 'uuid';

export default {
    name: "Compute",
    components: {
        Buttons
    },
    props: {
        libId: {
            type: String,
            require: true,
        },
        isExistsQid: {
            type: Object,
            require: true,
            default: {
                'exists': false,
                'content': null,
            }
        }
    },
    data() {
        return {
            txtAreaHeight: '35px',
            txtAreaHeightAnswer: '35px',

            showTitle: '',
            qTitle: '题干',
            qAnswer: '',
            creator: '',
            showEdit: false,
            questionObject: {
                "qType": 'blank',
                "qPicPath": '', // if question title wants to use picture to explain the meaning
                "qMathLatex": '', // if question title wants to use math formula to explain the meaning
            },
        };
    },
    methods: {
        autoSave() {
            /**
             * save the question object to localStorage
             */

            // add question title
            this.$set(this.$data.questionObject, 'qTitle', this.$data.qTitle);

            // add show title
            this.$set(this.$data.questionObject, 'showTitle', this.$data.showTitle);

            // add correct answer
            this.$set(this.$data.questionObject, 'correct', this.$data.qAnswer);

            // localStorage key must be "myQuestion-uuid"
            localStorage.setItem("myQuestion-" + this.$props.libId.split('-').join(''), JSON.stringify(this.$data.questionObject));

            // vue-toasted
            this.$toasted.success('已保存！', {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
            }).goAway(3000);
        },

        submitQuestion() {
            let dateObj = new Date();
            this.autoSave();
            console.log(JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))));

            // 提交题目到数据库
            this.$axios.post('apiQuestion/u/create_question', {
                'qid': uuidv4(),
                'title': this.$data.showTitle,
                'tp': '计算题',
                'createtime': dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                'creator': this.$data.creator,
                'creatorid': this.$cookies.get('TOKEN'),
                'belongtoid': this.$props.libId,
                'content': JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))),
            }).then(res => {
                this.$toasted.success('已提交！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                }).goAway(3000);

                localStorage.removeItem("myQuestion-" + this.$props.libId.split('-').join('')); // rm the localstorage

                this.$router.go(-1); // back to the previous page
            }).catch(e => {

            });
        },

        modifyQuestion() {
            let dateObj = new Date();
            this.autoSave();

            // 提交修改的题目到数据库
            this.$axios.post('apiQuestion/u/modify_question?qId=' + this.$props.isExistsQid['content'], {
                'qid': uuidv4(),
                'title': this.$data.showTitle,
                'createtime': dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                'creator': this.$data.creator,
                'creatorid': this.$cookies.get('TOKEN'),
                'belongtoid': this.$props.libId,
                'tp': '计算题',
                'content': JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))),
            }).then(res => {
                this.$toasted.success('已提交修改！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                }).goAway(3000);

                localStorage.removeItem("myQuestion-" + this.$props.libId.split('-').join('')); // rm the localstorage

                this.$router.go(-1); // back to the previous page
            }).catch(e => {

            });
        },

        getHeight() {
            this.txtAreaHeight = calcTextareaHeight(this.$refs.txtArea, 1, null).height;
        },
        getHeightAnswer() {
            this.txtAreaHeightAnswer = calcTextareaHeight(this.$refs.txtAreaAnswer, 1, null).height;
        },
        insertBlankSign() {
            let txtAreaElement = this.$refs.txtArea;
            let cursor = txtAreaElement.selectionEnd;
            txtAreaElement.value = txtAreaElement.value.slice(0, cursor) + '______' + txtAreaElement.value.slice(cursor, txtAreaElement.value.length);
            this.$data.qTitle = txtAreaElement.value;
        },
        howManyBlanks() {
            return this.$data.qTitle.match(/______/g) !== null ? this.$data.qTitle.match(/______/g).length : 0;
        }
    },
    watch: {
        qTitle() {
            this.getHeight();
        },
        qAnswer() {
            this.getHeightAnswer();
        }
    },
    mounted() {
        /**
         * save the question object to localStorage every minute
         * @type {number}
         */
        this.timer = setInterval(this.autoSave, 1000 * 60);
    },
    created() {
        this.$axios.get('apiQuestion/u/fetch_info').then(res => {
            this.$data.creator = res['data']['msg'];
        }).catch(e => {
            console.log(e);
        });

        let qs = localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''));
        if (JSON.parse(qs) && JSON.parse(qs)['qTitle']) { // if you choose a question type or save the question
            this.$data.questionObject = JSON.parse(qs);
            this.$set(this.$data.questionObject, 'action', '');

            this.$data.showTitle = JSON.parse(qs)['showTitle'];
            this.$data.qTitle = JSON.parse(qs)['qTitle'];
            this.$data.qAnswer = JSON.parse(qs)['correct'];

            this.$data.showEdit = true;
            console.log(this.$data.questionObject);
        } else {

        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.blank-question {
    display: flex;
    flex-direction: column;
}

.blank-question-preview {
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 14px;
    padding: 20px;
}

.blank-question-edit {
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 14px;
    margin-top: 20px;
    padding: 20px;
}

.set-input {
    height: 35px;
    border-radius: 10px;
    text-indent: 10px;
    font-size: 18px;
    border: #707070 1px solid;
}

.txt-area-wrap .set-txt-area {
    border-radius: 10px;
    resize: none;
    display: inline-block;
    font-size: 18px;
    border: #707070 1px solid;
    padding: 5px;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

input {
    box-sizing: border-box;
}

textarea {
    box-sizing: border-box;
}


/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
