<template>
    <div>
        <!--        {{ $route.query.libId }}-->
        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center;">
                <div class="card-title">期末考试</div><!--title-->
                <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
                    <!--new exam button-->
                    <div @click="clickToPushArg('/exam/manager/newPaper');">
                        <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"
                                 btn-color="#054FBF"
                                 txt-color="white"
                                 content="编辑该试卷"
                                 :h="30"
                                 :w="120"
                                 :f-size="5"></buttons>
                    </div>
                </div>
            </div>
            <div class="line"></div><!--line-->
        </div>

        <transition name="slide-fade">
            <div v-show="true" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
                <!--content of "题目"-->

                <h2>一、单选题</h2>

                <div style="font-weight: bold;">1. 题干</div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose" type="radio"></div>
                    <div>选项1</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose" type="radio"></div>
                    <div>选项2</div>
                </div>
                <p></p>
                <div style="font-weight: bold;">2. 智能区域</div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div style="color: #6f6f6f;">智能区域无法在此处预览，请前往试卷编辑处查看</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Buttons from "../../../../components/Buttons";
import MainHeader from "../../../../components/Header/MainHeader";
import MainSideBar from "../../../../components/SideBar/MainSideBar";
import HeavyList from "../../../../components/HeavyList";

export default {
    name: "TestPaperPreview",
    components: {
        Buttons,
        MainHeader,
        MainSideBar,
        HeavyList,
    },
    data() {
        return {
            kw: '',
            papers: [
                {
                    "libId": "71a422c8-6976-415a-9d4a-5902f14da152",
                    "title": "期末考试",
                    "creator": "李兰辰",
                    "creatorId": "178bab32-5a69-4ce0-93d6-74afcaac59ab",
                    "createTime": "2022年1月2日 10:00",
                    "koPoints": "",
                    "subjectClass": "",
                    "subjectClassId": "",
                },
            ],
        };
    },
    methods: {
        clickToPushArg(url) {
            this.$router.push({
                path: url,
                // query: {
                //     eId: id,
                //     eTitle: title,
                // }
            });
        },
    }
}
</script>

<style scoped>
#functionCenter {
    height: 100%;
}

#body {
    margin-top: 16px;
    margin-left: 16px;
    float: left;
    width: calc(100% - 308px);
    min-height: calc(100% - 87px);
    background: white;
    border-radius: 15px;
}

.card-title {
    margin-top: 14px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 13px;
}

.card-options {
    margin-left: 50px;
    margin-top: 24px;
    color: #666666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-options:hover {
    cursor: pointer;
}

.line {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}

.search-paper {
    position: relative;
}

.search-paper::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
