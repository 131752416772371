<template>
    <div id="studentSideBar">
        <div>
            <router-link to="/funcCenter/course">
                <div id="back">
                    <img style="margin-top: 9px" src="../../assets/Class/back.svg">
                </div>
            </router-link>
            <div id="picture">
                <img src="../../assets/Class/CLASS.svg">
                <div id="className" >{{name}}</div>
            </div>
        </div>
        <div id="body">
            <ul>
                <router-link style="width: 100%" :to="{path:'/class/classArea/notice',query:{name:name}}">
                    <li style="border-radius: 16px 16px 0 0" :class="this.$route.name === 'cNotice' ? 'selected':'notSelected'">
                            <img src="../../assets/Class/notice.svg">
                            <div class="cf">通知</div>
                    </li>
                </router-link>
                <router-link :to="{path:'/class/classArea/exam',query:{name:name}}">
                    <li :class="this.$route.name === 'cExam' ? 'selected':'notSelected'">
                            <img src="../../assets/Class/exam.svg">
                            <div class="cf">考试</div>
                    </li>
                </router-link>
                <router-link :to="{path:'/class/classArea/grade',query:{name:name}}">
                    <li :class="this.$route.name === 'cGrade' ? 'selected':'notSelected'">
                            <img src="../../assets/Class/grade.svg">
                            <div class="cf">成绩</div>
                    </li>
                </router-link>
                <router-link :to="{path:'/class/classArea/task',query:{name:name}}">
                    <li style="border-radius: 0px 0px 16px 16px" :class="this.$route.name === 'cTask' ? 'selected':'notSelected'">
                            <img  src="../../assets/Class/task.svg">
                            <div class="cf">课程任务</div>
                    </li>
                </router-link>
            </ul>
        </div>
        <div id="footer">
            <p>傲杰考试系统 V1.0傲杰之星</p>
            <p>©兴风科技2022</p>
            <p>浙ICP备20011690号-3</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StudentSideBar',
    data:function () {
        return {
            name:this.$route.query.name
        }
    }
}
</script>

<style scoped>
    #studentSideBar{
        margin-top: 16px;
        margin-left: 16px;
        float: left;
        width: 185px;
        height: 500px;
    }
    #body {
        width: 185px;
        background: white;
        margin-top: 16px;
        border-radius: 15px;
    }
    #footer {
        text-align: center;
        width: 180px;
        margin-top: 16px;
    }
    p {
        color: #999999;
        font-size: 12.5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #back{
        border-radius: 15px 15px 0 0;
        background: white;
        height: 40px;
        text-align: center;
        border-bottom: 1px solid #E9E9E9;
    }
    a {
        text-decoration: none;
    }
    #picture {
        border-radius: 0 0 15px 15px;
        background: white;
        width: 185px;
        height: 151px;
    }
    #picture > img {
        width: 153px;
        margin-left:16px;
        margin-top: 16px;
    }
    #className {
        text-align: center;
        margin-top: 6px;
        font-size: 16px;
        font-weight: 600;
    }
    ul {
        list-style: none;
        display: block;
        margin: 0px;
        padding: 0px;
    }
    li {
        line-height: 42.3px;
        text-align: center;
        height: 42.3px;
        width: 100%;
        color: black;
        font-size: 15px;
        font-weight: 100;
    }
    .cf{
        float: left;
        margin-left: 20px;

    }
    a {
        color: black;
        display: block;
        text-decoration:none;
    }
    li:hover {
        cursor: pointer;
        /*background: rgba(5,79,191,0.1);*/
    }
    li > img {
        margin-left: 48px;
        float: left;
        margin-top: 10px;
        width: 18px;
    }

    .selected {
        color: white;
        background: #054FBF;
    }

    .notSelected:hover{
        background: rgba(5,79,191,0.1);
    }
</style>
