<template>
    <div id="konwledge">
        <div id="header">
            <div class="card-title">知识点</div>
            <div id="sc">
                <Search :name="'搜索知识点...'"></Search>
            </div>
            <button style="width: 92px">模板导入</button>
            <button style="width: 92px">模板导出</button>
            <button @click="addKnowledge=true" >+ 添加知识点</button>
        </div>
        <div id="body">
            <div id="all">
                <div class="all-title">
                    <div class="title1">
                        知识点
                    </div>
                    <div class="title2">
                        操作
                    </div>
                </div>
                <div v-for="(item,idx) in dataPoint" class="content" @click="change(item.idx)" v-show="item.show" :style="item.idx === check ? 'background:#F0F6FF':''">
                    <div v-if="item.haveChildren" @click="show(idx)" :class="item.showChildren ? 'content_open':'content_m'">
                        <img src="../../assets/Class/more.svg" :style="'margin-left:' + item.deep*20 + 'px'">
                        {{item.name}}
                    </div>
                    <div v-if="!item.haveChildren" class="content_a">
                        <b :style="'margin-left:' + item.deep*23+ 'px'">·</b>
                        {{item.name}}
                    </div>
                    <div class="content_b">
                        <div class="mdd">
                            <div class="content_b_a">
                                添加子级知识点
                            </div>
                            <div class="content_b_a">
                                添加父级知识点
                            </div>
                            <div class="content_b_a">
                                编辑
                            </div>
                            <div class="delete">
                                <img width="100%" src="../../assets/Class/delete.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add" v-show="addKnowledge">
                    <div class="content_a">
                        <input type="text"/>
                    </div>
                    <div class="content_b">
                        <div class="mdd">
                            <div @click="addKnowledge=!addKnowledge" class="right">
                                <img width="100%" src="../../assets/common/right.svg">
                            </div>
                            <div @click="addKnowledge=!addKnowledge" class="delete">
                                <img width="100%" src="../../assets/Class/delete.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Search from "../../components/common/Search";
import Buttons from "../../components/Buttons";
export default {
    name: "Konwledge",
    components: {Buttons, Search},
    data:function () {
        return {
            check: -1,
            addKnowledge: false,
            dataPoint:[
                {
                    name: '知识点A',
                    haveChildren: true,
                    children:[1,3],
                    idx: 0,
                    deep: 1,
                    showChildren:false,
                    show:true
                },
                {
                    name: '知识点A.1',
                    haveChildren: true,
                    children:[2],
                    idx: 1,
                    deep: 2,
                    showChildren:false,
                    show:false
                },
                {
                    name: '知识点A.1.1',
                    haveChildren: false,
                    children:[],
                    idx: 2,
                    deep: 3,
                    showChildren:false,
                    show:false
                },
                {
                    name: '知识点A.2',
                    haveChildren: false,
                    children:[],
                    idx: 3,
                    deep: 2,
                    showChildren:false,
                    show:false
                },
                {
                    name: '知识点B',
                    haveChildren: true,
                    children:[5],
                    idx: 4,
                    deep: 1,
                    showChildren:false,
                    show:true
                },
                {
                    name: '知识点B.1',
                    haveChildren: true,
                    children:[6],
                    idx: 5,
                    deep: 2,
                    showChildren:false,
                    show:false
                },
                {
                    name: '知识点B.1.1',
                    haveChildren: true,
                    children:[7],
                    idx: 6,
                    deep: 3,
                    showChildren:false,
                    show:false
                },
                {
                    name: '知识点B.1.1.1',
                    haveChildren: true,
                    children:[8],
                    idx: 7,
                    deep: 4,
                    showChildren:false,
                    show:false
                },
                {
                    name: '知识点B.1.1.1.1',
                    haveChildren: false,
                    children:[],
                    idx: 8,
                    deep: 4,
                    showChildren:false,
                    show:false
                }
            ]
        }
    },
    methods:{
        change(idx){
            this.$set(this.$data,"check",idx);
        },
        show(idx) {
            this.$set(this.$data.dataPoint[idx],"showChildren",!this.$data.dataPoint[idx].showChildren);
            if(this.$data.dataPoint[idx].showChildren) this.showAll(idx);
            else this.closeAll(idx);
        },
        closeAll(idx) {
            for(let i = 0;i < this.$data.dataPoint[idx].children.length; i++) {
                let to = this.$data.dataPoint[idx].children[i];
                this.$set(this.$data.dataPoint[to],"show",false);
                this.closeAll(to);
            }
        },
        showAll(idx) {
            for(let i = 0;i < this.$data.dataPoint[idx].children.length; i++) {
                let to = this.$data.dataPoint[idx].children[i];
                this.$set(this.$data.dataPoint[to],"show",true);
                if(this.$data.dataPoint[to].showChildren)  this.showAll(to);
            }
        }
    }
}
</script>

<style scoped>
#konwledge {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
#sc{
    float: right;
    width: 157px;
    margin-top: 13px;
    margin-right: 25px;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

button {
    float: right;
    width: 108px;
    height: 31px;
    margin-top: 12px;
    margin-right: 25px;
    border: none;
    border-radius: 30px;
    background: #054FBF;
    color: white;
}

button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}
@media screen and (max-width: 895px){
    .card-title{
        display: none;
    }
}
.add{
    width: 100%;
    height: 50px;
    background: #F0F6FF;
}
#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}
#all {
    width: 92%;
    height: calc(100% - 90px);
    margin-top: 45px;
    margin-left: 4%;
    border-radius: 15px;
    border: 1px solid #dddddd;
}
.all-title {
    width: calc(100% + 2px);
    height: 55px;
    background: #054FBF;
    margin-top: -1px;
    margin-left: -1px;
    border-radius: 15px 15px 0 0;
}
.title1{
    font-size: 19px;
    font-weight: 100;
    line-height: 55px;
    text-align: center;
    height: 100%;
    float: left;
    width: calc(50% - 1px);
    color: white;
    border-right: 1px solid #dddddd;
}
.title2{
    font-size: 19px;
    font-weight: 100;
    line-height: 55px;
    text-align: center;
    height: 100%;
    float: left;
    width: 50%;
    color: white;
}
.content {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #dddddd;
}

.content:hover{
    background: #F0F6FF;
}
.delete {
    margin-top: 15px;
    float: left;
    width: 20px;
    margin-left: 13%;
}
.right{
    margin-right: 30px;
    margin-top: 15px;
    float: left;
    width: 20px;
    margin-left: 8%;
}

.right:hover{
    cursor: pointer;
}

.delete:hover {
   cursor: pointer;
}



.content_a {
    text-indent: 5px;
    font-size: 15px;
    font-weight: 100;
    line-height: 50px;
    height: 50px;
    float: left;
    width: calc(50% - 1px);
    border-right: 1px solid #dddddd;
}

.content_a > input {
    text-indent: 5px;
    outline: none;
    width: 200px;
    height: 26px;
    margin-left: 20px;
}

.content_m {
    text-indent: 5px;
    font-size: 15px;
    font-weight: 100;
    line-height: 50px;
    height: 50px;
    float: left;
    width: calc(50% - 1px);
    border-right: 1px solid #dddddd;
}

.content_open {
    text-indent: 5px;
    font-size: 15px;
    font-weight: 100;
    line-height: 50px;
    height: 50px;
    float: left;
    width: calc(50% - 1px);
    border-right: 1px solid #dddddd;
}
.content_open > img{
    width: 14px;
    float: left;
    margin-top: 18px;
    margin-left: 15px;
    transform: rotate(90deg);
}
.content_open:hover{
    cursor: pointer;
}

.content_m > img{
    width: 14px;
    float: left;
    margin-top: 18px;
    margin-left: 15px;
}

.content_a > img{
    width: 10px;
    float: left;
    margin-top: 20px;
}

.content_m>img:hover{
    transform: rotate(90deg);
}

.content_m:hover{
    cursor: pointer;
}


.content_b {
    text-align: center;
    float: left;
    width: 50%;
}
.content_a:hover {
    cursor: pointer;
}
.mdd {
    margin-left: 10%;
}


.content_b_a {
    font-size: 14px;
    font-weight: 100;
    line-height: 50px;
    float: left;
    margin-left:10%;
    color: #054FBF;
}
.content_b_a:hover {
    cursor: pointer;
}

@media screen and (max-width: 1301px) {
    .mdd {
        margin-left: 6%;
    }
    .delete {
        margin-left: 6%;
    }
    .content_b_a {
        margin-left:6%;
    }
}

@media screen and (max-width: 978px) {
    .mdd {
        margin-left: 3%;
    }
    .delete {
        margin-left: 3%;
    }
    .content_b_a {
        margin-left:3%;
    }
}
@media screen and (max-width: 857px) {
    .mdd {
        margin-left: 7px;
    }
    .delete {
        margin-left: 7px;
    }
    .content_b_a {
        font-size: 13px;
        margin-left: 8px;
    }
}



/*滚动条样式*/
::-webkit-scrollbar {
    width:3px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background-color:#dddddd;
    background-clip:padding-box;
    min-height:28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
}
</style>
