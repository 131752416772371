<template>
    <div>


        <!--modal-->
        <transition name="slide-fade">
            <div class="screen" v-if="okq || okm"></div>
        </transition>

        <transition name="bounce">
            <div class="Pop-ups" v-if="okq">
                <div class="pTitle">
                    <div class="pTitleFont">确认提交</div>
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div style="color: red;">本人明确理解并同意:点击"确定"后，意味着我将&nbsp;</div>
                    </div>
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div><input placeholder="不可再次登录参加" type="text" class="p-body-input"></div>
                    </div>
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div style="color: red;">本场考试。</div>
                    </div>
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>请输入学号:&nbsp;</div>
                        <div><input type="text" class="p-body-input"></div>
                    </div>
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>请输入姓名:&nbsp;</div>
                        <div><input type="text" class="p-body-input"></div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="ok = false;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div @click="submit" style="margin-left: 5px; margin-right: 23px;">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <transition name="bounce">
            <div class="Pop-ups" v-if="oks">
                <div class="pTitle">
                    <div class="pTitleFont">系统提醒</div>
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div style="color: red;">刚刚您已进行1次切屏操作，如切屏3次将自动提交试卷！</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div @click="oks = false;" style="margin-left: 5px; margin-right: 23px;">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <transition name="bounce">
            <div class="Pop-ups" v-if="okm">
                <div class="pTitle">
                    <div class="pTitleFont">监考老师提醒</div>
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div style="color: red;">请立即关闭你设备上的谷歌浏览器！</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div @click="okm = false;" style="margin-left: 5px; margin-right: 23px;">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center;">
                <div class="card-title">期末考试</div><!--title-->
                <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
                    <!--new exam button-->
                    <buttons style="margin-right: 15px;" tp="super_rounded_rectangle"
                             btn-color="#054FBF"
                             txt-color="white"
                             content="004号考生 左端"
                             :h="30"
                             :w="150"
                             :f-size="13"></buttons>
                </div>
            </div>
            <div class="line"></div><!--line-->
        </div>

        <div style="padding: 40px;">
            <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -15px;">
                <tr>
                    <td class="col1">考试课程</td>
                    <td class="col2">医学统计学期末考试</td>
                </tr>
                <tr>
                    <td class="col1">考试时间</td>
                    <td class="col2">04-17 18:00 至 04-17 20:00</td>
                </tr>
                <tr>
                    <td class="col1">答题时长</td>
                    <td class="col2">120分钟</td>
                </tr>
            </table>

            <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -25px;">
                <tr>
                    <td class="col1">本试卷共包含5道题目，其中填空题1道，单选题4道。</td>
                </tr>
            </table>

            <table style="width: 100%; -webkit-border-vertical-spacing: 15px; margin-top: -15px;">
                <tr>
                    <td class="col1">考试说明</td>
                    <td class="col2"></td>
                </tr>
            </table>

            <div>
                <div style="font-weight: normal; font-size: 14px;">1. 请不要中途离开考试界面，离开或退出考试界面会继续计时。</div>
                <div style="font-weight: normal; font-size: 14px;">2.
                    如在手机APP上考试，请将手机调至免打扰模式，避免来电引起考试中断。确保手机有充足电量或接入电源。
                </div>
                <div style="font-weight: normal; font-size: 14px;">3. 考试时间截止或答题时间结束，如果处于答题页面，将自动提交试卷。</div>
                <div style="font-weight: normal; font-size: 14px;">4. 考试过程中如果出现页面卡死、题目空白情况，请尝试切换网络或退出重新进入考试。</div>
            </div>

        </div>

        <transition name="slide-fade">
            <div v-show="true" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
                <!--content of "题目"-->
                <h3>一、填空题</h3>
                <div style="font-weight: bold;">医学统计工作的基本步骤是______、______、______、______</div>
                <div
                    style="display: flex; flex-direction: column; align-items: center; margin-top: 10px; margin-bottom: 10px;">
                    <input style="width: 100%;" class="set-input" :placeholder="'第1空...'">
                    <input style="width: 100%;" class="set-input" :placeholder="'第2空...'">
                    <input style="width: 100%;" class="set-input" :placeholder="'第3空...'">
                    <input style="width: 100%;" class="set-input" :placeholder="'第4空...'">
                </div>
                <p></p>
                <h3>二、单选题</h3>
                <div style="font-weight: bold;">1. 痊愈、显效、好转、无效属于</div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose" type="radio"></div>
                    <div>计数资料</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose" type="radio"></div>
                    <div>计量资料</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose" type="radio"></div>
                    <div>等级资料</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose" type="radio"></div>
                    <div>以上均不是</div>
                </div>
                <p></p>
                <div style="font-weight: bold;">
                    <div>
                        <div style="position: relative;">
                            2. 某市250名8岁男孩体重有95%的<span @contextmenu.prevent="shoeHRight">人</span><span @contextmenu.prevent="shoeRight" :style="mk">在18-30kg范围内</span>，由此可推知此250名男孩体重标准差大约为
                        </div>
                        <div v-if="rc" @click="mark" style="position: absolute; margin-left: 250px; background-color: white;
                         border-radius: 8px; box-shadow:0 0 20px 0 #6f6f6f; width: 100px; height: 40px; display: flex; justify-content: center; align-items: center;">
                            <div>标注题干</div>
                        </div>


                        <div v-if="rcC" @click="unMark" style="position: absolute; margin-left: 250px; background-color: white;
                         border-radius: 8px; box-shadow:0 0 20px 0 #6f6f6f; width: 100px; height: 40px; display: flex; justify-content: center; align-items: center;">
                            <div>取消标注</div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose1" type="radio"></div>
                    <div>2.0kg</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose1" type="radio"></div>
                    <div>2.3kg</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose1" type="radio"></div>
                    <div>3.1kg</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose1" type="radio"></div>
                    <div>0kg</div>
                </div>
                <p></p>
                <div style="font-weight: bold;">3. 单因素方差分析中，造成各组均数不等的原因有</div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose2" type="radio"></div>
                    <div>个体差异</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose2" type="radio"></div>
                    <div>测量误差</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose2" type="radio"></div>
                    <div>各处理组可能存在的差异</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose2" type="radio"></div>
                    <div>以上都有</div>
                </div>
                <p></p>
                <div style="font-weight: bold;">4. 在抽样研究中，当样本例数增多时</div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose3" type="radio"></div>
                    <div>标准误逐渐增大</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose3" type="radio"></div>
                    <div>标准误逐渐减小</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose3" type="radio"></div>
                    <div>标准差逐渐增大</div>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <div><input name="s_choose3" type="radio"></div>
                    <div>标准差逐渐减小</div>
                </div>
                <p></p>
                <div @click="okq = true;">
                    <buttons style="margin-right: 15px; margin-bottom: 20px;" tp="default"
                             btn-color="#054FBF"
                             txt-color="white"
                             content="提交"
                             :h="40"
                             :w="150"
                             :f-size="13"></buttons>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Buttons from "../../../components/Buttons";


export default {
    name: "Dash",
    components: {
        Buttons,
    },
    data() {
        return {
            okq: false,
            // oks: false,
            okm: false,

            rc: false,

            rcC: false,

            mk: '',
        };
    },
    methods: {
        submit() {
            this.$toasted.success('已提交！', {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
            }).goAway(3000);
            this.$router.push('/');
        },

        shoeRight() {
            this.$data.rc = true;
        },

        shoeHRight() {
            this.$data.rcC = true;
        },

        mark() {
            this.$data.mk = 'background-color: red;';
            this.$data.rc = false;
        },

        unMark() {
            this.$data.mk = 'background-color: white;';
            this.$data.rcC = false;
        }
    },
    mounted() {
        setTimeout(() => {
            // this.$data.oks = true;

            setTimeout(() => {
                this.$data.okm = true;
            }, 3000);

        }, 20000);
    }
}
</script>

<style scoped>
.card-title {
    margin-top: 14px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 13px;
}

.col1 {
    width: 15%;
    font-weight: bold;
    vertical-align: top;
    text-align: left;
}

.col2 {
    width: 85%;
    font-weight: bold;
    vertical-align: top;
    text-align: left;
}


/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none;
}

.public-qs-lib {
    display: flex;
    flex-direction: row;
    /*min-height: 78vh;*/
    margin-bottom: 20px;
    justify-content: space-between;
}

.dir {
    width: 25%;
    padding: 20px;
    border: #E3E3E3 1px solid;
    border-radius: 14px;
    height: 78vh !important;
    overflow: scroll;
}

.dir::-webkit-scrollbar {
    display: none;
}

.cont {
    width: 67%;
    padding: 20px;
    border: #E3E3E3 1px solid;
    border-radius: 14px;
    height: 78vh !important;
    overflow: scroll;
}

.cont::-webkit-scrollbar {
    display: none;
}

.search-collage {
    position: relative;
}

.search-collage::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.collage-element {
    font-size: 18px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}

.collage-element:hover {
    background-color: #274DE2;
    border-radius: 8px;
    color: white;
    padding: 8px;
    cursor: pointer;
    font-weight: bold;
}

/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.set-input {
    height: 35px;
    border-radius: 10px;
    text-indent: 10px;
    font-size: 18px;
    border: #707070 1px solid;

    margin: 5px;
}
</style>
