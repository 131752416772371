<template>
    <div id="mainSideBar">
      <AddClass :ok="ok" @sendOk="ok=!ok"></AddClass>
        <div id="SideOptions">
            <div id="options">
                <router-link to="/funcCenter/exam">
                    <div class="op" :class="this.$route.name === 'Exam' ? 'selected' : ''">
                        <img src="../../assets/SideBar/exam.svg">
                        <br>
                        <div>考试</div>
                    </div>
                </router-link>
                <router-link to="/funcCenter/course">
                    <div class="op" :class="this.$route.name === 'Course' ? 'selected' : ''">
                        <img src="../../assets/SideBar/class.svg">
                        <br>
                        <div>课程</div>
                    </div>
                </router-link>
                <router-link to="/funcCenter/problemSet">
                    <div class="op" :class="this.$route.name === 'ProblemSet' ? 'selected' : ''">
                        <img src="../../assets/SideBar/questionbank.svg">
                        <br>
                        <div>题库</div>
                    </div>
                </router-link>
                <router-link to="/funcCenter/invigilate">
                    <div class="op" :class="this.$route.name === 'Invigilate' ? 'selected' : ''">
                        <img src="../../assets/SideBar/invigilation.svg">
                        <br>
                        <div>监考</div>
                    </div>
                </router-link>
            </div>
            <div id="announcement">
                <div id="content">
                    <div id="title">现在开始参加一场考试？</div>
                    <div id="body">点击下方的按钮，输入分享码，即可参加考试或加入课程。</div>
                </div>
            </div>

            <div id="button" @click="ok = !ok">
                <div style="float: left"><img src="../../assets/SideBar/add.png"></div>
                <div style="float: left;margin-left: 5px"> 加入考试/课程</div>
            </div>
        </div>
        <router-link to="/about">
            <img id="help" src="../../assets/SideBar/welcome use aojie.svg">
        </router-link>
        <div id="footer">
            <p>傲杰考试系统 V1.0傲杰之星</p>
            <p>©兴风科技2022</p>
            <p>浙ICP备20011690号-3</p>
        </div>
    </div>
</template>

<script>
import AddClass from "../PopUps/AddClass";
export default {
    name: "MainSideBar",
    components: {AddClass},
    data: function () {
      return {
        ok:false
      }
    },
    methods:{}
}
</script>

<style scoped lang="less">

#mainSideBar {
    float: left;
    text-align: center;
    margin-top: 16px;
    margin-left: 16px;
    width: 260px;
    float: left;
}

#SideOptions {
    border-radius: 15px;
    height: 223px;
    width: 100%;
    background: white;
    border: 0px;
    outline: none;
}

#options {
    margin-left: 6px;
    height: 80px;
    width: 100%;
}

.op {
    margin-top: 10px;
    float: left;
    height: 60px;
    width: 60px;
    margin-left: 2px;
    font-size: 10px;
    font-weight: 100;
}

.op:hover {
    cursor: pointer;
    background: #EFEFEF;
    border-radius: 8px;
    color: #054FBF;
}

.selected {
    color: #054FBF;
}

#options img {
    margin-top: 8px;
    height: 31px;
}

a {
    color: #000000;
    text-decoration: none;
}

#announcement {
    margin-top: 6px;
    width: 90%;
    margin-left: 5%;
    height: 73px;
    background: #efefef;
    border-radius: 15px;
}

#title {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 600;
}

#content > div {
    text-align: left;
}

#content {
    width: 90%;
    margin-left: 5%;
}

#body {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 100;
}

#button {
    border-radius: 2px;
    line-height: 28px;
    margin-top: 16px;
    border: solid 1px #054FBF;
    height: 28px;
    width: 88%;
    margin-left: 6%;
    text-align: center;
    color: #054FBF;
    font-size: 12px;
    font-weight: 500;
}

#button img {
    margin-left: 61px;
    margin-top: 6px;
    height: 17px;
}

#button:hover {
    cursor: pointer;
    background: #054FBF;
    color: white;

    img {
        filter: brightness(1000);
    }
}


#help {
    width: 100%;
    margin-top: 16px;
    border-radius: 15px;
}

#help:hover {
    cursor: pointer;
}

#footer {
    margin-top: 11px;
}

p {
    color: #999999;
    font-size: 12.5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>
