<template>
    <div style="padding-left: 40px;">
        <h1>期末考试</h1>
        <h3>一、填空题</h3>
        <div style="font-weight: bold;">医学统计工作的基本步骤是______、______、______、______</div>
        <div
            style="display: flex; flex-direction: column; align-items: center; margin-top: 10px; margin-bottom: 10px;">
            <input style="width: 100%;" class="set-input" :placeholder="'第1空...'">
            <input style="width: 100%;" class="set-input" :placeholder="'第2空...'">
            <input style="width: 100%;" class="set-input" :placeholder="'第3空...'">
            <input style="width: 100%;" class="set-input" :placeholder="'第4空...'">
        </div>
        <p></p>
        <h3>二、单选题</h3>
        <div style="font-weight: bold;">1. 痊愈、显效、好转、无效属于</div>
        <div style="display: flex; flex-direction: row; align-items: center;">
            <div><input name="s_choose" type="radio"></div>
            <div>计数资料</div>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center;">
            <div><input name="s_choose" type="radio"></div>
            <div>计量资料</div>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center;">
            <div><input name="s_choose" type="radio"></div>
            <div>等级资料</div>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center;">
            <div><input name="s_choose" type="radio"></div>
            <div>以上均不是</div>
        </div>
        <p></p>
        <div style="font-weight: bold;">2. 智能区域</div>
        <div style="display: flex; flex-direction: row; align-items: center;">
            <div style="color: #6f6f6f;">由于智能区域具有随机性，因此无法在此处预览题目细节</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MonitorQuestions",
}
</script>

<style scoped>
.set-input {
    width: 800px;
    height: 35px;
    border-radius: 10px;
    text-indent: 10px;
    font-size: 18px;
    border: #707070 1px solid;
    margin-bottom: 10px;
}
</style>
