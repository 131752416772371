<template>
<div class="examIndex">
  <ExamHeader :flip="this.$route.name === 'Problem'"></ExamHeader>
  <div class="content">
    <router-view></router-view>
  </div>

</div>
</template>

<script>
import ExamHeader from "../../components/Header/ExamHeader";
import ExamSideBar from "../../components/SideBar/ExamSideBar";
export default {
  name: "ExamIndex",
  components: {ExamSideBar, ExamHeader},
  mounted() {
  }
}
</script>

<style scoped>
.examIndex {
  height: 100%;
  width: 100%;
  min-width: 1050px;
}

.content {
  height: calc(100% - 55px);
}
</style>
