<template>
    <div id="exam">
        <!--这个是弹窗-->
        <CourseAndExam :is-login="isLogin" :ok="ok" @sendOk="getOk"></CourseAndExam>
        <div id="header">
            <div class="card-title">考试</div>
            <div class="card-options" @click="switchTabs(0);">
                <div :style="isShow[0] ? 'color: #054FBF;' : ''">我参加的考试</div>
                <div :style="isShow[0] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(1);">
                <div :style="isShow[1] ? 'color: #054FBF;' : ''">我管理的考试</div>
                <div :style="isShow[1] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div id="sc">
                <Search :name="'搜索考试...'"></Search>
            </div>
            <div class="button" @click="ok=true">创建考试/课程</div>
        </div>

        <div id="body">
            <ScreenBar v-model="filter"></ScreenBar>
            <transition name="slide-fade2">
                <div v-show="isShow[0]"><!--content of "我参加的考试"-->
                    <!--          <BasalCard :data-basal-card="dataStudentExamCard[0]"></BasalCard>-->
                    <StudentExamCard v-for="(item,idx) in dataStudentExamCard"
                                     :data-student-exam-card=item></StudentExamCard>
                </div>
            </transition>
            <div class="transition-box">
                <transition name="slide-fade1">
                    <div v-show="isShow[1]"><!--content of "我管理的考试"-->
                        <TeacherExamCard v-for="(item,idx) in dataTeacherExamCard"
                                         :data-teacher-exam-card="item"></TeacherExamCard>
                        <div id="footer"></div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import MainSideBar from "../../../components/SideBar/MainSideBar";
    import BasalCard from "../../../components/Card/BasalCard";
    import CourseAndExam from "../../../components/PopUps/CourseAndExam";
    import Search from "../../../components/common/Search";
    import ScreenBar from "../../../components/common/ScreenBar";
    import TeacherExamCard from "../../../components/Card/TeacherExamCard";
    import StudentExamCard from "../../../components/Card/StudentExamCard";

    export default {
        name: "Exam",
        components: {
            StudentExamCard,
            TeacherExamCard,
            ScreenBar,
            CourseAndExam,
            Search,
            BasalCard,
            MainSideBar
        },
        data: function () {
            let dataStudentExamCard = new Array();
            let dataTeacherExamCard = new Array();
            return {
                isLogin: false,
                filter: {
                    sort: 'group', // group | time
                    select: 'all', // all | open | close | finish
                },
                isShow: [true, false], // Array elements from left to right represent the order of options from left to right respectively
                ok: false, // control the modal
                dataStudentExamCard,
                dataTeacherExamCard,
            }
        },
        methods: {
            switchTabs(position) {
                this.$set(this.$data.isShow, position, true);
                this.$set(this.$data.isShow, position ^ 1, false);
            },
            getOk(ok) {
                this.ok = ok;
            }
        },
        mounted() {
            this.$axios.get(
                '/apiExam/getExams').then((resp) => {

                console.log("考试信息start：");
                console.log(resp);
                console.log("考试信息end：");

                for (let i = 0; i < resp.data.data.length; i++) {
                    if (resp.data.data[i].purview === 1) this.$data.dataStudentExamCard.push(resp.data.data[i]);
                    else this.$data.dataTeacherExamCard.push(resp.data.data[i]);
                }
                this.isLogin = true;
            }).catch((err) => {
                console.log(err);
                this.$toasted.error('未登录,请先登录!', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-times-circle',
                    theme: 'bubble',
                    position: 'top-center'
                }).goAway(3500);
                this.isLogin = false;
            })
        },
        created() {
        },
    }
</script>
<style scoped>

    #exam {
        width: 100%;
        height: 100%;
    }

    #header {
        top: 0;
        width: 100%;
        height: 55px;
        border-bottom: 1px solid #e9e9e9;
        /*border-radius: 15px 15px 0 0;*/
    }

    #sc {
        float: right;
        width: 157px;
        margin-top: 13.5px;
        margin-right: 25px;
    }


    .card-title {
        user-select: none;
        float: left;
        margin-top: 12px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }

    .card-options {
        user-select: none;
        float: left;
        margin-left: 50px;
        margin-top: 20px;
        color: #999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*font-weight: 500;*/
        /*font-size: 16px;*/
    }

    .card-options:hover {
        cursor: pointer;
    }

    .option-underline {
        height: 4px;
        width: 110%;
        background-color: #054FBF;
        margin-top: 10px;
    }

    a {
        color: black;
    }

    #body {
        position: relative;
        /*position: fixed;*/
        overflow: auto;
        width: 100%;
        height: calc(100% - 56px);
    }

    .button {
        font-size: 14px;
        font-weight: 100;
        line-height: 28px;
        text-align: center;
        color: white;
        border-radius: 15px;
        margin-top: 14px;
        margin-right: 18px;
        float: right;
        height: 28px;
        width: 112px;
        background: #054FBF;
    }

    .button:hover {
        cursor: pointer;
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
    }

    .transition-box {
        position: absolute;
        width: 100%;
        overflow: hidden;
    }


    /*animation*/
    .slide-fade1-enter-active {
        transition: all .3s ease;
    }

    .slide-fade1-enter, .slide-fade1-leave-to {
        transform: translateX(300px);
        opacity: 0;
    }

    .slide-fade2-enter-active {
        transition: all .3s ease;
    }


    .slide-fade2-enter, .slide-fade2-leave-to {
        transform: translateX(-300px);
        opacity: 0;
    }


    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track-piece {
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #bbbbbb;
    }

    #footer {
        width: 100%;
        height: 50px;
        float: left;
    }
</style>
