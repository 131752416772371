import Exam from "../views/function_center/exam/Exam";
import CreateExam from "../views/function_center/exam/CreateExam";
import Course from "../views/function_center/course/Course";
import ProblemSet from "../views/function_center/problem_set/ProblemSet";
// import ProblemSet from "../views/function_center/QuestionsLib";
import Problems from "../views/function_center/problem_set/Problems";
import Invigilate from "../views/function_center/invigilate/Invigilate";
import Questions from "../views/function_center/questionslib_to_questions/Questions";
import NewQuestion from "../views/function_center/questionslib_to_questions/NewQuestion";
import AccountSettings from "../views/function_center/AccountSettings";

export default [
    {
        path: '/funcCenter/',
        redirect: '/funcCenter/exam'
    },
    {
        path: '/funcCenter/exam',
        name: 'Exam',
        component: Exam,
    },
    {
        path: '/funcCenter/exam/create',
        name: 'CreateExam',
        component: CreateExam,
    },
    {
        path: '/funcCenter/course',
        name: 'Course',
        component: Course,
    },
    {
        path: '/funcCenter/problemSet',
        name: 'ProblemSet',
        component: ProblemSet,
    },
    {
        path: '/funcCenter/problems',
        name: 'Problems',
        component: Problems,
    },
    {
        path: '/funcCenter/invigilate',
        name: 'Invigilate',
        component: Invigilate,
    },
    {
        path: '/u/funcCenter/problemSet/questions',
        name: 'questions',
        component: Questions,
    },
    {
        path: '/u/funcCenter/problemSet/questions/newQuestion',
        name: 'newQuestion',
        component: NewQuestion,
    },
    {
        path: 'account_settings',
        name: 'AccountSettings',
        component: AccountSettings,
    },
]
