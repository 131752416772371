<template>
    <div id="footer">
        登录即表示同意平台 <em>《隐私政策》</em>  和 <em>《用户协议》</em>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
    #footer {
        font-weight: 100;
        font-size: 13px;
        position: absolute;
        bottom: 25px;
        margin-left: 141px;
    }
    em {
        color: #054FBF;
    }
    em:hover{
        cursor: pointer;
    }
</style>
