<template>
    <div id="teacherView">
        <AddRemark :ok="ok" @sendOk="getOk" @sendInfo="getInfo"></AddRemark>
        <div class="title">
            查看和设置
        </div>
        <div class="info">
            <img v-show="idx === 0" src="../../assets/common/xinfeng.jpg">
            <img v-show="idx === 1" src="../../assets/common/xinfeng.jpg">
            <div class="studentinfo">
                <div>姓名：{{name }}</div>
                <div>性别：{{sex}}</div>
            </div>
            <div class="studentinfo">
                <div>年龄：{{ age }}</div>
                <div>民族：{{ nationality }}</div>
            </div>
            <div class="studentinfo">
                <div style="width: 400px">手机号：{{ phone }}</div>
            </div>
            <div class="studentinfo">
                <div style="width: 400px">加入课程日期：{{time }}</div>
            </div>
            <div class="studentinfo">
                <div style="width: 200px">备注：{{Other1}}</div><button @click="change()">修改备注</button>
            </div>
        </div>
    </div>
</template>

<script>
import Buttons from "../Buttons";
import AddRemark from "../PopUps/AddRemark";
export default {
    name: "TeacherView",
    components: {AddRemark, Buttons},
    props:{
        name:{
            type:String,
            default:'学生1'
        },
        sex:{
            type:String,
            default: '男'
        },
        age:{
            type:Number,
            default: 22
        },
        nationality:{
            type:String,
            default: '汉族'
        },
        phone:{
            type:String,
            default:'185****7807'
        },
        time:{
            type:String,
            default:'2022-02-10 19:27:46'
        },
        other:{
            type:String,
            default:'无'
        },
        idx:{
            type:Number,
            default:0
        }
    },
    data:function () {
        let ok = false;
        let Other1 = this.other;
        return {
            ok,
            Other1
        }
    },
    methods:{
        change() {
            this.ok = true;
        },
        getOk(ok) {
            this.ok = ok;
        },
        getInfo(info) {
            this.ok = false;
            this.Other1 = info;
        }
    }
}
</script>

<style scoped>
#teacherView {
    /*无法复制*/
    -moz-user-select:none; /* Firefox私有属性 */
    -webkit-user-select:none; /* WebKit内核私有属性 */
    -ms-user-select:none; /* IE私有属性(IE10及以后) */
    -khtml-user-select:none; /* KHTML内核私有属性 */
    -o-user-select:none; /* Opera私有属性 */
    user-select:none; /* CSS3属性 */

    width: 100%;
    height: 353px;
}
.title {
    font-size: 20px;
    width: 100%;
    line-height: 45px;
    text-align: center;
    font-weight: 100;
    height: 45px;
    border-bottom: 1px solid #D9D9D9;
}
.info {
    width: 100%;
    height: 307px;
    border-bottom: 1px solid #D9D9D9;
}
.info > img {
    float: left;
    width: 185px;
    border-radius: 5px;
    margin-top: 24px;
    margin-left: 15%;
}
.studentinfo {
    color: #999999;
    float: left;
    margin-left: 15%;
    overflow: auto;
    margin-top: 29px;
    font-size: 19px;
}
.studentinfo > div {
    float: left;
    width:200px;
}
button {
    float: left;
    background: #054FBF;
    color: white;
    border: none;
    border-radius: 15px;
    font-weight: 100;
    font-size: 12px;
    height: 22px;
    width: 70px;
}
button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

@media screen and (max-width: 1530px) {
    .info > img {
        margin-left: 12%;
    }
    .studentinfo{
        margin-left: 12%;
    }
}
@media screen and (max-width: 1430px) {
    .info > img {
        margin-left: 8%;
    }
    .studentinfo{
        margin-left: 8%;
    }
}
@media screen and (max-width: 1381px) {
    .info > img {
        margin-left: 4%;
    }
    .studentinfo{
        margin-left: 4%;
    }
}
@media screen and (max-width: 1242px) {
    .info > img {
        margin-left: 3%;
    }
    .studentinfo{
        margin-left: 3%;
    }
}
@media screen and (max-width: 1210px) {
    .info > img {
        float: left;
        width: 85px;
        border-radius: 5px;
        margin-top: 24px;
        margin-left: 8%;
    }
    .studentinfo {
        float: left;
        margin-left: 8%;
        overflow: auto;
        margin-top: 29px;
        font-size: 16px;
    }
    .studentinfo > div {
        width:140px;
    }
}
@media screen and (max-width: 1020px) {
    .info > img {
        float: left;
        width: 85px;
        border-radius: 5px;
        margin-top: 24px;
        margin-left: 3%;
    }
    .studentinfo {
        float: left;
        margin-left: 3%;
        overflow: auto;
        margin-top: 29px;
        font-size: 16px;
    }
    .studentinfo > div {
        width:130px;
    }
}
@media screen and (max-width: 936px) {
    .info > img {
        float: left;
        width: 85px;
        border-radius: 5px;
        margin-top: 10px;
        margin-left: calc(50% - 49px);
    }
    .studentinfo {
        float: left;
        margin-left: 10px;
        overflow: auto;
        margin-top: 12px;
        font-size: 16px;
    }
    .studentinfo > div {
        width:130px;
    }
}

/*滚动条样式*/
.scroll::-webkit-scrollbar {
    width:3px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background-color:#dddddd;
    background-clip:padding-box;
    min-height:28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#bbb;
}
</style>

