import Vue from 'vue';
import VueRouter from 'vue-router';
import FunctionCenter from "../views/function_center/FunctionCenter";
import Index from "../views/Index"
import Competition from "../views/Competition";
import About from "../views/About";
import ExamIndex from "../views/exam/ExamIndex";

import funcCenterChildren from './function_center';
import exam from './exam';

import Backstage from "./backstage";
import Login from "./login";
import MyClassArea from "./myClassArea";
import ClassArea from "./classArea";
import userExam from "./userExam";
import ois from "./ois";
import MyQuestionBank from "../views/MyQuestionBank";
import EnterExamHeader from "../components/Header/EnterExamHeader";
import EnterExam from "../views/exam/EnterExam";
import Problem from "../views/exam/Problem";
import AccountSettings from "../views/function_center/AccountSettings";

Vue.use(VueRouter);


let routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/myQuestionBank',
        name: 'myQuestionBank',
        component: MyQuestionBank
    },
    {
        path: '/funcCenter',
        name: 'funcCenter',
        component: FunctionCenter,
        children: funcCenterChildren
    },
    {
        path: '/class/notification',
        name: 'notification',
        component: Notification
    },
    {
        path: '/index',
        name: 'Index',
        component: Index,
    },
    {
        path: '/competition',
        name: 'Competition',
        component: Competition
    },
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/exam',
        name: 'ExamIndex',
        component: ExamIndex,
        children: exam
    },
    {
        path: '/enter',
        name: 'EnterExamIndex',
        component: EnterExam,
        children: [
            {
                name: 'Problem',
                path: "/enter/problem",
                component: Problem
            },
        ]


    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes:[
        ...routes,
        ...Backstage,
        ...Login,
        ...MyClassArea,
        ...ClassArea,
        ...exam,
        ...userExam,
        ...ois
    ]
})

export default router
