<template>
    <div id="groupCard">
        <div class="header"></div>
        <div id="second">
            <div class="header"></div>
            <div id="third">
                <div class="header">
                    <b>{{dataGroupCard['title']}}</b>
                    <img id="more" src="../../assets/Card/group.svg">
                </div>
                <div id="center" style="overflow: auto">
                    <p class="information">考试开始时间：{{dataGroupCard['startTime']}}</p>
                    <p class="information">考试结束时间：{{dataGroupCard['endTime']}}</p>
                    <p id="examStatus">考试状态：<b v-bind:class="{start:dataGroupCard['status'] === '进行中' || dataGroupCard['status'] == '已开放',end:dataGroupCard['status'] === '已结束'}">{{dataGroupCard['status']}}</b></p>
                    <router-link :to="{path:'#'}"><button>查看</button></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GroupCard",
    props:{
        dataGroupCard: {
            type: Object,
            default: function () {
                return {
                    title: "计算机网络",
                    startTime: "2022-02-03 09:45:00",
                    endTime: "2022-02-03 11:45:00",
                    status:"进行中"
                }
            }
        }
    }
}
</script>

<style scoped>
#groupCard {
    transition: 0.5s all;
    position: relative;
    height: 176px;
    width: 290px;
    float: left;
    margin-top: 30px;
    margin-left: max(40px,calc(25% - 310px));
    border-radius: 15px;
    border: 0px;
    outline:none;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.16);
}
@media screen and (max-width: 1636px) {
    #groupCard {
        margin-left: max(40px,calc(33% - 330px));
    }
}
@media screen and (max-width: 1313px) {
    #groupCard {
        margin-left: max(53px,calc(50% - 340px));
    }
}
@media screen and (max-width: 920px) {
    #groupCard {
        margin-left: max(53px,calc(50% - 145px));
    }
}


#groupCard:hover{
    transform: translateY(-3px);
    box-shadow: 0px 0px 35px #cccccc;
}

#second {
    height: 176px;
    width: 290px;
    float: left;
    margin-top: -35px;
    margin-left: -10px;
    border-radius: 15px;
    border: 0px;
    outline:none;
    background: white;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.16);
}

#third{
    height: 176px;
    width: 290px;
    float: left;
    margin-top: -35px;
    margin-left: -10px;
    border-radius: 15px;
    border: 0px;
    outline:none;
    background: white;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.16);
}

.header {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 47px;
    height: 47px;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    background: #054FBF;
    border: 0px;
    outline:none;
}
#more {
    /*background: black;*/
    width: 20px;
    position: absolute;
    left: 233px;
    top: 39px;
}
img {
    width: 100%;
}
#center {
    height: 129px;
    width: 100%;
}
#more:hover {
    cursor: pointer;
}
.information {

    margin-left: 25px;
    font-size: 14px;
    font-weight: 100;
}
#examStatus {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 100;
}
.start{
    color: #00A03E;
}
.end {
    color:#DD1E1E;
}
button {
    width: 57px;
    height: 29px;
    position: absolute;
    margin-top: -28px;
    margin-left: 219px;
    border-radius: 5px;
    background: #054FBF;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 100;
}
button:hover {
    cursor: pointer;
    background: #0547AC;
}
a {
    color: white;
    text-decoration:none;
}
</style>
