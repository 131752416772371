<template>
    <div id="target">


        <!--modal-->
        <transition name="slide-fade">
            <div class="screen" v-if="okAdd || okP || okPs"></div>
        </transition>

        <transition name="bounce">
            <div class="Pop-ups" v-if="okAdd">
                <div class="pTitle">
                    <div class="pTitleFont">安排人员</div>
                    <img @click="okAdd = false;" class="close" src="../../../assets/SideBar/close.svg" alt="">
                </div>
                <div class="p-body">
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>群组的名称:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div><input type="text" v-model="gname" class="p-body-input"></div>
                    </div>

                    <div class="p-body-content">
                        <div>选择任教班级:&nbsp;</div>
                        <div>
                            <select ref="slt" id="classOptions2"  class="p-body-input">
                                <option v-for="(item,it) in myClass" v-bind:value = "item.name">{{item.name}}</option>
                                <option value="noClass">不选择班级</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="okAdd = false;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="addGroup">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <transition name="bounce">
            <div class="Pop-ups" v-if="okPs">
                <div class="pTitle">
                    <div class="pTitleFont">手动添加人员</div>
                    <img  @click="okPs=false" class="close" src="../../../assets/SideBar/close.svg" alt="">
                </div>
                <div class="p-body">
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>账号:&nbsp;</div>
                        <div><input ref="userAccounts" type="text" placeholder="输入手机号/机构账号" class="p-body-input"></div>
                    </div>

                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>姓名:&nbsp;</div>
                        <div><input ref="userName" type="text" placeholder="输入用户名" class="p-body-input"></div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="okPs = false;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="addPs">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="bounce">
            <div class="Pop-ups" v-if="okP">
                <div class="pTitle">
                    <div class="pTitleFont">添加试卷</div>
                    <img @click="okP = false;" class="close" src="../../../assets/SideBar/close.svg" alt="">
                </div>
                <div class="p-body">
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>请选择您的试卷:&nbsp;</div>
                        <div>
                            <!--                            <input type="text" class="p-body-input">-->
                            <select v-model="pn" id="classOptions3" class="p-body-input">
                                <option>期末考试</option>
                                <option>期末考试-备份</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="okP = false;">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="addPaper">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <div id="header">
            <div class="card-title">发布对象</div>
            <div class="card-options" @click="switchTabs(0);">
                <div :style="isShow[0] ? 'color: #054FBF;' : ''">发布设置</div>
                <div :style="isShow[0] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(1);">
                <div :style="isShow[1] ? 'color: #054FBF;' : ''">群组设置</div>
                <div :style="isShow[1] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
        </div>
        <div id="body">
            <transition name="slide-fade2">
                <div v-show="isShow[0]">
                    <!--content of "发布设置"-->
                    <div class="release">
                        <div class="switch">
                            <div>发布</div>
                            <el-switch
                                v-model="releaseSetting.release.publish"
                                active-text="发布"
                                @change="changeShow()"
                                inactive-text="未发布"
                            ></el-switch>
                        </div>
                        <div class="txt">设置发布后，本考试将会出现在群组内的每一位成员的功能中心中，并可以接受管理员所发的通知。直到考试开放后，考生才能进入并开始考试。</div>
                    </div>
                    <div class="distribute">
                        <h1>考卷分发</h1>
                        <div class="table">
                            <div class="section" style="overflow: scroll;">
                                <div class="title">群组</div>
                                <div class="content">
                                    <div v-for="(it,idx) in distribute.group" class="double-item"
                                         :class="{'active': it.active}" :key="idx"
                                         @click="active(distribute.group,idx);distribute.groupSelect = idx">
                                        <div>{{ it.name }}</div>
                                        <div>{{ it.num }}人</div>
                                    </div>
                                </div>
                            </div>
                            <div class="section" style="overflow: scroll;">
                                <div class="title">试卷</div>
                                <div class="content">
                                    <div v-for="(it,idx) in distribute.exam" @click="changePaper(idx)" class="single-item">
                                        <div style="float: left">{{ it.title }}</div>
                                        <input :checked="idx === checkIt" style="float: right" type="radio" name="paper" :value="it.id"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="transition-box">
                <transition name="slide-fade1">
                    <div v-show="isShow[1]">
                        <!--content of "群组设置"-->
                        <div class="group">
                            <div class="table">
                                <div class="section" style="overflow: auto">
                                    <div class="title">群组</div>
                                    <div class="content">
                                        <div v-for="(it,idx) in distribute.group" class="multi-item"
                                             :class="{'active': it.active}" :key="idx"
                                             @click="active(distribute.group,idx);distribute.groupSelect = idx">
                                            <div class="mitem">
                                                <div>{{ it.name }}</div>
                                                <div>考试码：{{ it.code }}</div>
                                            </div>
                                            <div class="mitem">
                                                <div>{{ it.num }}人</div>
                                                <div><a href="#">重命名</a> <a @click="deleteGroup(it.name,it.id,idx)" href="#">删除</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div class="add" @click="okAdd = true;"><img
                                                src="../../../assets/SideBar/add.png">添加群组
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section" style="overflow: scroll;">
                                    <div class="title">人员</div>
                                    <div class="content">
                                        <div v-if="distribute.group[distribute.groupSelect]">
                                            <div v-for="(it,idx) in distribute.group[distribute.groupSelect].p"
                                                 class="single-multi-item">
                                                <div class="mitem">
                                                    <div>{{ it }}</div>
                                                    <div><a class="dt" @click="deleteUser(distribute.group[distribute.groupSelect].pId[idx],distribute.groupSelect,it,idx)">删除</a> <a class="dt" >调组</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div class="add" @click="okPs = true;"><img
                                                src="../../../assets/SideBar/add.png">添加人员
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="footer"></div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>

import BasalCard from "../../../components/Card/BasalCard";
import Buttons from "../../../components/Buttons";

export default {
    name: "Target",
    components: {
        BasalCard,
        Buttons,
    },
    data:function () {
        let myClass = new Array();
        return {
            checkIt:0,
            choose: -1,
            okAdd: false,
            okP: false,
            okPs: false,
            myClass,
            pn: '',
            gname: '',
            gid: 'AO75645768',

            isShow: [true, false], // Array elements from left to right represent the order of options from left to right respectively
            distribute: {
                group: [],
                groupSelect: -1,
                exam: [
                    // '补考卷1', '补考卷2'
                ]
            },
            releaseSetting: {
                release: {
                    publish: false
                },
                group: {}
            }
        }
    },
    methods: {

        changePaper(idx) {
            this.$set(this.$data,'checkIt',idx);
            let groupId = this.distribute.group[this.distribute.groupSelect].id;
            let paperId = this.distribute.exam[idx].id;
            // console.log(groupId + ' ' +paperId);
            this.$axios.post(
                'apiExam/bindPaper',{
                    'groupId':groupId,
                    'paperId':paperId
                }
            ).then(res => {
                this.PopUps('修改成功')
            })


        },
        changeGroup(idx) {
            // console.log(this.distribute.group[idx]);
            this.$axios.get(
                'apiExam/getPaperId',{
                    params:{
                        groupId:this.distribute.group[idx].id
                    }
                }
            ).then(res => {
                let idx = res.data.data;
                for(let i = 0; i < this.distribute.exam.length; i++) {
                    if(this.distribute.exam[i].id === idx) {
                        this.$set(this.$data,'checkIt',i);
                        return;
                    }
                }
                this.$set(this.$data,'checkIt',-1);
            }).catch(err => {
            })
        },


        deleteUser(userId,groupIdx,userName,idx){
            let ok = confirm("确定删除 " + userName +" 吗?");
            if(ok) {
                this.$axios.post(
                    '/apiExam/deleteUser',
                    {
                        groupId:this.distribute.group[groupIdx].id,
                        userId:userId
                    }
                ).then(res => {
                    this.distribute.group[groupIdx].num -= 1;
                    this.$delete(this.$data.distribute.group[groupIdx].p,idx);
                    this.$delete(this.$data.distribute.group[groupIdx].pId,idx);
                    this.PopUps("删除 "+ userName + "成功");
                }).catch(err => {
                    console.log(err);
                })
            }
        },
        switchTabs(position) {
            this.$set(this.$data.isShow, position, true);
            this.$set(this.$data.isShow, position ^ 1, false);
        },
        active(arr, idx) {
            this.changeGroup(idx);
            this.choose = idx;
            for (let i in arr) {
                arr[i].active = false;
            }
            arr[idx].active = true;
        },
        deleteGroup(name,id,idx) {
            let ok = confirm("确定删除 "+name+" 吗?");
            //删除群组
            if(ok === true) {
                this.$axios.post(
                    '/apiExam/deleteGroup', {
                        "groupId":id
                    }
                ).then((res)=>{
                    this.$toasted.success('删除成功！', {
                        iconPack: 'custom-class',
                        icon: 'fa fa-check-circle-o',
                        theme: 'bubble',
                        position:'top-center'
                    }).goAway(2500);
                    this.$delete(this.$data.distribute.group,idx);
                }).catch((err)=>{
                    console.log("网络错误");
                })
            }
            for (let i in this.distribute.group) {
                this.distribute.group[i].active = false;
            }
            this.idx = -1;
        },
        changeShow(){
            //修改发布状态
            let ok = this.$data.releaseSetting.release.publish;
            this.$axios.get(
                '/apiExam/changeShow',{
                    params:{
                        examId:this.$route.query.examId,
                        isShow: (ok ? 1 : 0)
                    }
                }
            ).then((res)=>{
                this.$toasted.success(ok ? '发布成功！':'已取消发布', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                    position:'top-right'
                }).goAway(2500);
            }).catch((err)=>{
                console.log("发布状态变更错误")
            })
        },
        addGroup() {
            if(this.gname == null || this.gname === '' || this.gname.match(/^\s+$/) != null) {
                alert("群组名称不能为空");
                return ;
            }
            //添加群组
            let classId = -1;
            if(this.$refs.slt.selectedIndex < this.myClass.length) classId = this.myClass[this.$refs.slt.selectedIndex].id;
            this.$axios.get(
                '/apiExam/addExamGroup',{
                    params:{
                        name: this.gname,
                        classId: classId,
                        examId:this.$route.query.examId
                    }
                }
            ).then((res)=>{
                let that = res.data.data;
                let p = new Array();
                let pId = new Array();
                for(let j = 0;j < that.examGroupUsers.length; j++) pId.push(that.examGroupUsers[j].id);
                for(let j = 0;j < that.examGroupUsers.length; j++) p.push(that.examGroupUsers[j].name);
                this.$data.distribute.group.push(
                    {
                        id:that.id,
                        active: false,
                        name: that.name,
                        num: that.examGroupUsers.length,
                        exam: 0,
                        code: that.groupCode,
                        p: p,
                        pId:pId
                    }
                )
                this.PopUps("添加群组成功");
            }).catch((err)=>{
                this.PopUps("添加群组失败");
            });


            this.$data.okAdd = false;
            this.$data.okP = false;
            this.$data.gname = "";
        },

        addPs() {

            // this.$data.distribute.group[0].p.push('左端');
            let userAccount = this.$refs.userAccounts.value;
            let userName =  this.$refs.userName.value;
            if(userAccount === null || userAccount === "" || userAccount.match(/^\s+$/) != null) {
                this.PopUps('账号不能为空!')
                return ;
            }
            if(userName === null || userName === "" || userName.match(/^\s+$/) != null) {
                this.PopUps('用户名不能为空!')
                return ;
            }
            if(this.choose === -1) {
                this.PopUps('请先选择群组!')
                this.okPs = false;
                return ;
            }

            this.$axios.get(
                '/apiExam/addUser',
                {
                    params:{
                        groupId:this.distribute.group[this.choose].id,
                        userAccount:userAccount,
                        userName:userName
                    }
                }
            ).then(res => {
                // console.log(res);
                this.distribute.group[this.choose].pId.push(res.data.data);
                this.distribute.group[this.choose].p.push(userName);
                this.distribute.group[this.choose].num += 1;
                this.$data.okPs = false;
                this.$data.okAdd = false;
                this.$data.okP = false;
                this.PopUps('成功添加 '+userName+ ' 进入 '+this.distribute.group[this.choose].name);
            }).catch(err => {
                this.PopUps('账号或用户名错误');
            })
        },

        addPaper() {
            this.$data.distribute.exam.push(this.$data.pn);
            this.$data.okAdd = false;
            this.$data.okP = false;
        },
        PopUps(puMsg) {
            this.$toasted.success(puMsg, {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position:'top-right'
            }).goAway(2500);
        }

    },
    mounted() {
    },
    created() {
        //获得班级
        this.$axios.post(
            '/apiExam/getMyClass',
        ).then((res)=>{
            this.myClass = res.data.data;
        }).catch((err)=>{
            console.log(err);
        })

        //获得考试发布状态
        this.$axios.get(
            '/apiExam/getShow',
            {
                params:{
                    examId:this.$route.query.examId
                }
            }
        ).then((res)=>{
            this.$data.releaseSetting.release.publish = (res.data.data !== 0);
        }).catch((err)=>{
            console.log(err);
        })

        //获得群组和人
        this.$axios.get(
            '/apiExam/getExamGroup',{
                params:{
                    examId:this.$route.query.examId
                }
            }
        ).then((res)=>{
            // console.log(res.data);
            for(let i = 0;i < res.data.data.length; i++) {
                let that = res.data.data[i];
                let p = new Array();
                let pId = new Array();
                for(let j = 0;j < that.examGroupUsers.length; j++) p.push(that.examGroupUsers[j].name);
                for(let j = 0;j < that.examGroupUsers.length; j++) pId.push(that.examGroupUsers[j].id);
                this.$data.distribute.group.push(
                    {
                        id:that.id,
                        active: false,
                        name: that.name,
                        num: that.examGroupUsers.length,
                        exam: 0,
                        code: that.groupCode,
                        p: p,
                        pId: pId
                    }
                )

            }
            this.$data.distribute.group[0].active = true;
            this.distribute.groupSelect = 0;

        }).catch((err)=>{
            console.log('网络连接错误');
        });

        this.$axios.get(
            'apiQuestion/u/get_paper_list',{
                params:{
                    examId:this.$route.query.examId
                }
            }
        ).then((res) => {
            this.distribute.exam = res.data.data;
            this.changeGroup(0);
        }).catch((err) => {

        })
    }
}
</script>

<style scoped lang="less">

.dt:hover {
    cursor: pointer;
}

.release {
    border-radius: 15px;
    background: #F3F3F3;
    margin: 10px 30px;
    padding: 25px 40px;

    .switch {
        display: flex;
        align-items: center;

        div {
            color: black;
            font-weight: 500;
            font-size: 20px;
            margin-right: 20px;
        }
    }

    .txt {
        font-weight: 300;
        font-size: 14px;
        margin-top: 5px;
    }
}

.distribute {
    margin: 10px 30px;

    h1 {
        user-select: none;
        font-weight: bold;
        font-size: 1.5em;
    }


}

.add {
    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
    }

    cursor: pointer;
}

.group {
    padding: 30px 50px;
}

.table {
    border-radius: 10px;
    box-shadow: #DDD 0 0 10px;
    display: flex;
    height: 300px;

    .section:first-child {
        border-right: 2px solid #DDD;
    }

    .section {

        &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
            // 或者 width: 0;
        }


        flex: 1;

        .title {
            border-bottom: 2px solid #DDD;
            height: 40px;
            line-height: 40px;
            align-items: center;
            padding-left: 20px;
        }

        .content {

            .single-item {
                height: 20px;
                padding: 20px;
                border-bottom: 2px solid #DDD;
            }

            .double-item {
                padding: 10px 20px;
                border-bottom: 2px solid #DDD;

                div:nth-child(2) {
                    font-size: 14px;
                    font-weight: 300;
                }
            }

            .multi-item {
                padding: 10px 20px;
                border-bottom: 2px solid #DDD;

                a {
                    color: #008AFF;
                    text-decoration: none;
                }

                div:nth-child(2), div:nth-child(4) {
                    font-size: 14px;
                    font-weight: 300;
                }

                .mitem {
                    display: flex;
                    align-items: baseline;

                    div:nth-child(1) {
                        flex: 1;
                    }
                }
            }

            .single-multi-item {
                padding: 10px 20px;
                border-bottom: 2px solid #DDD;

                .mitem {
                    display: flex;

                    div:first-child {
                        flex: 1;
                    }

                    div:last-child {
                        a {
                            font-size: 14px;
                            font-weight: 300;
                            color: #008AFF;
                            text-decoration: none;
                        }
                    }
                }
            }

            .single-item.active {
                background: #054FBF;
                color: white;
            }

            .double-item.active {
                background: #054FBF;
                color: white;
            }


            .multi-item.active {
                background: #054FBF;
                color: white;
            }

        }
    }
}

#target {
    width: 100%;
    height: 100%;
}

#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
    /*border-radius: 15px 15px 0 0;*/
}

#sc {
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}


.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.card-options {
    user-select: none;
    float: left;
    margin-left: 50px;
    margin-top: 20px;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*font-weight: 500;*/
    /*font-size: 16px;*/
}

.card-options:hover {
    cursor: pointer;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}

a {
    color: black;
}

#body {
    position: relative;
    /*position: fixed;*/
    overflow: auto;
    width: 100%;
    height: calc(100% - 55px);
}

.button {
    font-size: 14px;
    font-weight: 100;
    line-height: 28px;
    text-align: center;
    color: white;
    border-radius: 15px;
    margin-top: 14px;
    margin-right: 18px;
    float: right;
    height: 28px;
    width: 112px;
    background: #054FBF;
}

.button:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.transition-box {
    position: absolute;
    width: 100%;
    overflow: hidden;
}


/*animation*/
.slide-fade1-enter-active {
    transition: all .3s ease;
}

.slide-fade1-enter, .slide-fade1-leave-to {
    transform: translateX(300px);
    opacity: 0;
}

.slide-fade2-enter-active {
    transition: all .3s ease;
}


.slide-fade2-enter, .slide-fade2-leave-to {
    transform: translateX(-300px);
    opacity: 0;
}


::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbbbbb;
}

#footer {
    width: 100%;
    height: 50px;
    float: left;
}


/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    display: flex;
    justify-content: center;
    text-indent: 8px;
}

input:focus {
    outline: none;
}

.public-qs-lib {
    display: flex;
    flex-direction: row;
    /*min-height: 78vh;*/
    margin-bottom: 20px;
    justify-content: space-between;
}

.dir {
    width: 25%;
    padding: 20px;
    border: #E3E3E3 1px solid;
    border-radius: 14px;
    height: 78vh !important;
    overflow: scroll;
}

.dir::-webkit-scrollbar {
    display: none;
}

.cont {
    width: 67%;
    padding: 20px;
    border: #E3E3E3 1px solid;
    border-radius: 14px;
    height: 78vh !important;
    overflow: scroll;
}

.cont::-webkit-scrollbar {
    display: none;
}

.search-collage {
    position: relative;
}

.search-collage::after {
    cursor: pointer;
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.collage-element {
    font-size: 18px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}

.collage-element:hover {
    background-color: #274DE2;
    border-radius: 8px;
    color: white;
    padding: 8px;
    cursor: pointer;
    font-weight: bold;
}

/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>
