<template>
    <div>
        <!--modal-->
        <transition name="slide-fade">
            <div class="screen" v-if="ok"></div>
        </transition>

        <transition name="bounce">
            <div class="Pop-ups" v-if="ok">
                <div class="pTitle">
                    <div class="pTitleFont">新建题库</div>
                    <img @click="toggleModal()" class="close" src="../../../assets/SideBar/close.svg">
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>题库的名称:&nbsp;</div>
                        <div><input type="text" class="p-body-input" ref="qName"></div>
                    </div>
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>题库知识点:&nbsp;</div>
                        <div><input type="text" class="p-body-input" ref="qPoint"></div>
                    </div>
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>学科的门类:&nbsp;</div>
                        <div>
                            <!--                            <input type="text" class="p-body-input">-->
                            <select id="classOptions" ref="classOptions" value="sub:01:__philosophy__"
                                    class="p-body-input">
                                <option value="sub:01:__philosophy__">哲学</option>
                                <option value="sub:02:__economics__">经济学</option>
                                <option value="sub:03:__law__">法学</option>
                                <option value="sub:04:__pedagogy__">教育学</option>
                                <option value="sub:05:__literature__">文学</option>
                                <option value="sub:06:__history__">历史学</option>
                                <option value="sub:07:__science__">理学</option>
                                <option value="sub:08:__engineering__">工学</option>
                                <option value="sub:09:__agronomy__">农学</option>
                                <option value="sub:10:__medicine__">医学</option>
                                <option value="sub:12:__management__">管理学</option>
                                <option value="sub:13:__art__">艺术学</option>
                            </select>
                        </div>
                    </div>
                    <div class="p-body-content">
                        <!--checkbox of allow assigning permissions-->
                        <div><input type="checkbox"></div>
                        <div>允许分配本题库的编辑权限给他人</div>
                    </div>
                    <div class="p-body-content">
                        <!--checkbox of allow collection-->
                        <div><input type="checkbox"></div>
                        <div>上传至公共题库后允许他人收藏你的题库</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="toggleModal();">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="取消"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="createMyQuestionBank">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>

        <div><!--navbar in card-->
            <div style="display: flex; flex-direction: row; align-items: center; height: 55px !important;">
                <div class="card-title">题库</div><!--title-->
                <div class="card-options" @click="switchTabs(0);">
                    <div :style="isShow[0] ? 'color: #054FBF;' : ''">所有题库</div>
                    <div :style="isShow[0] ? '' : 'opacity: 0;'" class="option-underline"></div>
                </div>
                <div class="card-options" @click="switchTabs(1);">
                    <div :style="isShow[1] ? 'color: #054FBF;' : ''">我创建的题库</div>
                    <div :style="isShow[1] ? '' : 'opacity: 0;'" class="option-underline"></div>
                </div>
                <div class="card-options" @click="switchTabs(2);">
                    <div :style="isShow[2] ? 'color: #054FBF;' : ''">我收藏的题库</div>
                    <div :style="isShow[2] ? '' : 'opacity: 0;'" class="option-underline"></div>
                </div>
                <div class="card-options" @click="switchTabs(3);">
                    <div :style="isShow[3] ? 'color: #054FBF;' : ''">公共题库</div>
                    <div :style="isShow[3] ? '' : 'opacity: 0;'" class="option-underline"></div>
                </div>
                <div style="margin-left: auto; margin-right: 40px; display: flex; flex-direction: row;">
                    <!--new exam button-->
                    <div @click="toggleModal();">
                        <transition name="slide-fade">
                            <buttons v-show="isShow[1]" style="margin-right: 15px;" tp="super_rounded_rectangle"
                                     btn-color="#054FBF"
                                     txt-color="white"
                                     content="+ 创建题库"
                                     :h="30"
                                     :w="80"
                                     :f-size="12"></buttons>
                        </transition>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: center;" v-show="isShow[0]">
                        <div style="position:relative;">
                            <input v-model="kw" type="text" placeholder="搜索所有题库..."
                                   style="height: 30px; border-radius: 50px; border: none;
                                   background-color: #EFEFEF; text-indent: 10px; padding-right: 15px;">
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: row; align-items: center;" v-show="isShow[1]">
                        <div style="position:relative;">
                            <input v-model="kwPrivate" type="text" placeholder="搜索我创建的题库..."
                                   style="height: 30px; border-radius: 50px; border: none;
                                   background-color: #EFEFEF; text-indent: 10px; padding-right: 15px;">
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: row; align-items: center;" v-show="isShow[2]">
                        <div style="position:relative;">
                            <input v-model="kwStar" type="text" placeholder="搜索我收藏的题库..."
                                   style="height: 30px; border-radius: 50px; border: none;
                                   background-color: #EFEFEF; text-indent: 10px; padding-right: 15px;">
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: row; align-items: center;" v-show="isShow[3]">
                        <div style="position:relative;">
                            <input v-model="kwPublic" type="text" placeholder="搜索公共题库..."
                                   style="height: 30px; border-radius: 50px; border: none;
                                   background-color: #EFEFEF; text-indent: 10px; padding-right: 15px;">
                        </div>
                    </div>

                </div>
            </div>
            <div class="line"></div><!--line-->
        </div>

        <transition name="slide-fade">
            <div v-show="isShow[0]" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
                <!--content of "所有题库"-->
                <!--choose class box-->
                <div class="choose-class">
                    <div style="word-break: keep-all; font-weight: bold;">选择学科门类：</div>
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; padding: 0; align-items: center;">
                        <div @click="defaultChoice = '哲学'; kw = 'sub:01:__philosophy__'">
                            <buttons content="哲学" :txt-color="defaultChoice === '哲学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '哲学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '经济学'; kw = 'sub:02:__economics__'">
                            <buttons content="经济学" :txt-color="defaultChoice === '经济学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '经济学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '法学'; kw = 'sub:03:__law__'">
                            <buttons content="法学" :txt-color="defaultChoice === '法学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '法学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '教育学'; kw = 'sub:04:__pedagogy__'">
                            <buttons content="教育学" :txt-color="defaultChoice === '教育学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '教育学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '文学'; kw = 'sub:05:__literature__'">
                            <buttons content="文学" :txt-color="defaultChoice === '文学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '文学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '历史学'; kw = 'sub:06:__history__'">
                            <buttons content="历史学" :txt-color="defaultChoice === '历史学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '历史学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '理学'; kw = 'sub:07:__science__'">
                            <buttons content="理学" :txt-color="defaultChoice === '理学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '理学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '工学'; kw = 'sub:08:__engineering__'">
                            <buttons content="工学" :txt-color="defaultChoice === '工学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '工学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '农学'; kw = 'sub:09:__agronomy__'">
                            <buttons content="农学" :txt-color="defaultChoice === '农学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '农学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '医学'; kw = 'sub:10:__medicine__'">
                            <buttons content="医学" :txt-color="defaultChoice === '医学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '医学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '管理学'; kw = 'sub:12:__management__'">
                            <buttons content="管理学" :txt-color="defaultChoice === '管理学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '管理学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div @click="defaultChoice = '艺术学'; kw = 'sub:13:__art__'">
                            <buttons content="艺术学" :txt-color="defaultChoice === '艺术学' ? 'white' : 'black'"
                                     :btn-color="defaultChoice === '艺术学' ? '#274DE2' : 'white'" :h="30" :w="80"
                                     tp="super_rounded_rectangle"></buttons>
                        </div>
                        <div v-show="defaultChoice !== ''" @click="defaultChoice = ''; kw = '';"
                             style="margin-left: 4px;">
                            <buttons content="复原" txt-color="white" btn-color="#274DE2" :h="40" :w="80"></buttons>
                        </div>
                    </div>
                </div>

                <heavy-list :data-object="dataObject.concat(star)" :key-word="kw" :num-per-page="15"></heavy-list>
            </div>
        </transition>
        <transition name="slide-fade">
            <div v-show="isShow[1]" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
                <!--content of "我创建的题库"-->
                <heavy-list :data-object="dataObject" :key-word="kw"
                            :num-per-page="15"></heavy-list>
            </div>
        </transition>
        <transition name="slide-fade">
            <div v-show="isShow[2]" style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
                <!--content of "我收藏的题库"-->
                <heavy-list :data-object="star" :key-word="kwStar"
                            :num-per-page="15"></heavy-list>
            </div>
        </transition>
        <transition name="slide-fade">
            <div class="public-qs-lib" v-show="isShow[3]"
                 style="padding-left: 40px; padding-right: 40px; padding-top: 10px;"><!--content of "公共题库"-->
                <div class="dir"><!--directory-->
                    <div style="display: flex; flex-direction: column; margin-top: 10px;"><!--collage kind table-->
                        <div style="display: flex; flex-direction: column; border-bottom: #E3E3E3 1px solid;">
                            <div @click="kwPublic = ''; selected = {};" class="collage-element">所有题库</div>
                            <div v-for="(it, index) in kind" @click="selectClass(it)" class="collage-element" :class="it['id'] === selected['id'] ? 'selected' : ''">{{ it['name'] }}</div>
                        </div>
                    </div>
                </div>
                <div class="cont"><!--content-->

                    <div v-if="/sub:\d*:__\w*__/.test(kwPublic)" class="card-title" style="margin-left: 0;">{{ selected['name'] }}</div>
                    <div v-else-if="kwPublic !== ''" class="card-title" style="margin-left: 0;">"{{ kwPublic }}"搜索结果</div>
                    <div v-else class="card-title" style="margin-left: 0;">公共题库</div>

                    <heavy-list :data-object="specificCollageQuestionsLib" :key-word="kwPublic" :num-per-page="10"></heavy-list>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import HeavyList from "../../../components/HeavyList";
    import LiteList from "../../../components/LiteList";
    import MainSideBar from "../../../components/SideBar/MainSideBar";
    import Buttons from "../../../components/Buttons";
    import {v4 as uuidv4} from 'uuid';

    export default {
        inject: ['reload'],
        name: "ProblemSet",
        components: {
            LiteList,
            HeavyList,
            Buttons,
            MainSideBar
        },
        data() {
            return {
                ok: false, // control the modal

                defaultChoice: '',

                dataObject: [ // my create problem set
                ], // custom data

                isShow: [true, false, false, false], // array elements from left to right represent the order of options from left to right respectively

                star: [],  // 我收藏的题库

                kw: '',  // 所有题库
                kwPublic: '',
                kwPrivate: '',
                kwStar: '',

                selected: {},

                kind: [
                    {
                        'name': '哲学',
                        'id': 'sub:01:__philosophy__',
                    }, {
                        'name': '经济学',
                        'id': 'sub:02:__economics__',
                    }, {
                        'name': '法学',
                        'id': 'sub:03:__law__',
                    }, {
                        'name': '教育学',
                        'id': 'sub:04:__pedagogy__',
                    }, {
                        'name': '文学',
                        'id': 'sub:05:__literature__',
                    }, {
                        'name': '历史学',
                        'id': 'sub:06:__history__',
                    }, {
                        'name': '理学',
                        'id': 'sub:07:__science__',
                    }, {
                        'name': '工学',
                        'id': 'sub:08:__engineering__',
                    }, {
                        'name': '农学',
                        'id': 'sub:09:__agronomy__',
                    }, {
                        'name': '医学',
                        'id': 'sub:10:__medicine__',
                    }, {
                        'name': '管理学',
                        'id': 'sub:12:__management__',
                    }, {
                        'name': '艺术学',
                        'id': 'sub:13:__art__',
                    }
                ],

                specificCollageQuestionsLib: [  // collage problem set

                ], // default value is []

                creator: '',
            }
        },
        methods: {
            switchTabs(position) {
                /**
                 * switch tabs
                 */
                this.$data.isShow.forEach((item, index) => {
                    if (this.$data.isShow[index]) this.$set(this.$data.isShow, index, false);
                });
                this.$set(this.$data.isShow, position, true);
            },
            toggleModal() {
                this.$data.ok = !this.$data.ok;
            },

            selectClass(co) {
                this.$data.selected = co;
                this.$data.kwPublic = co['id'];
            },

            createMyQuestionBank() {
                let dateObj = new Date();
                let subjectHash = {
                    "sub:01:__philosophy__": "哲学",
                    "sub:02:__economics__": "经济学",
                    "sub:03:__law__": "法学",
                    "sub:04:__pedagogy__": "教育学",
                    "sub:05:__literature__": "文学",
                    "sub:06:__history__": "历史学",
                    "sub:07:__science__": "理学",
                    "sub:08:__engineering__": "工学",
                    "sub:09:__agronomy__": "农学",
                    "sub:10:__medicine__": "医学",
                    "sub:12:__management__": "管理学",
                    "sub:13:__art__": "艺术学",
                };

                let myQb = {
                    "libid": uuidv4(),
                    "title": this.$refs.qName.value,
                    "creator": this.$data.creator,
                    "creatorid": this.$cookies.get('TOKEN'),
                    "createtime": dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                    "kopoints": this.$refs.qPoint.value,
                    "subjectclass": subjectHash[this.$refs.classOptions.value],
                    "subjectclassid": this.$refs.classOptions.value,
                    "creatorinsid": this.$cookies.get('uniCode'),
                };

                this.$axios.post('/apiQuestion/u/create_qb', myQb).then(res => {
                    this.reload();
                }).catch(e => {
                    console.log(e);
                });
            }
        },
        mounted() {
        },
        created() {

            this.$axios.get('/apiQuestion/u/fetch_info').then(res => {
                this.$data.creator = res['data']['msg'];
            }).catch(e => {
                console.log(e);
            });

            this.$axios.get('/apiQuestion/u/get_qb').then(res => {
                this.$data.dataObject = JSON.parse(res['data']['msg']);
            }).catch(e => {
                console.log(e);
            });

            // 获取公共题库
            this.$axios.get('/apiQuestion/u/get_public_qb').then(res => {
                this.$data.specificCollageQuestionsLib = JSON.parse(res['data']['msg']);
            }).catch(err => {
                console.log(err);
            });

            // 获取我收藏的题库
            this.$axios.get('/apiQuestion/u/get_star_qb').then(res => {
                this.$data.star = JSON.parse(res['data']['msg']);
            }).catch(err => {
                console.log(err);
            });
        },
    }
</script>
<style scoped>
    .choose-class {
        border: 1px solid;
        border-color: #e3e3e3;
        border-radius: 14px;
        min-height: 35px;
        margin-top: 10px;
        margin-bottom: 10px;

        /* Center vertically */
        display: flex;
        flex-direction: row;
        padding: 20px;
        align-items: center;
    }

    .choose-class:hover {
        border: none;
        box-shadow: 0 0 10px #cbcbcb;
    }

    .card-title {
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }

    .card-options {
        margin-left: 50px;
        margin-top: 18px;
        color: #666666;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-options:hover {
        cursor: pointer;
    }

    .line {
        width: 100%;
        border-bottom: 1px solid #E9E9E9;
    }

    .option-underline {
        height: 4px;
        width: 110%;
        background-color: #054FBF;
        margin-top: 10px;
    }

    .search-qslib {
        position: relative;
    }

    .search-qslib::after {
        cursor: pointer;
        position: absolute;
        content: "";
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
    }

    /*animation*/
    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    /*modal*/
    .Pop-ups {
        z-index: 251;
        overflow: auto;
        width: 590px;
        /*min-height: 310px;*/
        border-radius: 10px;
        background-color: #ffffff;
        position: fixed;
        top: 25%;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .screen {
        z-index: 250;
        display: block;
        overflow: auto;
        width: 100%;
        height: calc(100% + 55px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: -55px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .pTitle {
        width: 100%;
        height: 55px;
        border-bottom: #E8EAEC 1px solid;
    }

    .pTitleFont {
        font-weight: bold;
        height: 100%;
        line-height: 55px;
        float: left;
        margin-left: 23px;
        font-size: 19px;
        font-family: "Microsoft Yahei";
    }

    .close {
        float: right;
        width: 15px;
        margin-right: 23px;
        margin-top: 20px;
    }

    .close:hover {
        cursor: pointer;
    }

    .p-body {
        height: calc(100% - 87px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .p-foot {
        width: 100%;
        height: 55px;
        border-top: #E8EAEC 1px solid;
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
    }

    .p-body-content {
        margin-left: 23px;
        margin-top: 6px;
        margin-bottom: 6px;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .p-body-input {
        height: 30px;
        width: 350px;
        border-radius: 5px;
        border: #999999 1px solid;
        display: flex;
        justify-content: center;
    }

    input:focus {
        outline: none;
    }

    .public-qs-lib {
        display: flex;
        flex-direction: row;
        /*min-height: 78vh;*/
        margin-bottom: 20px;
        justify-content: space-between;
    }

    .dir {
        width: 25%;
        padding: 20px;
        border: #E3E3E3 1px solid;
        border-radius: 14px;
        height: 78vh !important;
        overflow: scroll;
    }

    .dir::-webkit-scrollbar {
        display: none;
    }

    .cont {
        width: 67%;
        padding: 20px;
        border: #E3E3E3 1px solid;
        border-radius: 14px;
        height: 78vh !important;
        overflow: scroll;
    }

    .cont::-webkit-scrollbar {
        display: none;
    }

    .search-collage {
        position: relative;
    }

    .search-collage::after {
        cursor: pointer;
        position: absolute;
        content: "";
        left: -10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
    }

    .collage-element {
        font-size: 18px;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }

    .collage-element:hover {
        background-color: #274DE2;
        border-radius: 8px;
        color: white;
        padding: 8px;
        cursor: pointer;
        font-weight: bold;
    }

    .selected {
        background-color: #274DE2;
        border-radius: 8px;
        color: white;
        padding: 8px;
        cursor: pointer;
        font-weight: bold;
    }

    /*modal animation*/
    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
