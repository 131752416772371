<template>
    <div id="ifa">
        <iframe id="chatFrame" src="http://localhost:8081/ChatStu.vue#/user/chat" width="100%" height="700" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    name: "ChatStu"
}
</script>

<style scoped>

</style>
