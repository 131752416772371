<template>
    <div id="mainHeader">
        <div id="logo">
            <img id="Avatar" src="../../assets/logo.png">
            <div id="name">傲杰</div>
        </div>
        <ul>
            <li>
                <router-link class="menu-link" to="/index">首页</router-link>
            </li>
            <li>
                <router-link class="menu-link" to="/funcCenter">功能中心</router-link>
            </li>
            <li>
                <router-link class="menu-link" to="/competition">竞赛中心</router-link>
            </li>
            <li>
                <router-link class="menu-link" to="/about">关于</router-link>
            </li>
        </ul>
        <router-link to="/"><img id="mail" src="../../assets/Header/mail.svg"></router-link>
        <router-link  v-show="!isLogin" to="/login/userLogin">登录</router-link>
        <div style="float: left">
            <router-link v-show="isLogin"  to="#"><img @mouseenter="openHint()" @mouseleave="closeHint()" id="user" src="../../assets/Header/user.png"></router-link>
            <transition name="sub-comments">
                <div v-show="showHint" class="hint" @mouseenter="openHint()" @mouseleave="closeHint()">
                    <div class="myName" style="border-radius: 5px 5px 0 0;">{{ name }}</div>
                    <div class="set" @click="$router.push('/funcCenter/account_settings')"><img class="setImg" src="../../assets/Header/set.svg"><span style="margin-left: 28px">账号设置</span></div>
                    <div @click="logout()" class="set" style="border-radius: 0px 0px 5px 5px;border-bottom: none"><img class="setImg" src="../../assets/Header/logout.svg"><span style="margin-left: 28px">退出登录</span></div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainHeader',
    data:function (){
        return {
            name:"未登录",
            isLogin: false,
            showHint:false,
            hintTime:false,
        }
    },
    methods:{
        openHint() {
            console.log(1);
            this.hintTime = true;
            this.showHint = true;
        },
        closeHint() {
            this.hintTime = false;
            let that = this;
            setTimeout(function (){
                if(that. hintTime === false) that.$set(that.$data,'showHint',false);
            },300);
        },
        logout() {
            this.$cookies.remove('TOKEN');
            this.$cookies.remove('uniCode');
            this.$toasted.success('退出登陆成功！', {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position:'top-center'
            }).goAway(2500);
            this.hintTime = false;
            this.showHint = false;
            this.$router.push('/Login/userLogin');
        }
    },
    mounted() {
        this.$axios.get(
            '/apiLogin/getUserName',
        ).then(res => {
            this.isLogin = true;
            this.name = res.data.data;
        }).catch(err => {
            this.isLogin = false;
        })
    }
}
</script>

<style scoped lang="less">

//整个头
#mainHeader {
    width: 100%;
    height: 55px;
    background: white;
    border: none;
    text-align: center;
    line-height: 55px;
    border: 0px;
    outline: none;
    box-shadow: 0px 3px 15px #dbdbdb;
    min-width: 740px;
}

//logo和图片
#logo {
    width: 120px;
    float: left;
}


//logo-图片
#Avatar {
    border-radius: 0px;
    width: 40px;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 5px;
    float: left;
}

//logo-名字
#name {
    font-size: 18px;
    font-weight: 800;
}

.hint {
    z-index: 999;
    height: 95px;
    overflow: hidden;
    position: absolute;
    width: 120px;
    background: white;
    margin-top: 58px;
    margin-left: -70px;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #888888;
}

.set{
    color: #666666;
    width: 100%;
    height: 31px;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 31px;
    border-bottom: 1px solid #EFEFEF;
}
.set:hover {

    background: rgb(227,236,244);
    //color: white;
    cursor: pointer;

}
.setImg {
    width: 15px;
    position: absolute;
    margin-top: 8px;
    //margin-left: -10px;
}
.myName {
    background: #9BA3EB;
    color: white;
    width: 100%;
    height: 32px;
    font-size: 15px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: 32px;
    //border-bottom: 1px solid #EFEFEF;
}


ul {
    float: left;
    list-style: none;
    display: block;
    margin: 0px;
    padding: 0px;
}

li {
    text-align: -webkit-match-parent;
    //width: 60px;
    float: left;
    margin-left: 25px;
}


//选中
.router-link-active.menu-link {
    color: #054FBF;
}

.router-link-active.menu-link::before, li > a:hover::before {
    content: "";
    width: 100%;
    height: 4px;
    background: #054FBF;
    position: absolute;
    bottom: 0;
    left: 0;
}

li > a {
    font-weight: 400;
    font-size: 16px;
    font-family: PingFang SC;
    color: #666666;
    display: block;
    position: relative;
    padding: 0 5px;
    text-decoration: none;
}

//邮箱
#mail {
    height: 18px;
    float: left;
    margin-top: 20px;
    margin-left: calc(100% - 585px);
}

#mail:hover {
    cursor: pointer;
}

//用户
#user {
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-left: 33px;
    font-size: 5px;
    float: left;
}

#user:hover {
    cursor: pointer;
}

a:hover {
    color: #054FBF;
    cursor: pointer;
}

.sub-comments-leave-active,.sub-comments-enter-active {
    transition: max-height 0.05s;
}
.sub-comments-enter,.sub-comments-leave-to {
    max-height:0 ;
}
.sub-comments-enter-to,.sub-comments-leave {
    max-height: 4rem ;
}

</style>
