<template>
    <div id="register">
        <div class="logo" align="center">
            <router-link to="/login/userLogin">
                <div class="back">
                    <img style="width: 16px;float: left;margin-top: 2px;margin-right: 5px;"
                         src="../../assets/Login/back.svg">
                    返回
                </div>
            </router-link>
            <img src="../../assets/Login/aojie.svg">
        </div>
        <div class="title">
            新用户注册
        </div>
        <div class="ipt">
            <div class="select" @click="showRegion=!showRegion">+86</div>
            <img @click="showRegion=!showRegion" class="down" src="../../assets/Login/down.svg">
            <transition name="fade">
                <div v-show="showRegion" class="popUps">
                    <ul>
                        <li v-for="(item,idx) in area" :class="check===idx ? 'checked':''" @click="checkIt(item,idx)">
                            <div style="float: left;margin-left: 19px;">{{ item.region }}</div>
                            <div style="float: right;margin-right: 19px;">{{ item.number }}</div>
                        </li>
                    </ul>
                </div>
            </transition>
            <input placeholder="手机号" class="input1" type="text" ref="tel">
        </div>
        <div class="ipt">
            <input id="name" placeholder="姓名" class="input2" type="text" ref="name">
            <img class="a" src="../../assets/Login/account.svg">
        </div>
        <div class="ipt">
            <input id="gender" placeholder="性别（男/女）" class="input2" type="text" ref="gender">
            <img class="a" src="../../assets/Login/id.svg">
        </div>
        <div class="ipt">
            <input id="email" placeholder="邮箱" class="input2" type="text" ref="email">
            <img class="a" src="../../assets/Login/email-fill.png">
        </div>
        <div class="ipt">
            <input id="pwd" placeholder="设置密码" class="input2" type="password" ref="pswd">
            <img class="a" src="../../assets/Login/password.svg">
            <img v-show="!ok" @click="showPwd()" class="look" src="../../assets/Login/look.svg">
            <img v-show="ok" @click="showPwd()" class="look" src="../../assets/Login/openLook.svg">
            <div class="info">
                密码要求8-16位，至少包含数字，字母，字符两种元素
            </div>
        </div>
        <div class="ipt">
            <input placeholder="确认密码" class="input2" type="password">
            <img class="a" src="../../assets/Login/password.svg">
        </div>
        <div class="ipt">
            <input placeholder="验证码" class="input3" type="text">
            <div @click="send($event)" class="button">发送</div>
            <img class="a" src="../../assets/Login/verify.svg">

        </div>
        <div class="footer">
            <div style="float: left;margin-right: 8px;">
                <input type="checkbox">
            </div>
            <div style="float: left;margin-top: 1px">登录即表示同意平台 <em>《隐私政策》</em> 和 <em>《用户协议》</em></div>
        </div>
        <button id="userRegister">下一步</button>
    </div>
</template>

<script>
import Footer from "../../components/Login/Footer";
import Region from "../../assets/Login/region.json";
import {vaptcha} from "../../utils/vaptcha";
import id from "../../utils/vaptchaId";

export default {
    name: "Register",
    components: {Footer},
    data: function () {
        let area = Region;
        return {
            ok: false,
            area,
            showRegion: false,
            check: 0,
            isSend: false
        }
    },
    methods: {

        userPwd(e) {
            let nowDom = e.currentTarget;
            nowDom.value = '';
            nowDom.style.color = '#000000';
            nowDom.setAttribute("type", "password");
        },
        showPwd() {
            let pwd = document.getElementById("pwd");
            this.ok = !this.ok;
            pwd.setAttribute("type", this.ok ? "text" : "password");
        },
        cancelPop(event) {
            let tp = document.querySelector(".popUps");
            let tp1 = document.querySelector(".select");
            let tp2 = document.querySelector(".down");
            if (tp) {
                if (!tp.contains(event.target) && !tp1.contains(event.target) && !tp2.contains((event.target))) {
                    this.showRegion = false;
                }
            }
        },
        checkIt(item, x) {
            this.$set(this.$data, "check", x);
            let tp = document.getElementsByClassName('select')[0];
            tp.innerHTML = item.number;
            this.showRegion = false;
        },
        popUps(puMsg) {
            this.$toasted.success(puMsg, {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position:'top-center'
            }).goAway(2500);
        },
        send(event) {

            if (this.isSend) return;
            this.$set(this.$data, "isSend", true);
            let tp = event.target;
            tp.style.background = "#efefef";
            tp.style.border = "1px solid #efefef";
            tp.style.color = "#000000";
            let time = 60;
            let that = this;
            tp.innerHTML = time;
            time--;
            let timing = setInterval(function () {
                tp.innerHTML = time;
                time--;
                if (time === -1) {
                    tp.style.background = "#054FBF";
                    tp.style.border = "1px solid #054FBF";
                    tp.style.color = "#ffffff";
                    tp.innerHTML = '发送';
                    that.$set(that.$data, "isSend", false);
                    clearInterval(timing);
                }
            }, 1000);
        },
        reg(VAPTCHAObj, verifyObj) {
            this.$axios.post(
                '/register', {
                    name: this.$refs.name.value,
                    tel: this.$refs.tel.value,
                    password: this.$refs.pswd.value,
                    sex: this.$refs.gender.value,
                    email: this.$refs.email.value,
                    verify: verifyObj,
                }).then((resp) => {
                this.popUps("注册成功");
                this.$router.push("/login/userLogin");
            }).catch((err) => {
                console.log(err);
                VAPTCHAObj.reset();
            });
        },
    },
    mounted() {
        vaptcha().then(vaptcha => {
            vaptcha({
                vid: id, // 验证单元id
                type: 'invisible', // 显示类型 点击式
                scene: 1, // 场景值 默认0
            }).then(VAPTCHAObj => {
                // 将VAPTCHA验证实例保存到局部变量中

                // 验证成功进行后续操作
                VAPTCHAObj.listen('pass', () => {
                    let serverToken = VAPTCHAObj.getServerToken();
                    let data = {
                        server: serverToken.server,
                        token: serverToken.token,
                    };

                    console.log(data);

                    this.reg(VAPTCHAObj, data);
                });

                document.getElementById('userRegister').onclick = () => {
                    VAPTCHAObj.validate();
                };
            });
        });
    }
}
</script>

<style scoped>
#register {
    overflow: auto;
    width: 520px;
    height: 900px;
    border: none;
    border-radius: 15px;
    background-color: #ffffff;
    position: fixed;
    top: calc(50% - 500px);
    left: 0;
    right: 0;
    margin: 0 auto;
}

.back {
    position: absolute;
    left: 18px;
    font-weight: 100;
    font-size: 15px;
}

a {
    text-decoration: none;
    color: #888888;
}

.logo {
    margin-top: 15px;
}

.logo > img {
    width: 245px;
}

.title {
    font-weight: 500;
    font-size: 32px;
    margin: 30px 0 0 63px;
}

.down {
    width: 16px;
    position: absolute;
    margin-top: 46px;
    left: 130px;
}

.select {
    line-height: 43px;
    width: 90px;
    height: 43px;
    border-radius: 5px 0 0 5px;
    outline: none;
    border: 1px solid #a3a3a3;
    margin-left: 63px;
    margin-top: 30px;
    font-weight: 100;
    font-size: 17px;
    text-indent: 16px;
    float: left;
}

.select:hover {
    cursor: pointer;
}

.down:hover {
    cursor: pointer;
}

ul {
    margin-left: -40px;
    width: 345px;
}

li {
    line-height: 36px;
    font-weight: 100;
    font-size: 15px;
    height: 36px;
    width: 100%;
    list-style-type: none;
}

li:hover {
    cursor: pointer;
    background: rgb(247, 250, 252);
    color: #054FBF;
    border-radius: 2px;
}

.checked {
    background: rgb(247, 250, 252);
    color: #054FBF;
}

input {
    color: black;
}

.input1 {
    float: left;
    outline: none;
    border-radius: 0 5px 5px 0;
    border: 1px solid #a3a3a3;
    border-left: none;
    width: 300px;
    height: 41px;
    margin-top: 30px;
    font-weight: 100;
    font-size: 17px;
    text-indent: 15px;
}

.input2 {
    outline: none;
    border-radius: 5px;
    border: 1px solid #a3a3a3;
    width: 388px;
    height: 41px;
    margin-left: 63px;
    margin-top: 36px;
    font-weight: 100;
    font-size: 17px;
    text-indent: 48px;

}

.input3 {
    float: left;
    outline: none;
    border-radius: 5px 0 0 5px;
    border: 1px solid #a3a3a3;
    border-right: none;
    width: 298px;
    height: 41px;
    margin-left: 63px;
    margin-top: 36px;
    font-weight: 100;
    font-size: 17px;
    text-indent: 48px;
    margin-bottom: 15px;
}

.look {
    position: absolute;
    height: 21px;
    margin-top: 48px;
    right: 78px;
}

.look:hover {
    cursor: pointer;
}

.a {
    position: absolute;
    height: 23px;
    margin-top: 46px;
    left: 78px
}

.info {
    position: absolute;
    font-weight: 100;
    font-size: 11px;
    margin-left: 65px;
    margin-top: 10px;
}

.button {
    text-align: center;
    line-height: 43px;
    margin-top: 36px;
    float: left;
    width: 90px;
    border: 1px solid #054FBF;
    height: 43px;
    background: #054FBF;
    color: white;
    border-radius: 0 5px 5px 0;
    font-weight: 100;
    font-size: 17px;
}

.button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

.footer {
    overflow: auto;
    float: none;
    font-weight: 100;
    font-size: 13px;
    width: 330px;
    margin-left: 62px;
}

em {
    color: #054FBF;
}

em:hover {
    cursor: pointer;
}

button {
    width: 382px;
    height: 41px;
    margin-left: 69px;
    margin-top: 25px;
    outline: none;
    border-radius: 13px;
    border: none;
    background: #054FBF;
    color: white;
    font-size: 20px;
    font-weight: 100;
}

button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

.popUps {
    border-radius: 3px;
    overflow: auto;
    z-index: 100;
    position: absolute;
    width: 350px;
    height: 350px;
    background: white;
    margin-top: 76px;
    left: 86px;
    border: none;
    box-shadow: 0 0px 6px #a3a3a3;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}

</style>
