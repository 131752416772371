<!--problems list-->
<!--
list element height: 55px
font size: 1.3em
-->
<template>
    <div>
        <transition name="slide-fade">
            <div class="screen" v-if="delOk"></div>
        </transition>

        <!--delete modal-->
        <transition name="bounce">
            <div class="Pop-ups" v-if="delOk">
                <div class="pTitle">
                    <div class="pTitleFont">确认删除</div>
                    <img @click="toggleDelModal();" class="close" src="../assets/SideBar/close.svg">
                </div>
                <div class="p-body">
                    <div class="p-body-content"><!--input name of questions lib-->
                        <div>确定删除题目"{{ willDelTitle }}"？此操作不可撤销，要继续吗？</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 5px;" @click="toggleDelModal();">
                        <buttons :h="35" :w="70" btn-color="white" txt-color="#525353" content="否"></buttons>
                    </div>
                    <div style="margin-left: 5px; margin-right: 23px;" @click="delQs">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="是"></buttons>
                    </div>
                </div>
            </div>
        </transition>

        <!--list-->
        <div v-for="(item, index) in cutPageList(defaultPageNum)">

            <!--list element-->
            <div class="lt-list-frame" :style="index + 1 === defaultPageNum * numPerPage
            || index + 1 === cutPageList(defaultPageNum).length ? 'border-bottom: 1px solid; border-color: #e3e3e3;' : ''">
                <div style="display: flex; width: 100%; height: 55px; align-items: center;"><!--info summary-->
                    <div class="lt-list-frame-summary">
                        <div class="lt-list-frame-title-container"><!--title&icon div-->
                            <div class="lt-list-frame-title-icon"><!--icon-->
                                <svg id="组_116" data-name="组 116" xmlns="http://www.w3.org/2000/svg" width="25.417"
                                     height="25.417" viewBox="0 0 25.417 25.417">
                                    <g id="组_115" data-name="组 115">
                                        <path id="路径_123" data-name="路径 123"
                                              d="M4.542,6H2V23.792a2.549,2.549,0,0,0,2.542,2.542H22.333V23.792H4.542Z"
                                              transform="translate(-2 -0.917)" fill="#054fbf"/>
                                        <path id="路径_124" data-name="路径 124"
                                              d="M23.792,2H8.542A2.549,2.549,0,0,0,6,4.542v15.25a2.549,2.549,0,0,0,2.542,2.542h15.25a2.549,2.549,0,0,0,2.542-2.542V4.542A2.549,2.549,0,0,0,23.792,2ZM16.179,18.521a1.328,1.328,0,1,1,0-2.656A1.3,1.3,0,0,1,17.5,17.186,1.323,1.323,0,0,1,16.179,18.521Zm3.177-7.841c-.8,1.182-1.563,1.538-1.982,2.3-.165.3-.229.508-.229,1.5H15.214a4.094,4.094,0,0,1,.33-2.1c.521-.928,1.5-1.474,2.071-2.288A1.568,1.568,0,0,0,16.167,7.63,2.019,2.019,0,0,0,14.26,9.193l-1.741-.724a3.782,3.782,0,0,1,3.635-2.656,3.6,3.6,0,0,1,3.19,1.6A3.187,3.187,0,0,1,19.356,10.68Z"
                                              transform="translate(-0.917 -2)" fill="#054fbf"/>
                                    </g>
                                </svg>
                            </div>

                            <div class="lt-list-frame-title-title" @click="viewContent(item['qid'], item['title'], item['tp']);" style="cursor: pointer;"><!--title-->
                                {{ item['title'] }}
                            </div>
                            <buttons :content="item['tp']" :f-size="8"
                                     btn-color="#054FBF" :h="15" :w="56" tp="super_rounded_rectangle"
                                     txt-color="white"></buttons>

                        </div>
                    </div>

                    <div class="lt-list-frame-del-icon">
                        <div class="share-dropdown-button" @mouseover="shareDropdownMenu = item['qid'];"
                        @mouseleave="shareDropdownMenu = '';">
                            <div class="q-share" style="margin-right: 100px;"><!--share icon-->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.262"
                                     height="26.85" viewBox="0 0 24.262 26.85">
                                    <path id="路径_69" data-name="路径 69"
                                          d="M23.218,20.978a3.925,3.925,0,0,0-2.642,1.038l-9.61-5.594a4.412,4.412,0,0,0,.121-.944,4.412,4.412,0,0,0-.121-.944L20.469,9a4.035,4.035,0,1,0-1.294-2.952,4.412,4.412,0,0,0,.121.944l-9.5,5.54a4.044,4.044,0,1,0,0,5.9l9.6,5.607a3.8,3.8,0,0,0-.108.876,3.936,3.936,0,1,0,3.936-3.936Z"
                                          transform="translate(-3 -2)" fill="#054fbf"/>
                                </svg>
                            </div>
                            <transition name="slide-fade">
                                <div v-show="shareDropdownMenu === item['qid']" class="dropdown-menu">
                                    <div class="dropdown-option" @click=""
                                         style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
                                        复制到其他题库
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <div class="q-del"
                             @click="willDelId = item['qid']; willDelTitle = item['title']; delOk = true;">
                            <!--delete icon-->
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24.429" viewBox="0 0 19 24.429">
                                <path id="路径_67" data-name="路径 67"
                                      d="M22.643,24.714a2.722,2.722,0,0,1-2.714,2.714H9.071a2.722,2.722,0,0,1-2.714-2.714V8.429H22.643ZM5,4.357H9.75L11.107,3h6.786L19.25,4.357H24V7.071H5Z"
                                      transform="translate(-5 -3)" fill="#d94e4e"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; flex-direction: column; justify-content: center;align-items: center;"
             v-show="cutPageList(defaultPageNum).length === 0">
            <div style="margin: 5px;">
                暂无符合条件的记录
            </div>
        </div>

        <!--page indicator-->
        <div style="display: flex; justify-content: center;">
            <div class="page-indicator-surface">
                <div class="page-num" @click="prePage"><!--previous page-->
                    <div style="font-weight: bold;"><</div>
                </div>

                <div class="page-num"
                     v-for="count in (showPageElementNumProcessor() > 3 ? 3 : showPageElementNumProcessor())"
                     style="cursor: pointer;" @click="switchPageNum(count);"
                     :style="defaultPageNum === count ? 'background-color: #274DE2; color: white;' : ''">
                    <!--total num of page and position-->
                    <h3 style="cursor: pointer;">{{ count }}</h3>
                </div>

                <div class="page-num" v-show="showPageElementNumProcessor() > 3" style="text-align: center;"
                     @click="showJumpPageInput = !showJumpPageInput;">
                    <!--if total num of page greater than 3 then show this div-->
                    <h3 style="cursor: pointer;">{{ '...' }}</h3>
                </div>
                <transition name="slide-fade">
                    <div class="page-num" v-show="showJumpPageInput"><!--input num to jump the specific page-->
                        <input ref="p" @keyup.enter="customizePage" type="number" placeholder="输入页码然后回车"
                               style="height: 100%; border: 1px solid; border-color: #e3e3e3; border-radius: 8px; padding: 2px;">
                    </div>
                </transition>

                <div class="page-num" @click="nextPage">
                    <!--next page-->
                    <div style="font-weight: bold;">></div>
                </div>
                <div v-show="defaultPageNum > 0 && defaultPageNum <= showPageElementNumProcessor()"
                     style="font-weight: bold; color: #959595;">
                    第{{ defaultPageNum }}页，共{{ showPageElementNumProcessor() }}页，共{{ keyWord === '' ? dataObject.length : searchLength }}条记录
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Buttons from "./Buttons";
import 'x0popup/dist/x0popup.min.css';
import x0p from 'x0popup/dist/x0popup.min'
import axios from "axios";

export default {
    inject: ['reload'],
    name: "LiteList",
    components: {Buttons},
    data() {
        return {
            details: '',
            defaultPageNum: 1,
            showJumpPageInput: false,

            searchLength: 0,

            delOk: false, // control the delete modal
            willDelId: '', // if check this qs to delete and its qId will assign to this variable
            willDelTitle: '',

            shareDropdownMenu: false,
        };
    },
    props: {
        dataObject: Array,
        numPerPage: { // customize number of elements per page, default 10
            type: Number,
            default: 10
        },
        keyWord: {
            type: String,
        },
        libId: {
            type: String,
            require: true,
        },
        libTitle: {
            type: String,
            require: true,
        },
    },
    methods: {
        expandDetails(id) { // expand specific details
            this.$data.details = id;
        },
        notExpandDetails() { // close all details
            this.$data.details = "";
        },
        showPageElementNumProcessor() {
            /**
             * return value of total page num.
             */
            let total = this.$props.dataObject.length; // total data num
            if (this.$props.keyWord === '') return Math.ceil(total / this.$props.numPerPage);
            else return Math.ceil(this.$data.searchLength / this.$props.numPerPage);
        },
        cutPageList(pageRank) {
            /**
             * return customize page data list.
             * @param pagerank
             */

            let rawData = this.$props.dataObject;
            if (this.$props.keyWord !== '') rawData = this.$props.dataObject.filter(this.searchEveryThing);

            let customizeNumPerPage = this.$props.numPerPage;

            // re calculate the num of page
            this.$data.searchLength = rawData.length;

            return rawData.slice((pageRank - 1) * customizeNumPerPage, pageRank * customizeNumPerPage);
        },
        searchEveryThing(item, index, ar) {
            /**
             * search every criterion value
             */
            let reg = RegExp(this.$props.keyWord);
            return reg.test(item['title']) || reg.test(item['creator']) || reg.test(item['createtime']) || reg.test(item['tp']);
        },
        switchPageNum(page) {
            /**
             * process the click page number
             */
            this.$data.defaultPageNum = page;
        },
        customizePage() {
            /**
             * process the input page number
             */
            if (this.$refs.p.value <= 0) {
                x0p('错误', '请输入正整数！', 'error', false);
            } else this.$data.defaultPageNum = Math.floor(this.$refs.p.value); // if user input float then remove the decimal part
        },
        prePage() {
            /**
             * switch to the previous page
             */
            this.$data.defaultPageNum = this.$data.defaultPageNum > 1 ? this.$data.defaultPageNum - 1 : 1;
        },
        nextPage() {
            /**
             * switch to the next page
             */
            this.$data.defaultPageNum = this.$data.defaultPageNum + 1 <= Math.ceil(this.$props.dataObject.length / this.$props.numPerPage) ? this.$data.defaultPageNum + 1 : this.$data.defaultPageNum;
        },
        delQs() {

            console.log(this.$data.willDelId);
            this.$axios.get('/apiQuestion//u/delQuestion?qid=' + this.$data.willDelId + '&libId=' + this.$props.libId).then(res => {
                this.$toasted.success('删除题目 '+this.willDelTitle+' 成功', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                }).goAway(3000);
                this.reload();
            }).catch(e => {
                console.log(e);
            });
         },
        toggleDelModal() {
            this.$data.delOk = !this.$data.delOk;
        },

        viewContent(qid, qTitle, qType) {
            this.$router.push({
                path: '/u/funcCenter/problemSet/questions/newQuestion?qid=',
                query: {
                    qid: qid,
                    qTitle: qTitle,
                    qType: qType,
                    libId: this.$props.libId,
                    libTitle: this.$props.libTitle,
                }
            });
        },
    },
    mounted() {
    },

    created() {
    }
}
</script>

<style scoped>
.lt-list-frame {
    border-top: 1px solid;
    border-color: #e3e3e3;
    min-height: 55px;
    /* Center vertically */
    display: flex;
    flex-direction: column;
}

.lt-list-frame:hover {
    background-color: #e3e3e3;
}

.lt-list-frame-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lt-list-frame-title-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.lt-list-frame-title-icon {
    display: flex;
    align-items: center;
}

.lt-list-frame-title-title {
    font-size: 1.3em;
    font-weight: bold;
    margin-left: 10px;
}

.lt-list-frame-del-icon {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
}

.page-indicator-surface {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.page-num {
    border: 1px solid;
    border-color: #e3e3e3;
    height: 38px;
    min-width: 42px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.page-num:hover {
    background-color: #274DE2;
    color: white;
}

.q-share {
    position: relative;
}

.q-share::after {
    position: absolute;
    cursor: pointer;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

.q-del {
    position: relative;
}

.q-del::after {
    position: absolute;
    cursor: pointer;
    content: "";
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
}

/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

input:focus {
    outline: none;
}


/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}


.share-dropdown-button {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
}

.dropdown-menu {
    position: absolute;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    background-color: white;
    border: #707070 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.dropdown-option {
    transition: background-color 0.3s;
}

.dropdown-option:hover {
    cursor: pointer;
    background-color: #e8e8e8;
}
</style>
