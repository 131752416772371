<template>
    <div id="questions">
        <div id=main ref="main"></div>
        <div class="mg">
            <div>平均分：{{ avg }}分</div>
            <div>最高分：{{ max }}分</div>
            <div>最低分：{{ min }}分</div>
        </div>
    </div>
</template>

<script>
const echarts = require('./echarts.min');
export default {
    name: "SAQuestions2",
    props:{
        people:{
            type: Number,
            default: 0,
        },
        more90:{
            type: Number,
            default: 0,
        },
        more80:{
            type: Number,
            default: 0,
        },
        more60:{
            type: Number,
            default: 0,
        },
        other:{
            type: Number,
            default: 0,
        },
        avg:{
            type: Number,
            default: 0,
        },
        max:{
            type: Number,
            default: 0,
        },
        min:{
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            myChart:null
        }
    },
    methods:{
        initChart() {
            let chartDom = this.$refs.main;console.log(1);
            this.myChart = echarts.init(chartDom);
            let option;
            option = {
                tooltip: {
                    trigger: 'item',
                    axisPointer:{
                        type:'shadow'
                    },
                    formatter:function(params) {
                        let res="<p>"+params.data.name+": "+params.data.value+"人</p>";
                        return res;
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 16,
                    },
                    itemHeight: 23,
                    itemWidth:33,
                    orient: 'vertical',
                    left: '76%',  //图例距离左的距离
                    y: 'center',  //图例上下居中
                    data: ["12分以上","10-12分","8-10分","8分以下"],
                },
                graphic:[
                    {
                        type:"text",
                        left: '20%',
                        top:"36%",
                        style:{
                            text:"学生总人数",
                            textAlign:"center",
                            fill: "#666666",
                            fontSize:28,
                            fontWeight:700
                        }
                    },
                    {
                        type:"text",
                        left: '28%',
                        top:"56%",
                        style:{
                            text:this.people,
                            textAlign:"center",
                            fill:"#000000",
                            fontSize:38,
                            fontWeight:700
                        }
                    }
                ],
                series: [
                    {
                        name: '人数',
                        type: 'pie',
                        radius: ['70%', '90%'],
                        center:['32%','50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.more90, name: '90分及以上' },
                            { value: this.more80, name: '80-89分' },
                            { value: this.more60, name: '60-79分' },
                            { value: this.other, name: '60分以下' },
                        ]
                    }
                ]
            };
            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
    mounted() {
        this.initChart();
    }
}
</script>

<style scoped>
    #questions {
        width: 100%;
        overflow: auto;
    }
    #main {
        float: left;
        width: 580px;
        margin-left: 16%;
        margin-top: 5%;
        height: 380px;
    }
    .mg {
        width: 135px;
        margin-left: calc(30% - 300px);
        margin-top: calc(5% + 100px);
        float: left;
    }
    .mg > div {
        font-weight: 300;
        font-size: 18px;
        margin-top: 15px;
    }
</style>
