<template>
    <div id="footer"></div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
    #footer {
        width: 100%;
        height: 50px;
        float: left;
    }
</style>
