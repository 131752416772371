<template>
    <div id="notice">

        <div id="header">
            <div class="card-title">成绩查看</div>

        </div>
        <div id="body" style="overflow: auto">
            <transition name="slide-fade1">
                <div v-show="myData.ok[0]" style="padding: 40px;">
                    <!--成绩分析-->
                    <div class="body">
                        <div style="width: 100%">
                            <SAQuestions2 v-if = "okShow" :people="people" :more60="more60" :more80 = "more80" :more90="more90" :other="other" :avg="avg" :max="max" :min="min"></SAQuestions2>
                        </div>

                        <div class="content">
                            <div class="content_body" style="margin-top: 31px;margin-left: 130px">
                                <table class="table_b">
                                    <thead>
<!--                                    <td style="width: 200px;">名次</td>-->
                                    <td style="width: 200px;">姓名</td>
                                    <td style="width: 200px;">考号</td>
                                    <td style="width: 100px;">线上成绩</td>
                                    <td style="width: 150px;">线下成绩</td>
                                    <td style="width: 150px;">总成绩</td>
                                    </thead>
                                    <tr v-for="(item,idx) in peopleData">
<!--                                        <td class="name">-->
<!--                                            {{idx + 1}}-->
<!--                                        </td>-->
                                        <td>
                                            {{item.name}}
                                        </td>
                                        <td>
                                            {{item.Id}}
                                        </td>
                                        <td>
                                            {{ item.oGrade }}
                                        </td>
                                        <td>
                                            {{ item.uGrade }}
                                        </td>
                                        <td>
                                            {{ item.grade }}
                                        </td>
                                    </tr>
                                </table>

<!--                                <div class="next">-->
<!--                                    <div class="number" style="position: relative" @click="add(-1)">-->
<!--                                        <img src="../../../assets/Echarts/left.svg">-->
<!--                                    </div>-->
<!--                                    <div v-for="(item,it) in idx" class=""-->
<!--                                         @click="change(it)">-->
<!--                                        {{ item }}-->
<!--                                    </div>-->
<!--                                    <div class="" style="display: flex;">-->
<!--                                        <div>...</div>-->
<!--                                    </div>-->
<!--                                    <div class="" :class="num === 9 ? 'select':'notselect'" @click="change(9)">{{ 9 }}</div>-->
<!--                                    <div class="number" style="position: relative" @click="add(1)">-->
<!--                                        <img src="../../../assets/Echarts/right.svg">-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Search from "../../../components/common/Search";
import Buttons from "../../../components/Buttons";
import SAQuestions2 from "../../../components/Echarts/SAQuestions2";

export default {
    name: "LookLLook",
    components: {Buttons, Search, SAQuestions2},
    data: function () {
        return {
            okShow:false,
            okModal: false, // control the modal
            people:0,
            more90:0,
            more80:0,
            more60:0,
            other:0,
            avg:0,
            max:0,
            min:0,
            fakeTitle: '',
            fakeContent: '',
            idx: [1,2,3],
            num: '0',
            myData: {
                ok: [true, false],
            },

            peopleData: [
                // {
                //     Id: '201912213701023',
                //     name: '普奇文',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 12,
                //     uGrade: '/',
                //     grade: 12
                // },
                // {
                //     Id: '201912213701005',
                //     name: '万冷雪',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 10,
                //     uGrade: '/',
                //     grade: 10
                // },
                // {
                //     Id: '201912213701012',
                //     name: '胥永元',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 13,
                //     uGrade: '/',
                //     grade: 13
                // },
                // {
                //     Id: '201912213701034',
                //     name: '许藏',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 9,
                //     uGrade: '/',
                //     grade: 9
                // },
                // {
                //     Id: '201912213701051',
                //     name: '江湛恩',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 10,
                //     uGrade: '/',
                //     grade: 19
                // },
                // {
                //     Id: '201912213701001',
                //     name: '赏巧夏',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 9,
                //     uGrade: '/',
                //     grade: 9
                // },
                // {
                //     Id: '201912213701016',
                //     name: '阚尔丝',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 11,
                //     uGrade: '/',
                //     grade: 11
                // },
                // {
                //     Id: '201912213701039',
                //     name: '冀学',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 16,
                //     uGrade: '/',
                //     grade: 16
                // },
                // {
                //     Id: '201912213701021',
                //     name: '竹骊洁',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 10,
                //     uGrade: '/',
                //     grade: 10
                // },
                // {
                //     Id: '201912213701008',
                //     name: '左端',
                //     type: '已批阅',
                //     teacher: '李兰辰',
                //     oGrade: 9,
                //     uGrade: '/',
                //     grade: 9
                // },
            ],

            info: {
                basic: {
                    title: '',
                    beginTime: '',
                    endTime: '',
                    durationLimit: 'no-limit',
                    duration: 0,
                    limitSubmit: {
                        begin: 0,
                        end: 0
                    },
                    limitEnter: 0,
                },
                invigilate: {
                    type: 'browser',
                    generalOptions: [],
                    specificOptions: {
                        opt: [],
                        times: 3
                    }
                },
                mul: {
                    type: 'half',
                    generalOptions: [],
                    specificOptions: {
                        opt: [],
                        times: 2
                    }
                },
            },

            examData:[
                {
                    name:'期中考试',
                    author:'李兰辰',
                    time:'2022-01-03 09:00:00',
                    checkGrade:true,
                    checkRating:true,
                    checkAllRating:false
                },
                {
                    name:'期末考试',
                    author:'李兰辰',
                    time:'2022-02-03 09:00:00',
                    checkGrade:true,
                    checkRating:false,
                    checkAllRating:false
                }
            ]
        }
    },
    mounted() {


        this.$axios.get(
            'apiExam/getUserGrade',{
                params:{
                    'examId': this.$route.query.examId
                }
            }).then(res => {
            for(let i = 0;i < res.data.data.length; i++) {
                let that = res.data.data[i];
                this.peopleData.push({
                    Id: that.id,
                    name: that.userName,
                    type: '已批阅',
                    oGrade: that.subQuestion,
                    uGrade: '/',
                    grade: that.subQuestion
                })
                this.people += 1;
                console.log("fa" + this.people);
                let gd = parseInt(that.subQuestion);
                if(gd >= 90) this.more90 += 1;
                else if(gd >= 80) this.more80 += 1;
                else if(gd >= 60) this.more60 += 1;
                else this.other += 1;
                this.$set(this.$data,'avg',this.avg+gd);
                this.max = Math.max(this.max,gd);
                if(i === 0) this.min = gd;
                this.min = Math.min(this.min,gd);
                this.okShow = true;
            }
            this.$set(this.$data,'avg',parseFloat(this.avg/this.people).toFixed(2));
        })

    },
    created() {


    }
}
</script>

<style scoped>
#notice {
    width: 100%;
    height: 100%;
}

#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}

#sc {
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}

.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.card-options {
    user-select: none;
    float: left;
    margin-left: 50px;
    margin-top: 20px;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*font-weight: 500;*/
    /*font-size: 16px;*/
}

.card-options:hover {
    cursor: pointer;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}


a {
    color: black;
}

#body {
    position: relative;
    width: 100%;
    min-height: calc(100% - 55px);
    overflow: auto;
}

.infromation {
    overflow: auto;
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #E3E3E3;
}

.infromationhead {
    height: 22px;
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #E3E3E3;
}

.first {
    width: 100%;
    overflow: auto;
    margin-top: 8px;
}

.first > img {
    margin-top: 5px;
    margin-left: 13px;
    float: left;
}

.title_information {
    margin-top: 7px;
    margin-left: 13px;
    height: 23px;
    float: left;
    font-size: 13px;
    font-weight: 100;
    width: 66px;
    text-align: center;
    line-height: 23px;
    border-radius: 20px;
    background: #C01616;
    color: white;
}

.title {
    /*margin-top: 7px;*/
    float: left;
    margin-left: 13px;
    font-size: 25px;
    font-weight: 600;
}

.edit {
    margin-top: 4px;
    float: right;
    height: 22px;
    border-radius: 20px;
    margin-right: 66px;
}

.edit > img {
    height: 100%;
}

.edit:hover {
    cursor: pointer;
}

.delete {
    margin-top: 4px;
    float: right;
    margin-right: 15px;
}

.delete:hover {
    cursor: pointer;
}

.send {
    float: right;
    color: #00A03E;
    margin-top: 3px;
    margin-right: 66px;
    font-size: 16px;
    font-weight: 100;
}

.notSend {
    float: right;
    color: #999999;
    margin-top: 3px;
    margin-right: 66px;
    font-size: 16px;
    font-weight: 100;
}

.button {
    font-weight: 100;
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    background: #054FBF;
    /*margin-top: 10px;*/
    float: right;
    height: 30px;
    width: 98px;
    border-radius: 20px;
    margin-right: 20px;
}

.button:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.button1 {
    font-size: 14px;
    font-weight: 100;
    line-height: 28px;
    text-align: center;
    color: white;
    border-radius: 15px;
    margin-top: 14px;
    margin-right: 18px;
    float: right;
    height: 28px;
    width: 92px;
    background: #054FBF;
}

.button1:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.by {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 100;
    color: #666666;
    margin-left: 46px;
    margin-bottom: 8px;
}

.content {
    text-indent: 32px;
    width: 80%;
    margin-top: 13px;
    font-weight: 100;
    margin-left: 46px;
    margin-bottom: 8px;
}

.isOk {
    font-weight: 800;
    font-size: 14px;
    color: #000000;
    line-height: 30px;
    text-align: center;
    background: #efefef;
    /*margin-top: 10px;*/
    float: right;
    height: 30px;
    width: 98px;
    border-radius: 20px;
    margin-right: 20px;
}

.transition-box {
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.slide-fade1-enter-active {
    transition: all 0.3s ease;
}

.slide-fade1-enter, .slide-fade1-leave-to {
    transform: translateX(-300px);
    opacity: 0;
}


.slide-fade2-enter-active {
    transition: all 0.3s ease;
}

.slide-fade2-enter, .slide-fade2-leave-to {
    transform: translateX(300px);
    opacity: 0;
}

.slide-fade-enter-active {
    transition: opacity 0.3s;
}

.slide-fade-leave-active {
    transition: opacity 0.2s;
}

.slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
}


/*滚动条样式*/
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}


/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none;
}

/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

>>> .el-checkbox__label {
    color: #606266;
    font-weight: 400;
}
</style>

<style scoped lang="less">
.create {
    padding: 28px 40px;
    overflow-y: scroll;
    height: calc(100% - 56px);
}

.title-bar {
    display: flex;

    .text {
        margin-left: 15px;
    }
}

.content {
    margin: 23px 20px;

    .create-button {
        text-align: right;

        button {
            color: white;
            background: #054FBF;
            border-radius: 10px;
            border: none;
            width: 100px;
            height: 45px;
            font-size: 16px;
            //float: right;
            cursor: pointer;
        }

    }
}

.form-item {
    span {
        color: #606266;
    }
}

.tip {
    color: #606266;
    font-weight: 300;
}




#set {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}
#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}
.content {
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #efefef;
    overflow: auto;
}
.title {
    float: left;
    font-weight: 800;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 1px;
}
.content_body {
    margin-left: 150px;
    font-weight: 100;
    margin-top: 31px;
    float: left;
    overflow: auto;
    margin-bottom: 35px;
}
input {
    width: 28px;
    height: 20px;
    outline: none;
    font-weight: 100;
    font-size: 16px;
}
.a_a {
    float: left;
}
.message{
    margin-left: 66px;
    font-weight: 100;
    font-size: 13px;
    margin-top: 3px;
    float: left;
    color: #666666;
}
.a > button {
    border-radius: 25px;
    border: 1px solid #054FBF;
    background: white;
    color: #054FBF;
    margin-left: 20px;
}
button:hover{
    cursor: pointer;
    background: #054FBF;
    color: white;
}
.a {
    margin-top: 25px;
}
.table_a {
    text-align: center;
    width: 800px;
}
.table_b {
    text-align: center;
    width: 1000px;
}


table {
    letter-spacing:1px;
    border-collapse:collapse;
}
table,th,tr,td{
    border-bottom:1px solid #dedede;/* 表格横线 */
}


thead > td{
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #054FBF;
}

input {
    width: 28px;
    height: 20px;
    outline: none;
    font-weight: 100;
    font-size: 16px;
}
tr > td{
    line-height: 33px;
    height: 33px;
}
.name {
    color:#054FBF;
}
.name:hover {
    cursor: pointer;
}
td > img {
    position: absolute;
    width: 33px;
    margin-top: -17px;
    margin-left: -19px;
}
td > img:hover {
    cursor: pointer;
}
.button_a {
    float: right;
    margin-bottom: 25px;
    margin-right: 50px;
    width: 80px;
    height: 33px;
    border:none;
    background: #054FBF;
    color: white;
    border-radius: 6px;
}
.button_b {
    float: right;
    margin-bottom: 25px;
    margin-right: 50px;
    width: 80px;
    height: 33px;
    border:none;
    background: #C4C4C4;
    color: black;
    border-radius: 6px;
}
.button_a:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}
.button_b:hover {
    cursor: pointer;
    color: black;
    background: #999999;
}




.next {
    padding-top: 28px;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.number {
    border-radius: 3px;
    float: left;
    height: 28px;
    width: 30px;
    line-height: 28px;
    font-size: 14px;
    background: rgb(244, 244, 245);
    margin-left: 15px;
}

.number > img {
    position: absolute;
    float: left;
    width: 18px;
    top: 5px;
    left: 6px;
}

.number:hover {
    cursor: pointer;
}

.select {
    background: rgb(64, 158, 255);
    color: white;
}

.notselect:hover {
    color: rgb(64, 158, 255);
}

.back {
    width: 65px;
    position: absolute;
    margin: 25px 0 0 40px;
}

.back:hover {
    cursor: pointer;
}
</style>
