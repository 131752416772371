<template>
    <div id="markPapers">


        <transition name="slide-fade">
            <div id="screen" v-if="okExp"></div>
        </transition>
        <transition name="bounce">
            <div id="Pop-ups" v-if="okExp">
                <div id="pTitle">
                    <div id="pTitleFont">导出与存档</div>
                    <img @click="okExp = false;" id="close" src="../../../assets/SideBar/close.svg" alt="">
                </div>
                <router-link to="#">
                    <div class="pBody">
                        <div class="tooltip">
                            <span class="tooltiptext">导出当前考试的试卷、阅卷成绩和其他所有信息</span>
                            <img style="margin-top: 26%" src="../../../assets/SideBar/exam.svg" alt=""><br>
                            <div>导出</div>
                        </div>
                    </div>
                </router-link>
                <router-link to="#">
                    <div class="pBody">
                        <div class="tooltip">
                            <span class="tooltiptext">对当前考试的试卷、阅卷成绩和其他所有信息进行存档以便线上随时查看</span>
                            <img style="margin-top: 26%" src="../../../assets/SideBar/class.svg" alt=""><br>
                            <div>存档</div>
                        </div>
                    </div>
                </router-link>
            </div>
        </transition>


        <transition name="slide-fade">
            <div class="screen" v-if="okTime"></div>
        </transition>

        <transition name="bounce">
            <div class="Pop-ups" v-if="okTime">
                <div class="pTitle">
                    <div class="pTitleFont">确认提交</div>
                    <img @click="okTime = !okTime;" class="close" src="../../../assets/SideBar/close.svg">
                </div>
                <div class="p-body">
                    <div class="p-body-content">
                        <!--input knowledge points of questions lib-->
                        <div>确认提交该考试的阅卷结果？</div>
                    </div>
                </div>
                <div class="p-foot">
                    <div style="margin-left: 5px; margin-right: 23px;" @click="okTime = !okTime;">
                        <buttons :h="35" :w="70" btn-color="#274DE2" txt-color="white" content="确定"></buttons>
                    </div>
                </div>
            </div>
        </transition>


        <!--头-->
        <div v-show="showOne===0">
            <div class="header">
                <div class="card-options" @click="switchTabs(3);">
                    <div :style="ok===3 ? 'color: #054FBF;' : ''">客观题</div>
                    <div :style="ok===3 ? '' : 'opacity: 0;'" class="option-underline"></div>
                </div>
                <div class="card-options" @click="switchTabs(1);">
                    <div :style="ok===1 ? 'color: #054FBF;' : ''">主观题</div>
                    <div :style="ok===1 ? '' : 'opacity: 0;'" class="option-underline"></div>
                </div>
<!--                <div class="card-options" @click="switchTabs(2);">-->
<!--                    <div :style="ok===2 ? 'color: #054FBF;' : ''">按人阅卷</div>-->
<!--                    <div :style="ok===2 ? '' : 'opacity: 0;'" class="option-underline"></div>-->
<!--                </div>-->
                <div @click="okTime = true;" class="button1" style="width: 100px;">提交阅卷结果</div>
                <div @click="okExp = true;" class="button1">导出&存档</div>
            </div>
            <div class="body" v-show="!showProblem && !showChart">


                <!--主观题-->
                <div v-show="ok===1">
                    <table class="table_a">
                        <thead>
                        <td style="width: 20%">标题</td>
                        <td style="width: 20%">题型</td>
                        <td style="width: 20%">已阅</td>
                        <td style="width: 20%">未阅</td>
                        <td style="width: 20%">操作</td>
                        </thead>
                        <tr v-for="(item,idx) in problemData">
                            <td>
                                {{ item.title }}
                            </td>
                            <td>
                                {{ item.type }}
                            </td>
                            <td style="color: #5587D3">
<!--                                {{ subjectInvResult[item['qId']] ? Object.keys(subjectInvResult[item['qId']]['studentResult']).length : 0 }}-->
                              {{sbok ? (subjectInvResult[item['qId']] ? item['studentAnswers'].length - Object.keys(subjectInvResult[item['qId']]['studentResult']).length : item['studentAnswers'].length) : 0}}
                            </td>
                            <td style="color: #5587D3">
                              {{!sbok ? (subjectInvResult[item['qId']] ? item['studentAnswers'].length - Object.keys(subjectInvResult[item['qId']]['studentResult']).length : item['studentAnswers'].length) : 0}}
<!--                                {{ subjectInvResult[item['qId']] ? item['studentAnswers'].length - Object.keys(subjectInvResult[item['qId']]['studentResult']).length : item['studentAnswers'].length }}-->
                            </td>
                            <td style="color: #5587D3">
                                <div class="cz">
                                    <span @click="seeP(item['typeCode'], item['qId'])">批阅</span>
                                    <span style="margin-left: 20px" @click="viewProblem(item.qId)">查看</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="next">
                        <div class="number" style="position: relative" @click="add(-1)">
                            <img src="../../../assets/Echarts/left.svg">
                        </div>
                        <div v-for="(item,it) in idx" class="number" :class="num === it ? 'select':'notselect'"
                             @click="change(it)">{{ item }}
                        </div>
                        <div class="number" style="position: relative">
                            ...
                        </div>
                        <div class="number" :class="num === 8 ? 'select':'notselect'" @click="change(8)">{{ 8 }}</div>
                        <div class="number" style="position: relative" @click="add(1)">
                            <img src="../../../assets/Echarts/right.svg">
                        </div>
                    </div>
                </div>


<!--                &lt;!&ndash;按人阅卷&ndash;&gt;-->
<!--                <div v-show="ok===2">-->
<!--                    <table class="table_a">-->
<!--                        <thead>-->
<!--                        <td style="width: 20%">考号</td>-->
<!--                        <td style="width: 18%">姓名</td>-->
<!--                        <td style="width: 18%">批阅状态</td>-->
<!--                        <td style="width: 18%">批阅人</td>-->
<!--                        <td style="width: 18%">成绩</td>-->
<!--                        <td style="width: 18%">操作</td>-->
<!--                        </thead>-->
<!--                        <tr v-for="(item,idx) in peopleData">-->
<!--                            <td>-->
<!--                                {{ item.Id }}-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                {{ item.name }}-->
<!--                            </td>-->
<!--                            <td :style="item.type==='已批阅'?'color: #5587D3':''">-->
<!--                                {{ item.type }}-->
<!--                            </td>-->
<!--                            <td :style="item.teacher!=='/'?'color: #5587D3':''">-->
<!--                                {{ item.teacher }}-->
<!--                            </td>-->
<!--                            <td :style="item.grade==='/'?'':item.grade >= 60 ? 'color: #5587D3':'color: red'">-->
<!--                                {{ item.grade }}-->
<!--                            </td>-->
<!--                            <td style="color: #5587D3">-->
<!--                                <div class="cz">-->
<!--                                    <span @click="showOne=1">批阅</span>-->
<!--                                    <span style="margin-left: 20px" @click="showOne=2">查看</span>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </table>-->
<!--                    <div class="next">-->
<!--                        <div class="number" style="position: relative" @click="add(-1)">-->
<!--                            <img src="../../../assets/Echarts/left.svg">-->
<!--                        </div>-->
<!--                        <div v-for="(item,it) in idx" class="number" :class="num === it ? 'select':'notselect'"-->
<!--                             @click="change(it)">{{ item }}-->
<!--                        </div>-->
<!--                        <div class="number" style="position: relative">-->
<!--                            ...-->
<!--                        </div>-->
<!--                        <div class="number" :class="num === 9 ? 'select':'notselect'" @click="change(9)">{{ 9 }}</div>-->
<!--                        <div class="number" style="position: relative" @click="add(1)">-->
<!--                            <img src="../../../assets/Echarts/right.svg">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->


                <!--客观题-->
                <div v-show="ok===3">
                    <table class="table_a">
                        <thead>
                        <td style="width: 25%">标题</td>
                        <td style="width: 25%">题型</td>
                        <td style="width: 25%">操作</td>
                        </thead>
                        <tr v-for="(item,idx) in subjectiveData">
                            <td>
                                {{ item.title }}
                            </td>
                            <td>
                                {{ item.type }}
                            </td>
                            <td style="color: #5587D3">
                                <div class="cz">
                                    <span @click="viewProblem(item.qId)">查看</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="next">
                        <div class="number" style="position: relative" @click="add(-1)">
                            <img src="../../../assets/Echarts/left.svg">
                        </div>
                        <div v-for="(item,it) in idx" class="number" :class="num === it ? 'select':'notselect'"
                             @click="change(it)">{{ item }}
                        </div>
                        <div class="number" style="position: relative" @click="add(1)">
                            <img src="../../../assets/Echarts/right.svg">
                        </div>
                    </div>
                </div>
            </div>


            <!--批改题目-->
            <div class="body" v-if="showProblem">
                <img src="../../../assets/exam/back.svg" class="back" @click="spf()">
                <div class="title">{{ problemData[problemData.findIndex(findSpecificQidIndex)].title }}({{
                    problemData[problemData.findIndex(findSpecificQidIndex)].type }}，此题满分{{
                    problemData[problemData.findIndex(findSpecificQidIndex)].fullScore }}分)
                </div>
                <div class="problemBody">
                    <div class="problem"><!--title-->
                        {{ problemData[problemData.findIndex(findSpecificQidIndex)]['questionDescribe'] }}
                    </div>
                    <div class="answer"><!--student answer-->
                        学生答案：{{
                        JSON.parse(problemData[problemData.findIndex(findSpecificQidIndex)]['studentAnswers'][seeProblem]['content'])[invProblemQid]
                        }}
                    </div>
                    <div class="grade">
                        得 <input ref="subjGd" type="text"> 分
                    </div>
                </div>
                <div class="message">
                    <textarea ref="mg" placeholder="请在此输入考试评语"></textarea>
                </div>
                <div class="nextP" @click="preP"
                     v-if="seeProblem !== 0"><!--这个按钮仅具有跳到上一位考生作答的功能-->
                    上一个
                </div>

                <div class="nextP"
                     @click="nextP(problemData[problemData.findIndex(findSpecificQidIndex)]['qId'],
                      problemData[problemData.findIndex(findSpecificQidIndex)]['studentAnswers'][seeProblem]['userId'])"
                     v-if="seeProblem !== problemData[problemData.findIndex(findSpecificQidIndex)]['studentAnswers'].length - 1">
                    <!--这个按钮兼具跳到下一位考生的作答和提交上一位考生阅卷结果的功能-->
                    下一个
                </div>
                <div class="nextP" @click="submitInvResult(problemData[problemData.findIndex(findSpecificQidIndex)]['qId'],
                      problemData[problemData.findIndex(findSpecificQidIndex)]['studentAnswers'][seeProblem]['userId'])" v-else>
                    <!--这个按钮兼具提交最后一位考生阅卷结果和提交所有考生阅卷结果的功能-->
                    完成批阅
                </div>

                <div class="isRead">
                    ({{ seeProblem + 1 }}/{{
                    problemData[problemData.findIndex(findSpecificQidIndex)]['studentAnswers'].length }})
                </div>

            </div>
            <div class="body" v-if="showChart">
                <img src="../../../assets/exam/back.svg" class="back" @click="showChart=false">
                <Choose v-if="curViewProblemType === 'm_choose' || curViewProblemType === 's_choose'"
                        :prob="problemList[curViewProblemQid]"
                        :ans="answerList[curViewProblemQid]"></Choose>
                <Blank v-if="curViewProblemType === 'blank'"
                        :prob="problemList[curViewProblemQid]"
                        :ans="answerList[curViewProblemQid]"></Blank>
                <Footer></Footer>
                <Footer></Footer>
<!--                <div class="footer1">上一题</div>-->
<!--                <div class="footer2">下一题</div>-->
            </div>
        </div>


<!--        &lt;!&ndash;按人阅卷->批阅&ndash;&gt;-->
<!--        <div v-show="showOne===1">-->
<!--            <img src="../../../assets/exam/back.svg" class="back" @click="showOne=0">-->
<!--            <div class="title">20192022040101 普奇文</div>-->
<!--            <div class="body" style="position: relative">-->
<!--                <table class="table_a">-->
<!--                    <thead>-->
<!--                    <td style="width: 20%">题号</td>-->
<!--                    <td style="width: 20%">题型</td>-->
<!--                    <td style="width: 20%">状态</td>-->
<!--                    <td style="width: 20%">分数</td>-->
<!--                    <td style="width: 20%">操作</td>-->
<!--                    </thead>-->
<!--                    <tr v-for="(item,idx) in testData">-->
<!--                        <td>-->
<!--                            {{ item.Id }}-->
<!--                        </td>-->
<!--                        <td>-->
<!--                            {{ item.type }}-->
<!--                        </td>-->
<!--                        <td style="color: #5587D3">-->
<!--                            {{ item.read }}-->
<!--                        </td>-->
<!--                        <td style="color: #5587D3">-->
<!--                            {{ item.grade }}-->
<!--                        </td>-->
<!--                        <td style="color: #5587D3">-->
<!--                            <div class="cz">-->
<!--                                <span>查看/修改</span>-->
<!--                            </div>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                </table>-->
<!--                <div class="next">-->
<!--                    <div class="number" style="position: relative" @click="add(-1)">-->
<!--                        <img src="../../../assets/Echarts/left.svg">-->
<!--                    </div>-->
<!--                    <div v-for="(item,it) in idx" class="number" :class="num === it ? 'select':'notselect'"-->
<!--                         @click="change(it)">{{ item }}-->
<!--                    </div>-->
<!--                    <div class="number" style="position: relative">-->
<!--                        ...-->
<!--                    </div>-->
<!--                    <div class="number" :class="num === 6 ? 'select':'notselect'" @click="change(6)">{{ 6 }}</div>-->
<!--                    <div class="number" style="position: relative" @click="add(1)">-->
<!--                        <img src="../../../assets/Echarts/right.svg">-->
<!--                    </div>-->
<!--                </div>-->
<!--                <footer></footer>-->
<!--                <Footer></Footer>-->
<!--                <div class="footer1">上一个</div>-->
<!--                <div class="footer2">下一个</div>-->
<!--            </div>-->
<!--        </div>-->


<!--        &lt;!&ndash;按人阅卷->查看&ndash;&gt;-->
<!--        <div v-if="showOne===2" style="height: 100%">-->
<!--            <img src="../../../assets/exam/back.svg" class="back" @click="showOne=0">-->
<!--            <div class="title">20192022040101 普奇文</div>-->
<!--            <div style="height: calc(100% - 100px)">-->
<!--                <PeopleGrade></PeopleGrade>-->
<!--                <Footer></Footer>-->
<!--                <Footer></Footer>-->
<!--                <div class="footer1">上一个</div>-->
<!--                <div class="footer2">下一个</div>-->
<!--            </div>-->

<!--        </div>-->
    </div>
</template>

<script>
    import SAQuestions from "../../../components/Echarts/SAQuestions";
    import Choose from "../../../components/Echarts/Choose";
    import PeopleGrade from "../../../components/Echarts/PeopleGrade";
    import Footer from "../../../components/common/Footer";
    import Buttons from "../../../components/Buttons";
    import Blank from "../../../components/Echarts/Blank";

    export default {
        name: "MarkPapers",
        components: {Blank, Footer, PeopleGrade, Choose, SAQuestions, Buttons},
        data() {
            let idx = [1, 2, 3];
            return {


                invProblemType: '',  // 目前批阅的主观题题型
                invProblemQid: '',  // 目前批阅的主观题qId

              curViewProblemQid: '', // TODO: 目前查看的题目id
              curViewProblemType: '', // 目前查看的题目类型
              problemList: {},
              answerList: {},
              sbok: false,


                idx,
                num: '0',
                ok: 3,
                showOne: 0,
                showProblem: false,
                showChart: false,
                seeProblem: 0,
                okTime: false,
                okExp: false,

                problemData: [  // "主观题"tab下的列表List
                    // {
                    //     qId: "4b00d97f-659f-4f79-b937-d3bc443d0b0e",
                    //     title: '1',
                    //     type: '填空题',
                    //     read: 66,
                    //     noRead: 21,
                    //     questionDescribe: '',
                    //     studentAnswers: '[...]',
                    //     typeCode: 'blank',
                    //     fullScore: 25,
                    // },
                ],

                subjectInvResult: {  // 主观题批阅结果
                    //     'qType': 'type',
                    //     'qId': 'uuid',
                    //     'examId': 'int'
                    //     'studentResult': {
                    //         'uId1': 'score',
                    //         'uId2': 'score',
                    //         ...
                    //     }
                },

                peopleData: [
                    {
                        Id: '20192022040101',
                        name: '普奇文',
                        type: '已批阅',
                        teacher: '李兰辰',
                        grade: 83
                    },
                    {
                        Id: '20192022040102',
                        name: '万冷雪',
                        type: '已批阅',
                        teacher: '李兰辰',
                        grade: 59
                    },
                    {
                        Id: '20192022040103',
                        name: '胥永元',
                        type: '已批阅',
                        teacher: '李兰辰',
                        grade: 91
                    },
                    {
                        Id: '20192022040104',
                        name: '许藏',
                        type: '已批阅',
                        teacher: '李兰辰',
                        grade: 65
                    },
                    {
                        Id: '20192022040105',
                        name: '江湛恩',
                        type: '已批阅',
                        teacher: '李兰辰',
                        grade: 66
                    },
                    {
                        Id: '20192022040106',
                        name: '赏巧夏',
                        type: '未批阅',
                        teacher: '/',
                        grade: '/'
                    },
                    {
                        Id: '20192022040107',
                        name: '阚尔丝',
                        type: '未批阅',
                        teacher: '/',
                        grade: '/'
                    },
                    {
                        Id: '20192022040108',
                        name: '冀学',
                        type: '未批阅',
                        teacher: '/',
                        grade: '/'
                    },
                    {
                        Id: '20192022040109',
                        name: '竹骊洁',
                        type: '未批阅',
                        teacher: '/',
                        grade: '/'
                    },
                    {
                        Id: '20192022040110',
                        name: '左端',
                        type: '未批阅',
                        teacher: '/',
                        grade: '/'
                    }

                ],
                subjectiveData: [  // "客观题"tab下的列表List
                    // {
                    //     title: '1',
                    //     type: '单项选择',
                    //     grade: '86'
                    // },
                    // {
                    //     title: '2',
                    //     type: '单项选择',
                    //     grade: '96'
                    // },
                    // {
                    //     title: '3',
                    //     type: '单项选择',
                    //     grade: '80'
                    // },
                    // {
                    //     title: '4',
                    //     type: '单项选择',
                    //     grade: '98'
                    // },
                ],
                testData: [
                    {
                        Id: '1',
                        type: '填空题',
                        read: '未批阅',
                        grade: 3,
                    },
                ]
            }
        },

        created() {

            console.log('examId = ' + this.$route.query.examId);

            // 获取批阅的题目列表
            this.$axios.get('/apiExam/getInv?examId=' + this.$route.query.examId).then(res => {

              // TODO：直接前段统计好了

                /**
                 * 返回的res['data']['msg']的json格式如下：
                 {
                  "answer": [  // 所有学生的答案列表，列表长度等于一个考生中的考生数
                    {
                      "examId": 112,
                      "paperId": 24,
                      "userId": 1,
                      // content对应值是字符串，需要JSON.parse()一下才能变成JSON对象
                      "content": "{\"01d3ca40-685b-4509-83e1-e212afe14aac\": \"bcc31135-ab06-48d8-b844-d2094c4abd78\", \"2a2c96b6-d64d-4c9f-9625-10d61126b95d\": \"哈哈哈\", \"3a8771c4-c159-448c-b63a-1c9e6c39867e\": [\"a153453e-439b-4fec-a8d3-ac29a3c8c8ba\", \"62643c26-3ada-408a-9f74-7cba566f36e1\", \"60cc3f36-52de-4329-bf2c-efa67af151fd\"], \"4b00d97f-659f-4f79-b937-d3bc443d0b0e\": \"哈哈哈\"}"
                    }
                  ],
                  "paper": [  // 试卷对象，便于显示阅卷题目
                    {
                      "qId": "01d3ca40-685b-4509-83e1-e212afe14aac",
                      "data": {
                        "qType": "s_choose",
                        "qPrice": "25",
                        "qTitle": "这道题选A",
                        "isRandom": "",
                        "qOptions": [
                          {
                            "oID": "bcc31135-ab06-48d8-b844-d2094c4abd78",
                            "oTitle": "选项1",
                            "correct": true,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "947938bf-73bf-4da6-a9f5-e5a066bc8dc8",
                            "oTitle": "选项2",
                            "correct": false,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "6c777095-1e32-4610-ba8c-8c6fc75316e1",
                            "oTitle": "3",
                            "correct": false,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "bf92ca81-8889-4b9e-a693-471d26251a19",
                            "oTitle": "4",
                            "correct": false,
                            "oPicPath": "",
                            "oMathLatex": ""
                          }
                        ],
                        "qPicPath": "",
                        "showTitle": "这道题选A",
                        "qMathLatex": ""
                      },
                      "qType": "s_choose"
                    },
                    {
                      "qId": "3a8771c4-c159-448c-b63a-1c9e6c39867e",
                      "data": {
                        "qType": "m_choose",
                        "qPrice": "25",
                        "qTitle": "这道题选BCE",
                        "isRandom": null,
                        "qOptions": [
                          {
                            "oID": "0240ba1d-692e-444a-9ce4-cfd9b2624062",
                            "oTitle": "选项1",
                            "correct": false,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "a153453e-439b-4fec-a8d3-ac29a3c8c8ba",
                            "oTitle": "选项2",
                            "correct": true,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "62643c26-3ada-408a-9f74-7cba566f36e1",
                            "oTitle": "3",
                            "correct": true,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "613deac0-eb49-41b4-9bdb-1fcde77a55b1",
                            "oTitle": "4",
                            "correct": false,
                            "oPicPath": "",
                            "oMathLatex": ""
                          },
                          {
                            "oID": "60cc3f36-52de-4329-bf2c-efa67af151fd",
                            "oTitle": "5",
                            "correct": true,
                            "oPicPath": "",
                            "oMathLatex": ""
                          }
                        ],
                        "qPicPath": "",
                        "showTitle": "这道题选BCE",
                        "qMathLatex": ""
                      },
                      "qType": "m_choose"
                    },
                    {
                      "qId": "2a2c96b6-d64d-4c9f-9625-10d61126b95d",
                      "data": {
                        "qType": "blank",
                        "qPrice": "25",
                        "qTitle": "这道题填“哈哈哈”，有标准答案______",
                        "correct": "哈哈哈",
                        "qPicPath": "",
                        "showTitle": "哈哈哈",
                        "qMathLatex": ""
                      },
                      "qType": "blank"
                    },
                    {
                      "qId": "4b00d97f-659f-4f79-b937-d3bc443d0b0e",
                      "data": {
                        "qType": "blank",
                        "qPrice": "25",
                        "qTitle": "这道题填“哈哈哈”，没有标准答案______",
                        "correct": "",
                        "qPicPath": "",
                        "showTitle": "哈哈哈",
                        "qMathLatex": ""
                      },
                      "qType": "blank"
                    }
                  ],
                  "std": {  // 标准答案
                    "01d3ca40-685b-4509-83e1-e212afe14aac": {
                      "oid": [
                        "bcc31135-ab06-48d8-b844-d2094c4abd78"
                      ],
                      "qType": "s_choose",
                      "score": 25
                    },
                    "2a2c96b6-d64d-4c9f-9625-10d61126b95d": {
                      "oid": "哈哈哈",
                      "qType": "blank",
                      "score": 25
                    },
                    "3a8771c4-c159-448c-b63a-1c9e6c39867e": {
                      "oid": [
                        "a153453e-439b-4fec-a8d3-ac29a3c8c8ba",
                        "62643c26-3ada-408a-9f74-7cba566f36e1",
                        "60cc3f36-52de-4329-bf2c-efa67af151fd"
                      ],
                      "qType": "m_choose",
                      "score": 25
                    },
                    "4b00d97f-659f-4f79-b937-d3bc443d0b0e": {
                      "oid": "",
                      "qType": "blank",
                      "score": 25
                    }
                  }
                 }
                 */

                console.log(res);
                let originPaper = JSON.parse(res['data']['msg'])['paper'];
                let originAnswer = JSON.parse(res['data']['msg'])['answer'];
                this.processPaper(originPaper);
                this.processAnswer(originAnswer);
                originPaper.forEach((item, index) => {

                    let typeZhCN = () => {
                        switch (item['data']['qType']) {
                            case 'blank':
                                return '填空题';
                            case 's_choose':
                                return '单选题';
                            case 'm_choose':
                                return '多选题';
                        }
                    };

                    // 主观题
                    if (item['data']['qType'] === 'blank' && item['data']['correct'] === '') {

                        this.$data.problemData.push({
                            'qId': item['qId'],
                            'title': item['data']['showTitle'],
                            'type': typeZhCN(),
                            'typeCode': item['data']['qType'],
                            read: 0,
                            noRead: 1,
                            questionDescribe: item['data']['qTitle'],
                            studentAnswers: JSON.parse(res['data']['msg'])['answer'],
                            fullScore: JSON.parse(res['data']['msg'])['std'][item['qId']]['score'],
                        });
                    }

                    // 客观题
                    if ((item['data']['qType'] === 'blank' && item['data']['correct'] !== '') ||
                        item['data']['qType'] === 's_choose' || item['data']['qType'] === 'm_choose') {

                        this.$data.subjectiveData.push({
                            'title': item['data']['qTitle'].length >= 8 ? item['data']['qTitle'].substring(0, 8) + '...' : item['data']['qTitle'],
                            'type': typeZhCN(),
                            'read': '未批阅',
                            'grade': 5,
                            'qId': item['qId']
                        });
                    }
                });

                console.log('problemData start:');
                console.log(this.$data.problemData);
                console.log('problemData end:');

                console.log('subjectData start:');
                console.log(this.$data.subjectiveData);
                console.log('subjectData end:');
            }).catch(err => {
                console.log(err);
            });
        },

        methods: {
          processPaper(paper) {
            for (let i in paper) {
              this.problemList[paper[i].qId] = {
                qType: paper[i].qType,
                data: paper[i].data
              };
            }
          },

          processAnswer(ans) {
            /*
            "answer": [  // 所有学生的答案列表，列表长度等于一个考生中的考生数
                    {
                      "examId": 112,
                      "paperId": 24,
                      "userId": 1,
                      // content对应值是字符串，需要JSON.parse()一下才能变成JSON对象
                      "content": "{\"01d3ca40-685b-4509-83e1-e212afe14aac\": \"bcc31135-ab06-48d8-b844-d2094c4abd78\", \"2a2c96b6-d64d-4c9f-9625-10d61126b95d\": \"哈哈哈\", \"3a8771c4-c159-448c-b63a-1c9e6c39867e\": [\"a153453e-439b-4fec-a8d3-ac29a3c8c8ba\", \"62643c26-3ada-408a-9f74-7cba566f36e1\", \"60cc3f36-52de-4329-bf2c-efa67af151fd\"], \"4b00d97f-659f-4f79-b937-d3bc443d0b0e\": \"哈哈哈\"}"
                    }
                  ],
             */
            for (let i in ans) {
              let content = JSON.parse(ans[i].content);
              for (let j in content) {
                if (!(j in this.answerList)) this.answerList[j] = []
                this.answerList[j].push(content[j])
              }
            }
          },

          viewProblem(qId) {
            this.curViewProblemQid = qId
            // console.log(this.problemList[qId])
            // console.log(this.answerList[qId])
            this.curViewProblemType = this.problemList[qId].qType
            this.showChart = true
          },

            findSpecificQidIndex(item, index, arr) {
                // 查找qId相同的item
                return item['qId'] === this.$data.invProblemQid;
            },


            switchTabs(f) {
                this.spf();
                this.$set(this.$data, "showChart", false);
                this.$set(this.$data, "ok", f);
            },
            change(it) {
                this.num = it;
            },
            add(it) {
                this.num += it;
                this.num = Math.max(this.num, 0);
                this.num = Math.min(this.num, 2);
            },
            seeP(type, qId) {
                /**
                 * 显示要批阅所有考生的某个题型
                 */
                this.$data.invProblemType = type;
                this.$data.invProblemQid = qId;

                // 先对subjectInvResult进行预处理
                this.$set(this.$data.subjectInvResult, 'qType', type);
                this.$set(this.$data.subjectInvResult, 'qId', qId);
                this.$set(this.$data.subjectInvResult, 'studentResult', {});
                this.$set(this.$data.subjectInvResult, 'examId', this.$route.query.examId);

                this.$data.showProblem = true;
                this.sbok = true;
            },

          chartP(type, qId) {
            this.$data.invProblemType = type;
            this.$data.invProblemQid = qId;
            this.$data.showChart = true;
          },

            spf() {
                /**
                 * 退出阅卷界面
                 */
                this.$set(this.$data, "showProblem", false);
            },
            preP() {
                // 清空input
                this.$refs.subjGd.value = '';
                if (this.$data.seeProblem > 0) this.$data.seeProblem--;
            },
            nextP(qId, uId) {
                // 先提交此位考生的阅卷结果至汇总json
                this.$set(this.$data.subjectInvResult['studentResult'], 'studentID-' + uId, this.$refs.subjGd.value);

                console.log('此时subjectInvResult start:');
                console.log(this.$data.subjectInvResult);
                console.log('此时subjectInvResult end:');

                // 清空input
                this.$refs.subjGd.value = '';

                // 然后跳到下一位考生的阅卷界面
                if (this.$data.seeProblem < this.$data.problemData.length) this.$data.seeProblem++;
            },
            submitInvResult(qId, uId) {
                /**
                 * 提交特定题目类型的主观题批阅结果
                 */
                // 先提交最后一位考生的阅卷结果至汇总json
                this.$set(this.$data.subjectInvResult['studentResult'], 'studentID-' + uId, this.$refs.subjGd.value);

                console.log('此时subjectInvResult start:');
                console.log(this.$data.subjectInvResult);
                console.log('此时subjectInvResult end:');

                // 然后提交所有的考生阅卷结果至后端
                this.$axios.post('/apiExam/postInvResult', this.$data.subjectInvResult).then(res => {
                    this.$toasted.success('提交批改分数成功！', {
                        iconPack: 'custom-class',
                        icon: 'fa fa-check-circle-o',
                        theme: 'bubble',
                        position:'top-center'
                    }).goAway(2500);
                }).catch(err => {
                    console.log(err);
                });

                // 最后退出阅卷界面
                this.$set(this.$data, "showProblem", false);
            }
        }
    }
</script>

<style scoped>
    #markPapers {
        position: relative;
        overflow: auto;
        width: 100%;
        height: 100%;
    }

    .header {
        top: 0;
        width: 100%;
        height: 55px;
        border-bottom: 1px solid #e9e9e9;
    }

    .card-options {
        user-select: none;
        float: left;
        margin-left: 50px;
        margin-top: 20px;
        color: #999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-options:hover {
        cursor: pointer;
    }

    .option-underline {
        height: 4px;
        width: 135%;
        background-color: #054FBF;
        margin-top: 10px;
    }

    .body {
        height: calc(100% - 56px);
        overflow: auto;
        width: 100%;
    }

    .table_a {
        margin-top: 10px;
        text-align: center;
        width: calc(96%);
        margin-left: 2%;
    }

    table {
        letter-spacing: 1px;
        border-collapse: collapse;
    }

    table, th, tr, td {
        border-bottom: 1px solid #dedede; /* 表格横线 */
    }

    thead > td {
        height: 55px;
        line-height: 55px;
        font-weight: 600;
        font-size: 16px;
        border-bottom: 2px solid #054FBF;
    }

    tr > td {
        height: 43px;
        line-height: 43px;
        font-weight: 400;
        font-size: 15px;
    }

    .cz > span:hover {
        cursor: pointer;
        color: rgba(5, 79, 191, 2);
    }

    .next {
        padding-top: 28px;
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .number {
        border-radius: 3px;
        float: left;
        height: 28px;
        width: 30px;
        line-height: 28px;
        font-size: 14px;
        background: rgb(244, 244, 245);
        margin-left: 15px;
    }

    .number > img {
        position: absolute;
        float: left;
        width: 18px;
        top: 5px;
        left: 6px;
    }

    .number:hover {
        cursor: pointer;
    }

    .select {
        background: rgb(64, 158, 255);
        color: white;
    }

    .notselect:hover {
        color: rgb(64, 158, 255);
    }

    .back {
        width: 65px;
        position: absolute;
        margin: 25px 0 0 40px;
    }

    .back:hover {
        cursor: pointer;
    }

    .title {
        width: 100%;
        text-align: center;
        height: 80px;
        line-height: 80px;
        font-weight: 500;
        font-size: 24px;
    }

    .problemBody {
        color: #3a3a3a;
        width: 94%;
        margin-left: 3%;
        overflow: auto;
        border-bottom: 1px solid #e9e9e9;
    }

    .problem {
        font-weight: 300;
        font-size: 19px;
        margin: 30px 0 40px 28px;
    }

    .problem1 {
        font-weight: 300;
        font-size: 17px;
        margin: 13px 0 0 57px;
    }

    .answer {
        font-weight: 300;
        font-size: 17px;
        margin: 30px 0 38px 28px;
    }

    .message {
        margin-top: 39px;
        width: 90%;
        margin-left: 5%;
        overflow: auto;
    }

    .message > textarea {
        text-indent: 6px;
        font-size: 16px;
        font-weight: 300;
        resize: none;
        width: 99%;
        height: 130px;
        outline-style: none;
    }

    .grade {
        margin-top: -38px;
        /*background-color: black;*/
        width: 130px;
        height: 53px;
        float: right;
        font-weight: 300;
        font-size: 20px;
    }

    .grade > input {
        outline-style: none;
        text-indent: 5px;
        font-size: 20px;
        font-weight: 300;
        height: 30px;
        width: 38px;
    }

    .nextP {
        border-radius: 10px;
        margin-top: 39px;
        font-size: 19px;
        font-weight: 300;
        line-height: 50px;
        text-align: center;
        height: 50px;
        width: 160px;
        color: white;
        background: #4C8FF3;
        margin-left: calc(50% - 80px);
    }

    .nextP:hover {
        cursor: Pointer;
        background: rgb(84, 150, 255);
    }

    .isRead {
        margin-top: 13px;
        font-size: 19px;
        font-weight: 300;
        text-align: center;
        width: 160px;
        color: #777777;
        margin-left: calc(50% - 80px);
    }

    .footer1 {
        color: #666666;
        font-size: 18px;
        float: left;
        margin-bottom: 20px;
        margin-left: 45px;
        width: 100px;
        height: 20px;
    }

    .footer2 {
        color: #0547AC;
        font-size: 18px;
        float: right;
        margin-right: 45px;
        width: 100px;
        height: 20px;
    }

    .footer2:hover {
        cursor: pointer;
    }


    .button1 {
        font-size: 14px;
        font-weight: 100;
        line-height: 28px;
        text-align: center;
        color: white;
        border-radius: 15px;
        margin-top: 14px;
        margin-right: 18px;
        float: right;
        height: 28px;
        width: 92px;
        background: #054FBF;
        cursor: pointer;
    }


    /*modal*/
    .Pop-ups {
        z-index: 251;
        overflow: auto;
        width: 590px;
        /*min-height: 310px;*/
        border-radius: 10px;
        background-color: #ffffff;
        position: fixed;
        top: 25%;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .screen {
        z-index: 250;
        display: block;
        overflow: auto;
        width: 100%;
        height: calc(100% + 55px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: -55px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .pTitle {
        width: 100%;
        height: 55px;
        border-bottom: #E8EAEC 1px solid;
    }

    .pTitleFont {
        font-weight: bold;
        height: 100%;
        line-height: 55px;
        float: left;
        margin-left: 23px;
        font-size: 19px;
        font-family: "Microsoft Yahei";
    }

    .close {
        float: right;
        width: 15px;
        margin-right: 23px;
        margin-top: 20px;
    }

    .close:hover {
        cursor: pointer;
    }

    .p-body {
        height: calc(100% - 87px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .p-foot {
        width: 100%;
        height: 55px;
        border-top: #E8EAEC 1px solid;
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
    }

    .p-body-content {
        margin-left: 23px;
        margin-top: 6px;
        margin-bottom: 6px;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .p-body-input {
        height: 30px;
        width: 350px;
        border-radius: 5px;
        border: #999999 1px solid;
        display: flex;
        justify-content: center;
    }

    input:focus {
        outline: none;
    }

    /*modal animation*/
    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }


    /*modal*/
    .Pop-ups {
        z-index: 251;
        overflow: auto;
        width: 590px;
        /*min-height: 310px;*/
        border-radius: 10px;
        background-color: #ffffff;
        position: fixed;
        top: 25%;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .screen {
        z-index: 250;
        display: block;
        overflow: auto;
        width: 100%;
        height: calc(100% + 55px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: -55px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .pTitle {
        width: 100%;
        height: 55px;
        border-bottom: #E8EAEC 1px solid;
    }

    .pTitleFont {
        font-weight: bold;
        height: 100%;
        line-height: 55px;
        float: left;
        margin-left: 23px;
        font-size: 19px;
        font-family: "Microsoft Yahei";
    }

    .close {
        float: right;
        width: 15px;
        margin-right: 23px;
        margin-top: 20px;
    }

    .close:hover {
        cursor: pointer;
    }

    .p-body {
        height: calc(100% - 87px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .p-foot {
        width: 100%;
        height: 55px;
        border-top: #E8EAEC 1px solid;
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
    }

    .p-body-content {
        margin-top: 6px;
        margin-bottom: 6px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .p-body-input {
        height: 30px;
        width: 350px;
        border-radius: 5px;
        border: #999999 1px solid;
        display: flex;
        justify-content: center;
    }

    input:focus {
        outline: none;
    }

    /*modal animation*/
    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

    .tooltip {
        margin-top: 36px;
        position: relative;
        display: inline-block;
    }

    a {
        color: #000000;
        text-decoration: none;
    }

    #Pop-ups {
        z-index: 251;
        overflow: auto;
        width: 590px;
        height: 310px;
        border-radius: 10px;
        background-color: #ffffff;
        position: fixed;
        top: 25%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    #screen {
        z-index: 250;
        display: block;
        overflow: auto;
        width: 100%;
        height: calc(100% + 55px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: -55px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    #pTitle {
        width: 100%;
        height: 55px;
        border-bottom: #E8EAEC 1px solid;
    }

    #pTitleFont {
        font-weight: 100;
        height: 100%;
        line-height: 55px;
        float: left;
        margin-left: 23px;
        font-size: 19px;
        font-family: "Microsoft Yahei";
    }

    #close {
        float: right;
        width: 15px;
        margin-right: 23px;
        margin-top: 20px;
    }

    #close:hover {
        cursor: pointer;
    }

    .pBody {
        text-align: center;
        font-weight: 100;
        border-radius: 5px;
        font-size: 21px;
        margin-left: 70px;
        margin-top: 40px;
        float: left;
        width: 190px;
        height: 175px;
        box-shadow: 0px 0px 9px #cccccc;
    }

    .slide-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }

    .tooltip .tooltiptext {

        visibility: hidden;
        width: 250px;
        background-color: #efefef;
        color: #666666;
        /*text-align: center;*/
        padding: 10px 3px 10px 11px;
        border-radius: 6px;
        bottom: 100%;
        left: 50%;
        margin-left: -120px;
        /* position */
        font-size: 16px;
        font-weight: 100;
        position: absolute;
        text-align: left;
        text-indent: 33px;
    }

    /* hover and show tooltip */
    .tooltip:hover .tooltiptext {
        visibility: visible;
        z-index: 253;
    }
</style>
