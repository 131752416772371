import Problem from "../views/exam/Problem";
import ExamManager from "../views/exam/ExamManager";
import ConfigSetting from "../views/exam/manager/ConfigSetting";
import Target from "../views/exam/manager/Target";
import Notice from "../views/exam/manager/Notice";
import Admin from "../views/exam/manager/Admin";
import Invigilation from "../views/exam/manager/Invigilation";
import QuestionBank from "../views/exam/manager/QuestionBank";
import TestPaper from "../views/exam/manager/TestPaper";
import NewPaper from "../views/exam/manager/NewPaper";
import Knowledge from "../views/exam/manager/Knowledge";
import Correct from "../views/exam/manager/MarkPapers"
import GradeView from "../views/exam/manager/LookLLook"
import TestPaperPreview from "../views/exam/manager/tpp/TestPaperPreview";

const manager = [
    {
        path: "/exam/manager",
        redirect: '/exam/manager/config'
    },
    {
        path: '/exam/manager/config',
        component: ConfigSetting
    },
    {
        path: '/exam/manager/target',
        component: Target
    },
    {
        name: 'notice',
        path: '/exam/manager/notice',
        component: Notice,
    },
    {
        name: 'admin',
        path: '/exam/manager/admin',
        component: Admin,
    },
    {
        name: 'invigilation',
        path: '/exam/manager/invigilation',
        component: Invigilation,
    },
    {
        name: 'questionBank',
        path: '/exam/manager/questionBank',
        component: QuestionBank,
    },
    {
        name: 'TestPaper',
        path: '/exam/manager/groupVolume',
        component: TestPaper,
    },
    {
        name: 'newPaper',
        path: '/exam/manager/newPaper',
        component: NewPaper,
    },
    {
        name: 'knowledge',
        path: '/exam/manager/knowledge',
        component: Knowledge,
    },
    {
        name: 'correct',
        path:'/exam/manager/correct',
        component: Correct
    },
    {
        name: 'gradeView',
        path: '/exam/manager/gradeView',
        component: GradeView
    },
    {
        name: 'tpp',
        path: 'tpp',
        component: TestPaperPreview,
    }
]

export default [
    {
        path: '/exam/',
        redirect: '/exam/problem'
    },
    {
        name: 'Problem',
        path: "/exam/problem",
        component: Problem
    },
    {
        path: "/exam/manager",
        component: ExamManager,
        children: manager
    }
]
0
