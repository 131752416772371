<template>
    <div class="single-choose-question">
        <div class="single-choose-question-preview"><!--question preview-->
            <div style="font-weight: bold;">{{ qTitle }}</div>

            <div v-for="(item, index) in questionObject.qOptions"
                 style="display: flex; flex-direction: row; align-items: center;">
                <div><input name="s_choose" type="radio"></div>
                <div>{{ questionObject.qOptions[index].oTitle }}</div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: flex-end;">
                <div style="margin: 0;" @click="showEdit = !showEdit;">
                    <buttons :content="showEdit ? '收起' : '编辑'" btn-color="#D94E4E" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>

                <div v-if="!isExistsQid['exists']" style="margin: 0; margin-left: 5px;" @click="submitQuestion">
                    <buttons content="提交" btn-color="#054FBF" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>
                <div v-else style="margin: 0; margin-left: 5px;" @click="modifyQuestion">
                    <buttons content="修改" btn-color="#054FBF" txt-color="white" :h="30" :w="60"
                             :f-size="12"></buttons>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="single-choose-question-edit" v-show="showEdit"><!--question edit-->
                <div style="display: flex; flex-direction: row; width: 100%;"><!--question title and score-->
                    <div style="flex-grow: 8;" class="txt-area-wrap"><textarea :style="{'height': txtAreaHeight}"
                                                                               ref="txtArea" v-model="qTitle"
                                                                               class="set-txt-area" style="width: 94%;"
                                                                               placeholder="请输入题干内容..."></textarea>
                    </div>
                    <div style="flex-grow: 1;"><input v-model="questionObject.qPrice" class="set-input"
                                                      style="width: 94%;"
                                                      type="number"
                                                      placeholder="请输入该题分值..."></div>
                </div>
                <div
                    style="display: flex; flex-direction: row; width: 100%; margin-top: 10px; padding-bottom: 10px; border-bottom: #707070 1px solid;">
                    <!--insert pic and formula-->
                    <buttons style="border: #707070 1px solid; margin-right: 10px;" content="插入图片" txt-color="#555555"
                             btn-color="white" :h="30" :w="90"></buttons>
                    <buttons style="border: #707070 1px solid;" content="插入公式" txt-color="#555555" btn-color="white"
                             :h="30"
                             :w="90"></buttons>
                </div>

                <div><!--options-->

                    <!--options-->
                    <div v-for="(item, index) in questionObject.qOptions"
                         style="display: flex; flex-direction: row; align-items: center; padding-top: 10px; justify-content: flex-end;">
                        <div style="flex-grow: 4;"><!--option txt content-->
                            <input disabled v-model="questionObject.qOptions[index].oTitle" style="width: 100%;" type="text"
                                   class="set-input">
                        </div>

                        <div style="display: flex; flex-direction: row; margin-left: auto; align-items: center; flex-grow: 1;">
                            <div style="display: flex; flex-direction: row; margin-left: 20px;">
                                <!--set this to correct answer-->
                                <div><input v-model="questionObject.qOptions[index].correct" type="checkbox"></div>
                                <div>设置为正确答案</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Buttons from "../Buttons";
import 'font-awesome/css/font-awesome.min.css'
import calcTextareaHeight from '../../utils/calcTextareaHeight';
import {v4 as uuidv4} from 'uuid';

export default {
    name: "Judge",
    components: {
        Buttons
    },
    props: {
        libId: {
            type: String,
            require: true,
        },
        isExistsQid: {
            type: Object,
            require: true,
            default: {
                'exists': false,
                'content': null,
            }
        }
    },
    data() {
        return {
            txtAreaHeight: '25px',

            qTitle: '题干',
            creator: '',
            showEdit: false,
            questionObject: {
                "qType": 'judge',
                "qPicPath": '', // if question title wants to use picture to explain the meaning
                "qMathLatex": '', // if question title wants to use math formula to explain the meaning
                "qOptions": [ // options list
                    {
                        "oTitle": '正确',
                        "oPicPath": '', // if option wants to use picture to explain the meaning
                        "oMathLatex": '', // if option wants to use math formula to explain the meaning
                        "correct": false, // is correct answer?
                    },
                    {
                        "oTitle": '错误',
                        "oPicPath": '',
                        "oMathLatex": '',
                        "correct": false,
                    }
                ],
                // "correct": '', // correct answer
                "isRandom": '', // sort options randomly
            },
        };
    },
    methods: {
        autoSave() {
            /**
             * save the question object to localStorage
             */

            // add question title
            this.$set(this.$data.questionObject, 'qTitle', this.$data.qTitle);

            // add show title
            this.$set(this.$data.questionObject, 'showTitle', this.$data.qTitle.slice(0, 20));

            // localStorage key must be "myQuestion"
            localStorage.setItem("myQuestion-" + this.$props.libId.split('-').join(''), JSON.stringify(this.$data.questionObject));

            // vue-toasted
            this.$toasted.success('已保存！', {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
            }).goAway(3000);
        },

        getHeight() {
            this.txtAreaHeight = calcTextareaHeight(this.$refs.txtArea, 1, null).height;
        },

        submitQuestion() {
            let dateObj = new Date();
            this.autoSave();
            console.log(JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))));
            // 提交题目到数据库
            this.$axios.post('apiQuestion/u/create_question', {
                'qid': uuidv4(),
                'title': this.$data.qTitle.slice(0, 20),
                'tp': '判断题',
                'createtime': dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                'creator': this.$data.creator,
                'creatorid': this.$cookies.get('TOKEN'),
                'belongtoid': this.$props.libId,
                'content': JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))),
            }).then(res => {
                this.$toasted.success('已提交！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                }).goAway(3000);

                localStorage.removeItem("myQuestion-" + this.$props.libId.split('-').join('')); // rm the localstorage

                this.$router.go(-1); // back to the previous page
            }).catch(e => {

            });
        },

        modifyQuestion() {
            let dateObj = new Date();
            this.autoSave();

            // 提交修改的题目到数据库
            this.$axios.post('apiQuestion/u/modify_question?qId=' + this.$props.isExistsQid['content'], {
                'qid': uuidv4(),
                'title': this.$data.showTitle,
                'createtime': dateObj.getFullYear() + "年" + dateObj.getMonth() + "月" + dateObj.getDate() + "日 " + dateObj.getHours() + ":" + dateObj.getMinutes(),
                'creator': this.$data.creator,
                'creatorid': this.$cookies.get('TOKEN'),
                'belongtoid': this.$props.libId,
                'tp': '判断题',
                'content': JSON.parse(localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''))),
            }).then(res => {
                this.$toasted.success('已提交修改！', {
                    iconPack: 'custom-class',
                    icon: 'fa fa-check-circle-o',
                    theme: 'bubble',
                }).goAway(3000);

                localStorage.removeItem("myQuestion-" + this.$props.libId.split('-').join('')); // rm the localstorage

                this.$router.go(-1); // back to the previous page
            }).catch(e => {

            });
        },
    },
    watch: {
        qTitle() {
            this.getHeight();
        }
    },
    mounted() {
        /**
         * save the question object to localStorage every minute
         * @type {number}
         */
        this.timer = setInterval(this.autoSave, 1000 * 60);
    },
    created() {
        this.$axios.get('apiQuestion/u/fetch_info').then(res => {
            this.$data.creator = res['data']['msg'];
        }).catch(e => {
            console.log(e);
        });

        let qs = localStorage.getItem("myQuestion-" + this.$props.libId.split('-').join(''));
        if (JSON.parse(qs) && JSON.parse(qs)['qTitle']) { // if you choose a question type or save the question
            this.$data.questionObject = JSON.parse(qs);
            this.$set(this.$data.questionObject, 'action', '');

            this.$data.qTitle = JSON.parse(qs)['qTitle'];
            this.$data.qAnswer = JSON.parse(qs)['correct'];
            this.$data.showEdit = true;
            console.log(this.$data.questionObject);
        } else {

        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.single-choose-question {
    display: flex;
    flex-direction: column;
}

.single-choose-question-preview {
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 14px;
    padding: 20px;
}

.single-choose-question-edit {
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 14px;
    margin-top: 20px;
    padding: 20px;
}

.set-input {
    height: 35px;
    border-radius: 10px;
    text-indent: 10px;
    font-size: 18px;
    border: #707070 1px solid;
}

.txt-area-wrap .set-txt-area {
    border-radius: 10px;
    resize: none;
    display: inline-block;
    font-size: 18px;
    border: #707070 1px solid;
    padding: 6px;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}


/*animation*/
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
