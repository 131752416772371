<template>
    <div id="backstageCenter">
        <BackstageHeader></BackstageHeader>
        <BackstageSideBar></BackstageSideBar>
        <div id="body">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
import BackstageHeader from "../../components/Header/BackstageHeader";
import BackstageSideBar from "../../components/SideBar/BackstageSideBar";
export default {
    name: "BackstageCenter",
    components: {BackstageSideBar, BackstageHeader}
}
</script>

<style scoped>
    #backstageCenter {
        width: 100%;
        height: 100%;
        min-width: 820px;
    }
    #body {
        width: calc(100% - 213px);
        height: calc(100% - 87px);
        margin-left: 16px;
        margin-top: 16px;
        float: left;
        border-radius: 15px;
    }
</style>
