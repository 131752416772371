<template>
    <div class="arrange">
        <div class="title">
            近期考试安排
        </div>
        <div class="body">
            <ul>
                <li class="content_title">
                    <div class="content_t">学院</div>
                    <div class="content_t">考试名称</div>
                    <div class="content_t">日期</div>
                </li>
                <li v-for="(item,it) in list" :class="it&1?'even':''" class="st">
                    <div class="content">{{item.college}}</div>
                    <div class="content">{{item.name}}</div>
                    <div class="content">{{item.time}}</div>
                </li>
            </ul>
            <div class="next">
                <div class="number" style="position: relative" @click="add(-1)">
                    <img src="../../assets/Echarts/left.svg">
                </div>
                <div v-for="(item,it) in idx" class="number" :class="num == it ? 'select':'notselect'" @click="change(it)">{{item}}</div>
                <div class="number" style="position: relative" @click="add(1)">
                    <img src="../../assets/Echarts/right.svg">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Arrange",
    data() {
        let idx= [1,2,3];
        return {
            idx,
            num:0,
            list:[
                {
                    college:'电子信息学院',
                    name:'操作系统概念期末考试',
                    time:'2021-07-01 09:30:00'
                },
                {
                    college:'计算机学院',
                    name:'数据结构期末考',
                    time:'2021-07-01 13:30:00'
                },
                {
                    college:'数学学院',
                    name:'高等数学期末考',
                    time:'2021-07-02 09:30:00'
                },
                {
                    college:'政治学院',
                    name:'形势与政策春',
                    time:'2021-07-02 13:30:00'
                },
                {
                    college:'数学学院',
                    name:'离散数学期末考',
                    time:'2021-07-04 09:30:00'
                },
                {
                    college:'政治学院',
                    name:'中国近现代史纲要',
                    time:'2021-07-05 13:30:00'
                },
                {
                    college:'计算机学院',
                    name:'计算机图形学期末考',
                    time:'2021-07-06 09:30:00'
                },
                {
                    college:'计算机学院',
                    name:'计算机网络期末考',
                    time:'2021-07-06 13:30:00'
                },
                {
                    college:'计算机学院',
                    name:'Java应用开发A期末考',
                    time:'2021-07-06 09:30:00'
                }
            ]
        }
    },
    methods:{
        change(it) {
            this.num = it;
        },
        add(it) {
            this.num += it;
            this.num = Math.max(this.num,0);
            this.num = Math.min(this.num,2);
        }
    }
}
</script>

<style scoped>
    .arrange{
        width: 100%;
        background: white;
        border-radius: 15px;
        height: calc(100% - 249px);
        margin-top: 15px;
    }
    .title {
        padding: 12px 0 0 18px;
        font-size: 22px;
        font-weight: 600;
    }
    .body {
        margin-top: 12px;
        width: 100%;
        height: calc(100% - 80px);
        overflow: auto;
    }
    li {
        margin-left: -20px;
        width: 100%;
        height: 38px;
        list-style: none;
        color: black;
        font-weight: 100;
        border-bottom:1px solid rgba(84,112,198,0.5);
    }
    .content_title {

        border-top:1px solid rgba(84,112,198,0.5);
        color: rgb(84,112,198);
    }
    .content_t {
        width: 33%;
        float: left;
        height: 100%;
        line-height: 38px;
        text-align: left;
        text-indent: 21px;
        font-size: 16px;
    }
    .content {
        width: 33%;
        float: left;
        height: 100%;
        line-height: 38px;
        text-align: left;
        text-indent: 21px;
        font-size: 14px;
    }
    .even {
        background: rgba(84,112,198,0.06);
    }
    .st {
        color: rgb(84,112,198);
    }
    .st:hover {
        cursor: pointer;
        background: rgb(84,112,198);
        color: white;
    }
    .next {
        padding-top: 8px;
        display:flex;
        width: 100%;
        text-align: center;
        justify-content:center;
    }

    .number {
        border-radius: 3px;
        float: left;
        height: 28px;
        width: 30px;
        line-height: 28px;
        font-size: 14px;
        background: rgb(244,244,245);
        margin-left: 10px;
    }
    .number > img {
        position: absolute;
        float: left;
        width: 18px;
        top: 5px;
        left: 6px;
    }
    .number:hover{
        cursor: pointer;
    }
    .select {
        background: rgb(64,158,255);
        color: white;
    }
    .notselect:hover{
        color: rgb(64,158,255);
    }

    /*滚动条样式*/
    ::-webkit-scrollbar {
        width:3px;
    }

    ::-webkit-scrollbar-track-piece {
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:#dddddd;
        background-clip:padding-box;
        min-height:28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#bbb;
    }
</style>
