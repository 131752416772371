<template>
    <div id="account">
        <div class="header">
            <div class="card-title">考试</div>
            <div class="card-options" @click="switchTabs(0);">
                <div :style="isShow[0] ? 'color: #054FBF;' : ''">管理已有账号</div>
                <div :style="isShow[0] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(1);">
                <div :style="isShow[1] ? 'color: #054FBF;' : ''">账号批量生成</div>
                <div :style="isShow[1] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <transition name="slide-fade">
                <div v-show="isShow[0]" class="sc"><Search :name="'搜索账号'"></Search></div>
            </transition>
        </div>
        <div class="body">
            <AccountManger v-show="isShow[0]"></AccountManger>
        </div>
    </div>
</template>

<script>

import Search from "../../components/common/Search";
import AccountManger from "../../components/Backstage/AccountManger";
export default {
    name: "Account",
    components: {AccountManger, Search},
    data() {
        return {
            isShow: [true, false], // Array elements from left to right represent the order of options from left to right respectively
            ok: false, // control the modal
        }
    },
    methods:{
        switchTabs(position) {
            this.$set(this.$data.isShow, position, true);
            this.$set(this.$data.isShow, position^1, false);
        },
    }
}
</script>

<style scoped>
    #account {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 15px;
    }

    .header {
        top: 0;
        width: 100%;
        height: 55px;
        border-bottom: 1px solid #e9e9e9;
        /*border-radius: 15px 15px 0 0;*/
    }
    .sc{
        float: right;
        width: 157px;
        margin-top: 13.5px;
        margin-right: 25px;
    }


    .card-title {
        user-select: none;
        float: left;
        margin-top: 12px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 1.5em;
    }
    .card-options {
        user-select: none;
        float: left;
        margin-left: 50px;
        margin-top: 20px;
        color: #999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*font-weight: 500;*/
        /*font-size: 16px;*/
    }

    .card-options:hover {
        cursor: pointer;
    }

    .option-underline {
        height: 4px;
        width: 110%;
        background-color: #054FBF;
        margin-top: 10px;
    }

    .body {
        width: 100%;
        height: calc(100% - 55px);
    }


    .slide-fade-enter-active {
        transition: opacity 0.2s;
    }
    .slide-fade-leave-active {
        transition: opacity 0.2s;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        opacity: 0;
    }
</style>
