<template>
    <div id="functionCenter">
      <MainHeader></MainHeader><!--navbar-->
      <CreateExamSideBar v-if="$route.name === 'CreateExam'"></CreateExamSideBar>
      <MainSideBar v-else></MainSideBar><!--sidebar-->
      <div id="body">
        <router-view></router-view>
      </div>
    </div>
</template>

<script>
import MainHeader from "../../components/Header/MainHeader";
import MainSideBar from "../../components/SideBar/MainSideBar";
import BasalCard from "../../components/Card/BasalCard";
import CourseJoinCard from "../../components/Card/CourseJoinCard";
import CourseManageCard from "../../components/Card/CourseManageCard";
import QuestionBankCard from "../../components/Card/QuestionBankCard";
import ScoreCard from "../../components/Card/ScoreCard";
import GroupCard from "../../components/Card/GroupCard";
import CreateExamSideBar from "../../components/SideBar/CreateExamSideBar";

export default {
    name: 'FunctionCenter',
    components: {
      CreateExamSideBar,
        GroupCard,
        ScoreCard,
        QuestionBankCard,
        CourseManageCard,
        CourseJoinCard,
        BasalCard,
        MainHeader,
        MainSideBar
    },
    methods: {},
    data() {
      return {
        showClassOne: false,
      };
    }
}
</script>

<style scoped>
#functionCenter {
    height: 100%;
    width: 100%;
    min-width: 1050px;
}

#body {
    overflow: auto;
    margin-top: 16px;
    margin-left: 16px;
    float: left;
    width: calc(100% - 308px);
    height: calc(100% - 87px);
    background: white;
    border-radius: 15px;
}
</style>
