<template>
    <div class="b"
         :style="'width: ' + w + 'px; height: ' + h + 'px; /*margin: 0 auto*/; color: ' + txtColor + '; background-color: ' + btnColor + '; border-radius: ' + (tp === 'super_rounded_rectangle' ? 50 : 7) + 'px;'">
        <div :style="'font-size: ' + fSize + 'px;'">{{ content }}</div>
    </div>
</template>

<script>
export default {
    name: "Buttons",
    props: { // parameters definition
        tp: { // customize button types, include "default" and "super_rounded_rectangle"
            type: String,
            default: "default"
        },
        btnColor: String, // customize button bg colors
        txtColor: String, // customize button txt color
        content: String, // customize button txt content
        h: { // customize button height, default 48px
            type: Number,
            default: 48,
        },
        w: { // customize button width, default 96px
            type: Number,
            default: 96,
        },
        fSize: { // customize button txt size, default 16px
            type: Number,
            default: 16,
        }
    }
}
</script>
<style scoped>
.b {
    display: flex;
    justify-content: center;
    align-items: center;
}

.b:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}
</style>
