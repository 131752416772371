<template>
    <div id="examNow">
        <div class = head>
            <div class="title">正在进行的考试</div>
            <div class="day_time">
                <p class="day">{{day}}&nbsp;&nbsp;{{week}}</p>
                <p class="time">{{time}}</p>
            </div>
        </div>
        <table>
            <thead>
                <td style="width: 20%">考试名称</td>
                <td style="width: 15%">创建人</td>
                <td style="width: 25%">考试开始时间</td>
                <td style="width: 25%">考试结束时间</td>
                <td style="width: 15%">管理</td>
            </thead>
            <tr v-for="(item,it) in list">
                <td style="color: #054FBF">{{item.name}}</td>
                <td>{{item.author}}</td>
                <td>{{item.startTime}}</td>
                <td>{{item.endTime}}</td>
                <td><router-link to="#">查看详情</router-link></td>
            </tr>

        </table>
        <div class="next">

            <div class="number" style="position: relative" @click="add(-1)">
                <img src="../../assets/Echarts/left.svg">
            </div>
            <div v-for="(item,it) in idx" class="number" :class="num == it ? 'select':'notselect'" @click="change(it)">{{item}}</div>
            <div class="number" style="position: relative" @click="add(1)">
                <img src="../../assets/Echarts/right.svg">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExamNow",
    data() {
        let idx= [1,2,3];
        return {
            idx,
            num:0,
            week:null,
            day:null,
            time:null,
            list:[
                {
                    name:'数字图像处理提前考',
                    author:'李明',
                    startTime:'2022-02-28 18:00:00',
                    endTime:'2022-02-28 20:00:00',
                },
                {
                    name:'数据库提前考',
                    author:'李兰辰',
                    startTime:'2022-02-28 18:00:00',
                    endTime:'2022-02-28 20:00:00',
                },
                {
                    name:'软件工程提前考',
                    author:'李兰辰',
                    startTime:'2022-03-01 18:00:00',
                    endTime:'2022-03-01 20:00:00',
                }
            ]
        }
    },
    methods:{
        getTime() {
            let that = this;
            let timeNow = new Date();
            let years = timeNow.getFullYear();
            let months = timeNow.getMonth() + 1;
            months = months < 10 ? '0' + months : months;
            let days = timeNow.getDate();
            days = days < 10 ? '0' + days : days;
            let weeks = timeNow.getDay();
            switch (weeks) {
                case 1:weeks='星期一';break;
                case 2:weeks='星期二';break;
                case 3:weeks='星期三';break;
                case 4:weeks='星期四';break;
                case 5:weeks='星期五';break;
                case 6:weeks='星期六';break;
                case 7:weeks='星期日';break;
            }
            let hours = timeNow.getHours();
            hours = hours < 10 ? '0' + hours : hours;
            let minutes = timeNow.getMinutes();
            minutes = minutes < 10 ? '0' + minutes : minutes;
            let seconds = timeNow.getSeconds();
            seconds  =seconds < 10 ? '0' + seconds : seconds;
            that.$set(that.$data,'day',years+'/'+months+'/'+days);
            that.$set(that.$data,'week',weeks);
            that.$set(that.$data,'time',hours+':'+minutes+':'+seconds);
            setInterval(function (){
                let timeNow = new Date();
                let years = timeNow.getFullYear();
                let months = timeNow.getMonth() + 1;
                months = months < 10 ? '0' + months : months;
                let days = timeNow.getDate();
                days = days < 10 ? '0' + days : days;
                let weeks = timeNow.getDay();
                switch (weeks) {
                    case 1:weeks='星期一';break;
                    case 2:weeks='星期二';break;
                    case 3:weeks='星期三';break;
                    case 4:weeks='星期四';break;
                    case 5:weeks='星期五';break;
                    case 6:weeks='星期六';break;
                    case 7:weeks='星期日';break;
                }
                let hours = timeNow.getHours();
                hours = hours < 10 ? '0' + hours : hours;
                let minutes = timeNow.getMinutes();
                minutes = minutes < 10 ? '0' + minutes : minutes;
                let seconds = timeNow.getSeconds();
                seconds  =seconds < 10 ? '0' + seconds : seconds;
                that.$set(that.$data,'day',years+'/'+months+'/'+days);
                that.$set(that.$data,'week',weeks);
                that.$set(that.$data,'time',hours+':'+minutes+':'+seconds);
            },1000);
        },
        setTime() {

        },
        change(it) {
            this.num = it;
        },
        add(it) {
            this.num += it;
            this.num = Math.max(this.num,0);
            this.num = Math.min(this.num,2);
        }
    },
    mounted() {
        this.getTime();
    }
}
</script>

<style scoped>
    #examNow {
        overflow: auto;
        margin-top: 15px;
        width: 100%;
        height: calc(100% - 508px);
        background: white;
        border-radius: 15px;
    }
    .head {
        padding-top: 12px;
        overflow: auto;
        width: 100%;
    }
    .title {
        margin: 0px 0 0 18px;
        font-size: 22px;
        font-weight: 600;
        float: left;
    }
    .day_time {
        width: 200px;
        height: 83px;
        float: right;
        margin: 0 18px 0 0;
    }
    .day {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 19px;
    }
    .time {
        margin-top: 10px;
        text-align: center;
        font-size: 21px;
    }
    table,th,tr,td{
        height: 33px;
        border-bottom:1px solid #dedede;/* 表格横线 */
    }
    thead > td{
        height: 33px;
        line-height: 30px;
        font-weight: 600;
        font-size: 16px;
        border-bottom: 2px solid #054FBF;
    }
    tr > td {
        text-align: center;
        font-size: 14px;
        font-weight: 100;
    }
    table{
        /*margin-top: 13px;*/
        width: 90%;
        margin-left: 5%;
        letter-spacing:1px;
        border-collapse:collapse;
    }
    thead {
        text-align: center;
    }
    a {
        width: 100%;
        text-decoration: none;
        color: #054FBF;
        text-align: center;
    }
    .next {
        margin-top: 21px;
        display:flex;
        width: 100%;
        text-align: center;
        justify-content:center;
    }

    .number {
        border-radius: 3px;
        float: left;
        height: 28px;
        width: 30px;
        line-height: 28px;
        font-size: 14px;
        background: rgb(244,244,245);
        margin-left: 10px;
    }
    .number > img {
        position: absolute;
        float: left;
        width: 18px;
        top: 5px;
        left: 6px;
    }
    .number:hover{
        cursor: pointer;
    }
    .select {
        background: rgb(64,158,255);
        color: white;
    }
    .notselect:hover{
        color: rgb(64,158,255);
    }

    /*滚动条样式*/
    ::-webkit-scrollbar {
        width:3px;
    }

    ::-webkit-scrollbar-track-piece {
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:#dddddd;
        background-clip:padding-box;
        min-height:28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#bbb;
    }


</style>
