<template>
    <div id="courseJoinCard">
        <div id="header">
            <b>{{dataCourseJoinCard['title']}}</b>
            <img id="more" src="../../assets/Card/more.svg">
        </div>
        <div id="center">
            <div class="information" style="margin-top: 15px">考试开始时间：{{dataCourseJoinCard['startTime']}}</div>
            <div class="information">考试结束时间：{{dataCourseJoinCard['endTime']}}</div>
            <div class="information">教师：{{dataCourseJoinCard['teacher']}} &nbsp;&nbsp;班级：{{dataCourseJoinCard['class']}}</div>
            <p id="examStatus">课程状态：<b v-bind:class="{start:dataCourseJoinCard['status'] === '进行中' || dataCourseJoinCard['status'] == '已开放',end:dataCourseJoinCard['status'] === '已结束'}">{{dataCourseJoinCard['status']}}</b></p>
            <router-link :to="{path:'/class/classArea',query:{name:dataCourseJoinCard['title']}}"><button>进入</button></router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseJoinCard',
    props:{
        dataCourseJoinCard: {
            type: Object,
            default: function () {
                return {
                    title: "计算机网络",
                    startTime: "2022-02-03 09:45:00",
                    endTime: "2022-02-03 11:45:00",
                    status:"进行中",
                    teacher:'李兰辰',
                    class:'默认班级'
                }
            }
        }
    }
}
</script>

<style scoped>
#courseJoinCard {
    transition: 0.5s all;
    position: relative;
    height: 176px;
    width: 290px;
    float: left;
    margin-top: 45px;
    margin-left: max(40px,calc(25% - 310px));
    border-radius: 15px;
    border: 0px;
    outline:none;
    box-shadow: 0px 0px 15px #DDDDDD;
}

@media screen and (max-width: 1636px) {
    #courseJoinCard {
        margin-left: max(40px,calc(33% - 330px));
    }
}
@media screen and (max-width: 1313px) {
    #courseJoinCard {
        margin-left: max(53px,calc(50% - 340px));
    }
}
@media screen and (max-width: 920px) {
    #courseJoinCard {
        margin-left: max(53px,calc(50% - 145px));
    }
}

#courseJoinCard:hover{
    transform: translateY(-3px);
    box-shadow: 0px 0px 35px #cccccc;
}

#header {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 47px;
    height: 47px;
    width: 100%;
    color: white;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    background: #054FBF;
    border: 0px;
    outline:none;
}
#more {
    /*background: black;*/
    width: 20px;
    position: absolute;
    left: 255px;
    top: 15px;
}
img {
    width: 100%;
}
#center {
    height: 129px;
    width: 100%;
}
#more:hover {
    cursor: pointer;
}
.information {
    margin-top: 4px;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 100;
}
#examStatus {
    margin-top: 10px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 100;
}
.start{
    color: #00A03E;
}
.end {
    color:#DD1E1E;
}
button {
    width: 57px;
    height: 29px;
    position: absolute;
    margin-top: -37px;
    margin-left: 219px;
    border-radius: 5px;
    background: #054FBF;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 100;
}
button:hover {
    cursor: pointer;
    background: #0547AC;
}
a {
    color: white;
    text-decoration:none;
}

</style>
