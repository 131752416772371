import BackstageCenter from "../views/backstage/BackstageCenter";
import Analyze from "../views/backstage/Analyze";
import Account from "../views/backstage/Account";
import Permission from "../views/backstage/Permission";
import ExamManage from "../views/backstage/Exam";
import Examination from "../views/backstage/Examination";
import Score from "../views/backstage/Score";
import Face from "../views/backstage/Face";
import Vue from "vue";
import VueRouter from "vue-router";

// Vue.use(VueRouter)

export default [
    {
        path:'/backstage',
        name: 'backstage',
        component: BackstageCenter,
        children:
        [
            {
                path: '/',
                redirect:'analyze'
            },
            {
                path: 'analyze',
                name: 'analyze',
                component: Analyze
            },
            {
                path: 'account',
                name: 'account',
                component: Account
            },
            {
                path: 'permission',
                name: 'permission',
                component: Permission
            },
            {
                path: 'examManage',
                name: 'examManage',
                component: ExamManage
            },
            {
                path: 'examination',
                name: 'examination',
                component: Examination
            },
            {
                path: 'score',
                name: 'score',
                component: Score
            },
            {
                path: 'face',
                name: 'face',
                component: Face
            }
        ]
    }
]
