<template>
    <div class="list" v-show="showAll">
        <transition name="slide-fade">
            <Alert ref="a" v-show="showAlert" @cancel="cancel()" @confirmDelete="confirmDelete()"></Alert>
        </transition>
        <div class="a" style="width: 18%">{{dataList['ID']}}</div>
        <div class="a" style="width: 18%">{{dataList['studentID']}}</div>
        <div class="a" style="width: 16%">{{dataList['name']}}</div>
        <div class="a" style="width: 16%">{{dataList['grade']}}</div>
        <div class="a" style="width: 16%">{{dataList['classBelong']}}</div>
        <div v-show="!show" class="getMore a" style="width: 16%" @click="show=!show">
            查看更多
            <img class="more" src="../../assets/Backstage/more.svg">
        </div>
        <div v-show="show" class="getMore a" style="width: 16%" @click="show=!show">
            收起
            <img style="transform: rotateX(180deg)" class="more" src="../../assets/Backstage/more.svg">
        </div>
        <transition name="sub-menu">
            <div v-show="show" class="content">
                <img src="../../assets/common/xinfeng.jpg">
                <div class="tb">
                    <table cellpadding="0" cellspacing="0">
                        <tr>
                            <td class="b">
                                手机号
                            </td>
                            <td style="width: 30%">
                                <input :value="dataList['phone']" disabled="disabled">
                            </td>
                            <td class="b">
                                密码
                            </td>
                            <td style="width: 30%; border-right: 1px solid #ebeef5">
                                重置为初始密码
                            </td>

                        </tr>
                        <tr>
                            <td style="border-top: none" class="b">
                                性别
                            </td>
                            <td style="border-top: none">
                                <input :value="dataList['sex']" disabled="disabled">
                            </td>
                            <td class="b">
                                标签
                            </td>
                            <td style=" border-right: 1px solid #ebeef5">
                                {{dataList['label']}}
                            </td>
                        </tr>
                        <tr>
                            <td style="border-top: none" class="b">
                                备注
                            </td>
                            <td style="border-top: none;border-right: 1px solid #ebeef5;" colspan="3" >
                                <input :value="dataList['remark']" disabled="disabled">
                            </td>
                        </tr>
                    </table>
                    <div class="bt">
                        <div class="change">
                            编辑
                        </div>
                        <div class="save">
                            保存
                        </div>
                        <div class="delete" @click="deleteIt()">
                            删除
                        </div>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
import Alert from "../common/Alert";
export default {
    name: "List",
    components: {Alert},
    props:{
        dataList: {
            type:Object,
            default: function () {
                return {
                    ID: '1',
                    studentID:'20191532424001',
                    name:'李弘辰',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'18651303100',
                    sex:'男',
                    label:'/',
                    remark:'/'
                }
            }
        }
    },
    data() {
        return {
            showAlert:false,
            showAll: true,
            show:false
        }
    },
    methods:{
        deleteIt() {
            this.showAlert = true;
            let dom = this.$refs.a;
            let input = dom.$refs.input;
            setTimeout(function (){
                input.focus();
            },300);

        },
        cancel() {
            this.$set(this.$data,'showAlert',false);
        },
        confirmDelete() {
            this.$set(this.$data,'show',false);
            this.$set(this.$data,'showAlert',false);
            let that = this;
            setTimeout(function () {
                that.$set(that.$data,'showAll',false);
            },296)
        }
    }

}
</script>

<style scoped>
    .list {
        width: 100%;
        overflow: auto;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-x: hidden;
        overflow-y: auto;
    }
    .change {
        margin-top: 7px;
        font-size: 16px;
        font-weight: 100;
        text-align: center;
        line-height: 33px;
        width: 88%;
        margin-left: 6%;
        border-radius: 5px;
        height: 33px;
        color: #efefef;
        background: rgb(252,85,49);

    }
    .change:hover,.save:hover,.delete:hover {
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
        cursor: pointer;
    }
    .save {
        margin-top: 13px;
        font-size: 16px;
        font-weight: 100;
        text-align: center;
        line-height: 33px;
        width: 88%;
        margin-left: 6%;
        border-radius: 5px;
        height: 33px;
        color: white;
        background: rgb(30,89,180);

    }
    .delete {
        margin-top: 13px;
        font-size: 16px;
        font-weight: 100;
        text-align: center;
        line-height: 33px;
        width: 88%;
        margin-left: 6%;
        border-radius: 5px;
        height: 33px;
        background: #efefef;
    }
    .change,.save,.delete:hover {
        cursor: pointer;
    }
    .getMore{
        position: relative;
        color: rgb(103,171,243);
    }
    .a{
        height: 40px;
        float: left;
        line-height: 40px;
        text-align: center;
        font-weight: 100;
        font-size: 16px;
        background: rgba(5,71,172,0.1);
    }
    .getMore:hover{
        cursor: pointer;
    }

    .more{
        position: absolute;
        width: 13px;
        top:14px;
        margin-left: 3px;
    }
    .content {
        position: relative;
        margin-top: 40px;
        width: 100%;
        height: 140px;
    }
    .content > img {
        display: block;
        max-height: 140px;
        max-width: 100px;
        position: absolute;
        margin-left: 1px;
        float: left;
    }
    .tb{
        position: absolute;
        width: calc(100% - 101px);
        height: 100%;
        left: 101px;
    }

    table{
        float: left;
        border: none;
        width: calc(100% - 150px);
    }
    td,tr{
        color: #909399;
        text-align: center;
        border: 1px solid #ebeef5;
        margin: 0;
        padding: 0;
        border-right: none;
    }
    .b{
        line-height: 45px;
        height: 45px;
        width: 20%;
        padding: 0;
        font-weight: 100;
        background: rgb(250,250,250);
    }
    td > input {
        outline-style: none;
        text-indent: 18px;
        width: 100%;
        height: 43px;
        border: none;
        font-size: 15px;
        font-weight: 400;
    }
    .bt {
        float: left;
        overflow: auto;
        width: 150px;
    }
    .sub-menu-enter-active,
    .sub-menu-leave-active {
        transition: all 0.3s ease;
        max-height: 140px; /* 这里将最大高度设置为折叠元素不可能超越的高度 比如1000px */
    }
    .sub-menu-enter,
    .sub-menu-leave-to
    {
        opacity: 0;
        max-height: 0px;
    }
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all 0.3s ease;
    }
    .slide-fade-enter,
    .slide-fade-leave-to
    {
        transform: translateY(-100px);
        opacity: 0;
    }
</style>
