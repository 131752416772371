<template>
    <div id="login" @click="cancelPop($event)">
        <div class="background"></div>
        <router-view ref="child"></router-view>
    </div>
</template>

<script>
export default {
    name: "Login",
    methods:{
        cancelPop(event) {
            if(this.$route.name === 'register') this.$refs.child.cancelPop(event);
        }
    }

}
</script>

<style scoped>
    #login{
        width: 100%;
        height: 100%;
    }
    .background {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/Login/background.jpg");
        background-size:cover;
        filter: brightness(96%);
    }
</style>
