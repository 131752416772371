import MyClassArea from "../views/my_class/MyClassArea";
import MyNotice from "../views/my_class/Notice";
import MyExam from "../views/my_class/Exam";
import MyGroup from "../views/my_class/Group";
import MyTask from "../views/my_class/Task";
import MyKnowledge from "../views/my_class/Knowledge";
import MyQuestionBank from "../views/my_class/QuestionBank";
import MySet from "../views/my_class/Set";

export default [
    {
        path: '/myClass/myClassArea',
        name: 'MyClassArea',
        component: MyClassArea,
        children: [
            {
                path: '/',
                redirect: 'notice'
            },
            {
                path:'notice',
                name:'MyNotice',
                component: MyNotice
            },
            {
                path:'exam',
                name:'MyExam',
                component: MyExam
            },
            {
                path:'group',
                name:'MyGroup',
                component: MyGroup
            },
            {
                path:'task',
                name:'MyTask',
                component: MyTask
            },
            {
                path:'knowledge',
                name:'MyKnowledge',
                component: MyKnowledge
            },
            {
                path:'questionBank',
                name:'MyQuestionBank',
                component:MyQuestionBank
            },
            {
                path:'Set',
                name:'MySet',
                component:MySet
            }
        ]
    }
]
