import StuExamFace from "../views/stu_exam_face/StuExamFace";
import Dash from "../views/stu_exam_face/func/Dash";
import Info from "../views/stu_exam_face/func/Info";
import ChatStu from "../views/stu_exam_face/func/ChatStu";

export default [
    {
        name: 'stuExamFace',
        path: '/stuExamFace',
        component: StuExamFace,
        children: [
            {
                name: 'dash',
                path: 'dash',
                component: Dash,
            },
            {
                name: 'info',
                path: 'info',
                component: Info,
            },
            {
                name: 'chat',
                path: 'chat',
                component: ChatStu,
            }
        ]
    }
]
