<template>
    <div id="studentExamCard">
        <div id="header">
            <b>{{dataStudentExamCard['title']}}</b>
            <img id="more" src="../../assets/Card/more.svg">
        </div>
        <div id="center">
            <div class="information" style="margin-top: 10px">考试开始时间：{{dataStudentExamCard['startTime']}}</div>
            <div class="information">考试结束时间：{{dataStudentExamCard['endTime']}}</div>
            <div class="examStatus">考试状态：<b
                    v-bind:class="{start:dataStudentExamCard['status'] === '开放登录'||dataStudentExamCard['status'] === '开放中(已完成)'||dataStudentExamCard['status'] === '开放中',end:dataStudentExamCard['status'] === '已结束'}">{{dataStudentExamCard['status']}}</b>
            </div>
            <div class="examStatus">
                考试类型：<b
                    v-bind:class="{start:dataStudentExamCard['status'] === '开放登录'||dataStudentExamCard['status'] === '开放中(已完成)'||dataStudentExamCard['status'] === '开放中'}">{{dataStudentExamCard['type']}}</b>
            </div>
            <div class="info">{{dataStudentExamCard['info']}}</div>
            <!--            <router-link :to="{path:'#' ,query:{name:dataStudentExamCard['title']}}" v-show="dataStudentExamCard['enter'] !== '进入'"><button>{{dataStudentExamCard['enter']}}</button></router-link>-->
            <router-link
                    :to="{path:'/enter/problem?examId=' + this.dataStudentExamCard.id + '&paperId=' + paperId ,query:{name:dataStudentExamCard['title']}}">
                <button>{{dataStudentExamCard['enter']}}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StudentExamCard',
        props: {
            dataStudentExamCard: {
                type: Object,
                default: function () {
                    return {
                        id: "1",
                        title: "计算机网络",
                        startTime: "2022-02-03 09:45:00",
                        endTime: "2022-02-03 11:45:00",
                        status: "开放登录",
                        type: "双机位考试",
                        info: "诚信考试，请勿作弊",
                        enter: "进入",

                    }
                }
            }
        },
        data() {
            return {
                paperId: 0
            }
        },
        created() {
            let that = this;
            this.$axios.get(
                '/apiInvigilate/getUserPaperId?examId=' + that.dataStudentExamCard.id).then((resp) => {
                console.log(resp);
                that.paperId = resp.data;
                // console.log(resp.data)
            }).catch((err) => {
                console.log("错误");
            })

        }
    }
</script>

<style scoped>
    #studentExamCard {
        transition: 0.5s all;
        position: relative;
        height: 176px;
        width: 290px;
        float: left;
        margin-top: 45px;
        margin-left: max(40px, calc(25% - 310px));
        border-radius: 15px;
        border: 0px;
        outline: none;
        box-shadow: 0px 0px 15px #DDDDDD;
    }

    @media screen and (max-width: 1636px) {
        #studentExamCard {
            margin-left: max(40px, calc(33% - 330px));
        }
    }

    @media screen and (max-width: 1313px) {
        #studentExamCard {
            margin-left: max(53px, calc(50% - 340px));
        }
    }

    @media screen and (max-width: 920px) {
        #studentExamCard {
            margin-left: max(53px, calc(50% - 145px));
        }
    }

    #studentExamCard:hover {
        transform: translateY(-3px);
        box-shadow: 0px 0px 35px #cccccc;
    }

    #header {
        font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
        font-size: 17px;
        line-height: 47px;
        height: 47px;
        width: 100%;
        color: white;
        text-align: center;
        border-radius: 15px 15px 0px 0px;
        background: #054FBF;
        border: 0px;
        outline: none;
    }

    #more {
        /*background: black;*/
        width: 20px;
        position: absolute;
        left: 255px;
        top: 15px;
    }

    img {
        width: 100%;
    }

    #center {
        height: 129px;
        width: 100%;
    }

    #more:hover {
        cursor: pointer;
    }

    .information {
        margin-top: 5px;
        margin-left: 25px;
        font-size: 14px;
        font-weight: 100;
    }

    .examStatus {
        margin-top: 5px;
        margin-left: 16px;
        font-size: 14px;
        font-weight: 100;
    }

    .info {
        position: absolute;
        margin-top: 3px;
        font-size: 13px;
        font-weight: 100;
        color: #DD1E1E;
        margin-left: 16px;
    }

    .start {
        color: #00A03E;
    }

    .end {
        color: #DD1E1E;
    }


    button {
        width: 57px;
        height: 29px;
        position: absolute;
        margin-top: -15px;
        margin-left: 219px;
        border-radius: 5px;
        background: #054FBF;
        border: none;
        color: white;
        font-size: 13px;
        font-weight: 100;
    }

    button:hover {
        cursor: pointer;
        background: #0547AC;
    }

    a {
        color: white;
        text-decoration: none;
    }
</style>

