<template>
    <div id="accountManager">
        <div class="select">
            <div class="type">
                <div class="title">账号类型&nbsp;&nbsp;&nbsp;&nbsp; :</div>
                <ul class="f_ul">
                    <li class="f_li" v-for="(item,it) in typeName.slice(0,12)" :class="item.show?'selected':'notSelected'" @click="selectType(it)">
                        &nbsp;&nbsp;&nbsp;{{item.name}}&nbsp;&nbsp;&nbsp;
                    </li>
                </ul>
            </div>
            <div class="college">
                <div class="title">学院&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
                <ul class="f_ul">
                    <li class="f_li" v-for="(item,it) in collegeName.slice(0,12)" :class="item.show?'selected':'notSelected'" @click="selectCollege(it)">
                        &nbsp;&nbsp;&nbsp;{{item.name}}&nbsp;&nbsp;&nbsp;
                    </li>
                    <li class="f_li" v-show="ok && it >= 12" v-for="(item,it) in collegeName" :class="item.show?'selected':'notSelected'" @click="selectCollege(it)">
                        &nbsp;&nbsp;&nbsp;{{item.name}}&nbsp;&nbsp;&nbsp;
                    </li>
                    <div v-show="!ok" class="more" @click="ok=!ok">
                        <div>更多</div>
                        <img src="../../assets/Backstage/more.svg">
                    </div>
                    <div v-show="ok" class="more" @click="ok=!ok">
                        <div>收起</div>
                        <img style="transform: rotateX(180deg);" src="../../assets/Backstage/more.svg">
                    </div>
                </ul>
            </div>
            <div class="class">
                <div class="title">年级与班级 &nbsp;:</div>
                <div class="choose" @mouseenter="showGrade=true" @mouseleave="showGrade=false">
                    <div id="a">请选择年级与班级</div>
                    <img src="../../assets/Backstage/more.svg">
                </div>
                <div v-show="showGrade" class="hint" @mouseenter="showGrade=true;" @mouseleave="showGrade=false;">
                    <ul class="s_ul">
                        <li class="s_li" v-for="(item,it) in gradeName"  @mouseenter="moseOver(it)" @mouseleave="moseLeave(it)">{{item}}</li>
                    </ul>
                </div>
                <div v-show="item1" v-for="(item1,it) in showClass" class="hint1" @mouseenter="moseOver(it);showGrade=true;" @mouseleave="moseLeave(it);showGrade=false;">
                    <ul class="s_ul">
                        <li @click="selectClass(it,item)" class="s_li" v-for="(item,i) in className[it]">{{item}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="head">
                <div style="width: 18%">ID</div>
                <div style="width: 18%">学号</div>
                <div style="width: 16%">姓名</div>
                <div style="width: 16%">年级</div>
                <div style="width: 16%">班级</div>
                <div style="width: 16%">操作</div>
            </div>
            <List v-for="(item,it) in studentInformation" :data-list="item"></List>

        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import collegeName from "../../assets/Backstage/collegeName.json";
import List from "./List";
import Footer from "../common/Footer";
export default {
    name: "AccountManger",
    components: {Footer, List},
    data() {
        return {
            showGrade:false,
            showClass:[false,false,false,false,false,false,false],
            ok:false,
            collegeName,
            typeName:[
                {
                    name: "学生账号",
                    show: true
                },
                {
                    name: "教师账号",
                    show: false
                },
                {
                    name: "管理员账号",
                    show: false
                },

            ],
            gradeName:["2015级","2016级","2017级","2018级","2019级","2020级","2021级"],
            className:[
                ['一班','二班'],
                ['一班','二班','三班'],
                ['一班','二班','三班','四班'],
                ['一班','二班'],
                ['一班','二班'],
                ['一班','二班','三班'],
                ['一班','二班','三班','四班']
            ],
            studentInformation:[
                {
                    ID:'1',
                    studentID:'20191532424001',
                    name:'徐欣丰',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'18651332100',
                    sex:'男',
                    label:'/',
                    remark:'/'
                },
                {
                    ID:'2',
                    studentID:'20191532424002',
                    name:'李弘辰',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'18651332415',
                    sex:'男',
                    label:'/',
                    remark:'/'
                },
                {
                    ID:'3',
                    studentID:'20191532424003',
                    name:'徐东东',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'18651365214',
                    sex:'男',
                    label:'/',
                    remark:'/'
                },
                {
                    ID:'4',
                    studentID:'20191532421004',
                    name:'门子萱',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'18651331421',
                    sex:'女',
                    label:'/',
                    remark:'/'
                },
                {
                    ID:'5',
                    studentID:'20191532424005',
                    name:'墨司辰',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'13851332355',
                    sex:'男',
                    label:'/',
                    remark:'/'
                },
                {
                    ID:'6',
                    studentID:'20191532424006',
                    name:'功禹',
                    grade:'2019级',
                    classBelong:'一班',
                    phone:'13851365214',
                    sex:'男',
                    label:'/',
                    remark:'/'
                }
            ]
        }
    },
    methods:{
        selectCollege(idx) {
            if(idx !== 0) this.$set(this.collegeName[0],'show',false);
            else {
                for(let i = 1 ;i < this.collegeName.length; i++) {
                    this.$set(this.collegeName[i],'show',false);
                }
            }
            this.$set(this.collegeName[idx],'show',!this.collegeName[idx].show);
        },
        selectType(idx) {
            for(let i = 0 ;i < this. typeName.length; i++) {
                this.$set(this.typeName[i],'show',false);
            }
            this.$set(this.typeName[idx],'show',!this.typeName[idx].show);
        },
        moseOver(idx){
            this.$set(this.showClass,idx,true);
        },
        moseLeave(idx) {
            this.$set(this.showClass,idx,false);
        },
        selectClass(it,item) {
            document.getElementById('a').innerText = this.gradeName[it]+' -----  '+item;
        }
    }
}
</script>

<style scoped>
    #accountManager {
        position: relative;
        font-family: "Times New Roman";
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    .select {
        overflow: auto;
        width: 96%;
        margin: 20px 0 0 2%;
        border-radius: 15px;
        background: rgba(246,248,252,0.7);
    }

    .body {
        overflow: auto;
        width: 96%;
        margin: 20px 0 0 2%;
        background: rgb(253,253,253);
    }
    .head{
        width: 100%;
        height: 45px;
        background: rgba(5,71,172,0.9);
        border-radius: 5px 5px 0 0;
        color: white;
    }
    .head > div{
        float: left;
        font-size: 18px;
        font-weight: 500;
        height: 45px;
        text-align: center;
        line-height: 45px;
    }

    .type {
        position: relative;
        overflow: auto;
        width: 96%;
        padding: 16px 0 0 2%;
    }
    .college {
        position: relative;
        overflow: auto;
        width: 96%;
        margin: 3px 0 0 2%;
    }
    .class {
        /*position: relative;*/
        overflow: auto;
        width: 96%;
        margin: 3px 0 16px 2%;
    }
    .title {
        margin-top: 2px;
        float: left;
        font-size: 18px;
    }
    .f_ul {
        margin-top: -12px;
        width: calc(100% - 100px);
        overflow: auto;
        display: block;
        float: left;
        padding: 0;
        list-style-type: none;
    }
    .f_li {
        transition: 0.1s;
        text-align: -webkit-match-parent;
        float: left;
        margin: 12px 0 0 14px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 100px;
        font-weight: 300;
        color: #0547AC;
    }
    .s_ul {
        margin: 0;
        padding: 0;
        width: 100%;
        list-style-type: none;
    }
    .s_li {
        height: 38px;
        line-height: 38px;
        text-align: center;
        width: 100%;
        font-weight: 400;
    }
    .s_li:hover {
        cursor: pointer;
        background: rgb(247,250,252);
        color: #0547AC;
    }


    li:hover{
        cursor: pointer;
    }
    .notSelected:hover {
        background: rgba(5,71,172,0.1);
        cursor: pointer;
    }

    .more {
        margin-left: 20px;
        margin-top: 21px;
        float: left;
        font-size: 12px;
        font-weight: 100;
        color: #0547AC;
    }
    .more:hover {
        cursor: pointer;
    }
    .more>div {
        float: left;
    }
    .more>img{
        float: left;
        width: 13px;
        margin: 2px 0 0 2px;
    }
    .choose {
        border-radius: 5px;
        background: white;
        border: 1px solid #0547AC;
        margin: 0px 0 0 10px;
        float: left;
        width: 220px;
        height: 28px;
        line-height: 28px;
        font-weight: 100;
        font-size: 14px;
        text-align: center;
    }
    .choose > div{
        text-indent: 47px;
        float: left;
    }
    .choose > img {
        width: 12px;
        float: left;
        margin: 10px 0 0 5px;
    }
    .choose:hover{
        cursor: pointer;
        background: rgba(5,71,172,0.1);;
    }
    .hint {
        z-index: 999;
        height: 266px;
        overflow: auto;
        position: absolute;
        width: 130px;
        background: white;
        margin-top: 30px;
        margin-left: 115px;
        border-radius: 5px;
        box-shadow: 0px 0px 8px #dddddd;
    }
    .hint1 {
        z-index: 999;
        height: 266px;
        border-left: 1px solid #efefef;
        overflow: auto;
        position: absolute;
        width: 130px;
        background: white;
        margin-top: 30px;
        margin-left: 245px;
        border-radius: 5px;
        box-shadow: 3px 0px 8px #efefef;
    }

    .selected {
        background: #0547AC;
        color: white;
    }
    .slide-fade-enter-active,.slide-fade-leave-active{
        transition: all .3s ease;
        max-height: 1000px;
    }
    .slide-fade-enter,.slide-fade-leave-to{
        opacity: 0;
        height: 0px;
    }
    ::-webkit-scrollbar {
        width:5px;
    }

    ::-webkit-scrollbar-track-piece {
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:#dddddd;
        background-clip:padding-box;
        min-height:28px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:#bbb;
    }

</style>
