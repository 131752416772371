<template>
    <div id="addClass">
        <transition name="slide-fade">
            <div id="screen" v-if="ok"></div>
        </transition>
        <transition name="bounce">
            <div id="Pop-ups" v-if="ok">
                <div id="pTitle">
                    <div id="pTitleFont">加入考试 / 课程</div>
                    <img @click="sendOk" id="close" src="../../assets/SideBar/close.svg">
                </div>
                <div id="body">
                    <input ref="groupCode" id="it" placeholder="请在此输入课程/考试码" type="text"/>
                </div>
                <button @click="addClass()">加入</button>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "AddClass",
    props:{
        ok:Boolean
    },
    methods:{
        sendOk() {
            this.$emit('sendOk')
        },
        PopUps(puMsg) {
            this.$toasted.success(puMsg, {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position:'top-right'
            }).goAway(2500);
        },
        addClass() {

            if(this.$refs.groupCode.value == null || this.$refs.groupCode.value === ' '||this.$refs.groupCode.value.match(/^\s+$/) != null) {
                this.PopUps('考试码不能为空');
            }

            this.$axios.post(
                '/apiExam/userJoinInGroup',{
                    groupCode:this.$refs.groupCode.value
                }
            ).then(res => {
                this.PopUps(res.data.msg);
                if (res.data.msg === '加入考试成功') this.sendOk();
            }).catch(err => {
               console.log(err);
            });
        }
    }

}
</script>

<style scoped>
#addClass{}



a {
    color: #000000;
    text-decoration:none;
}

#Pop-ups {
    z-index:251;
    overflow:auto;
    width: 590px;
    height: 150px;
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

#screen {
    z-index:250;
    display: block;
    overflow:auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
#pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}
#pTitleFont {
    font-weight: 100;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}
#close{
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}
#close:hover {
    cursor: pointer;
}

#body {
    margin-top: 25px;
    float: left;
    margin-left: 23px;
}
#body > input {
    text-indent: 12px;
    font-weight: 100;
    font-size: 16px;
    height: 35px;
    width: 380px;
    color: #000000;
    outline: none;
}
button {
    float: left;
    margin-left: 60px;
    width: 70px;
    height: 35px;
    margin-top: 28px;
    border-radius: 10px;
    border: none;
    background: #054FBF;
    color: white;
    font-weight: 100;
    font-size: 15px;
}
button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.bounce-enter-active {
    animation: bounce-in .5s;
}
.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.tooltip .tooltiptext {

    visibility: hidden;
    width: 250px;
    background-color: #efefef;
    color: #666666;
    /*text-align: center;*/
    padding: 10px 3px 10px 11px;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -120px;
    /* position */
    font-size: 16px;
    font-weight: 100;
    position: absolute;
    text-align: left;
    text-indent: 33px;
}

/* hover and show tooltip */
.tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 253;
}
</style>
