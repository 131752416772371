<template>
    <div class="chart">
        <div class="main" ref="main"></div>
    </div>
</template>

<script>
const echarts = require('./echarts.min');
export default {
    name: "Chart",
    data() {
        return {
            myChart:null
        }
    },
    methods:{
        initChart(){
            let chartDom = this.$refs.main;
            this.myChart = echarts.init(chartDom);
            let option;
            option = {
                width:"auto",
                height:"80%",
                title: {
                    text:'最近一年累计考试场次',
                    x:'18px',
                    y:'0',
                    textStyle:{
                        color: '#000000',
                        fontSize: 22
                    }
                },

                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },//鼠标移上的阴影，默认是线
                    formatter: function (params) {

                        return params[0].name + ': ' + params[0].value + '场考试';
                        // 鼠标移上显示的文字
                        //1.span是小蓝点
                        //2.后边是显示的文字，一定要加[0](如果想知道为啥log一下看看数据console.log(params))
                    }
                },


                xAxis: {
                    type: 'category',
                    data: ['4月','5月','6月','7月','8月','9月', '10月', '11月', '12月', '1月', '2月', '3月']
                },
                yAxis: {
                    type: 'value',
                },

                series: [
                    {
                        data: [85,131, 152, 383, 67, 101, 123,58,97,255,378,109,68],
                        type: 'bar',
                    }
                ]
            };

            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
    mounted() {
        this.initChart();
    }
}
</script>

<style scoped>
    .chart{
        width: 100%;
        height: 493px;
        background: white;
        border-radius: 15px;
    }
    .main{
        padding-top: 12px;
        width: 100%;
        height: 98%;
    }
</style>
