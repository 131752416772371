<template>
    <div id="classArea">
        <MainHeader></MainHeader>
        <StudentSideBar ></StudentSideBar>
        <div id="body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import MainHeader from "../../components/Header/MainHeader";
import StudentSideBar from "../../components/SideBar/StudentSideBar";
export default {
    name: "ClassArea",
    components: {StudentSideBar, MainHeader}
}
</script>

<style scoped>
    #classArea {
        width: 100%;
        height: 100%;
    }
    #body {
        width: calc(100% - 233px);
        height: calc(100% - 87px);
        margin-left: 16px;
        margin-top: 16px;
        float: left;
        background: white;
        border-radius: 15px;
    }
</style>
