<template>
    <div id="notice">

        <div id="header">
            <div class="card-title">监考管理</div>
            <div class="card-options" @click="switchTabs(0);">
                <div :style="myData.ok[0] ? 'color: #054FBF;' : ''">监考参数</div>
                <div :style="myData.ok[0] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(1);">
                <div :style="myData.ok[1] ? 'color: #054FBF;' : ''">考场设置</div>
                <div :style="myData.ok[1] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(2);">
                <div :style="myData.ok[2] ? 'color: #054FBF;' : ''">人员分配</div>
                <div :style="myData.ok[2] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
            <div class="card-options" @click="switchTabs(3);">
                <div :style="myData.ok[3] ? 'color: #054FBF;' : ''">批卷设置</div>
                <div :style="myData.ok[3] ? '' : 'opacity: 0;'" class="option-underline"></div>
            </div>
        </div>
        <div id="body" style="overflow: auto">
            <transition name="slide-fade1">
                <div v-show="myData.ok[0]" style="padding: 40px;">
                    <!--监考参数-->
                    <el-form ref="create-form" :model="info" label-width="200px" label-position="left">

                        <el-form-item label="考生登录">
                            <el-checkbox-group v-model="info.invigilate.generalOptions">
                                <el-checkbox label="直接登录" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="小程序双机位登录" name="generalOptions"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="监考手段">
                            <el-checkbox-group v-model="info.invigilate.generalOptions">
                                <el-checkbox label="独占模式" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="视频监考" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="弹窗通知" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="虚拟机检测" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="U盘检测" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="剪切板检测" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="网络环境变化检测" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="屏幕录制" name="generalOptions"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="通用防作弊设置">
                            <el-checkbox-group v-model="info.invigilate.generalOptions">
                                <el-checkbox label="选项乱序" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="同类题目乱序" name="generalOptions"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="浏览器/小程序防作弊设置" v-if="info.invigilate.type !== 'aois'">
                            <el-checkbox-group v-model="info.invigilate.specificOptions.opt">
                                <el-checkbox label="开启摄像头监控" name="specificOptions.opt"></el-checkbox>
                                <br>
                                <el-checkbox v-if="info.invigilate.type === 'browser'" label="开启屏幕监控"
                                             name="specificOptions.opt"></el-checkbox>
                                <br v-if="info.invigilate.type === 'browser'">
                                <el-checkbox label="考生离开页面" name="specificOptions.opt"></el-checkbox>
                                <el-input type="number" style="width: 100px"
                                          v-model="info.invigilate.specificOptions.times"></el-input>
                                <span style="font-size: 14px;color: #606266">次，自动收卷</span>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                    <div>
                        <buttons style="margin-left: auto;" content="保存" btn-color="#054FBF" txt-color="white"
                                 :w="100"
                                 :h="40"></buttons>
                    </div>
                </div>
            </transition>
            <transition name="slide-fade1">
                <div v-show="myData.ok[1]" style="padding: 40px;">
                    <!--考场设置-->
                    <el-form ref="create-form" :model="info" label-width="200px" label-position="left">

                        <el-form-item label="每个考场安排人数" v-if="info.invigilate.type !== 'aois'">
                            <el-checkbox-group v-model="info.invigilate.specificOptions.opt">
                                <el-input type="number" style="width: 100px"
                                          v-model="info.invigilate.specificOptions.times"></el-input>
                                <span style="font-size: 14px;color: #606266">人</span>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="考生座位号安排">
                            <el-checkbox-group v-model="info.invigilate.generalOptions">
                                <el-checkbox label="按学号顺序排列" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="随机排列" name="generalOptions"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="考生登录地验证">
                            <el-checkbox-group v-model="info.invigilate.generalOptions">
                                <el-checkbox label="登录时校验考生所在位置" name="generalOptions"></el-checkbox>
                                <br>
                                <el-checkbox label="登录时不校验考生所在位置" name="generalOptions"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                    <div>
                        <buttons style="margin-left: auto;" content="保存" btn-color="#054FBF" txt-color="white"
                                 :w="100"
                                 :h="40"></buttons>
                    </div>
                </div>
            </transition>
            <transition name="slide-fade1">
                <div v-show="myData.ok[2]">
                    <!--人员分配-->

                    <div class="content">
                        <div class="title">
                            监考教师名单
                        </div>
                        <div class="content_body" style="margin-top: 31px">
                            <table class="table_a">
                                <thead>
                                <td style="width: 25%"></td>
                                <td style="width: 25%">监考教师姓名</td>
                                <td style="width: 25%">监考开始时间</td>
                                <td style="width: 25%">监考结束时间</td>
                                </thead>
                                <tr v-for="(item,idx) in weightsData">
                                    <td class="name">
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{item.author}}
                                    </td>
                                    <td>
                                        {{item.time}}
                                    </td>
                                    <td>
                                        {{ item.rate }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <button class="button_a">保存</button>
                    <button class="button_b">取消</button>


                </div>
            </transition>
            <transition name="slide-fade1">
                <div v-show="myData.ok[3]" style="padding: 40px;">
                    <!--批卷设置-->
                    <el-form ref="create-form" :model="info" label-width="200px" label-position="left">
                        <el-form-item label="机器阅卷" v-if="info.invigilate.type !== 'aois'">
                            <el-checkbox-group v-model="info.invigilate.specificOptions.opt">
                                <el-checkbox label="开启单项选择题判分，选对得分，选错不得分" name="specificOptions.opt"></el-checkbox>
                                <br>
                                <el-checkbox label="开启多项选择题判分，全部选对得分，选对但不全得" name="specificOptions.opt"></el-checkbox>
                                <el-select v-model="info.invigilate.mul" style="width: 220px;">
                                    <el-option label="一半" value="half"></el-option>
                                    <el-option label="不得" value="no"></el-option>
                                </el-select>
                                <span style="font-size: 14px;color: #606266">分，有错选不得分</span>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                    <div>
                        <buttons style="margin-left: auto;" content="保存" btn-color="#054FBF" txt-color="white"
                                 :w="100"
                                 :h="40"></buttons>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Search from "../../../components/common/Search";
import Buttons from "../../../components/Buttons";

export default {
    name: "Invigilation",
    components: {Buttons, Search},
    data: function () {
        return {
            okModal: false, // control the modal

            fakeTitle: '',
            fakeContent: '',

            myData: {
                ok: [true, false, false, false],
            },

            info: {
                basic: {
                    title: '',
                    beginTime: '',
                    endTime: '',
                    durationLimit: 'no-limit',
                    duration: 0,
                    limitSubmit: {
                        begin: 0,
                        end: 0
                    },
                    limitEnter: 0,
                },
                invigilate: {
                    type: 'browser',
                    generalOptions: [],
                    specificOptions: {
                        opt: [],
                        times: 3
                    }
                },
                mul: {
                    type: 'half',
                    generalOptions: [],
                    specificOptions: {
                        opt: [],
                        times: 2
                    }
                },
            },

            weightsData:[
                {
                    name:'监考教师1',
                    author:'方圆圆',
                    time:'2022-06-05 18:00:00',
                    rate:'2022-06-05 20:00:00'
                },
                {
                    name:'监考教师2',
                    author:'李兰辰',
                    time:'2022-06-05 18:00:00',
                    rate:'2022-06-05 20:00:00'
                }
            ],
        }
    },
    methods: {
        switchTabs(index) {
            this.$data.myData.ok.forEach((item, idx) => {
                this.$set(this.$data.myData.ok, idx, false);
            });

            this.$set(this.$data.myData.ok, index, true);
        },

        submit() {
            this.$data.okModal = false;
            this.$data.infromationDataPost.push({
                title: this.$data.fakeTitle,
                from: `李兰辰·${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日 ${new Date().getHours()}:${new Date().getMinutes()}`,
                typee: '作业通知',
                content: '发送成功',
            });
        }
    }
}
</script>

<style scoped>
#notice {
    width: 100%;
    height: 100%;
}

#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}

#sc {
    float: right;
    width: 157px;
    margin-top: 13.5px;
    margin-right: 25px;
}

.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.card-options {
    user-select: none;
    float: left;
    margin-left: 50px;
    margin-top: 20px;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*font-weight: 500;*/
    /*font-size: 16px;*/
}

.card-options:hover {
    cursor: pointer;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}


a {
    color: black;
}

#body {
    position: relative;
    width: 100%;
    min-height: calc(100% - 55px);
    overflow: auto;
}

.infromation {
    overflow: auto;
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #E3E3E3;
}

.infromationhead {
    height: 22px;
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #E3E3E3;
}

.first {
    width: 100%;
    overflow: auto;
    margin-top: 8px;
}

.first > img {
    margin-top: 5px;
    margin-left: 13px;
    float: left;
}

.title_information {
    margin-top: 7px;
    margin-left: 13px;
    height: 23px;
    float: left;
    font-size: 13px;
    font-weight: 100;
    width: 66px;
    text-align: center;
    line-height: 23px;
    border-radius: 20px;
    background: #C01616;
    color: white;
}

.title {
    /*margin-top: 7px;*/
    float: left;
    margin-left: 13px;
    font-size: 25px;
    font-weight: 600;
}

.edit {
    margin-top: 4px;
    float: right;
    height: 22px;
    border-radius: 20px;
    margin-right: 66px;
}

.edit > img {
    height: 100%;
}

.edit:hover {
    cursor: pointer;
}

.delete {
    margin-top: 4px;
    float: right;
    margin-right: 15px;
}

.delete:hover {
    cursor: pointer;
}

.send {
    float: right;
    color: #00A03E;
    margin-top: 3px;
    margin-right: 66px;
    font-size: 16px;
    font-weight: 100;
}

.notSend {
    float: right;
    color: #999999;
    margin-top: 3px;
    margin-right: 66px;
    font-size: 16px;
    font-weight: 100;
}

.button {
    font-weight: 100;
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    background: #054FBF;
    /*margin-top: 10px;*/
    float: right;
    height: 30px;
    width: 98px;
    border-radius: 20px;
    margin-right: 20px;
}

.button:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.button1 {
    font-size: 14px;
    font-weight: 100;
    line-height: 28px;
    text-align: center;
    color: white;
    border-radius: 15px;
    margin-top: 14px;
    margin-right: 18px;
    float: right;
    height: 28px;
    width: 92px;
    background: #054FBF;
}

.button1:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.by {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 100;
    color: #666666;
    margin-left: 46px;
    margin-bottom: 8px;
}

.content {
    text-indent: 32px;
    width: 80%;
    margin-top: 13px;
    font-weight: 100;
    margin-left: 46px;
    margin-bottom: 8px;
}

.isOk {
    font-weight: 800;
    font-size: 14px;
    color: #000000;
    line-height: 30px;
    text-align: center;
    background: #efefef;
    /*margin-top: 10px;*/
    float: right;
    height: 30px;
    width: 98px;
    border-radius: 20px;
    margin-right: 20px;
}

.transition-box {
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.slide-fade1-enter-active {
    transition: all 0.3s ease;
}

.slide-fade1-enter, .slide-fade1-leave-to {
    transform: translateX(-300px);
    opacity: 0;
}


.slide-fade2-enter-active {
    transition: all 0.3s ease;
}

.slide-fade2-enter, .slide-fade2-leave-to {
    transform: translateX(300px);
    opacity: 0;
}

.slide-fade-enter-active {
    transition: opacity 0.3s;
}

.slide-fade-leave-active {
    transition: opacity 0.2s;
}

.slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
}


/*滚动条样式*/
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}


/*modal*/
.Pop-ups {
    z-index: 251;
    overflow: auto;
    width: 590px;
    /*min-height: 310px;*/
    border-radius: 10px;
    background-color: #ffffff;
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen {
    z-index: 250;
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% + 55px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pTitle {
    width: 100%;
    height: 55px;
    border-bottom: #E8EAEC 1px solid;
}

.pTitleFont {
    font-weight: bold;
    height: 100%;
    line-height: 55px;
    float: left;
    margin-left: 23px;
    font-size: 19px;
    font-family: "Microsoft Yahei";
}

.close {
    float: right;
    width: 15px;
    margin-right: 23px;
    margin-top: 20px;
}

.close:hover {
    cursor: pointer;
}

.p-body {
    height: calc(100% - 87px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-foot {
    width: 100%;
    height: 55px;
    border-top: #E8EAEC 1px solid;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.p-body-content {
    margin-left: 23px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-body-input {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    border: #999999 1px solid;
    display: flex;
    justify-content: center;
}

input:focus {
    outline: none;
}

/*modal animation*/
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

>>> .el-checkbox__label {
    color: #606266;
    font-weight: 400;
}
</style>

<style scoped lang="less">
.create {
    padding: 28px 40px;
    overflow-y: scroll;
    height: calc(100% - 56px);
}

.title-bar {
    display: flex;

    .text {
        margin-left: 15px;
    }
}

.content {
    margin: 23px 20px;

    .create-button {
        text-align: right;

        button {
            color: white;
            background: #054FBF;
            border-radius: 10px;
            border: none;
            width: 100px;
            height: 45px;
            font-size: 16px;
            //float: right;
            cursor: pointer;
        }

    }
}

.form-item {
    span {
        color: #606266;
    }
}

.tip {
    color: #606266;
    font-weight: 300;
}




#set {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}
#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}
.content {
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #efefef;
    overflow: auto;
}
.title {
    float: left;
    font-weight: 800;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 1px;
}
.content_body {
    margin-left: 150px;
    font-weight: 100;
    margin-top: 31px;
    float: left;
    overflow: auto;
    margin-bottom: 35px;
}
input {
    width: 28px;
    height: 20px;
    outline: none;
    font-weight: 100;
    font-size: 16px;
}
.a_a {
    float: left;
}
.message{
    margin-left: 66px;
    font-weight: 100;
    font-size: 13px;
    margin-top: 3px;
    float: left;
    color: #666666;
}
.a > button {
    border-radius: 25px;
    border: 1px solid #054FBF;
    background: white;
    color: #054FBF;
    margin-left: 20px;
}
button:hover{
    cursor: pointer;
    background: #054FBF;
    color: white;
}
.a {
    margin-top: 25px;
}
.table_a {
    text-align: center;
    width: 800px;
}
.table_b {
    text-align: center;
    width: 1000px;
}


table {
    letter-spacing:1px;
    border-collapse:collapse;
}
table,th,tr,td{
    border-bottom:1px solid #dedede;/* 表格横线 */
}


thead > td{
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #054FBF;
}

input {
    width: 28px;
    height: 20px;
    outline: none;
    font-weight: 100;
    font-size: 16px;
}
tr > td{
    line-height: 33px;
    height: 33px;
}
.name {
    color:#054FBF;
}
.name:hover {
    cursor: pointer;
}
td > img {
    position: absolute;
    width: 33px;
    margin-top: -17px;
    margin-left: -19px;
}
td > img:hover {
    cursor: pointer;
}
.button_a {
    float: right;
    margin-bottom: 25px;
    margin-right: 50px;
    width: 80px;
    height: 33px;
    border:none;
    background: #054FBF;
    color: white;
    border-radius: 6px;
}
.button_b {
    float: right;
    margin-bottom: 25px;
    margin-right: 50px;
    width: 80px;
    height: 33px;
    border:none;
    background: #C4C4C4;
    color: black;
    border-radius: 6px;
}
.button_a:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}
.button_b:hover {
    cursor: pointer;
    color: black;
    background: #999999;
}
</style>
