<template>
    <div id="questions">
        <div id=main ref="main"></div>
        <div class="mg">
            <div>已批阅：23人</div>
            <div>平均分：7分</div>
            <div>最高分：10分</div>
            <div>最低分：2分</div>
        </div>
    </div>
</template>

<script>
const echarts = require('./echarts.min');
export default {
    name: "SAQuestions",
    data() {
        return {
            myChart:null
        }
    },
    methods:{
        initChart() {

            let chartDom = this.$refs.main;
            console.log(1);
            this.myChart = echarts.init(chartDom);
            let option;
            option = {
                tooltip: {
                    trigger: 'item',
                    axisPointer:{
                        type:'shadow'
                    },
                    formatter:function(params) {
                        // console.log(params)
                        let res="<p>"+params.data.name+": "+params.data.value+"人</p>";
                        return res;
                    }
                },
                legend: {
                    textStyle: {
                        fontSize: 16,
                    },
                    itemHeight: 23,
                    itemWidth:33,
                    orient: 'vertical',
                    left: '76%',  //图例距离左的距离
                    y: 'center',  //图例上下居中
                    data: ["10分","9分","7~8分","3~6分","3分以下"]
                },

                graphic:[
                    {
                        type:"text",
                        left: '20%',
                        top:"36%",
                        style:{
                            text:"学生总人数",
                            textAlign:"center",
                            fill: "#666666",
                            fontSize:28,
                            fontWeight:700
                        }
                    },
                    {
                        type:"text",
                        left: '28%',
                        top:"56%",
                        style:{
                            text:"70",
                            textAlign:"center",
                            fill:"#000000",
                            fontSize:38,
                            fontWeight:700
                        }
                    }
                ],
                series: [
                    {
                        name: '人数',
                        type: 'pie',
                        radius: ['70%', '90%'],
                        center:['32%','50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 1, name: '10分' },
                            { value: 3, name: '9分' },
                            { value: 14, name: '7~8分' },
                            { value: 3, name: '3~6分' },
                            { value: 3, name: '3分以下' },
                        ]
                    }
                ]
            };
            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
    mounted() {
        this.initChart();
    }
}
</script>

<style scoped>
    #questions {
        width: 100%;
        overflow: auto;
    }
    #main {
        float: left;
        width: 580px;
        margin-left: 16%;
        margin-top: 5%;
        height: 380px;
    }
    .mg {
        width: 120px;
        margin-left: calc(30% - 300px);
        margin-top: calc(5% + 100px);
        float: left;
    }
    .mg > div {
        font-weight: 300;
        font-size: 18px;
        margin-top: 15px;
    }
</style>
