<template>
    <div id="examHeader" :style="{'background': flip ? '#054FBF' : 'white'}">
        <div id="logo">
            <div id="name" :style="{'color': flip ? 'white' : 'black'}">{{examName}}</div>
        </div>
        <ul :class="flip ? 'flip' : ''">
<!--            <li><router-link to="/funcCenter">功能中心</router-link></li>-->
            <li><router-link :to="'/exam/problem?examId=' + this.$route.query.examId">题目</router-link></li>
            <li><router-link :to="'/exam/manager?examId=' + this.$route.query.examId">管理</router-link></li>
        </ul>
        <a><img id="user" src="../../assets/Header/user.png"></a>
        <a><img id="mail" src="../../assets/Header/mail.svg"></a>
    </div>
</template>

<script>
export default {
    name: 'ExamHeader',
    data:function () {
      return {
        examName:'',
      }
    },
    props: {
      flip: {
        type: Boolean,
        default: ()=>false
      }
    },
    mounted() {
        this.$axios.get(
            '/apiExam/getExamConfig',{
                params:{
                    examId:this.$route.query.examId
                }
            }
        ).then((resp)=>{
            this.examName = resp.data.data.title;
        }).catch((err)=>{
            console.log(err);
        })
    }
}
</script>

<style scoped lang="less">

    //整个头
    #examHeader {
        width: 100%;
        height: 55px;
        background: white;
        border: none;
        text-align: center;
        line-height: 55px;
        border:0px;
        outline:none;
        box-shadow: 0px 3px 15px #dbdbdb;
        min-width: 740px;
    }

    //logo和图片
    #logo {
        margin-left: 30px;
        margin-right: 13px;
        float: left;
    }

    //logo-名字
    #name {
        font-size: 18px;
        font-weight: 800;
    }

    ul {
        float: left;
        list-style: none;
        display: block;
        margin: 0px;
        padding: 0px;
    }
    li {
        text-align: -webkit-match-parent;
        //width: 60px;
        float: left;
        margin-left: 25px;
    }


    //选中
    .router-link-active {
        color:#054FBF;
    }
    .router-link-active::before,li > a:hover::before{
        content: "";
        width: 100%;
        height: 4px;
        background: #054FBF;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    li > a {
        font-weight: 400;
        font-size: 16px;
        font-family: PingFang SC;
        color: #666666;
        display: block;
        position: relative;
        padding: 0 5px;
        text-decoration:none;
    }

    //邮箱
    #mail {
        height: 18px;
        float: right;
        margin-top: 20px;
        margin-right: 33px;
    }
    #mail:hover{
        cursor: pointer;
    }

    //用户
    #user {
        width: 35px;
        height: 35px;
        margin-top: 10px;
        font-size: 5px;
        margin-right: 38px;
        float: right;
    }
    #user:hover{
        cursor: pointer;
    }

    a:hover{
        color: #054FBF;
        cursor: pointer;
    }

    a.flip:hover {
      color: white;
      cursor: pointer;
    }

    //选中
    ul.flip .router-link-active {
      color:white;
    }
    ul.flip > li > .router-link-active::before,ul.flip > li > a:hover::before{
      content: "";
      width: 100%;
      height: 4px;
      background: white;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    ul.flip > li > a {
      font-weight: 400;
      font-size: 16px;
      font-family: PingFang SC;
      color: white;
      display: block;
      position: relative;
      padding: 0 5px;
      text-decoration:none;
    }
</style>
