<template>
    <div class="alert" @keyup.enter="deleteIt()">
        <div class="body">
            <input ref="input"  type="text" placeholder="确认" @input="judge($event)">删除该账号，
            删除后数据<span style="color: red">无法恢复</span>
        </div>
        <div :class="ok?'ok':'notOk'" @mouseenter="openHint()" @mouseleave="closeHint" @click="deleteIt()">确认
            <div class="hint" v-show="isHint">
                请先在输入框内输入确认
            </div>
        </div>
        <div class="cancel" @click="cancel()">取消</div>
    </div>
</template>

<script>
export default {
    name: "Alert",
    data() {
        return {
            ok:false,
            isHint:false
        }
    },
    methods:{
        judge(event) {
            this.$set(this.$data,'ok',event.target.value === '确认');
        },
        openHint() {
            this.$set(this.$data,'isHint',!this.ok);
        },
        closeHint() {
            this.$set(this.$data,'isHint',false);
        },
        deleteIt() {
            if(this.ok) this.$emit('confirmDelete');
        },
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>

<style scoped>
    .alert {
        z-index: 999;
        position: absolute;
        width: 430px;
        height: 136px;
        padding: 0;
        top: 10px;
        left:calc(50% - 215px);
        background: white;
        border-radius: 5px;
        box-shadow: 0 1px 5px #D9d9d9;
    }
    .body {
        font-weight: 300;
        font-size: 17px;
        margin-top: 30px;
        text-align: center;
    }
    .body > input {
        border: 1px solid black;
        border-radius: 3px;
        outline-style: none;
        width: 45px;
        height: 23px;
        font-size: 16px;
        font-weight: 100;
        text-indent: 7px;
        margin-right: 4px;
        color: black;
    }
    .hint {

        color: black;
        height: 28px;
        text-align: center;
        font-weight: 300;
        font-size: 13px;
        width: 155px;
        position: absolute;
        background: rgba(255,255,255,1);
        border-radius: 3px;
        box-shadow: 0 0 2px #AAAAAA;
        top: 53px;
        left: 103px;
    }

    .notOk {
        float: left;
        border-radius: 5px;
        margin-left: 103px;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 60px;
        background: #DDDDDD;
        font-weight: 300;
        margin-top: 26px;
    }
    .ok {
        float: left;
        border-radius: 5px;
        margin-left: 103px;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 60px;
        background: #0547AC;
        color: white;
        font-weight: 300;
        margin-top: 26px;
    }

    .cancel {
        float: left;
        border-radius: 5px;
        margin-left: 103px;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 60px;
        color: white;
        background: #0547AC;
        font-weight: 300;
        margin-top: 26px;
    }
    .ok:hover,.cancel:hover {
        cursor: pointer;
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
    }
</style>
