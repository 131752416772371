<template>
    <div id="analyze">
        <div class="left">
            <Number></Number>
            <Arrange></Arrange>
        </div>
        <div class="right">
            <Chart></Chart>
            <ExamNow></ExamNow>
        </div>
    </div>
</template>

<script>
import Number from "../../components/Echarts/Number";
import Arrange from "../../components/Echarts/Arrange";
import Chart from "../../components/Echarts/Chart";
import ExamNow from "../../components/Echarts/ExamNow";
export default {
    name: "Analyze",
    components: {ExamNow, Chart, Arrange, Number}
}
</script>

<style scoped>
    #analyze {
        overflow: auto;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    .left {
        width: 538px;
        height: 100%;
        float: left;
    }
    .right{
        margin-left: 15px;
        width: calc(100% - 553px);
        height: 100%;
        float: left;
    }

</style>
