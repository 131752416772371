<template>
    <div id="set">
        <div id="header">
            <div class="card-title">设置</div>
        </div>
        <div id="body">
            <div class="content">
                <div class="title">
                    总体权重占比
                </div>
                <div class="content_body">
                    <div class="a" style="margin-top: 1px">
                        <div class="a_a">
                            平时成绩占比
                            <input type="text" value="50"/> %
                        </div>
                        <div class="message">
                            平时成绩为可以通过教师手动录入或Excel导入
                        </div>
                        <button>导入</button>
                        <button>查看明细</button>
                    </div>
                    <div class="a">
                        <div class="a_a">
                            考试成绩占比
                            <input type="text" value="50"/> %
                        </div>
                        <div class="message">
                            按所有考试的平均分计分，或设置考试明细分配，按【考试成绩*权重占比】之和计分
                        </div>
                        <button>明细分配</button>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="title">
                    考试权重分配
                </div>
                <div class="content_body" style="margin-top: 31px">
                    <table class="table_a">
                        <thead>
                            <td style="width: 25%">考试名称</td>
                            <td style="width: 25%">创建人</td>
                            <td style="width: 25%">创建时间</td>
                            <td style="width: 25%">权重占比</td>
                        </thead>
                        <tr v-for="(item,idx) in weightsData">
                            <td class="name">
                                {{item.name}}
                            </td>
                            <td>
                                {{item.author}}
                            </td>
                            <td>
                                {{item.time}}
                            </td>
                            <td>
                                <input type="text" :value="item.rate"> %
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="content">
                <div class="title">
                    开放课程题库
                </div>
                <div class="content_body" style="margin-top: 31px">
                    <table class="table_a">
                        <thead>
                        <td style="width: 25%">题库名称</td>
                        <td style="width: 25%">创建人</td>
                        <td style="width: 25%">创建时间</td>
                        <td style="width: 25%">开放题库</td>
                        </thead>
                        <tr v-for="(item,idx) in questionBankData">
                            <td class="name">
                                {{item.name}}
                            </td>
                            <td>
                                {{item.author}}
                            </td>
                            <td>
                                {{item.time}}
                            </td>
                            <td>
                                <img @click="item.check=!item.check" v-if="item.check" src="../../assets/Class/open.svg">
                                <img @click="item.check=!item.check" v-if="!item.check" src="../../assets/Class/close.svg">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="content">
                <div class="title">
                    成绩和排名显示
                </div>
                <div class="content_body" style="margin-top: 31px;margin-left: 130px">
                    <table class="table_b">
                        <thead>
                        <td style="width: 200px;">考试名称</td>
                        <td style="width: 200px;">创建人</td>
                        <td style="width: 200px;">创建时间</td>
                        <td style="width: 100px;">显示成绩</td>
                        <td style="width: 150px;">显示本人排名</td>
                        <td style="width: 150px;">显示所有排名</td>
                        </thead>
                        <tr v-for="(item,idx) in examData">
                            <td class="name">
                                {{item.name}}
                            </td>
                            <td>
                                {{item.author}}
                            </td>
                            <td>
                                {{item.time}}
                            </td>
                            <td>
                                <img @click="item.checkGrade=!item.checkGrade" v-if="item.checkGrade" src="../../assets/Class/open.svg">
                                <img @click="item.checkGrade=!item.checkGrade" v-if="!item.checkGrade" src="../../assets/Class/close.svg">
                            </td>
                            <td>
                                <img @click="item.checkRating=!item.checkRating" v-if="item.checkRating" src="../../assets/Class/open.svg">
                                <img @click="item.checkRating=!item.checkRating" v-if="!item.checkRating" src="../../assets/Class/close.svg">
                            </td>
                            <td>
                                <img @click="item.checkAllRating=!item.checkAllRating" v-if="item.checkAllRating" src="../../assets/Class/open.svg">
                                <img @click="item.checkAllRating=!item.checkAllRating" v-if="!item.checkAllRating" src="../../assets/Class/close.svg">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <button class="button_a">保存</button>
            <button class="button_b">取消</button>

        </div>
    </div>

</template>

<script>
export default {
    name: "Set",
    data:function () {
        return {
            weightsData:[
                {
                    name:'期中考试',
                    author:'李兰辰',
                    time:'2022-01-03 09:00:00',
                    rate:'20'
                },
                {
                    name:'期末考试',
                    author:'李兰辰',
                    time:'2022-02-03 09:00:00',
                    rate:'80'
                }
            ],
            questionBankData:[
                {
                    name:'选择题',
                    author:'李兰辰',
                    time:'2022-02-01 11:10:20',
                    check:true
                },
                {
                    name:'填空题',
                    author:'李兰辰',
                    time:'2022-02-01 15:10:20',
                    check:false
                },
                {
                    name:'主观题',
                    author:'李兰辰',
                    time:'2022-02-01 11:10:20',
                    check:false
                }
            ],
            examData:[
                {
                    name:'期中考试',
                    author:'李兰辰',
                    time:'2022-01-03 09:00:00',
                    checkGrade:true,
                    checkRating:true,
                    checkAllRating:false
                },
                {
                    name:'期末考试',
                    author:'李兰辰',
                    time:'2022-02-03 09:00:00',
                    checkGrade:true,
                    checkRating:false,
                    checkAllRating:false
                }
            ]
        }
    }
}
</script>

<style scoped>
#set {
    width: 100%;
    height: 100%;
}
#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}
.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}
#body {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
}
.content {
    width: 92%;
    margin-left: 4%;
    border-bottom: 1px solid #efefef;
    overflow: auto;
}
.title {
    float: left;
    font-weight: 800;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 1px;
}
.content_body {
    margin-left: 150px;
    font-weight: 100;
    margin-top: 31px;
    float: left;
    overflow: auto;
    position: relative;
    margin-bottom: 35px;
}
input {
    width: 28px;
    height: 20px;
    outline: none;
    font-weight: 100;
    font-size: 16px;
}
.a_a {
    float: left;
}
.message{
    margin-left: 66px;
    font-weight: 100;
    font-size: 13px;
    margin-top: 3px;
    float: left;
    color: #666666;
}
.a > button {
    border-radius: 25px;
    border: 1px solid #054FBF;
    background: white;
    color: #054FBF;
    margin-left: 20px;
}
button:hover{
    cursor: pointer;
    background: #054FBF;
    color: white;
}
.a {
    margin-top: 25px;
}
.table_a {
    text-align: center;
    width: 800px;
}
.table_b {
    text-align: center;
    width: 1000px;
}


table {
    letter-spacing:1px;
    border-collapse:collapse;
}
table,th,tr,td{
    border-bottom:1px solid #dedede;/* 表格横线 */
}


thead > td{
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #054FBF;
}

input {
    width: 28px;
    height: 20px;
    outline: none;
    font-weight: 100;
    font-size: 16px;
}
tr > td{
    line-height: 33px;
    height: 33px;
}
.name {
    color:#054FBF;
}
.name:hover {
    cursor: pointer;
}
td > img {
    position: absolute;
    width: 33px;
    margin-top: -17px;
    margin-left: -19px;
}
td > img:hover {
    cursor: pointer;
}
.button_a {
    float: right;
    margin-top: 25px;
    margin-right: 50px;
    width: 80px;
    height: 33px;
    border:none;
    background: #054FBF;
    color: white;
    border-radius: 6px;
}
.button_b {
    float: right;
    margin-top: 25px;
    margin-right: 50px;
    width: 80px;
    height: 33px;
    border:none;
    background: #C4C4C4;
    color: black;
    border-radius: 6px;
}
.button_a:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}
.button_b:hover {
    cursor: pointer;
    color: black;
    background: #999999;
}

</style>
