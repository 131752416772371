<template>
    <div class="s_choose">
        <div class="title">
            <span>{{index}}、</span><span>{{questionObject.title}}</span>
        </div>
        <div class="content">
            <div class="option" v-for="(val,idx) in questionObject.options">
                <el-radio v-model="ans" :label="val.id" @change="$emit('answer',ans)"><span class="option-content">{{String.fromCharCode('A'.charCodeAt(0) + idx)}}. {{val.title}}</span>
                </el-radio>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "SingleChoose",
        data() {
            return {
                ans: ''
            }
        },
        props: {
            index: {
                type: String,
                default: () => '1'
            },
            questionObject: {
                type: Object,
                default: function () {
                    return {};
                }
            },
        },
        model: {
            prop: 'answer',
            event: 'answer'
        },
        created() {
            // this.ans = this.questionObject.answer;
        }
    }
</script>

<style scoped lang="less">
    .s_choose {
        font-size: 18px;

        .title {
            margin: 16px 0 0 0;
        }

        .content {
            margin-left: 40px;

            .option {
                margin: 7px 0;

            }
        }
    }

    .option-content {
        font-size: 16px;
        margin-left: 10px;
        font-weight: 400;
    }


</style>
