<template>
    <div class="create">
        <div class="title-bar">
            <img src="../../../assets/SideBar/exam.svg">
            <div class="text">
                <div style="color: #434343;">考试</div>
                <div style="color: #646464;font-weight: 300">建立单场考试，适合单场次的竞赛、选拔等场景。</div>
            </div>
        </div>
        <div class="content">
            <el-form ref="create-form" :model="info" label-width="200px" label-position="left">
                <el-form-item label="考试标题">
                    <el-input v-model="title"></el-input>
                </el-form-item>
                <el-form-item label="开放时间">
                    <el-date-picker type="datetime" placeholder="选择日期和时间" v-model="startTime"
                                    style="width: 220px;"></el-date-picker>
                </el-form-item>
                <el-form-item label="关闭时间">
                    <el-date-picker type="datetime" placeholder="选择日期和时间" v-model="endTime"
                                    style="width: 220px;"></el-date-picker>
                </el-form-item>
                <el-form-item label="答题时限">
                    <el-col>
                        <el-select v-model="info.basic.durationLimit" style="width: 220px;">
                            <el-option label="从开放到结束均可答题" value="no-limit"></el-option>
                            <el-option label="限时" value="limit"></el-option>
                        </el-select>
                    </el-col>
                    <el-col v-if="info.basic.durationLimit === 'limit'">
                        <el-input type="number" v-model="info.basic.duration" style="width: 100px"></el-input>
                        分钟
                    </el-col>
                </el-form-item>
                <el-form-item class="form-item" label="限时提交">
          <span>考试<strong>开始</strong>后 <el-input type="number" style="width: 100px"
                                                 v-model="info.basic.limitSubmit.begin"></el-input> 分钟内不允许提交；考试<strong>结束</strong>前 <el-input
              type="number" style="width: 100px"
              v-model="info.basic.limitSubmit.end"></el-input> 分钟内不允许提交。（0分钟即不做限制）</span>
                </el-form-item>
                <el-form-item class="form-item" label="限时进入">
          <span>考试<strong>开放</strong> <el-input type="number" style="width: 100px"
                                                v-model="info.basic.limitEnter"></el-input> 分钟后，不允许参加考试。（0分钟即不做限制）</span>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="登陆限制">
                    <span>只允许</span>
                    <el-select v-model="info.invigilate.type" style="width: 220px;">
                        <el-option label="浏览器" value="browser"></el-option>
                        <el-option label="小程序" value="micro"></el-option>
                        <el-option label="AOIS" value="aois"></el-option>
                    </el-select>
                    <span>登录</span>
                    <div class="tip">
                        {{
                            info.invigilate.type === 'aois' ? 'AOIS（傲杰在线监考系统）通过监考客户端程序，全面监控学生使用电脑进行考试时的情况，并在监考模块中反馈给教师。' : '系统会限制多端登录，并对每一次登录进行记录，反馈在监考模块中。'
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="通用防作弊设置">
                    <el-checkbox-group v-model="info.invigilate.generalOptions">
                        <el-checkbox label="选项乱序" name="generalOptions"></el-checkbox>
                        <br>
                        <el-checkbox label="同类题目乱序" name="generalOptions"></el-checkbox>
                        <br>
                        <el-checkbox label="答案防粘贴" name="generalOptions"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="浏览器/小程序防作弊设置" v-if="info.invigilate.type !== 'aois'">
                    <el-checkbox-group v-model="info.invigilate.specificOptions.opt">
                        <el-checkbox label="开启摄像头监控" name="specificOptions.opt"></el-checkbox>
                        <br>
                        <el-checkbox v-if="info.invigilate.type === 'browser'" label="开启屏幕监控"
                                     name="specificOptions.opt"></el-checkbox>
                        <br v-if="info.invigilate.type === 'browser'">
                        <el-checkbox label="考生离开页面" name="specificOptions.opt"></el-checkbox>
                        <el-input type="number" style="width: 100px"
                                  v-model="info.invigilate.specificOptions.times"></el-input>
                        <span style="font-size: 14px;color: #606266">次，自动收卷</span>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div class="create-button">
                <a @click="create()">创建</a>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "CreateExam",
    data() {

        return {
            title: '',
            startTime: '',
            endTime: '',

            info: {
                basic: {
                    title: '',
                    beginTime: '',
                    endTime: '',
                    durationLimit: 'no-limit', // no-limit|limit
                    duration: 0,
                    limitSubmit: {
                        begin: 0,
                        end: 0
                    },
                    limitEnter: 0,
                },
                invigilate: {
                    type: 'browser', // browser|micro|aois
                    generalOptions: [],
                    specificOptions: {
                        opt: [],
                        times: 3
                    }
                },
                stuff: {
                    repeat: {
                        opt: ['允许考生重考'],
                        times: 2,
                        type: 'max' // max|avg|last|first
                    },
                    exam: {
                        opt: ['允许考生考后查看已答试卷'],
                        when: 'close', // close|submitted
                        comment: '',
                        ip: ''
                    },
                    score: {
                        opt: [],
                        type: 'white', // white|black
                    }
                }
            }
        }
    },
    methods: {
        popUps(puMsg) {
            this.$toasted.success(puMsg, {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position: 'top-center'
            }).goAway(2500);
        },
        create() {
            if (this.title === null || this.title === "" || this.title.match(/^\s+$/) != null) {
                this.popUps('标题不能为空!')
                return;
            }
            if (this.startTime === null || this.startTime === "") {
                this.popUps('考试开始时间不能为空!')
                return;
            }

            if (this.startTime === null || this.endTime === "") {
                this.popUps('考试结束时间不能为空!')
                return;
            }


            let examType = this.info.invigilate.type === 'aois' ? "双机位考试" : "单机位考试";
            this.$axios.post(
                '/apiExam/addExam', {
                    "title": this.title,
                    "startTime": this.startTime,
                    "endTime": this.endTime,
                    "type": examType,
                    "config": JSON.stringify(this.info),
                }
            ).then((res) => {
                // this.popUps('考试创建成功!')
                let routerJump = this.$router.resolve({
                    path: "/exam/manager",
                    query: {
                        examId: res.data.data
                    }
                });
                window.open(routerJump.href, "_blank");
                this.$router.go(-1);
            }).catch((err) => {
                console.log("连接失败");
            })
        }
    }
}
</script>

<style scoped lang="less">
.create {
    padding: 28px 40px;
    overflow-y: scroll;
    height: calc(100% - 56px);
}

.title-bar {
    display: flex;

    .text {
        margin-left: 15px;
    }
}

.content {
    margin: 23px 20px;

    .create-button {
        display: flex;
        justify-content: right;

        a {
            text-align: center;
            text-decoration: none;
            display: block;
            color: white;
            background: #054FBF;
            border-radius: 10px;
            border: none;
            width: 100px;
            height: 45px;
            line-height: 43px;
            font-size: 16px;
            //float: right;
            cursor: pointer;
        }

    }
}

.form-item {
    span {
        color: #606266;
    }
}

.tip {
    color: #606266;
    font-weight: 300;
}
</style>

<style scoped>
>>> .el-checkbox__label {
    color: #606266;
    font-weight: 400;
}
</style>
