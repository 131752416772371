<template>
    <div id="examSideBar">
        <div class="choose">
            <ul>
                <li v-for="(item,i) in list.slice(0,5)">
                    <router-link :to="{path:Link[i],query:{examId:id}}" :style="{'border-radius': i === 0 ? '15px 15px 0 0' : (i ===4 ? '0 0 15px 15px':'')}">
                        <img :src="url[i]">
                        <div class="cf">{{item}}</div>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="choose" >
            <ul>
                <li v-for="(item,i) in list.slice(0,5)">
                    <router-link :to="{path:Link[i+5],query:{examId:id}}" :style="{'border-radius': i === 0 ? '15px 15px 0 0' : (i ===4 ? '0 0 15px 15px':'')}">
                        <img :src="url[i+5]">
                        <div class="cf">{{list[i+5]}}</div>
                    </router-link>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>

import set from '../../assets/SideBar/set.svg'
import notice from '../../assets/SideBar/notice.svg'
import mans from '../../assets/SideBar/mans.svg'
import admin from '../../assets/SideBar/admin.svg'
import invigilation from '../../assets/SideBar/invigilation.svg'
import questionbank from '../../assets/SideBar/questionbank.svg'
import testpaper from '../../assets/SideBar/testpaper.svg'
import knowledge from '../../assets/SideBar/knowledge.svg'
import exam from '../../assets/SideBar/exam.svg'
import lookLook from '../../assets/SideBar/lookllook.svg'

export default {
    name: 'ExamSideBar',
    data: function () {
        //这里依次输入侧边栏网站名字
        let Link = [
            './config',
            './target',
            './notice',
            './admin',
            './invigilation',
            './questionBank',
            './groupVolume',
            './knowledge',
            './correct',
            './gradeView'
        ]

        let list = [
            '参数设置',
            '发布对象',
            '通知公告',
            '管理员组',
            '监考管理',
            '关联题库',
            '试卷组卷',
            '知识点',
            '批改试卷',
            '成绩查看'
        ]

        let url = [
            set,
            mans,
            notice,
            admin,
            invigilation,
            questionbank,
            testpaper,
            knowledge,
            exam,
            lookLook
        ]

        return {
            id:this.$route.query.examId,
            Link,
            list,
            url
        }
    }
}
</script>

<style scoped lang="less">
    #examSideBar {
        margin-left: 16px;
        float: left;
        width: 165px;
        height: calc(100% - 55px);
    }

    .choose {
        width: 100%;
        margin-top: 16px;
        color: white;
        border-radius: 15px;
        background: white;
    }

    ul {
        list-style: none;
        display: block;
        margin: 0px;
        padding: 0px;
    }
    li {
        line-height: 39px;
        text-align: center;
        height: 39px;
        width: 100%;
        color: black;
        font-size: 14px;
        font-weight: 100;
        //background: #999999;
    }
    .notSelected:hover{
        background: rgba(5,79,191,0.1);
    }
    a > img {
        margin-left: 35px;
        float: left;
        margin-top: 11px;
        width: 18px;
    }

    .cf{

        float: left;
        margin-left: 18px;
    }
    a {
        width: 100%;
        height: 100%;
        color: black;
        display: block;
        text-decoration:none;
    }


    .router-link-active {
        color: white;
        background: #054FBF;
    }

    a:hover{
        background: rgb(227,236,244);
    }
    .router-link-active:hover{
        color: white;
        background: #054FBF;
    }

    li:hover {
        cursor: pointer;
    }
    .first {
        border-radius: 15px 15px 0 0;
    }
    .end {
        border-radius: 0 0 15px 15px;
    }
</style>
