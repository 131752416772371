<template>
    <div style="padding-left: 40px; padding-right: 40px; padding-top: 10px;">
        <h3>小程序第二机位二维码</h3>
        <div style="width: 300px;">
            <img style="width: 100%;" src="../../../assets/qrc.png" alt="">
        </div>
        <div style="color: #777777; font-weight: bold;">
            当您的小程序掉线时，重新扫描该二维码可重新上线
        </div>

<!--        <div style="display: flex; flex-direction: row; width: 80%;">-->
<!--            <div class="cont" style="display: block; padding-top: 1%; margin-right: 1%; padding-bottom: 50%;">-->
<!--                &lt;!&ndash;content&ndash;&gt;-->
<!--                <div style="color: #cecece; font-weight: bold; margin-left: 5px;">客户端摄像头</div>&lt;!&ndash;init show&ndash;&gt;-->
<!--            </div>-->
<!--            <div style="width: 20px;"></div>-->
<!--            <div class="cont" style="display: block; padding-top: 1%; margin-right: 1%; padding-bottom: 50%;">-->
<!--                &lt;!&ndash;content&ndash;&gt;-->
<!--                <div style="color: #cecece; font-weight: bold; margin-left: 5px;">小程序</div>&lt;!&ndash;init show&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "Info"
}
</script>

<style scoped>
#notice {
    width: 100%;
    height: 100%;
}

#header {
    top: 0;
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e9e9e9;
}

.card-title {
    user-select: none;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.card-options {
    user-select: none;
    float: left;
    margin-left: 50px;
    margin-top: 20px;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*font-weight: 500;*/
    /*font-size: 16px;*/
}

.card-options:hover {
    cursor: pointer;
}

.option-underline {
    height: 4px;
    width: 110%;
    background-color: #054FBF;
    margin-top: 10px;
}


a {
    color: black;
}

#body {
    position: relative;
    width: 100%;
    min-height: calc(100% - 55px);
    overflow: auto;
}

.msg-banner {
    width: 50%;
    min-height: 55px;
    background-color: #F5F5F5;
    border-radius: 15px;

    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.dock {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.dock-ico {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cont {
    width: 100%;
    padding-top: 25%;
    padding-bottom: 25%;
    border: #cecece 4px dotted;
    border-radius: 14px;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cont::-webkit-scrollbar {
    display: none;
}


#viewAndSet {
    /*无法复制*/
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */

    width: 100%;
    height: 100%;
}

.title {
    font-size: 20px;
    width: 100%;
    line-height: 45px;
    text-align: center;
    font-weight: 100;
    height: 45px;
    border-bottom: 1px solid #D9D9D9;
}

.info {
    width: 100%;
    height: 307px;
    border-bottom: 1px solid #D9D9D9;
}

.info > img {
    float: left;
    height: 260px;
    border-radius: 5px;
    margin-top: 24px;
    margin-left: 15%;
}

.studentinfo {
    color: #999999;
    float: left;
    margin-left: 15%;
    overflow: auto;
    margin-top: 29px;
    font-size: 19px;
}

.studentinfo > div {
    float: left;
    width: 200px;
}

button {
    float: left;
    background: #054FBF;
    color: white;
    border: none;
    border-radius: 15px;
    font-weight: 100;
    font-size: 12px;
    height: 22px;
    width: 70px;
}

button:hover {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
    cursor: pointer;
}

@media screen and (max-width: 1510px) {
    .info > img {
        margin-left: 12%;
    }

    .studentinfo {
        margin-left: 12%;
    }
}

@media screen and (max-width: 1408px) {
    .info > img {
        margin-left: 8%;
    }

    .studentinfo {
        margin-left: 8%;
    }
}

@media screen and (max-width: 1296px) {
    .info > img {
        margin-left: 4%;
    }

    .studentinfo {
        margin-left: 4%;
    }
}

@media screen and (max-width: 1212px) {
    .info > img {
        margin-left: 3%;
    }

    .studentinfo {
        margin-left: 3%;
    }
}

@media screen and (max-width: 1200px) {
    .info > img {
        float: left;
        height: 120px;
        border-radius: 5px;
        margin-top: 24px;
        margin-left: 8%;
    }

    .studentinfo {
        float: left;
        margin-left: 8%;
        overflow: auto;
        margin-top: 29px;
        font-size: 16px;
    }

    .studentinfo > div {
        width: 140px;
    }
}

@media screen and (max-width: 997px) {
    .info > img {
        float: left;
        height: 120px;
        border-radius: 5px;
        margin-top: 24px;
        margin-left: 3%;
    }

    .studentinfo {
        float: left;
        margin-left: 3%;
        overflow: auto;
        margin-top: 29px;
        font-size: 16px;
    }

    .studentinfo > div {
        width: 130px;
    }
}

@media screen and (max-width: 926px) {
    .info > img {
        float: left;
        height: 120px;
        border-radius: 5px;
        margin-top: 10px;
        margin-left: calc(50% - 49px);
    }

    .studentinfo {
        float: left;
        margin-left: 10px;
        overflow: auto;
        margin-top: 12px;
        font-size: 16px;
    }

    .studentinfo > div {
        width: 130px;
    }
}

/*滚动条样式*/
.scroll::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track-piece {
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}
</style>
