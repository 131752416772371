<template>
  <div class="config">
    <div class="title-bar">
      <div class="card-title">参数设置</div>
      <div class="save">
        <div class="btn cancel"><a>取消</a></div>
        <div class="btn confirm" @click="confirm()"><a>保存</a></div>
      </div>
    </div>

    <div class="content">
      <el-form ref="create-form" :model="info" label-width="200px" label-position="left">
        <el-form-item label="考试标题">
          <el-input v-model="title"></el-input>
        </el-form-item>
        <el-form-item label="开放时间">
          <el-date-picker type="datetime" placeholder="选择日期和时间" v-model="startTime" style="width: 220px;"></el-date-picker>
        </el-form-item>
        <el-form-item label="关闭时间">
          <el-date-picker type="datetime" placeholder="选择日期和时间" v-model="endTime" style="width: 220px;"></el-date-picker>
        </el-form-item>
        <el-form-item label="答题时限">
          <el-col>
            <el-select v-model="info.basic.durationLimit" style="width: 220px;">
              <el-option label="从开放到结束均可答题" value="no-limit"></el-option>
              <el-option label="限时" value="limit"></el-option>
            </el-select>
          </el-col>
          <el-col  v-if="info.basic.durationLimit === 'limit'">
            <el-input type="number" v-model="info.basic.duration" style="width: 100px"></el-input> 分钟
          </el-col>
        </el-form-item>
        <el-form-item class="form-item" label="限时提交">
          <span>考试<strong>开始</strong>后 <el-input type="number" style="width: 100px" v-model="info.basic.limitSubmit.begin"></el-input> 分钟内不允许提交；考试<strong>结束</strong>前 <el-input type="number" style="width: 100px" v-model="info.basic.limitSubmit.end"></el-input> 分钟内不允许提交。（0分钟即不做限制）</span>
        </el-form-item>
        <el-form-item class="form-item" label="限时进入">
          <span>考试<strong>开放</strong> <el-input type="number" style="width: 100px" v-model="info.basic.limitEnter"></el-input> 分钟后，不允许参加考试。（0分钟即不做限制）</span>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item class="form-item" label="登陆限制">
          <span>只允许</span><el-select v-model="info.invigilate.type" style="width: 220px;">
          <el-option label="浏览器" value="browser"></el-option>
          <el-option label="小程序" value="micro"></el-option>
          <el-option label="AOIS" value="aois"></el-option>
        </el-select><span>登录</span>
          <div class="tip">{{info.invigilate.type === 'aois' ? 'AOIS（傲杰在线监考系统）通过监考客户端程序，全面监控学生使用电脑进行考试时的情况，并在监考模块中反馈给教师。' : '系统会限制多端登录，并对每一次登录进行记录，反馈在监考模块中。'}}</div>
        </el-form-item>
        <el-form-item label="通用防作弊设置">
          <el-checkbox-group v-model="info.invigilate.generalOptions">
            <el-checkbox label="选项乱序" name="generalOptions"></el-checkbox><br>
            <el-checkbox label="同类题目乱序" name="generalOptions"></el-checkbox><br>
            <el-checkbox label="答案防粘贴" name="generalOptions"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="浏览器/小程序防作弊设置" v-if="info.invigilate.type !== 'aois'">
          <el-checkbox-group v-model="info.invigilate.specificOptions.opt">
            <el-checkbox label="开启摄像头监控" name="specificOptions.opt"></el-checkbox><br>
            <el-checkbox v-if="info.invigilate.type === 'browser'" label="开启屏幕监控" name="specificOptions.opt"></el-checkbox><br v-if="info.invigilate.type === 'browser'">
            <el-checkbox label="考生离开页面" name="specificOptions.opt"></el-checkbox> <el-input type="number" style="width: 100px" v-model="info.invigilate.specificOptions.times"></el-input> <span style="font-size: 14px;color: #606266">次，自动收卷</span>
          </el-checkbox-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="重考设置">
          <el-checkbox-group v-model="info.stuff.repeat.opt">
            <el-checkbox label="允许考生重考" name="repeat.opt" style="margin-right: 0" value="repeat"></el-checkbox> <el-input type="number" style="width: 100px" v-model="info.stuff.repeat.times"></el-input> <span style="font-size: 14px;color: #606266">次，成绩计算方式将按</span>
            <el-select v-model="info.stuff.repeat.type" style="width: 220px;">
              <el-option label="成绩最高的一次" value="max"></el-option>
              <el-option label="平均成绩" value="avg"></el-option>
              <el-option label="第一次考试成绩" value="first"></el-option>
              <el-option label="最后一次考试成绩" value="last"></el-option>
            </el-select> <span style="font-size: 14px;color: #606266">作为最终成绩</span><br>
            <el-checkbox label="重考保留前一次作答纪录" name="repeat.opt"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="考试设置">
          <el-checkbox-group v-model="info.stuff.exam.opt">
            <el-checkbox label="允许考生考后查看已答试卷" name="exam.opt" value="paper"></el-checkbox><br>
            <el-checkbox label="允许考生" name="exam.opt" value="answer" style="margin-right: 0"></el-checkbox>
            <el-select v-model="info.stuff.exam.when" style="width: 220px;">
              <el-option label="考试关闭" value="close"></el-option>
              <el-option label="交卷后" value="submitted"></el-option>
            </el-select>
            <span style="font-size: 14px;color: #606266">查看答案</span><br>
            <el-checkbox label="考试完显示自定义评语" name="exam.opt" value="comment"></el-checkbox><br>
            <el-input type="textarea"  v-model="info.stuff.exam.comment"></el-input>
            <el-checkbox label="开启讨论板" name="exam.opt" value="board"></el-checkbox><br>
            <div class="tip">开启此选项后，允许考生在讨论版里单独和监考老师进行交流提问。</div>
            <el-checkbox label="公开讨论板" name="exam.opt" value="public" :disabled="!has('开启讨论板',info.stuff.exam.opt)"></el-checkbox><br>
            <div class="tip">开启此选项后，允许考生在讨论版里提问，所有考生包括监考老师，都能在其中回答。</div>
            <el-checkbox label="限制ip参加考试" name="exam.opt" value="ip"></el-checkbox><el-input type="text" style="width: 100%" v-model="info.stuff.exam.ip"></el-input>
            <div class="tip">只允许固定的ip登录系统参加考试，如果有多个，请使用英文分号;进行分割。</div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="成绩设置">
          <el-checkbox-group v-model="info.stuff.score.opt">
            <el-checkbox label="批改完自动向考生发布分数" name="score.opt" value="see"></el-checkbox><br>
            <el-checkbox label="分数公布后允许考生查看排行榜" name="score.opt" value="rank"></el-checkbox><br>
            <el-checkbox label="实时排行榜" name="score.opt" value="real-time-rank"></el-checkbox>
            <el-select v-model="info.stuff.score.type" style="width: 220px;">
              <el-option label="白盒制" value="white"></el-option>
              <el-option label="黑盒制" value="black"></el-option>
            </el-select>
          </el-checkbox-group>
          <div class="tip">{{info.stuff.score.type === 'white' ? '白盒制采用IOI赛制，适合程序设计等竞赛使用。' : '黑盒制采用ICPC赛制，适合程序设计等竞赛使用。'}}</div>
        </el-form-item>
      </el-form>
    </div>


  </div>
</template>

<script>
export default {
  name: "ConfigSetting",
  methods: {
    has(val,arr) {
      for (let i in arr) {
        if (arr[i] === val) return true;
      }
      return false
    },
    confirm() {
        localStorage.setItem('examName',this.title);
        let examType = this.info.invigilate.type === 'aois' ? "双机位考试":"单机位考试";
        this.$axios({
            method:'POST',
            url:'/apiExam/updateExamConfig',
            data:{
                "id":this.$route.query.examId,
                "title":this.title,
                "startTime":this.startTime,
                "endTime":this.endTime,
                "type":examType,
                "config": JSON.stringify(this.info)
            }
        }).then((res)=>{
            this.$toasted.success('保存成功！', {
                iconPack: 'custom-class',
                icon: 'fa fa-check-circle-o',
                theme: 'bubble',
                position:'top-center'
            }).goAway(2500);
        }).catch((err)=>{
            console.log("连接失败");
        })
    }
  },
  data:function () {
      let title;
      let startTime;
      let endTime;
      return {
          title,
          startTime,
          endTime,
          info: {
              basic: {
                  title: '',
                  beginTime: '',
                  endTime: '',
                  durationLimit: 'no-limit', // no-limit|limit
                  duration: 0,
                  limitSubmit: {
                      begin: 0,
                      end: 0
                  },
                  limitEnter: 0,
              },
              invigilate: {
                  type: 'browser', // browser|micro|aois
                  generalOptions: [],
                  specificOptions: {
                      opt: [],
                      times: 3
                  }
              },
              stuff: {
                  repeat: {
                      opt: ['允许考生重考'],
                      times: 2,
                      type: 'max' // max|avg|last|first
                  },
                  exam: {
                      opt: ['允许考生考后查看已答试卷'],
                      when: 'close', // close|submitted
                      comment: '',
                      ip: ''
                  },
                  score: {
                      opt: [],
                      type: 'white', // white|black
                  }
              }
          }
      }
  },
  mounted() {
      this.$axios.get(
          '/apiExam/getExamConfig',
          {
              params:{
                  examId: this.$route.query.examId
              }
          }
      ).then((resp)=>{
          this.title = resp.data.data.title;
          this.startTime = resp.data.data.startTime;
          this.endTime = resp.data.data.endTime;
          this.info = JSON.parse(resp.data.data.config);
      }).catch((err)=>{
          console.log(err);
      })
  }
}
</script>

<style scoped lang="less">
.config {

  overflow-y: scroll;
  height: calc(100%);
  display: flex;
  flex-direction: column;
}

.title-bar {
  display: flex;
}

.card-title {
  user-select: none;
  float: left;
  margin-top: 15px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 1.5em;
}

.save {
  flex: 1;
  display: flex;
  padding: 10px;
  justify-content: right;

  .btn {
    a {
      text-align: center;
      text-decoration: none;
      display: block;

      border-radius: 10px;
      border: none;
      width: 100px;
      height: 45px;
      line-height: 43px;
      font-size: 16px;
      //float: right;
      cursor: pointer;
    }
  }

  .confirm {
    a {
      color: white;
      background: #054FBF;
    }
    margin: 0 20px;
  }

  .cancel {
    a {
      background: #D5D5D5;
    }
  }

  .cancel {

  }
}





.content {
  padding: 50px 60px 0 60px;
  overflow-y: scroll;
}

.form-item {
  span {
    color: #606266;
  }
}

.tip {
  color: #606266;
  font-weight: 300;
}

.el-checkbox-group {
  .tip {
    font-size: 14px;
    line-height: 15px;
    color: #606266;
    font-weight: 300;
  }
}
</style>

<style scoped>
>>>.el-checkbox__label {
  color: #606266;
  font-weight: 400;
}
</style>
